import React from 'react';
import {useLocation} from "react-router-dom";

function EditAssignmnent (){
    const search = useLocation().search;
    const qs = new URLSearchParams(search).get('qs');

    return(
       <div>
           <p>{qs}</p>
       </div>
    );
}
export default EditAssignmnent;