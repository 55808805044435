import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../../Layout/Layout';
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import DataTable from 'react-data-table-component';
import { SavedSearchService } from '../../../Services/SavedSearchService';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { AuthService } from '../../../Services/AuthService';
function SavedSearch() {
    const [searchListData, setsearchListData] = useState([]);
    const [page, setPage] = useState(0);
    const [openLoader, setopenLoader] = React.useState(false);
    const countPerPage = 10;
    const { register, setValue, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [renameId, setrenameId] = useState(0);
    const columns = [

        {
            name: 'Name',
            selector: row => `${row.name}`,
            sortable: true,
        },
        {
            selector: row => (
                <button className='btn btn-sm btn-primary' onClick={() => renameSearch(row.clientSearchRuleID)}>
                    Rename Search
                </button>
            )
        },
        {
            selector: row => (
                <button className='btn btn-sm btn-info' onClick={() => editSearch(row.clientSearchRuleID)}>
                    Edit Search
                </button>
            )
        },
        {
            selector: row => (
                <button className='btn btn-sm btn-warning ml-1' onClick={() => runSeacrh(row.clientSearchRuleID)}>
                    Run Search
                </button>
            )
        },
        {
            selector: row => (
                <button className='btn btn-sm btn-danger ml-1' onClick={() => deleteSeacrh(row.clientSearchRuleID)}>
                    Delete Search
                </button>
            )
        }
    ];
    const runSeacrh = (id) => {
        const parms = btoa(JSON.stringify(id));
        navigate ({
            pathname: '/advancedSearchResult',
            search: '?qs=' + parms,
        })
      
    }
    const editSearch = (id) => {
        const parms = btoa(JSON.stringify(id));
        navigate ({
            pathname: '/advanceSearch',
            search: '?qs=' + parms,
        })
    }
    const handleSaveRenameSearch = async (formData) => {
        setopenLoader(true);
        const renameData = {
            ClientSearchRuleID: renameId,
            SearchName: formData.searchName,
            userID: parseInt(AuthService.getCurrentUser().userId)
        }
        const res = await SavedSearchService.renameSavedSearches(renameData);
       
        if (res.isSuccess) {
            handleClose();
            ShowAlert(1, res.message);
            initSavedSearch();
        } else {
            ShowAlert(0, res.message);
            handleClose();
        }
        setopenLoader(false);
    }
    const renameSearch = (id) => {
        setrenameId(id);
        handleShow();
    }
  
    const deleteSeacrh = async (id) => {
        setopenLoader(true);
        const res = await SavedSearchService.deleteSavedSearches(id);
        if (res.isSuccess) {
            ShowAlert(1, res.message);
            initSavedSearch();
            setopenLoader(false);
        } else {
            ShowAlert(0, res.message);
        }
        setopenLoader(false);
    }
    const initSavedSearch = async () => {
        setopenLoader(true);
        const searchList = await SavedSearchService.getSavedSearches(page);
        setsearchListData(searchList);
        setopenLoader(false);
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    useEffect(() => {
        initSavedSearch();
    }, [page]);
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "5rem" }}
            >
                <Modal.Header>

                    <Modal.Title>
                        <span className='text-primary'> Save Search</span>
                    </Modal.Title>
                    <button onClick={handleClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form method='POST' onSubmit={handleSubmit(handleSaveRenameSearch)}>
                        <input type="hidden" name='ID' {...register("ID")} />
                        <div className='row mb-2'>
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Search Name</label>
                            </div>
                            <div className='col-7 mt-1'>
                                {
                                    <input type="text" name='searchName' className="form-control"
                                        {...register("searchName", {
                                            required: true
                                        })}
                                    />
                                }

                                {errors.searchName?.type === 'required' && <p className='text-danger mt-1'>Please enter search name</p>}
                            </div>

                        </div>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                            </Button>
                            <Button variant="primary" type='submit'><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save</Button>
                        </Modal.Footer>
                    </form>


                </Modal.Body>

            </Modal>
            <Layout>
                <DataTable
                    columns={columns}
                    data={searchListData.data}
                    highlightOnHover
                    pagination
                    paginationServer
                    paginationTotalRows={searchListData.recordsTotal}
                    paginationPerPage={countPerPage}
                    paginationComponentOptions={{
                        noRowsPerPage: true
                    }}
                    onChangePage={page => setPage(page)}
                />
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Layout>
        </div>
    )
}
export default SavedSearch;