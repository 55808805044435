import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
import axios from 'axios';
const baseUrl = DomainService.GetBaseUrl() + "CandidateResumeDetail/";
const getResumeDetailsBycandidateID = async (canResumeID, canID,assignmentID,jobpostingID) => {
    const candidateData={
        CandidateResumeID:parseInt(canResumeID),
        CandidateID:parseInt(canID),
        RoleID:parseInt(AuthService.getCurrentUser().roleId),
        UserTypeID:1,
        ReferenceID:parseInt(AuthService.getCurrentUser().referenceID),
    }
    
    const res = await fetch(baseUrl + "GetCandidateResumeDetails", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(candidateData)
    });

    const data = await res.json();
    return data;
}
const saveCommentWithDocument = async (formdata) => {
    try {
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        };
        const res = await axios.post(baseUrl + "SaveCommentWithDocument", formdata, config).then((response) => {
            console.log(response);
            return response;
        });
       return res;
    } catch (error) {
        console.log(error);
    }
}
const saveCommentWithoutDocument = async (comments,candidateID) => {
    const candidateData={
        Comments:comments,
        CandidateID:parseInt(candidateID),
        UserID:parseInt(AuthService.getCurrentUser().userId),
    }
    const res = await fetch(baseUrl + "SaveCommentWithoutDocument", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(candidateData)
    });
    const data = await res.json();
    return data;
}
const getCandidateFeed = async (candidateID) => {
    const res = await fetch(baseUrl + "GetCandidateFeed?CandidateID="+parseInt(candidateID)+"&UserID="+parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getCommentWithDoc = async (candidateID,startIndex,pageSize) => {
    const res = await fetch(baseUrl + "GetCommentWithDoc?CandidateID="+parseInt(candidateID)+"&startIndex="+startIndex+"&pageSize="+pageSize+"&UserID="+parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getCommentWithoutDoc = async (candidateID,startIndex,pageSize) => {
    const res = await fetch(baseUrl + "GetCommentWithoutDoc?CandidateID="+parseInt(candidateID)+"&startIndex="+startIndex+"&pageSize="+pageSize+"&UserID="+parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getCandidateDoc = async (candidateID) => {
    const res = await fetch(baseUrl + "GetCandidateDoc?candidateID="+parseInt(candidateID)+"&UserID="+parseInt(AuthService.getCurrentUser().userId)+0, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
export const CandidateResumeDetailService={
    getResumeDetailsBycandidateID,
    saveCommentWithoutDocument,
    saveCommentWithDocument,
    getCandidateFeed,
    getCommentWithDoc,
    getCommentWithoutDoc,
    getCandidateDoc
}