import React, { useState, useEffect } from 'react';
import { AssignmentService } from '../../../Services/AssignmentService';
import ReactPaginate from 'react-paginate';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import { AuthService } from '../../../Services/AuthService';
import Layout from '../../../Layout/Layout';
import { useNavigate } from 'react-router-dom';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

function Client() {
    const navigate = useNavigate();
    const [pageCount, setpageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(10);
    const [openLoader, setopenLoader] = useState(false);
    const [searchText, setsearchText] = useState("");
    const itemsPerPage = 10;
    const [startIndex, setstartIndex] = useState(0);
    const [clientListData, setclientListData] = useState([]);
    const [clientRecords, setclientRecords] = useState(0);
    const [assignmentList, setassignmentList] = useState([]);
    const [headingTitle, setheadingTitle] = useState("Client List");
    const [isShowBackBtn, setisShowBackBtn] = useState(false);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % clientRecords;
        setstartIndex(event.selected);
        setItemOffset(newOffset);
    }
    const handleClientClick = async (encClientID) => {
        setopenLoader(true);
        const apiRes = await AssignmentService.getAssignmentListByClientID(encClientID);
        if (apiRes.apiData) {
            setassignmentList(JSON.parse(apiRes.apiData));
        }
        setheadingTitle("Assignment List");
        setpageCount(0);
        setisShowBackBtn(true);
        setopenLoader(false);
    }
    const handleAssignmentViewClick = (assignmentID) => {
        // navigate({
        //     pathname: "/projectdetails",
        //     search: "?qs=" + assignmentID
        // })
        navigate("jobPostingDetails", { state: { assignID : assignmentID ,jpID: "" ,isCallJobList:true } });
    }
    const handleSearchChange = (value) => {
        if (value !== null && value !== "" && value !== undefined) {
            setsearchText(value);
        } else {
            setsearchText("");
        }
    }
    const initClientList = async (IsReset) => {
        if (IsReset) {
            setheadingTitle("Client List");
            setisShowBackBtn(false);
        }
        setopenLoader(true);
        const apiRes = await AssignmentService.getClientListByUserID(startIndex + 1, searchText);
        if (apiRes.apiData) {
            setclientListData(JSON.parse(apiRes.apiData));
            setclientRecords(apiRes.recordCount);
            setpageCount(apiRes.recordCount / 10);
        }
        setopenLoader(false);
    }
    useEffect(() => {
        if (AuthService.isAuthenticatedUser()) {
            initClientList();
        } else {

        }
    }, [startIndex, searchText])

    return (
        <Layout>
            <div className='' style={{ marginTop: "6rem" }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className='row'>
                    <div className='col-md-2'></div>
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-8'>
                                {
                                    isShowBackBtn ? null : <input type="text"
                                        style={{ marginLeft: "13%" }}
                                        placeholder="Type here to search"
                                        onChange={(e) => handleSearchChange(e.target.value)}
                                        className="form-control" />
                                }
                            </div>
                            <div className='col-md-4'>
                                {
                                    isShowBackBtn ? null : <button type='button' title='clear search' className='btn btn-warning'
                                        onClick={() => setsearchText("")}
                                    >X
                                    </button>
                                }
                            </div>


                        </div>
                    </div>
                    <div className='col-md-3'>
                        <h6 className='text-left text-primary' style={{ marginLeft: "-14%" }} >{headingTitle}</h6>
                    </div>
                    <div className='col-md-3'></div>
                </div>


                <div className='row mt-3 mb-3'>
                    <div className='col-md-2'></div>
                    <div className='col-md-7'>
                        <div className=''>
                            <ul>
                                {
                                    isShowBackBtn ?

                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className='font-weight-bold'>Assignment List</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {assignmentList.map((row, i) => (
                                                        <TableRow

                                                            onClick={() => { handleClientClick(row.EncClientID) }}
                                                            style={{ cursor: "pointer" }}
                                                            key={row.ClientName}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell key={i} component="th" scope="row"
                                                                onClick={() => handleAssignmentViewClick(row.EncAssignmentID)}
                                                            >
                                                                {row.AssignmentName}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        :
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className='font-weight-bold'>Client Name</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {clientListData.map((row, i) => (
                                                        <TableRow

                                                            onClick={() => { handleClientClick(row.EncClientID) }}
                                                            style={{ cursor: "pointer" }}
                                                            key={row.ClientName}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell key={i} component="th" scope="row">
                                                                {row.ClientName}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>


                                }
                                {
                                    isShowBackBtn ?
                                        <li style={{ listStyle: "none", marginLeft: "-5%", cursor: "pointer" }}>
                                            <i className="fa fa-arrow-left" onClick={() => initClientList(true)}  ></i></li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'></div>

                    <div className='col-md-1'></div>
                    <div className='col-md-9'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                    <div className='col-md-2'>
                        {
                            isShowBackBtn ? null : <span
                                style={{ boxShadow: " 0px 3px 2px 1px #dde3e3" ,marginLeft:"-67%"}}
                                className='text-primary'>
                                 Record(s) Total: &nbsp;{clientRecords}
                            </span>
                        }

                    </div>
                </div>

            </div>
        </Layout>
    );
}
export default Client;