import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { CommonDropDownService } from '../../../Services/CommonDropDownService';
import { AdvancesearchResultService } from '../../../Services/AdvancesearchResultService';
function ResumeMoveToFolder(props) {
    const [myFolders, setmyFolders] = useState([]);
    const [assignments, setAssignments] = useState([]);
    const [jobPostingList, setjobPostingList] = useState([]);
    const [tabvalue, settabValue] = React.useState('one');
    const [folderName, setfolderName] = useState("");
    const [jobPostingfolderName, setjobPostingfolderName] = useState("");
    const [openLoader, setopenLoader] = React.useState(false);
    const handleFolderCancel = () => {
        setfolderName("");
        setjobPostingfolderName("");
    }
    const handleResumeMove = async () => {
        let folders = document.getElementById("folderSelect");
        let folderid = folders.options[folders.selectedIndex].value;
        let foldertext = folders.options[folders.selectedIndex].text;
        const res = await AdvancesearchResultService.resumeMoveToFolder(folderid, props.CandidateResumeID);
        console.log(res);
        if (res.isSuccess) {
            ShowAlert(1, res.message + foldertext);
        } else {
            ShowAlert(0, res.message);
        }
    }
    const handlePersonalFolderChange = (e) => {
        if (e.target.value) {
            setfolderName(e.target.value);
        } else {
            setfolderName("");
        }
    }
    const handlePersonalFolderClick = async () => {
        const res = await AdvancesearchResultService.createNewFolder(0, folderName);
        console.log(res);
        if (res.isSuccess) {
            ShowAlert(1, res.message);
            initFolderlist();
            setfolderName("");
        } else {
            ShowAlert(0, res.message);
        }
    }
    const handleJobPostingFolderChange = (e) => {
        if (e.target.value) {
            setjobPostingfolderName(e.target.value);
        } else {
            setjobPostingfolderName("");
        }
    }
    const handleJobPostingFolderClick = async () => {
        let assignment = document.getElementById("assignmentid");
        let assignmentid = assignment.options[assignment.selectedIndex].value;
        let assignmenttext = assignment.options[assignment.selectedIndex].text;

        let jobpostings = document.getElementById("jobpostings");
        let jobpostingid = jobpostings.options[jobpostings.selectedIndex].value;
        let jobpostingstext = jobpostings.options[jobpostings.selectedIndex].text;
        const folderid = 0;
        const res = await AdvancesearchResultService.createNewJobPostingFolder(folderid, jobPostingfolderName, jobpostingid);
        if (res.isSuccess) {
            ShowAlert(1, res.message);
        } else {
            ShowAlert(0, res.message);
        }
    }
    const handleChange = (event, newValue) => {
        settabValue(newValue);
    };
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const handleAssignmentChange = async () => {
        let assignment = document.getElementById("assignmentid");
        let assignmentid = assignment.options[assignment.selectedIndex].value;
        let assignmenttext = assignment.options[assignment.selectedIndex].text;
        if (assignmentid > 0) {
            const res = await CommonDropDownService.getJobPostingsById(assignmentid);
            res ? setjobPostingList(res) : setjobPostingList([]);
        } else {
            setjobPostingList([]);
        }
    }
    const handlejobPostingChange = async () => {


    }
    const initAssignmentlist = async () => {
        const res = await CommonDropDownService.getAssignments();
        setAssignments(res);
    }
    const initFolderlist = async () => {
        const res = await CommonDropDownService.getFolders();
        setmyFolders(res);
    }

    useEffect(() => {
        console.log(props);
        initAssignmentlist();
        initFolderlist();
    }, []);
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className='row mb-2'>
                <div className='col-3 mt-1'>
                    <label className='control-label'>Select folder</label>
                </div>
                <div className='col-5 mt-1'>
                    <select className='form-control' name='folderSelect' id='folderSelect'>
                        <option value="">-- Select Folder --</option>
                        {
                            myFolders.map((item, i) =>
                                <option key={i} value={item.value}>{item.label}</option>
                            )
                        }
                    </select>
                </div>
                <div className='col-3 mt-1'>
                    <Button variant="warning" className='btn-sm' type='button' onClick={() => handleResumeMove()}>Move</Button>
                </div>


            </div>
            <div >
                <TabContext value={tabvalue} >
                    <Box sx={{ marginTop: "1rem", borderColor: 'divider', width: "105%" }}>
                        <TabList onChange={handleChange}
                            aria-label="lab API tabs example"
                            textColor="secondary"
                            indicatorColor="secondary"
                            centered>
                            <Tab selected={true} label="Personal Folder" value="one" iconPosition='start' icon="" style={{ minHeight: "0%" }} />
                            <Tab label="Job Posting Folder" value="two" iconPosition='start' icon="" style={{ minHeight: "0%" }} />

                        </TabList>
                    </Box>
                    <TabPanel value="one" >
                        <div className='row'>
                            <div className='col-5'>
                                <label className='control-label'>Folder Name <span style={{ color: "red" }}>*</span> :</label>
                            </div>
                            <div className='col-7'>
                                <input type='text' name='folderName' className='form-control' onChange={handlePersonalFolderChange} />
                            </div>
                            <div className='col-5 mt-3'>
                            </div>
                            <div className='col-7 mt-3'>
                                <Button variant="success" className='btn-sm' onClick={() => handlePersonalFolderClick()} >Save </Button>&nbsp;
                                <Button variant="danger" className='btn-sm' onClick={() => handleFolderCancel()} >Cancel </Button>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel value="two">
                        <div className='row'>
                            <div className='col-5'>
                                <label className='control-label' >Create new folder <span style={{ color: "red" }}>*</span> :</label>
                            </div>
                            <div className='col-7'>
                                <input type='text' name='folderName' className='form-control' onChange={handleJobPostingFolderChange} />
                            </div>
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Assignment<span style={{ color: "red" }}>*</span> :</label>
                            </div>
                            <div className='col-7 mt-1'>
                                <select className='form-control' name='assignmentName' id='assignmentid' onChange={handleAssignmentChange}>
                                    <option value="">-- Select Assignment --</option>
                                    {
                                        assignments.map((item, i) =>
                                            <option key={i} value={item.value}>{item.label}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Job Posting<span style={{ color: "red" }}>*</span> :</label>
                            </div>
                            <div className='col-7 mt-1' >
                                <select className='form-control' name='jobpostingName' id='jobpostings' onChange={handlejobPostingChange}>
                                    <option value="" >-- Select Job --</option>
                                    {
                                        jobPostingList.map((item, i) =>
                                            <option key={i} value={item.value} >{item.label}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className='col-5 mt-1'>
                            </div>
                            <div className='col-7 mt-1'>
                                <Button variant="success" className='btn-sm' onClick={() => handleJobPostingFolderClick()}>Save </Button>&nbsp;
                                <Button variant="danger" className='btn-sm' >Cancel </Button>
                            </div>
                        </div>
                    </TabPanel>

                </TabContext>
            </div>
        </div>
    )
}
export default ResumeMoveToFolder;