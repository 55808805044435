import { AuthService } from './AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "ClientFolders/";

const getPersonalFolder = async () => {
    const res = await fetch(baseUrl + "GetPersonalFolder?UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getJobPostingFolder = async () => {
    const res = await fetch(baseUrl + "GetJobPostingFolder?UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const saveorUpdatePersonalFolder = async (folderId, folderName, isnewfolder) => {
    const folderdata = {
        FolderID: folderId,
        FolderName: folderName,
        IsNewFolder: isnewfolder,
        UserID: parseInt(AuthService.getCurrentUser().userId)

    }
    const res = await fetch(baseUrl + "SaveorUpdatePersonalFolder", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(folderdata)
    });

    const data = await res.json();
    return data;
}
const saveorUpdateJobPostingFolder = async (folderId, folderName,jobpostingid, isnewfolder) => {
    const folderdata = {
        FolderID: folderId,
        FolderName: folderName,
        JobPostingID:parseInt(jobpostingid),
        IsNewFolder: isnewfolder,
        UserID: parseInt(AuthService.getCurrentUser().userId)

    }
    const res = await fetch(baseUrl + "SaveorUpdateJobPostingFolder", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(folderdata)
    });

    const data = await res.json();
    return data;
}
const deleteMyJobPostingFolder = async (folderID) => {
    const res = await fetch(baseUrl + "DeleteMyJobPostingFolder?folderID=" + folderID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getFolderByID = async (folderID) => {
    const res = await fetch(baseUrl + "FolderByID?folderID=" + folderID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const deleteMyPersonalFolder = async (folderID) => {
    const res = await fetch(baseUrl + "DeleteMyPersonalFolder?folderID=" + folderID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
export const ClientFolderService = {
    getPersonalFolder,
    getJobPostingFolder,
    saveorUpdatePersonalFolder,
    saveorUpdateJobPostingFolder,
    deleteMyJobPostingFolder,
    deleteMyPersonalFolder,
    getFolderByID
}