import React, { useState, useEffect } from 'react';
import LayoutAuthUser from '../../../Layout/Layout';
import { set, useForm } from "react-hook-form";
import { CommonDropDownService } from "../../../Services/CommonDropDownService";
import '../../../css/3Dgrid.css';
import Select from 'react-select';
import { AuthService } from '../../../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
function AdvanceSearch() {
    const { register, setValue, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const navigate  = useNavigate();
    const loc = useLocation();
    const qString = loc.state ? loc.state.qs : null;
    //DROPDOWN LIST
    const [KeywordsInList, setKeywordsInList] = useState([]);
    const [IndustryList, setIndustryList] = useState([]);
    const [FunctionalAreaList, setFunctionalAreaList] = useState([]);
    const [LocationlList, setLocationlList] = useState([]);
    const [NationalityList, setNationalityList] = useState([]);
    const [QualificationList, setQualificationList] = useState([]);
    const [InstituteList, setInstituteList] = useState([]);
    const [CurrentEmployerList, setCurrentEmployerList] = useState([]);
    const [AllOrAnyKeyWordsList, setAllOrAnyKeyWordsList] = useState([]);
    const [UpdatedWithinList, setUpdatedWithinList] = useState([]);
    const [SortResultByList, setSortResultByList] = useState([]);
    const [KeyWordsList, setKeyWordsList] = useState([]);
    const [searchKeyWords, setsearchKeyWords] = useState([]);
    const [selectedOptionForIndustry, setselectedOptionForIndustry] = useState([]);
    const [selectedOptionForFunctionalArea, setselectedOptionForFunctionalArea] = useState([]);
    const [selectedOptionForQualification, setselectedOptionForQualification] = useState([]);
    const [formSelectedAllOrAnyKeywords, setformSelectedAllOrAnyKeywords] = useState("");
    const [formSelectedSearchKeywordsIn,setformSelectedSearchKeywordsIn]=useState("");
    const [nationalityValue, setnationalityValue] = useState("");
    const [candidateIDValue, setcandidateIDValue] = useState("");
    const [QualificationSpecializationSpecializationList, setQualificationSpecializationSpecializationList] = useState([]);
    const [FunctionalAreaSpecializationList, setFunctionalAreaSpecializationList] = useState([]);
    const [selectedOptionForFunctionalAreaSpecialization, setselectedOptionForFunctionalAreaSpecialization] = useState([]);
    const [selectedOptionForLocation, setSelectedOptionForLocation] = useState([]);
    const [QualificationSpecializationList, setQualificationSpecializationList] = useState([]);
    const [selectedOptionForQualificationSpecialization, setselectedOptionForQualificationSpecialization] = useState([]);
    const [selectedOptionForInstitute, setselectedOptionForInstitute] = useState([]);
    const [FormSelectedExperienceFrom, setFormSelectedExperienceFrom] = useState(0);
    const [FormSelectedExperienceTo, setFormSelectedExperienceTo] = useState(0);
    const [FormSelectedSalaryFrom, setFormSelectedSalaryFrom] = useState(0);
    const [FormSelectedSalaryTo, setFormSelectedSalaryTo] = useState(0);
    const[formSelectedGender,setformSelectedGender]=useState("");
    const [formAmongEmployee, setformAmongEmployee] = useState("");


    const handleInputChange = (inputText) => {
        initKeyWordsList(inputText);
        if (KeyWordsList.length > 0) {
            autocomplete(document.getElementById("myInput"), KeyWordsList);
        }
    }
    const autocomplete = (inp, arr) => {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }
    const handleOnclickAllOrAnyKeywords = (e) => {
        if (e.target.value) {
            setformSelectedAllOrAnyKeywords(e.target.value);
        } else {
            setformSelectedAllOrAnyKeywords(undefined);
        }
    }
    const handleSearchKeywordsIn =(e)=>{
        if (e.target.value) {
            setformSelectedSearchKeywordsIn(e.target.value);
        } else {
            setformSelectedSearchKeywordsIn(undefined);
        }
    }
    const handleCandidateIdChange = (e) => {
        let val = e.target.value;
        if (val) {
            setcandidateIDValue(val);
        } else {
            setcandidateIDValue("");
        }
    }
    const handleNationalityChange = (e) => {
        let val = e.target.value;
        if (val) {
            setnationalityValue(val);
        } else {
            setnationalityValue("");
        }
    }
    const handleSelectIndustry = (selectedOption) => {
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 6) {
                setselectedOptionForIndustry(selectedOption);
            }
        } else {
            setselectedOptionForIndustry(undefined);
        }
    }
    const handleSelectFunctionalArea = (selectedOption) => {
        let functionalAreaId = [];
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForFunctionalArea(selectedOption);
                for (let i = 0; i < selectedOption.length; i++) {
                    functionalAreaId.push(selectedOption[i].value);
                }
                getFunctionalAreaSpecializationDDList(functionalAreaId)
            }
        } else {
            setselectedOptionForFunctionalArea(undefined);
            setselectedOptionForFunctionalArea("");
            setFunctionalAreaSpecializationList(functionalAreaId);
        }
    }
    const getFunctionalAreaSpecializationDDList = async (functionalAreaId) => {
        const functionalAreaSpecializationList = await CommonDropDownService.getFunctionalAreasSpecializaton(functionalAreaId);
        functionalAreaSpecializationList ? setFunctionalAreaSpecializationList(functionalAreaSpecializationList) : setFunctionalAreaSpecializationList({});
    }
    const handleSelectFunctionalAreaSpecialization = (selectedOption) => {
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForFunctionalAreaSpecialization(selectedOption);
            }
        } else {
            setselectedOptionForFunctionalAreaSpecialization(undefined);
        }
    }
    const handleSelectLocation = (selectedOption) => {
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setSelectedOptionForLocation(selectedOption);
            }
        } else {
            setSelectedOptionForLocation(undefined);
        }
    }
    const handleSelectQualification = (selectedOption) => {
        let qualificationIds = [];
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForQualification(selectedOption);
                for (let i = 0; i < selectedOption.length; i++) {
                    qualificationIds.push(selectedOption[i].value);
                }
                getqualificationSpecializationDDList(qualificationIds)
            }
        } else {
            setselectedOptionForQualification(undefined);
            setselectedOptionForQualification("");
            setQualificationSpecializationSpecializationList(qualificationIds);
        }
    }
    const getqualificationSpecializationDDList = async (qualificationId) => {
        const qualificationSpecialization = await CommonDropDownService.getQualificationSpecialization(qualificationId);
        qualificationSpecialization ? setQualificationSpecializationList(qualificationSpecialization) : setQualificationSpecializationList({});
    }
    const handleSelectQualificationSpecialization = (selectedOption) => {
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForQualificationSpecialization(selectedOption);
            }
        } else {
            setselectedOptionForQualificationSpecialization(undefined);
        }

    }
    const handleSelectInstitute = async (selectedOption) => {
        console.log(selectedOption);
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForInstitute(selectedOption);
            }
        } else {
            setselectedOptionForInstitute(undefined);
        }
    }
    const handleExperienceFrom = (e) => {
        if (e.target.value) {
            setFormSelectedExperienceFrom(e.target.value);
        } else {
            setFormSelectedExperienceFrom(undefined);
        }
    }
    const handleExperienceTo = (e) => {
        if (e.target.value) {
            setFormSelectedExperienceTo(e.target.value);
        } else {
            setFormSelectedExperienceTo(undefined);
        }
    }
    const handleSalaryFrom = (e) => {
        if (e.target.value) {
            setFormSelectedSalaryFrom(e.target.value);
        } else {
            setFormSelectedSalaryFrom(undefined);
        }
    }
    const handleSalaryTo = (e) => {
        if (e.target.value) {
            setFormSelectedSalaryTo(e.target.value);
        } else {
            setFormSelectedSalaryTo(undefined);
        }
    }
    const handleGenderChange=(e)=>{
        if (e.target.value) {
            setformSelectedGender(e.target.value);
        } else {
            setformSelectedGender(undefined);
        }
    }
    const handleAmongEmployeeChange = (e) => {
        let val = e.target.value;
        if (val) {
            setformAmongEmployee(val);
        } else {
            setformAmongEmployee("");
        }
    }
    const bindFieldsForReturnQS = (parmQS) => {
        console.log(parmQS);
        if (parmQS.SearchKeywords !== null && parmQS.SearchKeywords !== "" && parmQS.SearchKeywords !== undefined) {
            document.getElementById('myInput').value = parmQS.SearchKeywords;
        }
        if (parmQS.SearchAnyKeywords) {
            setformSelectedAllOrAnyKeywords(parmQS.SearchAnyKeywords);
        }
        if (parmQS.SearchKeywordsIn) {
            setformSelectedSearchKeywordsIn(parmQS.SearchKeywordsIn);
        }
        if (parmQS.CandidateID) {
            setcandidateIDValue(parmQS.CandidateID);
        }
        if (parmQS.CandidateID) {
            setcandidateIDValue(parmQS.CandidateID);
        }
        if (parmQS.NationalityID) {
            setnationalityValue(parmQS.NationalityID);
        }
        if (parmQS.IndustryIDs.length>0) {
            const Industries = [];
            const getIndustries = (industry) => {
                parmQS.IndustryIDs.filter(function (f) {
                    if (f === industry.value) {
                        Industries.push({ label: industry.label, value: industry.value })
                    }
                })
            }
            IndustryList.filter(function (f) {
                getIndustries(f);
            });
            setselectedOptionForIndustry(Industries);
        }
        if (parmQS.FunctionalAreaIDs.length > 0) {
            const functionAres = [];
            const getFunctionalAreas = (farea) => {
                parmQS.FunctionalAreaIDs.filter(function (f) {
                    if (f === farea.value) {
                        functionAres.push({ label: farea.label, value: farea.value })
                    }
                })
            }
            FunctionalAreaList.filter(function (f) {
                getFunctionalAreas(f);
            });
            setselectedOptionForFunctionalArea(functionAres);
        }
        if (parmQS.FunctionalSpecializationIDs.length > 0) {
            let specializationIDs = [];
            parmQS.FunctionalSpecializationIDs.filter(function (f) {
                specializationIDs.push(f);
            })
            getFunctionalAreaSpecializationDDList(specializationIDs);
            let specializations = [];
            const getSpecializations = (sp) => {
                parmQS.FunctionalSpecializationIDs.filter(function (f) {
                    if (f === sp.value) {
                        specializations.push({ label: sp.label, value: sp.value });
                    }
                })
            }
            FunctionalAreaSpecializationList.filter(function (f) {
                getSpecializations(f);
            })
            setselectedOptionForFunctionalAreaSpecialization(specializations);
        }
        if (parmQS.CurrentLocationIDs.length > 0) {
            const locations = [];
            const getCurrentLocation = (loc) => {
                parmQS.CurrentLocationIDs.filter(function (f) {
                    if (f === loc.value) {
                        locations.push({ label: loc.label, value: loc.value })
                    }
                })
            }
            LocationlList.filter(function (f) {
                getCurrentLocation(f);
            });
            setSelectedOptionForLocation(locations);
        }
       
        if (parmQS.SalaryFrom >0) {
            setFormSelectedSalaryFrom(parmQS.SalaryFrom);
        }
        if (parmQS.SalaryTo > 0) {
            setFormSelectedExperienceTo(parmQS.SalaryTo);
        }
        if (parmQS.ExperienceFrom > 0) {
            setFormSelectedExperienceFrom(parmQS.ExperienceFrom);
        }
        if (parmQS.ExperienceTo > 0) {
            setFormSelectedExperienceTo(parmQS.ExperienceTo);
        }
        if(parmQS.Gender !== null && parmQS.Gender !== "" && parmQS.Gender !== undefined){
            setformSelectedGender(parmQS.Gender);
        }
        
    }
    const isFormValid = () => {
        setValue(document.getElementById('myInput').value);
        let keywordValue = document.getElementById('myInput').value;
        let errCount = 0;
        errCount += (keywordValue === "" || keywordValue === undefined || keywordValue === null) ? 1 : 0;
        errCount += (candidateIDValue === "" || candidateIDValue === undefined || candidateIDValue === null) ? 1 : 0;
        errCount += (nationalityValue === 0 || nationalityValue === undefined || nationalityValue === null) ? 1 : 0;
        errCount += (FormSelectedSalaryFrom === 0 || FormSelectedSalaryFrom === undefined || FormSelectedSalaryFrom === null) ? 1 : 0;
        errCount += (FormSelectedSalaryTo === 0 || FormSelectedSalaryTo === undefined || FormSelectedSalaryTo === null) ? 1 : 0;
        errCount += (FormSelectedExperienceFrom === 0 || FormSelectedExperienceFrom === undefined || FormSelectedExperienceFrom === null) ? 1 : 0;
        errCount += (FormSelectedExperienceTo === 0 || FormSelectedExperienceTo === undefined || FormSelectedExperienceTo === null) ? 1 : 0;
        errCount += (selectedOptionForInstitute.length === 0 || selectedOptionForInstitute.length === undefined) ? 1 : 0;
        errCount += (selectedOptionForLocation.length === 0 || selectedOptionForLocation.length === undefined) ? 1 : 0;
        errCount += (selectedOptionForIndustry.length === 0 || selectedOptionForIndustry.length === undefined) ? 1 : 0;
        errCount += (selectedOptionForFunctionalArea.length === 0 || selectedOptionForFunctionalArea.length === undefined) ? 1 : 0;
        errCount += (selectedOptionForFunctionalAreaSpecialization.length === 0 || selectedOptionForFunctionalAreaSpecialization.length === undefined) ? 1 : 0;
        errCount += (selectedOptionForQualification.length === 0 || selectedOptionForQualification.length === undefined) ? 1 : 0;
        errCount += (selectedOptionForQualificationSpecialization.length === 0 || selectedOptionForQualificationSpecialization.length === undefined) ? 1 : 0;
        errCount += (formAmongEmployee === "" || formAmongEmployee === undefined || formAmongEmployee === null) ? 1 : 0;
        console.log(errCount);
        return errCount <= 14 ? true : false;
    }
    const handleAdvanceSearchSubmit = async (formData) => {
        setValue(document.getElementById('myInput').value);
        let keywordValue = document.getElementById('myInput').value
        let keyWord = "";
        if (keywordValue) {
            if (keywordValue !== "" || keywordValue !== null || keywordValue !== undefined) {
                keyWord = keywordValue;
            }
        }
        let experienceFrom = 0;
        if (FormSelectedExperienceFrom !== "" || FormSelectedExperienceFrom !== null || FormSelectedExperienceFrom !== undefined) {
            experienceFrom = parseInt(FormSelectedExperienceFrom) || 0;
        }
        let experienceTo = 0;
        if (FormSelectedExperienceTo !== "" || FormSelectedExperienceTo !== null || FormSelectedExperienceTo !== undefined) {
            experienceTo = parseInt(FormSelectedExperienceTo) || 0;
        }
        let salaryFrom = 0;
        if (FormSelectedSalaryFrom !== "" || FormSelectedSalaryFrom !== null || FormSelectedSalaryFrom !== undefined) {
            salaryFrom = parseInt(FormSelectedSalaryFrom) || 0;
        }
        let salaryTo = 0;
        if (FormSelectedSalaryTo !== "" || FormSelectedSalaryTo !== null || FormSelectedSalaryTo !== undefined) {
            salaryTo = parseInt(FormSelectedSalaryTo) || 0;
        }
        if (isFormValid()) {
        
            const searchdata = {
                RoleID:parseInt(AuthService.getCurrentUser().roleId),
                UserID:parseInt(AuthService.getCurrentUser().userId),
                SearchKeywords: keyWord,
                SearchAnyKeywords: formData.Allword,
                SearchKeywordsIn: formData.KeyWordsIn,
                CandidateID: formData.candidateID,
                IndustryIDs: selectedOptionForIndustry,
                FunctionalAreaIDs: selectedOptionForFunctionalArea,
                FunctionalSpecializationIDs: selectedOptionForFunctionalAreaSpecialization,
                QualificationIDs: selectedOptionForQualification,
                QualificationSpecializationIDs: selectedOptionForQualificationSpecialization === undefined ? null : selectedOptionForQualificationSpecialization,
                InstituteIDs: selectedOptionForInstitute,
                CurrentLocationIDs: selectedOptionForLocation,
                ExperienceFrom: experienceFrom,
                ExperienceTo: experienceTo,
                SalaryFrom: salaryFrom,
                SalaryTo: salaryTo,
                Gender: formData.Gender,
                NationalityID: nationalityValue,
                SearchEmployerKeywords: formData.amongEmployee,
                SearchAnyEmployerKeywords: formData.amongEmployeeKeywords,
                SearchEmployerKeywordsIn: formData.employeeIn,
                ResumeUpdateWithin: formData.updateWithin,
                SortByResult: formData.sortByresult,
                IsAdvanceSearch:true
            }
            let qsKeyword="";
            if(keyWord){
                 qsKeyword=keyWord;
            }
            let qsCandidateID="";
            if(formData.candidateID){
                qsCandidateID=formData.candidateID;
            }
            let qsExperienceFrom=0;
            if(experienceFrom){
                qsExperienceFrom=experienceFrom;
            }
            let qsExperienceTo=0;
            if(experienceTo){
                qsExperienceTo=experienceTo;
            }
            let qsSalaryFrom=0;
            if(salaryFrom){
                qsSalaryFrom=salaryFrom;
            }
            let qsSalaryTo=0;
            if(salaryTo){
                qsSalaryTo=salaryTo;
            }
            let qsSearchEmployerKeywords="";
            if(formData.amongEmployee){
                qsSearchEmployerKeywords=formData.amongEmployee;
            }
           const searches= "SearchKeywords="+qsKeyword
                            +"&SearchAnyKeywords="+formData.Allword
                            +"&SearchKeywordsIn="+formData.KeyWordsIn
                            +"&CandidateID="+qsCandidateID
                            +"&ExperienceFrom="+qsExperienceFrom
                            +"&ExperienceTo="+qsExperienceTo
                            +"&SalaryFrom="+qsSalaryFrom
                            +"&SalaryTo="+qsSalaryTo
                            +"&SearchEmployerKeywords="+qsSearchEmployerKeywords
                            ;
            console.log(searches);

             const parms = btoa(JSON.stringify(searchdata));
             navigate ({
                 pathname: '/advancedSearchResult',
                 search: '?qs=' + parms,
             })
        } else {
            alert("Please select atleast one field.")
        }
    }

    const initSearchAnyKeywords = async () => {
        const searchkeyWords = await CommonDropDownService.getSearchAnyKeywords();
        searchkeyWords ? setsearchKeyWords(searchkeyWords) : setsearchKeyWords({});
    }

    const initKeyWordsList = async (inputText) => {
        const keyWords = await CommonDropDownService.getKeywordsList(inputText);
        keyWords ? setKeyWordsList(keyWords) : setKeyWordsList({});
    }
    useEffect(() => {
        const initKeywordsInlist = async () => {
            const keywordsin = await CommonDropDownService.getKeyWordWithin();
            keywordsin ? setKeywordsInList(keywordsin) : setKeywordsInList({});
        }
        const initIndustrylist = async () => {
            const industry = await CommonDropDownService.getIndustry();
            industry ? setIndustryList(industry) : setIndustryList({});
        }
        const initFunctionalArealist = async () => {
            const functionalArea = await CommonDropDownService.getFunctionalAreas();
            functionalArea ? setFunctionalAreaList(functionalArea) : setFunctionalAreaList({});
        }
        const initLocationlist = async () => {
            const location = await CommonDropDownService.getCurrentLocation();
            location ? setLocationlList(location) : setLocationlList({});
        }
        const initNationalitylist = async () => {
            const nationality = await CommonDropDownService.getCountry();
            nationality ? setNationalityList(nationality) : setNationalityList({});
        }
        const initQualificationlist = async () => {
            const qualification = await CommonDropDownService.getQualification();
            qualification ? setQualificationList(qualification) : setQualificationList({});
        }
        const initInstitutelist = async () => {
            const institute = await CommonDropDownService.getInstitutes();
            institute ? setInstituteList(institute) : setInstituteList({});
        }
        const initCurrentEmployerlist = async () => {
            const currentemployee = await CommonDropDownService.getEmployeeSearchCondition();

            currentemployee ? setCurrentEmployerList(currentemployee) : setCurrentEmployerList({});
        }
        const initAllOrAnyKeyWordslist = async () => {
            const employeIn = await CommonDropDownService.getAnyEmployeeType();
            employeIn ? setAllOrAnyKeyWordsList(employeIn) : setAllOrAnyKeyWordsList({});
        }
        const initUpdatedWithinlist = async () => {
            const updatedwithin = await CommonDropDownService.getResumeLastUpdatedInMonths();

            updatedwithin ? setUpdatedWithinList(updatedwithin) : setUpdatedWithinList({});
        }
        const initSortResultBylist = async () => {
            const sortresultby = await CommonDropDownService.getSortSearchKeyWords();
            sortresultby ? setSortResultByList(sortresultby) : setSortResultByList({});
        }

        initKeywordsInlist();
        initSearchAnyKeywords();
        initIndustrylist();
        initFunctionalArealist();
        initLocationlist();
        initNationalitylist();
        initQualificationlist();
        initInstitutelist();
        initCurrentEmployerlist();
        initAllOrAnyKeyWordslist();
        initUpdatedWithinlist();
        initSortResultBylist();
        initKeyWordsList();
        setValue("KeyWordsIn", "E");
        setValue("amongEmployeeKeywords", "C");
        setValue("employeeIn", "ALL");
        setValue("sortByresult", "RowRank Desc");
        if (qString) {
            bindFieldsForReturnQS(qString);
        }
    }, []);
    return (
        <LayoutAuthUser>
            <div>
                <div className='container mt-2' id='grid'>
                    <form method='POST' onSubmit={handleSubmit(handleAdvanceSearchSubmit)}>
                        <div className='row'>
                            <h6 style={{ marginTop: "1%", marginLeft: "1.5%" }} >ADVANCED SEARCH</h6>
                            <hr style={{ backgroundColor: "#1976D2", width: "1095px", marginTop: "1%" }} />
                            <div className='col-md-3'>
                                <h6>PRIMARY PARAMETERS</h6>
                            </div>
                            <div className='col-md-9' style={{ borderLeft: "1px solid #1976D2" }}>
                                <div className='row'>
                                    <div className='col-1'></div>
                                    <div className='col-3'>
                                        <label className='control-label font-13 mt-1'>Keywords :</label>
                                    </div>
                                    <div className='col-6'>
                                        <span className="autocomplete">
                                            <input id="myInput" className='form-control'
                                                onChange={(e) => handleInputChange(e.target.value)}
                                                type="text" />
                                        </span>
                                        <span className="font-11">* Please use comma ( , ) , AND/OR to separate multiple keywords .</span>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3'>
                                        <label className='control-label font-13 mt-1'> </label>
                                    </div>
                                    <div className='col-6'>
                                        <input type="radio" name="Allword" className='allorany' checked={true} value='ALL' 
                                            {...register("Allword", {
                                                required: true,
                                                onChange:(e)=>{handleOnclickAllOrAnyKeywords(e)}
                                            })}
                                        /> All words &nbsp;
                                        <input type="radio" name="Allword" className='allorany' value='ANY'
                                            {...register("Allword", {
                                                required: true,
                                            })}
                                        /> Any words
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13 '>Keywords in:</label>
                                    </div>
                                    <div className='col-6'>
                                        <select className='form-control' name='KeyWordsIn' 
                                        value={formSelectedSearchKeywordsIn} onChange={handleSearchKeywordsIn}
                                            {...register("KeyWordsIn", {
                                                
                                            })}
                                        >
                                            {
                                                KeywordsInList.map((item, i) =>
                                                    <option key={i} value={item.value}>{item.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13'>CandidateID :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <input className='form-control' name='candidateID' value={candidateIDValue}
                                            {...register("candidateID", {
                                                onChange: (e) => { handleCandidateIdChange(e) }
                                            })} />
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13'>Industry  :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <Select
                                            placeholder={"-- Select Industries --"}
                                            onChange={handleSelectIndustry}
                                            value={selectedOptionForIndustry}
                                            isMulti={true}
                                            options={IndustryList}
                                        />
                                        <span className="font-11">* You can select multiple max 10 Industries.</span>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13'>Functional Area :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <Select
                                            placeholder={"-- Select Functional Area --"}
                                            onChange={handleSelectFunctionalArea}
                                            value={selectedOptionForFunctionalArea}
                                            isMulti={true}
                                            options={FunctionalAreaList}
                                        />

                                        <span className="font-11">* You can select multiple max 10 Functional Areas.</span>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3'>
                                        <label className='control-label font-13 mt-1'>Specialization  :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <Select
                                            placeholder={"-- Select Functional Specialization --"}
                                            onChange={handleSelectFunctionalAreaSpecialization}
                                            value={selectedOptionForFunctionalAreaSpecialization}
                                            isMulti={true}
                                            options={FunctionalAreaSpecializationList}
                                        />
                                        <span className="font-11">* You can select multiple max 10 Functional Specializations.</span>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13'>Current Location :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <Select
                                            placeholder={"-- Select locations --"}
                                            onChange={handleSelectLocation}
                                            value={selectedOptionForLocation}
                                            isMulti={true}
                                            options={LocationlList}
                                        />
                                        <span className="font-11">* You can select multiple max 10 Locations.</span>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13 mt-1'>Experience :</label>
                                    </div>
                                    <div className='col-3 mt-1'>
                                        <input type="number" placeholder='From' min="0" value={FormSelectedExperienceFrom} onChange={handleExperienceFrom} name='Experience' className="form-control" />
                                    </div>
                                    <div className='col-3 mt-1'>
                                        <input type="number" placeholder='To' min="0" value={FormSelectedExperienceTo} onChange={handleExperienceTo} name='Experience' className="form-control" />
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13 mt-1'>Yearly Salary (P.A.) :</label>
                                    </div>
                                    <div className='col-3 mt-1'>
                                        <input type="number" placeholder='From' min="0" value={FormSelectedSalaryFrom} onChange={handleSalaryFrom} name='SalaryFrom' className="form-control" />
                                        <span className="font-11">Thousand (in USD)</span>
                                    </div>
                                    <div className='col-3 mt-1'>
                                        <input type="number" placeholder='To' min="0" value={FormSelectedSalaryTo} onChange={handleSalaryTo} name='SalaryTo' className="form-control" />

                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13'>Nationality :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <select className='form-control' name='nationality'
                                            {...register("nationality", {
                                                onChange: (e) => { handleNationalityChange(e) }
                                            })}
                                        >
                                            <option value="">--- Select Nationality ---</option>
                                            {
                                                NationalityList.map((item, i) =>
                                                    <option key={i} value={item.value}>{item.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13'>Gender :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <input type="radio" name="Gender" value="M"
                                            {...register("Gender", {
                                                onChange: (e) => { handleGenderChange(e) }
                                            })}
                                        /> &nbsp;Male &nbsp;
                                        <input type="radio" name="Gender" value="F"
                                            {...register("Gender", {
                                                onChange: (e) => { handleGenderChange(e) }
                                            })}
                                        />&nbsp; Female &nbsp;
                                        <input type="radio" name="Gender" value="" checked={true}
                                            {...register("Gender", {
                                                onChange: (e) => { handleGenderChange(e) }
                                            })}
                                        />&nbsp; Decline to Identity
                                    </div>
                                    <div className='col-2'></div>
                                </div>
                            </div>

                        </div>
                        <div className='row'>
                            <hr style={{ backgroundColor: "#1976D2", width: "1095px", marginTop: "1%" }} />
                            <div className='col-md-3'>
                                <h6>EDUCATIONAL QUALIFICATION</h6>
                            </div>
                            <div className='col-md-9' style={{ borderLeft: "1px solid #1976D2" }}>
                                <div className='row'>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-3'>
                                        <label className='control-label font-13 mt-1'>Qualification :</label>
                                    </div>
                                    <div className='col-md-6 mt-1'>
                                        <Select
                                            placeholder={"---- Select Qualification ----"}
                                            onChange={handleSelectQualification}
                                            value={selectedOptionForQualification}
                                            isMulti={true}
                                            options={QualificationList}
                                        />
                                        <span className="font-11">* You can select multiple max 10 Qualifications .</span>
                                    </div>
                                    <div className='col-md-2'></div>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-3 mt-1'>
                                        <label className='control-label font-13 mt-1'>Specialization :</label>
                                    </div>
                                    <div className='col-md-6 mt-1'>
                                        <Select
                                            placeholder={"-- Select Qualification Specialization --"}
                                            onChange={handleSelectQualificationSpecialization}
                                            value={selectedOptionForQualificationSpecialization}
                                            isMulti={true}
                                            options={QualificationSpecializationList}
                                        />

                                        <span className="font-11">* You can select multiple max 10 Qualification Specializations.</span>
                                    </div>
                                    <div className='col-md-2'></div>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-3 mt-1'>
                                        <label className='control-label font-13 mt-1'>Institute  :</label>
                                    </div>
                                    <div className='col-md-6 mt-1'>
                                        <Select
                                            placeholder={"-- Select Institutes --"}
                                            onChange={handleSelectInstitute}
                                            value={selectedOptionForInstitute}
                                            isMulti={true}
                                            options={InstituteList}
                                        />

                                        <span className="font-11">* You can select multiple max 10 Institutes.</span>
                                    </div>
                                    <div className='col-md-2'></div>
                                    <div className='col-md-1 mt-1'></div>
                                    <div className='col-md-3 mt-1'>
                                        <label className='control-label font-13 mt-1'>Search among Employers :</label>
                                    </div>
                                    <div className='col-md-2 mt-1'>
                                        <input className='form-control form-control-sm' name='amongEmployee'
                                            {...register("amongEmployee", {
                                                onChange: (e) => { handleAmongEmployeeChange(e) }
                                            })}
                                        />
                                    </div>
                                    <div className='col-md-3 mt-1'>
                                        <select className='form-control form-control-sm' name='amongEmployeeKeywords'
                                            {...register("amongEmployeeKeywords", {
                                            })}>
                                            {
                                                AllOrAnyKeyWordsList.map((item, i) =>
                                                    <option key={i} value={item.value}>{item.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-1 mt-1'><label className='control-label font-13 mt-1'>IN :</label></div>
                                    <div className='col-md-2 mt-1'>
                                        <select className='form-control form-control-sm' name='employeeIn' style={{ marginLeft: "-28%" }}
                                            {...register("employeeIn", {
                                            })}>
                                            {
                                                CurrentEmployerList.map((item, i) =>

                                                    <option key={i} value={item.value}>{item.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-3 mt-1'>
                                        <label className='control-label font-13 mt-1'>Updated Within :</label>
                                    </div>
                                    <div className='col-md-6 mt-1'>
                                        <select className='form-control' name='updateWithin'
                                            {...register("updateWithin", {
                                            })}>
                                            {
                                                UpdatedWithinList.map((item, i) =>
                                                    item.value === "" ?
                                                        <option key={i} value="">{item.label}</option> :
                                                        <option key={i} value={item.value}>{item.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-2'></div>
                                    <div className='col-md-1'></div>
                                    <div className='col-md-3 mt-1'>
                                        <label className='control-label font-13 mt-1'>Sort Result By :</label>
                                    </div>
                                    <div className='col-md-6 mt-1'>
                                        <select className='form-control' name='sortByresult'
                                            {...register("sortByresult", {

                                            })}>
                                            {
                                                SortResultByList.map((item, i) =>
                                                    <option key={i} value={item.value}>{item.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className='col-md-2'></div>
                                    <div className='col-3 mt-2'></div>
                                    <div className='col-4 mt-3 mb-5' style={{ textAlign: "center" }}>
                                        <button type='submit' className='btn btn-sm btn btn-outline-primary mr-1'><i className='fa fa-floppy-o'></i> Submit</button>
                                    </div>
                                    <div className='col-5 mt-2'></div>
                                </div>

                            </div>
                        </div>

                    </form>
                </div>
                <br></br>
            </div>
        </LayoutAuthUser>
    )
}

export default AdvanceSearch