import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Layout from '../../Layout/Layout';
import { AuthService } from '../../Services/AuthService';
import { JobPostingDetailsService } from '../../Services/JobPostingDetailsService';
import ReactPaginate from 'react-paginate';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import '../../css/leftnav.css';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';


function JobPostingDetails() {
    const loc = useLocation();
    const search = useLocation().search;
    const navigate = useNavigate();

    const [pageCount, setpageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(10);
    const itemsPerPage = 10;
    const [startIndex, setstartIndex] = useState(0);

    const [openLoader, setopenLoader] = useState(false);
    const [jobFilters, setjobFilters] = useState([]);
    const [jobActions, setjobActions] = useState([]);
    const [jobStatus, setjobStatus] = useState([]);
    const [resumes, setresumes] = useState([]);
    const [resumeRecords, setresumeRecords] = useState(0);
    const [isFirstResumeReqest, setisFirstResumeReqest] = useState(true);

    const [assignmentDDlList, setassignmentDDlList] = useState([]);
    const [stFolderCode, setstFolderCode] = useState(0);
    const [stFolderID, setstFolderID] = useState(0);

    const getIconClassName = (fname) => {
        let icon = "fa fa-asterisk text-success";
        switch (fname) {
            case "Applied by Candidate":
                icon = "fa fa-check-circle text-success";
                break;
            case "Forward To Client":
                icon = "fa fa-arrow-circle-right text-success";
                break;
            case "Not Available":
                icon = "fa fa-ban text-danger"
                break;
            case "Not Interested":
                icon = "fa fa-times-circle-o text-danger"
                break;
            case "Rejected By Client":
                icon = "fa fa-times-circle-o text-danger"
                break;
            case "Schedule Interview":
                icon = "fa fa-clock-o text-success";
                break;
            case "Selected":
                icon = "fa fa-check-circle text-success";
                break;
            case "Shortlisted":
                icon = "fa fa-list-ol text-success";
                break;
            case "Suitable":
                icon = "fa fa-thumbs-up text-success";
                break;
            case "EMAIL":
                icon = "fa fa-envelope text-success"
                break;
            case "SMS":
                icon = "fa fa-comment text-success";
                break;
        }
        return icon;
    }
    const handleLiClick = (e, id) => {
        let parentEL = e.parentNode;
        let activeElements = document.getElementsByClassName('active-class');
        for (let i = 0; i < activeElements.length; i++) {
            activeElements[i].classList.remove('active-class');
        }
        if (parentEL.className.split(' ').indexOf("prnt") >= 0) {
            e.className = 'active-class';
            //console.log(e.childNodes);
        } else {
            parentEL.className = 'active-class';
            // console.log(parentEL.childNodes);
            // let oldClass = parentEL.lastElementChild.childNodes[0].className.split(" ")[0]+" "+parentEL.lastElementChild.childNodes[0].className.split(" ")[1];
            // parentEL.lastElementChild.childNodes[0].className = oldClass;
            // console.log(oldClass);
        }

    }
    const handlePageClick = (event) => {
        setisFirstResumeReqest(false);
        const newOffset = (event.selected * itemsPerPage) % resumeRecords;
        setstartIndex(event.selected);
        setItemOffset(newOffset);

    }
    useEffect(() => {
        let assignmentID = "";
        let jpID = "";
        let folderID = 0;
        let folderCode = 0;
        if (loc.state) {
            // if (loc.state.isCallJobList) {
               
            // }else{

            // }
            jpID = loc.state.jpID ? loc.state.jpID : "";
            assignmentID = loc.state.assignID ? loc.state.assignID : "";
        }
        const getQs = new URLSearchParams(search).get('qs');
        let qs = null;
        if (getQs) {
            qs = atob(new URLSearchParams(search).get('qs'));
        }
        if (jpID === "") {
            jpID = qs ? qs.split(":")[0] : "";
        }
        if (assignmentID === "") {
            assignmentID = qs ? qs.split(":")[1] : "";
        }
        if (AuthService.isAuthenticatedUser()) {
            if (assignmentID === "" || jpID === "") {
                navigate("/404");
                return false;
            }
            const initPageLoad = async () => {
                if (isFirstResumeReqest) {
                    setopenLoader(true);
                    const apiRes = await JobPostingDetailsService.getFilterAndStatusWithActionDetails(jpID);
                    console.log(apiRes);
                    apiRes.filters ? setjobFilters(apiRes.filters) : setjobFilters([]);
                    apiRes.actions ? setjobActions(apiRes.actions) : setjobActions([]);
                    apiRes.statusDDl ? setjobStatus(apiRes.statusDDl) : setjobStatus([]);
                    folderCode = apiRes.filters ? apiRes.filters[0].typeID : 0;
                    folderID = apiRes.filters ? apiRes.filters[0].id : 0;
                    setstFolderCode(folderCode);
                    setstFolderID(folderID);
                }
                setopenLoader(true);
                initResumes();
            }
            const initJobPostingList = async () => {
                const apiRes = await JobPostingDetailsService.getJobpostingDDlByAssignmentID(assignmentID);
                apiRes ? setassignmentDDlList(apiRes) : setassignmentDDlList([]);
            }
            const initResumes = async () => {
                setopenLoader(true);
                const reqData = {
                    PageIndex: startIndex + 1,
                    PageSize: 10,
                    UserID: parseInt(AuthService.getCurrentUser().userId),
                    EncJobPostingId: jpID,
                    FolderID: isFirstResumeReqest ? folderID : stFolderID,
                    SearchGo: 1,
                    FolderCode: isFirstResumeReqest ? folderCode : stFolderCode,
                }
                const apiRes = await JobPostingDetailsService.getResumes(reqData);
                setresumes(JSON.parse(apiRes.apiData));
                setpageCount(parseInt(apiRes.pageCount) / 10);
                setresumeRecords(parseInt(apiRes.pageCount));
                setopenLoader(false);
            }
            initPageLoad();
            if (isFirstResumeReqest) {
                initJobPostingList();
            }

        } else {
            const qs = btoa(jpID + ":" + assignmentID);
            navigate({
                pathname: "/login",
                search: "?ReturnUrl=/jobPostingDetails&qs=" + qs
            })
        }

    }, [startIndex])
    return (
        <div>
            <Layout>

                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <div className='row'>
                    <div className='col-md-3'>
                        <div className="msb" id="msb">
                            <nav className="navbar navbar-default" role="navigation">


                                <div className="side-menu-container">
                                    <ul className="nav navbar-nav prnt">
                                        {

                                            jobFilters.map((d, i) =>
                                                i === 0 ?
                                                    <li className='active-class' key={i}
                                                        style={{ cursor: "pointer" }}
                                                        onClick={(e) => handleLiClick(e.target, d.id)}
                                                    >
                                                        <a className='custom-font'>
                                                            <i className={getIconClassName(d.name)}></i>
                                                            {d.name + " (" + d.count + ")"}
                                                        </a>
                                                    </li>
                                                    :
                                                    <li className='' onClick={(e) => handleLiClick(e.target, d.id)} key={i}
                                                        style={{ cursor: "pointer" }}
                                                    >

                                                        <a className='custom-font'>
                                                            <i className={getIconClassName(d.name)}></i>
                                                            {d.name + " (" + d.count + ")"}
                                                        </a>
                                                    </li>


                                            )
                                        }
                                    </ul>
                                    <select className='form-control mt-1 mb-2'>
                                        <option value="">Select Status</option>
                                        {
                                            jobStatus.map((d, i) =>
                                                <option key={i} value={d.id}>{d.name}</option>
                                            )
                                        }
                                    </select>
                                    <ul className="nav navbar-nav mt-1 mb-2">
                                        {
                                            jobActions.map((d, i) =>
                                                <li key={i}>{d.name}</li>
                                            )
                                        }
                                    </ul>
                                    <select className='form-control mt-1 mb-2'>
                                        {
                                            assignmentDDlList.map((d, i) =>
                                                <option key={i} value={d.value}>{d.label}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </nav>
                        </div>
                    </div>
                    <div className='col-md-9'>

                        <div className="mcw">
                            <div className="cv">
                                <div>


                                    <div className='mt-3'>
                                        <Box sx={{ minWidth: 275 }} id="muiBoxShadow">
                                            {
                                                resumes.map((d, i) =>
                                                    <Card key={i + 100} variant="outlined" className='mt-2 mb-2'>
                                                        <CardContent key={i + 200}>

                                                            <div className='row'>
                                                                <div className='col-md-4'>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>
                                                                        <div className='canName mb-1'>
                                                                            <input type="checkbox" />
                                                                            &nbsp;
                                                                            <span className='hdcol'> {d.CandidateResumeTitle} </span>
                                                                        </div>
                                                                    </Typography>

                                                                </div>
                                                                <div className='col-md-4'></div>
                                                                <div className='col-md-4'>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>
                                                                        <div className='canName mb-1'>

                                                                            <span className='hdcol'> <i className='fa fa-user'></i> {d.CandidateName} </span>
                                                                        </div>

                                                                    </Typography>
                                                                </div>


                                                                <div className='col-md-4'>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>

                                                                        <span className='mt-4'>
                                                                            <br />
                                                                            <span className='bold' variant="h5">key Skill :</span>   {d.CandidateKeySkills}
                                                                            <br />
                                                                            <span className='bold'>Age :</span>  {d.CandidateAge}
                                                                            <br />
                                                                            <span className='bold'>Education :</span>  {d.CandidateQualification}
                                                                            <br />
                                                                            <span className='bold'> Candidate ID : </span> {d.CandidateID}
                                                                            <br />
                                                                            <span className='bold'>Last Modified On :</span>  {d.CandidateModifiedOn}
                                                                        </span>
                                                                    </Typography>
                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>
                                                                        <span className='bold'>Employer :</span>  {d.CandidateCurrentEmployer + "," + d.CandidatePreviousEmployer}
                                                                        <br />
                                                                        <span className='bold'> Experience :</span>   {d.CandidateExperienceYear} Year(s)- {d.CandidateExperienceMonth} Month(s)
                                                                        <br />
                                                                        <span className='bold'>Annual Salary : </span>    {d.MaskAnnualSalary ? null : d.AnnualSalary === 0 ? "Not mentioned" : d.AnnualSalary}
                                                                        <br />
                                                                        <span className='bold'>Status Modified On :</span>    {d.StatusModifiedOn}
                                                                        <br />
                                                                        <span className='bold'>Mark As UnViewed</span>
                                                                    </Typography>

                                                                </div>
                                                                <div className='col-md-4'>
                                                                    <Typography sx={{ fontSize: 12 }} color="text.primary" gutterBottom>

                                                                        <span className='mt-4'>
                                                                            <span className='bold'> Email :</span>    {d.IsEmailVerified ? d.CandidateEmail : null}
                                                                            <br />
                                                                            <span className='bold'> Mobile :</span>   {d.IsMobileVerified ? d.CandidateMobile : null}
                                                                            <br />
                                                                            <span className='bold'>Location :</span>    {d.LocationName}
                                                                        </span>
                                                                    </Typography>
                                                                </div>
                                                            </div>


                                                        </CardContent>

                                                    </Card>

                                                )
                                            }

                                        </Box>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <div className='row'>
                            <div className='col-md-7'>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next >"
                                    onPageChange={handlePageClick}
                                    pageCount={pageCount}
                                    previousLabel="< previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                            <div className='col-md-5'>
                                <span className='text-primary'>recordsTotal :{resumeRecords}</span>
                            </div>
                        </div>

                    </div>
                </div>


            </Layout>
        </div>
    );
}
export default JobPostingDetails;