import React, { useState, useEffect } from 'react';
import { SmsSendService } from '../../../Services/SmsSendService';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import { AuthService } from '../../../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";

function SmsTemplate(props) {

    const [smsTemplateList, setSmsTemplateList] = useState([]);
    const [smsNumber, setsmsNumber] = useState("");
    const [smsMessage, setsmsMessage] = useState("");
    const [newSMSTempName, setnewSMSTempName] = useState("");
    const [openLoader, setopenLoader] = React.useState(false);
    const [newsmsTempShow, setnewsmsTempShow] = useState(false);
    const handleSMSTempShow = () => setnewsmsTempShow(true);
    const handleSMSTempClose = () => {
        setnewsmsTempShow(false);
    }
    const handleSMSTempSelectChange = () => {
        let smsTemps = document.getElementById("smsTemp");
        let smsTempsCode = smsTemps.options[smsTemps.selectedIndex].value;
        let smsTempsName = smsTemps.options[smsTemps.selectedIndex].text;
        let smsMessages = [];
        smsTemplateList.filter(function (filter) {
            if (parseInt(smsTempsCode) === filter.SmsTemplateCode) {
                smsMessages.push({ message: filter.SmsTemplateMessage })
            }
        });
        setsmsMessage(smsMessages[0].message);
    }
    const handleNewSMSTemplate = () => {
        handleSMSTempShow();
    }
    const handleSMSNumberChange = (e) => {
        if (e.target.value) {
            setsmsNumber(e.target.value);
        } else {
            setsmsNumber("");

        }
    }
    const handleSMSMessageChange = (e) => {
        if (e.target.value) {
            setsmsMessage(e.target.value);
        } else {
            setsmsMessage("");
        }
    }
    const handleNewSMSTemplateNameChange = (e) => {
        if (e.target.value) {
            setnewSMSTempName(e.target.value);
        } else {
            setnewSMSTempName("");
        }
    }
    const handleSaveNewSMSTemplate = async () => {
        //setopenLoader(true);
        ShowAlert(1, "Successfully saved");
        // setopenLoader(false);
    }
    const handleSendSMSToCandidate = async () => {
        ShowAlert(1, "Successfully SMS send");
    }
    const handleSendSMSAndSaveTemplate = async () => {
        ShowAlert(1, "Template save and send SMS to Candidate");
    }

    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const initSMSTemplates = async () => {
        const res = await SmsSendService.getSmsTemplate();
        setSmsTemplateList(JSON.parse(res.smsTemplate));
    }
    useEffect(() => {
        initSMSTemplates();
        setsmsNumber(props.CandidateMobileNo);
    }, [])
    return (
        <div>
            {/* sms template modal */}
            <Modal
                show={newsmsTempShow}
                onHide={handleSMSTempClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "5rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Add New SMS Template</span>
                    </Modal.Title>
                    <button onClick={handleSMSTempClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row mb-2'>
                        <div className='col-5 mt-1'>
                            <label className='control-label'>Template Name <span style={{ color: "red" }}>*</span>:</label>
                        </div>
                        <div className='col-7 mt-1'>
                            <input type='text' className='form-control' onChange={handleNewSMSTemplateNameChange} />
                        </div>

                    </div>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleSMSTempClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                        <Button variant="primary" type='button' onClick={() => handleSaveNewSMSTemplate()}><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* end sms template modal */}
            <div className='row mb-2'>
                <div className='col-3 mt-1'>
                    <label className='control-label'>Template :</label>
                </div>
                <div className='col-5 mt-1' >
                    <select className='form-control' name='smsTemp' id='smsTemp' onChange={handleSMSTempSelectChange}>
                        <option value="">-- Select Template --</option>
                        {
                            smsTemplateList.map((item, i) =>
                                <option key={i} value={item.SmsTemplateCode}>{item.SmsTemplateName}</option>
                            )
                        }
                    </select>
                </div>
                <div className='col-3 mt-1'>
                    <Button variant="warning" className='btn-sm' type='button' onClick={() => handleNewSMSTemplate()}>Add New</Button>
                </div>
                <div className='col-3 mt-1'>
                    <label className='control-label'>Number :</label>
                </div>
                <div className='col-5 mt-1' >
                    <textarea rows="2" cols="25" className='form-control' readOnly value={smsNumber} onChange={handleSMSNumberChange} />
                </div>
                <div className='col-4 mt-1'></div>

                <div className='col-3 mt-1'>
                    <label className='control-label'>Message :</label>
                </div>
                <div className='col-8 mt-1' >
                    <textarea rows="4" cols="25" className='form-control' value={smsMessage} onChange={handleSMSMessageChange} />
                </div>
                <div className='col-6 mt-2'></div>
                <div className='col-6 mt-2'>
                    <Button variant="success" onClick={() => handleSendSMSToCandidate()}> Send </Button> &nbsp;
                    <Button variant="warning" type='button' onClick={() => handleSendSMSAndSaveTemplate()}><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save Template And Send</Button>
                </div>
            </div>

        </div>
    )
}
export default SmsTemplate;