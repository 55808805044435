
const baseUrlDevlopment ="https://localhost:44309/api/";
const baseUrlLive = "https://mm1xx7ygib.execute-api.ap-south-1.amazonaws.com/Prod/api/";
const baseUrlLiveDevelopment = "https://ft26ky4ua6.execute-api.ap-south-1.amazonaws.com/Prod/api/";
const GetBaseUrl = () =>{
   //return baseUrlDevlopment;
   //return baseUrlLive;
  return baseUrlLiveDevelopment;
}


export const DomainService = {
    GetBaseUrl
}