import React, { useState, useEffect, useCallback } from 'react';
import LayoutAuthUser from '../../../Layout/Layout';
import { AuthService } from '../../../Services/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { set, useForm } from "react-hook-form";
import Select from 'react-select';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { AdvancesearchResultService } from '../../../Services/AdvancesearchResultService';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ShortListCandidateService } from '../../../Services/ShortListCandidateService';


function ShortlistToOtherJob(props) {
    const navigate = useNavigate();
    const [openLoader, setopenLoader] = React.useState(false);
    const [shortListCandidates, setShortListCandidates] = useState([]);
    const [selectedOptionForClients, setselectedOptionForClients] = useState([]);
    const [selectedOptionForAssignments, setselectedOptionForAssignments] = useState([]);
    const [selectedOptionForJobpostings, setselectedOptionForJobpostings] = useState([]);
    const [selectedOptionForStatus, setselectedOptionForStatus] = useState("");
    const [assignmentOnClientList, setAssignmentOnClientList] = useState([]);
    const [jobpostingsOnAssignmentList, setJobpostingsOnAssignmentList] = useState([]);
    const [clientsList, setClientsList] = useState([]);
    const [statusLists, setStatusLists] = useState([]);
    const search = useLocation().search;
    const [candidateID, setCandidateID] = useState("");
    //validation
    const [isClientShowErr, setisClientShowErr] = useState(false);
    const [isAssignmentShowErr, setisAssignmentShowErr] = useState(false);
    const [isJobShowErr, setisJobShowErr] = useState(false);
    const [isStatusShowErr, setisStatusShowErr] = useState(false);
    const handleSelectClients = (selectedOption) => {
        let clientID = [];
        if (selectedOption.length > 0) {
            setisClientShowErr(false);
            if (selectedOption.length <= 10) {
                setselectedOptionForClients(selectedOption);
                setisClientShowErr(false);
                for (let i = 0; i < selectedOption.length; i++) {
                    clientID.push(selectedOption[i].value);
                }
                getAssignementDDList(clientID);
            }
        } else {
            setisClientShowErr(true);
            setselectedOptionForClients(undefined);
            setselectedOptionForClients("");
            setAssignmentOnClientList(clientID);
        }
    }
    const getAssignementDDList = async (clientID) => {
        setopenLoader(true);
        const assignmentonClientList = await ShortListCandidateService.getAssignMentOnClients(clientID);
        assignmentonClientList ? setAssignmentOnClientList(assignmentonClientList) : setAssignmentOnClientList({});
        setopenLoader(false);
    }
    const handleAssignmentSelect = (selectedOption) => {
        let assignmentId = [];
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForAssignments(selectedOption);
                setisAssignmentShowErr(false);
                for (let i = 0; i < selectedOption.length; i++) {
                    assignmentId.push(selectedOption[i].value);
                }
                getJobPostingDDList(assignmentId);
            }
        } else {
            setselectedOptionForAssignments(undefined);
            setselectedOptionForAssignments("");
            setJobpostingsOnAssignmentList(assignmentId);
            setisAssignmentShowErr(true);
        }
    }
    const getJobPostingDDList = async (assignmentId) => {
        setopenLoader(true);
        const jobpostingList = await ShortListCandidateService.getJobPostingsByassignmentId(assignmentId);
        jobpostingList ? setJobpostingsOnAssignmentList(jobpostingList) : setJobpostingsOnAssignmentList({});
        setopenLoader(false);
    }
    const handleJobPostingSelect = (selectedOption) => {
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForJobpostings(selectedOption);
                setisJobShowErr(false);
            }
        } else {
            setselectedOptionForJobpostings(undefined);
            setisJobShowErr(true);
            setselectedOptionForJobpostings("");
        }
    }
    const handleStatusonChange = (e) => {
        if (e.target.value) {
            setselectedOptionForStatus(e.target.value);
            setisStatusShowErr(false);
        } else {
            setselectedOptionForStatus("");
            setisStatusShowErr(true);
        }
    }
    const isFormValid = () => {
        let errCount = (selectedOptionForClients.length === 0 || selectedOptionForClients.value === "" || selectedOptionForClients.length === undefined || selectedOptionForClients.value === "") ? 1 : 0;
        errCount += (selectedOptionForAssignments.length === 0 || selectedOptionForAssignments.value === "" || selectedOptionForAssignments.length === undefined || selectedOptionForAssignments.value === "") ? 1 : 0;
        errCount += (selectedOptionForJobpostings.length === 0 || selectedOptionForJobpostings.value === "" || selectedOptionForJobpostings.length === undefined || selectedOptionForJobpostings.value === "") ? 1 : 0;
        errCount += (selectedOptionForStatus === "" || selectedOptionForStatus === "" || selectedOptionForStatus === undefined || selectedOptionForStatus === null) ? 1 : 0;
        return errCount === 0 ? true : false;
    }
    const ClearForm = () => {
        setselectedOptionForClients([]);
        setselectedOptionForAssignments([]);
        setselectedOptionForJobpostings([]);
        setselectedOptionForStatus();
    }
    const handleSaveShortListClick = async () => {
        setopenLoader(true);
      
        if (selectedOptionForClients.length === 0 || selectedOptionForClients.value === "" || selectedOptionForClients.length === undefined) {
            setisClientShowErr(true);
        } else {
            setisClientShowErr(false);
        }
        if (selectedOptionForAssignments.length === 0 || selectedOptionForAssignments.value === "" || selectedOptionForAssignments.length === undefined || selectedOptionForAssignments.value === "") {
            setisAssignmentShowErr(true);
        } else {
            setisAssignmentShowErr(false);
        }
        if (selectedOptionForJobpostings.length === 0 || selectedOptionForJobpostings.value === "" || selectedOptionForJobpostings.length === undefined || selectedOptionForJobpostings.value === "") {
            setisJobShowErr(true);
        } else {
            setisJobShowErr(false);
        }
        if (selectedOptionForStatus === "" || selectedOptionForStatus === undefined || selectedOptionForStatus === null) {
            setisStatusShowErr(true);
        } else {
            setisStatusShowErr(false);
        }
        let statuses = document.getElementById("statusID");
        let statusid = statuses.options[statuses.selectedIndex].value;
        let statusName = statuses.options[statuses.selectedIndex].text;
        let jobpostingid = [];
        for (let i = 0; i < selectedOptionForJobpostings.length; i++) {
            jobpostingid.push(selectedOptionForJobpostings[i].value);
        }
        var jpID = jobpostingid.map(Number);
        let newJpID = jpID.toString();
        if (isFormValid()) {
            const res = await ShortListCandidateService.shortListToOtherJob(candidateID, newJpID, statusid);
            if (res.isSuccess) {
                ShowAlert(1, res.message);
                setopenLoader(false);
                ClearForm();
                const parms = new URLSearchParams(search).get('search_qs');
                navigate({
                    pathname: '/advancedSearchResult',
                    search: '?qs=' + parms,
                })
            } else {
                ShowAlert(0, res.message);
            }
        } else {
            ShowAlert(0, "select all field");
        }

        setopenLoader(false);
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const initClientLists = async () => {
        const res = await ShortListCandidateService.getClientList();
        setClientsList(res);
    }
    const initStatusLists = async () => {
        const res = await ShortListCandidateService.getStatuses();
        setStatusLists(res);
    }
    const handleCloseClick = () => {
        const parms = new URLSearchParams(search).get('search_qs');
        navigate({
            pathname: '/advancedSearchResult',
            search: '?qs=' + parms,
        })
    }
    useEffect(() => {
        const qs = new URLSearchParams(search).get('shortlistqs');
        const initCandidateLists = async () => {
            setopenLoader(true);
            if (qs) {
                const urlDecodedSearchParms = JSON.parse(atob(qs));
                setCandidateID(urlDecodedSearchParms.toString());
                let xmlCandidateID = "";
                for (let i = 0; i < urlDecodedSearchParms.length; i++) {
                    xmlCandidateID += "<Candidate>";
                    xmlCandidateID += "<CandidateID>";
                    xmlCandidateID += urlDecodedSearchParms[i];
                    xmlCandidateID += "</CandidateID>";
                    xmlCandidateID += "</Candidate>";
                }
                const res = await ShortListCandidateService.getCandidateByID(xmlCandidateID);
                res.apiData ? setShortListCandidates(JSON.parse(res.apiData)) : setShortListCandidates([]);
            }
            setopenLoader(false);
        }
        initCandidateLists();
        initClientLists();
        initStatusLists();
    }, [])

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <LayoutAuthUser>
                {/* shortlist candidate  */}
                <div className='row'>
                    <div className='col-3 mt-1'>
                        <Select
                            placeholder={"-- Select Clients --"}
                            onChange={handleSelectClients}
                            value={selectedOptionForClients}
                            isMulti={true}
                            options={clientsList}
                        />
                        {isClientShowErr ? <p className='text-danger'>Select Client</p> : null}
                    </div>
                    <div className='col-3 mt-1'>
                        <Select
                            placeholder={"-- Select Assignment --"}
                            onChange={handleAssignmentSelect}
                            value={selectedOptionForAssignments}
                            isMulti={true}
                            options={assignmentOnClientList}
                        />
                        {isAssignmentShowErr ? <p className='text-danger'>Select Assignment</p> : null}
                    </div>
                    <div className='col-2 mt-1'>
                        <Select
                            placeholder={"-- Select Job--"}
                            onChange={handleJobPostingSelect}
                            value={selectedOptionForJobpostings}
                            isMulti={true}
                            options={jobpostingsOnAssignmentList}
                        />
                        {isJobShowErr ? <p className='text-danger'>Select Job</p> : null}
                    </div>
                    <div className='col-2 mt-1'>
                        <select className='form-control' name='status' id='statusID' onChange={(e) => handleStatusonChange(e)}
                        >
                            <option value="">--- Select Status ---</option>
                            {
                                statusLists.map((item, i) =>
                                    <option key={i} value={item.value}>{item.label}</option>)
                            }
                        </select>
                        {isStatusShowErr ? <p className='text-danger'>Select Status</p> : null}
                    </div>
                    <div className='col-2 mt-1'>
                        <Button variant="primary" type='button' onClick={() => handleSaveShortListClick()} >
                            <i className="fa fa-floppy-o icon-color" aria-hidden="true"></i>&nbsp;Save</Button>
                        &nbsp;
                        <Button variant="danger" onClick={() => handleCloseClick()} >
                            <i className="fa fa-times icon-color" aria-hidden="true"></i>&nbsp;Close
                        </Button>
                    </div>
                </div>
                <div className="col-md-12">
                    <div className="table-wrapper" >
                        <table className="fl-table"   >
                            <thead>
                                <tr className="canTr">
                                    <th>CandidateFirstName		</th>
                                    <th>PassportNo	             </th>
                                    <th>PassportDetail</th>
                                    <th>CandidateMobileNo	     </th>
                                    <th>CandidateEmailID	     </th>
                                    <th>CandidateDateOfBirth	 </th>
                                    <th>CurrentLocation</th>
                                    <th>CurrentSalary	 </th>
                                    <th>ExpectedSalary</th>
                                    <th>Education	 </th>
                                    <th>IndianExp</th>
                                    <th>NoticePeriod</th>
                                    <th>OverseasExp</th>
                                    <th>TotalExp</th>
                                    <th>OtherDetails</th>
                                </tr>
                            </thead>
                            <tbody id="tblBody">
                                {
                                    shortListCandidates.map((item, i) =>
                                        <tr key={i}>
                                            <td>{item.CandidateFirstName}</td>
                                            <td>{item.PassportNo}</td>
                                            <td>{item.CandidatePassportDetails}</td>
                                            <td>{item.CandidateMobileNo}</td>
                                            <td>{item.CandidateEmailID}</td>
                                            <td>{item.CandidateDateOfBirth}</td>
                                            <td>{item.CurrentLocation}</td>
                                            <td>{item.CurrentSalary}</td>
                                            <td>{item.ExpectedSalary}</td>
                                            <td>{item.Education}</td>
                                            <td>{item.IndianExp}</td>
                                            <td>{item.NoticePeriod}</td>
                                            <td>{item.OverseasExp}</td>
                                            <td>{item.TotalExp}</td>
                                            <td>{item.OtherDetails}</td>
                                        </tr>
                                    )
                                }

                            </tbody>
                        </table>
                    </div>
                </div>

                {/* End shortlist candidate  */}
            </LayoutAuthUser>
        </div>

    )
}
export default ShortlistToOtherJob;