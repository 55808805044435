import React, { useState, useEffect } from 'react';
import { DashboardService } from '../../../Services/DashboardService';
import ReactPaginate from 'react-paginate';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import { AuthService } from '../../../Services/AuthService';
import Layout from '../../../Layout/Layout';
import { useNavigate } from 'react-router-dom';

function Dashboard() {
    const navigate = useNavigate();
    const [pageCount, setpageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(10);
    const [openLoader, setopenLoader] = useState(false);
    const itemsPerPage = 10;
    const [startIndex, setstartIndex] = useState(0);
    const [assignmentListData, setassignmentListData] = useState([]);
    const [assignmentRecords, setassignmentRecords] = useState(0);
    const [joblist, setjoblist] = useState([]);
    const [headingTitle, setheadingTitle] = useState("Client List");
    const [isShowBackBtn, setisShowBackBtn] = useState(false);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % assignmentRecords;
        setstartIndex(event.selected);
        setItemOffset(newOffset);

    }
    const handleClientClick = async (encAssignmentID) => {
        setopenLoader(true);
        const getJobList = await DashboardService.GetJobListByAssignmentID(encAssignmentID);
        setjoblist(getJobList);
        setheadingTitle("Job List");
        setpageCount(0);
        setisShowBackBtn(true);
        setopenLoader(false);
    }
    const handleJobViewClick = (assignmentID,jobPostingId) => {
        navigate("jobPostingDetails", { state: { assignID : assignmentID ,jpID: jobPostingId  } })
    }
    const initAssignmentList = async (IsReset) => {
        if (IsReset) {
            setheadingTitle("Client List");
            setisShowBackBtn(false);
        }
        setopenLoader(true);
        const Assignment = await DashboardService.GetAssignmentLists(startIndex + 1);
        setassignmentListData(Assignment.data);
        setassignmentRecords(Assignment.recordsTotal);
        setpageCount(Assignment.recordsTotal/10);
        setopenLoader(false);
    }
    useEffect(() => {

        if (AuthService.isAuthenticatedUser()) {

            initAssignmentList();
        } else {

        }
    }, [startIndex])

    return (
        <Layout>
            <div className=''>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <h6 className='text-center text-primary'>{headingTitle}</h6>
                <div className='row mt-3 mb-3'>
                    <div className='col-md-3'></div>
                    <div className='col-md-6'>
                        <div className='custom-card'>
                            <ul>
                                {
                                    isShowBackBtn ?

                                        joblist.map((d, i) =>
                                            <li className='ml-4' onClick={() => handleJobViewClick(d.encAssignmentID,d.encJobPostingId)} style={{ listStyle: "none", cursor: "pointer" }} key={i}>
                                                {d.jobTitle}
                                            </li>
                                        ) :
                                        assignmentListData.map((d, i) =>
                                            <li className='ml-4' onClick={() => { handleClientClick(d.encAssignmentID) }} style={{ listStyle: "none", cursor: "pointer" }} key={i}>
                                                {d.clientName}
                                            </li>
                                        )

                                }
                                {
                                    isShowBackBtn ?
                                        <li style={{ listStyle: "none", marginLeft: "-6%", cursor: "pointer" }}>
                                            <i className="fa fa-arrow-left" onClick={() => initAssignmentList(true)}  ></i></li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'></div>
                    <div className='col-md-3'>

                    </div>
                    <div className='col-md-5'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                    <div className='col-md-4'>
                        {
                            isShowBackBtn ? null : <span className='text-primary'>
                                recordsTotal:{assignmentRecords}
                            </span>
                        }

                    </div>
                </div>

            </div>
        </Layout>
    );
}
export default Dashboard;