import React, { useState, useEffect } from 'react';
import { set, useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import Layout from '../../Layout/Layout';
import Button from 'react-bootstrap/Button';
import { AuthService } from '../../Services/AuthService';
import DataTable from 'react-data-table-component';
import Tooltip from '@mui/material/Tooltip';
import Card from '@mui/material/Card';
import RCard from 'react-bootstrap/Card';
import CardContent from '@mui/material/CardContent';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { CandidateSearchService } from '../../Services/CandidateSearchService';
import { useNavigate } from 'react-router-dom';
import ReactPaginate from 'react-paginate';
import { CandidateEntryService } from '../../Services/CandidateEntryService';
function CandidateList() {
    const navigate = useNavigate();
    const { register, setValue, clearErrors, watch, formState: { errors }, handleSubmit } = useForm();
    const [showPagingCandidate, setshowPagingCandidate] = useState([]);
    const [totalrecord, settotalrecord] = useState(0);
    const itemPerPage = 10;
    const [formdata, setformdata] = useState([]);
    const [pageCount, setpageCount] = useState(0);
    const [message, setmessage] = useState("");
    const [openLoader, setopenLoader] = React.useState(false);
    const [itemOffset, setItemOffset] = useState(5);
    const [startIndex, setstartIndex] = useState(0);

    const handleAddNewCandidate = () => {
        navigate('/candidateentry');
    }
    const handleEditCandidate = async (encCandidateID) => {
        navigate({
            pathname: '/candidateentry',
            search: "?qs=" + encCandidateID,
        });
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemPerPage) % totalrecord;
        setItemOffset(newOffset);
        setstartIndex(event.selected);
        let inputCandidateID = document.getElementById('candiddateID').value ? document.getElementById('candiddateID').value : "";
        let inputName = document.getElementById('candidateName').value ? document.getElementById('candidateName').value : "";
        let inputEmail = document.getElementById('emailID').value ? document.getElementById('emailID').value : "";
        let inputDob = document.getElementById('dob').value ? document.getElementById('dob').value : "";
        let inputContactNo = document.getElementById('contactNo').value ? document.getElementById('contactNo').value : "";
        let inputPassportNo = document.getElementById('passportNo').value ? document.getElementById('passportNo').value : "";
        const formData = {
            candidateid: inputCandidateID,
            candidateName: inputName,
            emailId: inputEmail,
            dob: inputDob,
            contactNo: inputContactNo,
            passportNo: inputPassportNo,
            hasIndexChange: true,
            indexValue: event.selected
        }
        handleSearchCandidates(formData);
    };
    const handleSearchCandidates = async (formData) => {
        setopenLoader(true);
        const formatFormData = {
            CandidateID: parseInt(formData.candidateid) ? parseInt(formData.candidateid) : 0,
            CandidateName: formData.candidateName ? formData.candidateName : "",
            EmailID: formData.emailId ? formData.emailId : "",
            DateOfBirth: formData.dob ? formData.dob : "",
            ContactNo: formData.contactNo ? formData.contactNo : "",
            PassportNo: formData.passportNo ? formData.passportNo : "",
            StartIndex: formData.hasIndexChange ? formData.indexValue + 1 : startIndex + 1,
        }
        formatFormData ? setformdata(formatFormData) : setformdata({});
        const res = await CandidateSearchService.getCandidatesList(formatFormData);
        console.log(res);
        if (res.isSuccess) {
            setshowPagingCandidate(res.candidateList);
            settotalrecord(res.recordsTotal);
            setpageCount(Math.ceil(res.recordsTotal / itemPerPage));
            setmessage(res.message);
            ShowAlert(1, res.message);
        } else {
            setmessage(res.message);
            ShowAlert(0, res.message);
            setshowPagingCandidate([]);
            settotalrecord(0);
            setpageCount(0);
        }
        setopenLoader(false);
    }
    useEffect(() => {
        if (AuthService.isAuthenticatedUser()) {

        } else {
            navigate({
                pathname: "/login",
                search: "?ReturnUrl=/candidateList"
            })
        }
    }, []);
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}

            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Layout>
                <div >
                    <h4 className='text-center'><b> MANAGE CANDIDATE LIST </b></h4>
                    <Card >
                        <CardContent>
                            <div className='row'>
                                <div className='col-3'></div>
                                <div className='col-5'></div>
                                <div className='col-4'>
                                    <button className='btn btn-primary float-right mb-1' onClick={() => handleAddNewCandidate()}> Add Candidate</button>
                                </div>
                            </div>
                            <div className='' style={{ padding: "4px", boxShadow: "rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px" }}>
                                <RCard style={{ padding: "4px" }}>
                                    <form method='POST' className="mb-2" onSubmit={handleSubmit(handleSearchCandidates)}>
                                        <div className=' row mt-3' style={{ marginRight: "6%" }}>
                                            <div className='col-2'>
                                                <label className="font-14">Candidate ID</label>
                                            </div>
                                            <div className='col-2'>
                                                <label className="  font-14">Candidate Name</label>
                                            </div>
                                            <div className='col-2'>
                                                <Tooltip title="Multiple Email search separate by comma" placement="top">
                                                    <label className=" font-14 ml-4">Email ID</label>
                                                </Tooltip>
                                            </div>
                                            <div className='col-2'>
                                                <label className="  font-14 ml-4">Date of Birth </label>
                                            </div>
                                            <div className='col-2'>
                                                <label className="font-14 ml-4">Mobile Number </label>
                                            </div>
                                            <div className='col-2'>
                                           
                                                <label className=" font-14 ml-4">Passport Number </label>
                                              
                                            </div>
                                        </div>
                                        <div className=' row mb-2' style={{ marginRight: "6%" }}>
                                            <div className="col-2" >
                                                <input className='form-control' type="number" id='candiddateID' min={0} name="candidateid"
                                                    {...register("candidateid")}
                                                />
                                            </div>
                                            <div className="col-2" >
                                                <input className='form-control text-lowercase' type="text" id='candidateName' name="candidateName"
                                                    {...register("candidateName")}
                                                />
                                            </div>
                                            <div className="col-2" >
                                                <Tooltip title="Multiple Email search separate by comma" placement="bottom">
                                                    <input className='form-control' type="text" id='emailID' name="emailId"
                                                        {...register("emailId")}
                                                    />
                                                </Tooltip>
                                            </div>

                                            <div className="col-2" >
                                                <input className='form-control' type="date" id='dob' name="dob"
                                                    {...register("dob")}
                                                />
                                            </div>
                                            <div className="col-2" >
                                                <input className='form-control' type="number" id='contactNo' name="contactNo"
                                                    {...register("contactNo")}
                                                />
                                            </div>

                                            <div className="col-2" >
                                            <Tooltip title="Enter Passport No." placement="top">
                                                <input className='form-control' type="text text-capitalize" id='passportNo' name="passportNo"
                                                    {...register("passportNo")}
                                                />
                                                  </Tooltip>
                                            </div>

                                        </div>
                                        <div className=" " style={{ marginTop: "-4%" }}>
                                            <Button type='submit' className="btn btn-warning float-right" >
                                                <i className="fa fa-search"></i>
                                            </Button>
                                        </div>
                                    </form>
                                </RCard>
                                <hr />


                                <TableContainer component={Paper}>
                                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>ID</TableCell>
                                                <TableCell>Candidate Name</TableCell>
                                                <TableCell>DateOfBirth</TableCell>
                                                <TableCell >Email</TableCell>
                                                <TableCell>Mobile No</TableCell>
                                                <TableCell>Contact No</TableCell>
                                                <TableCell>Passport No</TableCell>
                                                <TableCell >Modified On</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                showPagingCandidate.map((item, i) =>
                                                    <TableRow key={i}>
                                                        <TableCell>{item.candidateID}</TableCell>
                                                        <TableCell>{item.candidateName}</TableCell>
                                                        <TableCell>{item.dateOfBirth}</TableCell>
                                                        <TableCell>{item.emailID}</TableCell>
                                                        <TableCell>{item.contactNo}</TableCell>
                                                        <TableCell>{item.telePhoneNo}</TableCell>
                                                        <TableCell>{item.passportNo}</TableCell>
                                                        <TableCell>{item.modifiedOn}</TableCell>
                                                        <TableCell><button className='btn btn-warning' onClick={() => handleEditCandidate(item.encCandidateID)}><i className="fa fa-pencil" ></i></button></TableCell>
                                                    </TableRow>
                                                )
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                                <div className='mt-2' style={{ marginLeft: "40rem" }}>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="next >"
                                        onPageChange={handlePageClick}
                                        pageCount={pageCount}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </div>

                        </CardContent>
                    </Card>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </Layout>
        </div>
    )
}
export default CandidateList;