import React, { useState, useEffect, useCallback } from 'react';
import LayoutAuthUser from '../../../Layout/Layout';
import { AuthService } from '../../../Services/AuthService';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { set, useForm } from "react-hook-form";
import Select from 'react-select';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import BCard from 'react-bootstrap/Card';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import MuiModal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { AdvancesearchResultService } from '../../../Services/AdvancesearchResultService';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import ReactPaginate from 'react-paginate';
import { CommonDropDownService } from '../../../Services/CommonDropDownService';
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { EmailSendService } from '../../../Services/EmailSendService';
import { SmsSendService } from '../../../Services/SmsSendService';
import { ResumeDownloadService } from '../../../Services/ResumeDownloadService';
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { ShortListCandidateService } from '../../../Services/ShortListCandidateService';

function AdvancedSearchResult() {

    // react text editor 
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const [emailBodyState, setEmailBodyState] = useState("");
    // react text editor 
    const { register, setValue, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleCancel = () => setOpen(false);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [resumeshow, setresumeShow] = useState(false);
    const handleResumeClose = () => setresumeShow(false);
    const handleResumeShow = () => setresumeShow(true);
    const [shortListshow, setshortListshow] = useState(false);
    const handleShortListShow = () => setshortListshow(true);
    const [excelshow, setexcelShow] = useState(false);
    const handleExcelClose = () => setexcelShow(false);
    const handleExcelShow = () => setexcelShow(true);
    const [emailshow, setemailShow] = useState(false);

    const handleEmailShow = () => setemailShow(true);
    const [templateshow, settemplateShow] = useState(false);

    const handleEmailTempShow = () => settemplateShow(true);
    const [smsTempShow, setsmsTempShow] = useState(false);

    const handleSMSShow = () => setsmsTempShow(true);
    const [newsmsTempShow, setnewsmsTempShow] = useState(false);

    const handleSMSTempShow = () => setnewsmsTempShow(true);
    const [commentShow, setcommentShow] = useState(false);
    const handleCommentClose = () => setcommentShow(false);
    const handleCommentShow = () => setcommentShow(true);
    const search = useLocation().search;
    const [searchResultText, setsearchResultText] = useState("");
    const [searchResultAnyword, setsearchResultAnyword] = useState("");
    const [emailTemplateCode, setemailTemplateCode] = useState("");
    const [emailSubject, setemailSubject] = useState("");
    const [newEmailTempName, setnewEmailTempName] = useState("");
    const [newTempValue, setnewTempValue] = useState("");
    const [emailtemplateFrom, setemailtemplateFrom] = useState("");
    const [emailtemplateTo, setemailtemplateTo] = useState("");
    const [smsNumber, setsmsNumber] = useState("");
    const [smsMessage, setsmsMessage] = useState("");
    const [newSMSTempName, setnewSMSTempName] = useState("");
    const [openLoader, setopenLoader] = React.useState(false);
    const [searchResult, setsearchResult] = useState([]);
    const [emailTemplateList, setemailTemplateList] = useState([]);
    const [smsTemplateList, setSmsTemplateList] = useState([]);
    const [totalResultCount, settotalResultCount] = useState(0);
    const itemPerPage = 10;
    const [pageCount, setpageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(5);
    const [startIndex, setstartIndex] = useState(0);
    const [assignments, setAssignments] = useState([]);
    const [jobPostingList, setjobPostingList] = useState([]);
    const [myFolders, setmyFolders] = useState([]);
    const [selected, setSelected] = useState([]);
    const [tabvalue, settabValue] = React.useState('one');
    const [folderName, setfolderName] = useState("");
    const [jobPostingfolderName, setjobPostingfolderName] = useState("");
    //shortlist candidate
    const [selectedOptionForClients, setselectedOptionForClients] = useState([]);
    const [selectedOptionForAssignments, setselectedOptionForAssignments] = useState([]);
    const [selectedOptionForJobpostings, setselectedOptionForJobpostings] = useState([]);
    const [assignmentOnClientList, setAssignmentOnClientList] = useState([]);
    const [jobpostingsOnAssignmentList, setJobpostingsOnAssignmentList] = useState([]);
    const [clientsList, setClientsList] = useState([]);
    const [statusLists, setStatusLists] = useState([]);
    //resume details
    const handleResumeTitleClick = (candidateResumeID, candidateID) => {
        //navigate('/client/resumeDetails');
        const parms = btoa(JSON.stringify("candidateResumeID=" + candidateResumeID + "&candidateID=" + candidateID));
        navigate({
            pathname: '/client/resumeDetails',
            search: "?qs=" + parms,
        })
    }
    //shortlist candidate
    const style = {
        position: 'absolute',
        top: '30%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    const handleShortListClose = () => {
        setshortListshow(false);
        setselectedOptionForClients(undefined);
        setselectedOptionForClients("");
        setselectedOptionForAssignments(undefined);
        setselectedOptionForAssignments("");
        setselectedOptionForJobpostings(undefined);
        setselectedOptionForJobpostings("");
    }
    const handleSMSTempClose = () => {
        setnewsmsTempShow(false);
    }
    const handleSelectClients = (selectedOption) => {
        let clientID = [];
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForClients(selectedOption);
                for (let i = 0; i < selectedOption.length; i++) {
                    clientID.push(selectedOption[i].value);
                }
                getAssignementDDList(clientID);
                console.log(clientID);
            }
        } else {
            setselectedOptionForClients(undefined);
            setselectedOptionForClients("");
            setAssignmentOnClientList(clientID);
        }
    }
    const getAssignementDDList = async (clientID) => {
        const assignmentonClientList = await ShortListCandidateService.getAssignMentOnClients(clientID);
        assignmentonClientList ? setAssignmentOnClientList(assignmentonClientList) : setAssignmentOnClientList({});
    }
    const handleAssignmentSelect = (selectedOption) => {
        let assignmentId = [];
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForAssignments(selectedOption);
                for (let i = 0; i < selectedOption.length; i++) {
                    assignmentId.push(selectedOption[i].value);
                }
                getJobPostingDDList(assignmentId);
            }
        } else {
            setselectedOptionForAssignments(undefined);
            setselectedOptionForAssignments("");
            setJobpostingsOnAssignmentList(assignmentId);
        }
    }
    const getJobPostingDDList = async (assignmentId) => {
        const jobpostingList = await ShortListCandidateService.getJobPostingsByassignmentId(assignmentId);
        console.log(jobpostingList);
        jobpostingList ? setJobpostingsOnAssignmentList(jobpostingList) : setJobpostingsOnAssignmentList({});
    }
    const handleJobPostingSelect = (selectedOption) => {
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setselectedOptionForJobpostings(selectedOption);
            }
        } else {
            setselectedOptionForJobpostings(undefined);
            setselectedOptionForJobpostings("");
        }
    }
    const handleSaveShortListClick = async () => {
        handleShortListClose();
        ShowAlert(0, "Candidate Shortlisted");
    }
    const handleChange = (event, newValue) => {
        settabValue(newValue);
    };
    const handleChangeIndex = (index) => {
        setValue(index);
    };
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemPerPage) % totalResultCount;
        setItemOffset(newOffset);
        setstartIndex(event.selected);
    }
    const handleCandidateResumeCheck = (event) => {
        const selectedIndex = selected.indexOf(event);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, event);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }

        setSelected(newSelected);
    }
    const handleClickShortList = () => {
        if (selected.length > 0) {
            // handleShortListShow();
            const searchQs = new URLSearchParams(search).get('qs');
            const canIDparams = btoa(JSON.stringify(selected));
            navigate({
                pathname: '/shortlist',
                search: "?shortlistqs=" + canIDparams+"&search_qs="+searchQs,
            })
        } else {
            ShowAlert(0, "Please Select atleast one resume");
        }
    }
    const getSearchParms = () => {
        const qs = new URLSearchParams(search).get('qs');
        if (qs) {
            const urlDecodedSearchParms = JSON.parse(atob(qs));
            return urlDecodedSearchParms;
        } else {
            return null;
        }
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }

    const handleRefineShow = () => {
        handleOpen();
    }
    const handleRefineSearch = async () => {
        setopenLoader(true);
        if (AuthService.isAuthenticatedUser()) {
            let keywordValue = document.getElementById('refinesearchInput').value;
            let allorAnyKeyWord = document.getElementById('refineallorany').checked;
            let searchQueryParms = getSearchParms();
            searchQueryParms.SearchKeywords = keywordValue ? searchQueryParms.SearchKeywords + "," + keywordValue : searchQueryParms.SearchKeywords;
            if (allorAnyKeyWord) {
                searchQueryParms.SearchAnyKeywords = "ALL";
            }
            else {
                searchQueryParms.SearchAnyKeywords = "ANY";
            }
            const res = await AdvancesearchResultService.getAdvanceSearchResult(searchQueryParms);
            handleCancel();
            console.log(res);
        }

        setopenLoader(false);
    }
    const handleSaveShow = () => {
        handleShow();
    }
    const handlePersonalFolderChange = (e) => {
        if (e.target.value) {
            setfolderName(e.target.value);
        } else {
            setfolderName("");
        }
    }
    const handlePersonalFolderClick = async () => {
        const res = await AdvancesearchResultService.createNewFolder(0, folderName);
        console.log(res);
        if (res.isSuccess) {
            ShowAlert(1, res.message);
            initFolderlist();
            setfolderName("");
        } else {
            ShowAlert(0, res.message);
        }
    }
    const handleJobPostingFolderChange = (e) => {
        if (e.target.value) {
            setjobPostingfolderName(e.target.value);
        } else {
            setjobPostingfolderName("");
        }
    }
    const handleJobPostingFolderClick = async () => {
        let assignment = document.getElementById("assignmentid");
        let assignmentid = assignment.options[assignment.selectedIndex].value;
        let assignmenttext = assignment.options[assignment.selectedIndex].text;

        let jobpostings = document.getElementById("jobpostings");
        let jobpostingid = jobpostings.options[jobpostings.selectedIndex].value;
        let jobpostingstext = jobpostings.options[jobpostings.selectedIndex].text;
        const folderid = 0;
        const res = await AdvancesearchResultService.createNewJobPostingFolder(folderid, jobPostingfolderName, jobpostingid);
        if (res.isSuccess) {
            ShowAlert(1, res.message);
        } else {
            ShowAlert(0, res.message);
        }
    }
    const initSearchResults = async (searchdata) => {
        setopenLoader(true);
        try {
            searchdata["StartIndex"] = startIndex + 1;
            const res = await AdvancesearchResultService.getAdvanceSearchResult(searchdata);
            //emailBtn saveResumeBtn shortListBtn smsBtn
            console.log(JSON.parse(res.searchResultsString));
            setsearchResult(JSON.parse(res.searchResultsString));
            settotalResultCount(res.recordsTotal);
            setpageCount(Math.ceil(res.recordsTotal / itemPerPage));
            if (res.recordsTotal === 0) {
                ShowAlert(1, "No Resume Found");
                // navigate("/advanceSearch");
            }

        } catch (err) {
            navigate("/advanceSearch");
            ShowAlert(0, "Session timeout");
            setopenLoader(false);
        }
        setopenLoader(false);
    }
    const handleSaveSearch = async (formData) => {
        if (AuthService.isAuthenticatedUser()) {
            let searchQueryParms = getSearchParms();
            searchQueryParms["SearchName"] = formData.searchName;
            const res = await AdvancesearchResultService.saveAdvanceSearchResult(searchQueryParms);
            if (res.isSuccess) {
                ShowAlert(1, res.message);
                handleClose();
                clearErrors("searchName", "");
            } else {
                ShowAlert(0, res.message);
            }
        }
    }
    const handleModifySearch = async () => {
        if (AuthService.isAuthenticatedUser()) {
            let searchQueryParms = getSearchParms();
            if (getSearchParms().IsAdvanceSearch) {
                navigate('/advanceSearch', { state: { qs: searchQueryParms } });
            } else {
                navigate('/quickSearch', { state: { qs: searchQueryParms } });
            }
        }
    }
    const handleSaveResume = () => {
        if (selected.length > 0) {
            handleResumeShow();
        } else {
            ShowAlert(0, "Please Select atleast one resume");
        }
    }
    const handleExcelBtnClick = () => {
        if (selected.length > 0) {
            handleExcelShow();
        } else {
            ShowAlert(0, "Please Select atleast one resume");
        }

    }
    const handleEmailBtnClick = async () => {
        if (selected.length > 0) {
            setopenLoader(true);
            const useremail = await EmailSendService.getCurrentUserEmailID();
            setemailtemplateFrom(useremail.currentUserEmailID);
            const candidateresumes = [];
            searchResult.filter(function (filter) {
                for (let i = 0; i < selected.length; i++) {
                    if (selected[i] === filter.CandidateResumeID) {
                        candidateresumes.push({ canEmail: filter.CandidateEmail })
                    }
                }
            });
            let val = "";
            for (var i = 0; i < candidateresumes.length; i++) {
                val += candidateresumes[i].canEmail + ",";
            }
            if (val) {
                let candidateEmailToSend = val.substring(0, val.length - 1);
                setemailtemplateTo(candidateEmailToSend);
            }
            handleEmailShow();
            setopenLoader(false);


        } else {
            ShowAlert(0, "Please select atleast one resume");
        }
    }
    const handleAddNewTemplate = () => {
        handleEmailTempShow();
    }

    const handleEmailSubjectChange = (e) => {
        if (e.target.value) {
            setemailSubject(e.target.value);
        } else {
            setemailSubject("");
        }
    }
    const handleEmailFromChange = (e) => {
        if (e.target.value) {

            setemailtemplateFrom(e.target.value);
        } else {
            setemailtemplateFrom("");
        }
    }
    const handleEmailToChange = (e) => {
        if (e.target.value) {
            setemailtemplateTo(e.target.value);
        } else {
            setemailtemplateTo("");
        }
    }
    const handleTemplateSelectChange = async () => {
        let templates = document.getElementById("selectTemplate");
        let templateCode = templates.options[templates.selectedIndex].value;
        let templateName = templates.options[templates.selectedIndex].text;
        const res = await EmailSendService.getTemplateBody(templateCode);
        setemailTemplateCode(templateCode);
        if (res.templateBody) {
            setemailSubject(res.templateSubject);
            const blocksFromHTML = convertFromHTML(res.templateBody);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            setEditorState(EditorState.createWithContent(state));
        } else {
            setemailSubject("");
            setEmailBodyState("");
            setEditorState(EditorState.createEmpty());
        }
    }
    const handleNewEmailTemplateNameChange = (e) => {
        if (e.target.value) {
            setnewEmailTempName(e.target.value);
        } else {
            setnewEmailTempName("");
        }
    }
    const handleSaveNewEmailTemplate = async () => {
        setopenLoader(true);
        const res = await EmailSendService.createNewEmailTemplate("0", newEmailTempName, "", "", "");
        if (res.isSuccess) {
            ShowAlert(1, res.message);
            initEmailTemplates(newEmailTempName);
            // setnewEmailTempName(newEmailTempName);
            setemailSubject("");
            setEmailBodyState("");
            setEditorState(EditorState.createEmpty());
            handleEmailTempClose();
            //             let tempcode = "";
            //             emailTemplateList.filter(function (filter) {
            //                 if (newEmailTempName) {
            //                     if (newEmailTempName.toString() === filter.EmailTemplateName) {
            //                         tempcode= filter.EmailTemplateCode ;
            //                     }
            //                 }
            //             });
            // console.log(tempcode);
            //setnewTempValue(tempcode);
        } else {
            ShowAlert(0, res.message);
        }
        setopenLoader(false);
    }
    const handleSendEmailToCandidate = async () => {
        setopenLoader(true);
        const candidateIds = [];
        searchResult.filter(function (filter) {
            for (let i = 0; i < selected.length; i++) {
                if (selected[i] === filter.CandidateResumeID) {
                    candidateIds.push({ canID: filter.CandidateID })
                }
            }
        });
        let val = "";
        for (var i = 0; i < candidateIds.length; i++) {
            val += candidateIds[i].canID + ",";
        }
        let candidateIDs = val.substring(0, val.length - 1);
        const res = await EmailSendService.sendEmailtoCandidate(candidateIDs, 0, emailtemplateTo, emailtemplateFrom, emailSubject, markup);
        if (res.isSuccess) {
            ShowAlert(1, res.message);
            handleEmailClose();
        } else {
            ShowAlert(0, res.message);
        }
        setopenLoader(false);
    }
    const handleSaveTemplateAndSendEmail = async () => {
        setopenLoader(true);
        const tempRes = await EmailSendService.createNewEmailTemplate(emailTemplateCode, newEmailTempName, "", emailSubject, markup);
        if (tempRes.isSuccess) {
            const candidateIds = [];
            searchResult.filter(function (filter) {
                for (let i = 0; i < selected.length; i++) {
                    if (selected[i] === filter.CandidateResumeID) {
                        candidateIds.push({ canID: filter.CandidateID })
                    }
                }
            });
            let val = "";
            for (var i = 0; i < candidateIds.length; i++) {
                val += candidateIds[i].canID + ",";
            }
            let candidateIDs = val.substring(0, val.length - 1);
            const res = await EmailSendService.sendEmailtoCandidate(candidateIDs, 0, emailtemplateTo, emailtemplateFrom, emailSubject, markup);
            if (res.isSuccess) {
                ShowAlert(1, "Email Send to candidates and template Saved");
                handleEmailClose();
            } else {
                ShowAlert(0, res.message);
            }

        } else {
            ShowAlert(0, "Email Not Sent");
        }
        setopenLoader(false);
    }
    const handleSMSBtnClick = () => {
        if (selected.length > 0) {
            const canContactNo = [];
            searchResult.filter(function (filter) {
                for (let i = 0; i < selected.length; i++) {
                    if (selected[i] === filter.CandidateResumeID) {
                        canContactNo.push({ canSmsNo: filter.CandidateMobile })
                    }
                }
            });
            let val = "";
            for (var i = 0; i < canContactNo.length; i++) {
                val += canContactNo[i].canSmsNo + ",";
            }
            if (val) {
                let candidateNoToSendSms = val.substring(0, val.length - 1);
                setsmsNumber(candidateNoToSendSms);
                handleSMSShow();
            }
        } else {
            ShowAlert(0, "Please select atleast one resume to Send SMS");
        }
    }
    const handleDownloadResumeBtnClick = async () => {
        setopenLoader(true);
        if (selected.length > 0) {
            const candidateIds = [];
            searchResult.filter(function (filter) {
                for (let i = 0; i < selected.length; i++) {
                    if (selected[i] === filter.CandidateResumeID) {
                        candidateIds.push({ canIDs: filter.CandidateID })
                    }
                }
            });
            let val = "";
            for (var i = 0; i < candidateIds.length; i++) {
                val += candidateIds[i].canIDs + ",";
            }
            if (val) {
                let candidateIDs = val.substring(0, val.length - 1);
                const res = await ResumeDownloadService.downloadResumes(candidateIDs);
                console.log(res);
                if (res.isSuccess) {
                    const zip = new JSZip();
                    const folder = zip.folder("Resumes"); // folder name where all files will be placed in 
                    for (let i = 0; i < res.resumes.length; i++) {
                        folder.file(res.resumes[i].fileName, res.resumes[i].resumeData, { base64: true });
                    }
                    //Generate the zip file asynchronously
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            // Force down of the Zip file
                            saveAs(content, "CandidateResumes.zip");
                        });
                } else {
                    ShowAlert(0, res.message);
                }
            } else {
            }

        } else {
            ShowAlert(0, "Please select one candidate");
        }
        setopenLoader(false);
    }
    const handleCheckClick = (isCheckAllRequest) => {
        let checkboxes = document.getElementsByClassName('resumeCheck');
        if (isCheckAllRequest) {
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
            }
        } else {
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
            }
        }
    }
    const handleSMSTempSelectChange = () => {
        let smsTemps = document.getElementById("smsTemp");
        let smsTempsCode = smsTemps.options[smsTemps.selectedIndex].value;
        let smsTempsName = smsTemps.options[smsTemps.selectedIndex].text;
        let smsMessages = [];
        smsTemplateList.filter(function (filter) {
            if (parseInt(smsTempsCode) === filter.SmsTemplateCode) {
                smsMessages.push({ message: filter.SmsTemplateMessage })
            }
        });
        setsmsMessage(smsMessages[0].message);
    }
    const handleNewSMSTemplate = () => {
        handleSMSTempShow();
    }
    const handleSMSNumberChange = (e) => {
        if (e.target.value) {
            setsmsNumber(e.target.value);
        } else {
            setsmsNumber("");

        }
    }
    const handleSMSMessageChange = (e) => {
        if (e.target.value) {
            setsmsMessage(e.target.value);
        } else {
            setsmsMessage("");
        }
    }
    const handleNewSMSTemplateNameChange = (e) => {
        if (e.target.value) {
            setnewSMSTempName(e.target.value);
        } else {
            setnewSMSTempName("");
        }
    }
    const handleSaveNewSMSTemplate = async () => {
        //setopenLoader(true);
        ShowAlert(1, "Successfully saved");
        // setopenLoader(false);
    }
    const handleSendSMSToCandidate = async () => {
        ShowAlert(1, "Successfully SMS send");
    }
    const handleSendSMSAndSaveTemplate = async () => {
        ShowAlert(1, "Template save and send SMS to Candidate");
    }
    const handleSMSClose = () => {
        setsmsMessage("");
        setsmsTempShow(false);
    }
    const handleEmailTempClose = () => {
        setemailSubject("");
        setEditorState(EditorState.createEmpty());
        settemplateShow(false);
    }
    const handleEmailClose = () => {
        setemailSubject("");
        setEmailBodyState("");
        setEditorState(EditorState.createEmpty());
        setemailShow(false);
    }
    const handleAddCommentClick = () => {
        handleCommentShow();
    }
    const handleResumeMove = async () => {
        let folders = document.getElementById("folderSelect");
        let folderid = folders.options[folders.selectedIndex].value;
        let foldertext = folders.options[folders.selectedIndex].text;
        const res = await AdvancesearchResultService.resumeMoveToFolder(folderid, selected);
        console.log(res);
        if (res.isSuccess) {
            ShowAlert(1, res.message + foldertext);
            handleResumeClose();

        } else {
            ShowAlert(0, res.message);
        }
    }
    const handleAssignmentChange = async () => {
        let assignment = document.getElementById("assignmentid");
        let assignmentid = assignment.options[assignment.selectedIndex].value;
        let assignmenttext = assignment.options[assignment.selectedIndex].text;
        if (assignmentid > 0) {
            const res = await CommonDropDownService.getJobPostingsById(assignmentid);
            res ? setjobPostingList(res) : setjobPostingList([]);
        } else {
            setjobPostingList([]);
        }
    }
    const handlejobPostingChange = async () => {


    }
    const initAssignmentlist = async () => {
        const res = await CommonDropDownService.getAssignments();
        setAssignments(res);
    }
    const initFolderlist = async () => {
        const res = await CommonDropDownService.getFolders();
        setmyFolders(res);
    }
    const initEmailTemplates = async (parmTempName) => {
        const res = await EmailSendService.getEmailTemplate();
        const emilTemp = JSON.parse(res.emailTemplate)
        setemailTemplateList(emilTemp);
        if (parmTempName) {
            let selectedID = "";
            emilTemp.filter(function (f) {
                if (f.EmailTemplateName.toLowerCase() === parmTempName.toLowerCase()) {
                    selectedID = f.EmailTemplateCode;
                }
            });
            setTimeout(() => {
                const selectEl = document.getElementById('selectTemplate');
                console.clear();
                console.log(selectEl)
                for (let i = 0; i < selectEl.length; i++) {
                    if (selectEl[i].value == selectedID) {
                        selectEl[i].selected = true;
                    }
                }

            }, 400);
        }


    }
    const initSMSTemplates = async () => {
        const res = await SmsSendService.getSmsTemplate();
        setSmsTemplateList(JSON.parse(res.smsTemplate));
    }
    const initClientLists = async () => {
        const res = await ShortListCandidateService.getClientList();
        setClientsList(res);
    }
    const initStatusLists = async () => {
        const res = await ShortListCandidateService.getStatuses();
        setStatusLists(res);
    }
    useEffect(() => {
        console.log(AuthService.getCurrentUser().userId);
        if (getSearchParms()) {
            setsearchResultText(getSearchParms().SearchKeywords);
            setsearchResultAnyword(getSearchParms().SearchAnyKeywords);
            //console.log(getSearchParms().SortByResult);
            initSearchResults(getSearchParms());
        }
        initFolderlist();
        initAssignmentlist();
        initEmailTemplates(null);
        initSMSTemplates();
        initClientLists();
        initStatusLists();
    }, [startIndex, itemPerPage]);

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

            {/* save your search */}
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "5rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Save Search</span>
                    </Modal.Title>
                    <button onClick={handleClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form method='POST' onSubmit={handleSubmit(handleSaveSearch)}>
                        <input type="hidden" name='ID' {...register("ID")} />
                        <div className='row mb-2'>
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Search Name</label>
                            </div>
                            <div className='col-7 mt-1'>
                                {
                                    <input type="text" name='searchName' className="form-control"
                                        {...register("searchName", {
                                            required: true
                                        })}
                                    />
                                }

                                {errors.searchName?.type === 'required' && <p className='text-danger mt-1'>Please enter search name</p>}
                            </div>

                        </div>
                        <Modal.Footer>
                            <Button variant="danger" onClick={handleClose}>
                                <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                            </Button>
                            <Button variant="primary" type='submit'><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save</Button>
                        </Modal.Footer>
                    </form>
                </Modal.Body>
            </Modal>
            {/*End save your search */}
            {/* resumeSave modal */}
            <Modal
                show={resumeshow}
                onHide={handleResumeClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Move To Personal Folder</span>
                    </Modal.Title>
                    <button onClick={handleResumeClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row mb-2'>
                        <div className='col-3 mt-1'>
                            <label className='control-label'>Select folder</label>
                        </div>
                        <div className='col-5 mt-1'>
                            <select className='form-control' name='folderSelect' id='folderSelect'>
                                <option value="">-- Select Folder --</option>
                                {
                                    myFolders.map((item, i) =>
                                        <option key={i} value={item.value}>{item.label}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className='col-3 mt-1'>
                            <Button variant="warning" className='btn-sm' type='button' onClick={() => handleResumeMove()}>Move</Button>
                        </div>


                    </div>
                    <div >
                        <TabContext value={tabvalue} >
                            <Box sx={{ marginTop: "1rem", borderColor: 'divider', width: "105%" }}>
                                <TabList onChange={handleChange}
                                    aria-label="lab API tabs example"
                                    textColor="secondary"
                                    indicatorColor="secondary"
                                    centered>
                                    <Tab selected={true} label="Personal Folder" value="one" iconPosition='start' icon="" style={{ minHeight: "0%" }} />
                                    <Tab label="Job Posting Folder" value="two" iconPosition='start' icon="" style={{ minHeight: "0%" }} />

                                </TabList>
                            </Box>
                            <TabPanel value="one" >
                                <div className='row'>
                                    <div className='col-5'>
                                        <label className='control-label'>Folder Name <span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className='col-7'>
                                        <input type='text' name='folderName' className='form-control' onChange={handlePersonalFolderChange} />
                                    </div>
                                    <div className='col-5 mt-3'>
                                    </div>
                                    <div className='col-7 mt-3'>
                                        <Button variant="success" className='btn-sm' onClick={() => handlePersonalFolderClick()} >Save </Button>&nbsp;
                                        <Button variant="danger" className='btn-sm' >Cancel </Button>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel value="two">
                                <div className='row'>
                                    <div className='col-5'>
                                        <label className='control-label' >Create new folder <span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className='col-7'>
                                        <input type='text' name='folderName' className='form-control' onChange={handleJobPostingFolderChange} />
                                    </div>
                                    <div className='col-5 mt-1'>
                                        <label className='control-label'>Assignment<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className='col-7 mt-1'>
                                        <select className='form-control' name='assignmentName' id='assignmentid' onChange={handleAssignmentChange}>
                                            <option value="">-- Select Assignment --</option>
                                            {
                                                assignments.map((item, i) =>
                                                    <option key={i} value={item.value}>{item.label}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className='col-5 mt-1'>
                                        <label className='control-label'>Job Posting<span style={{ color: "red" }}>*</span> :</label>
                                    </div>
                                    <div className='col-7 mt-1' >
                                        <select className='form-control' name='jobpostingName' id='jobpostings' onChange={handlejobPostingChange}>
                                            <option value="" >-- Select Job --</option>
                                            {
                                                jobPostingList.map((item, i) =>
                                                    <option key={i} value={item.value} >{item.label}</option>
                                                )
                                            }
                                        </select>
                                    </div>
                                    <div className='col-5 mt-1'>
                                    </div>
                                    <div className='col-7 mt-1'>
                                        <Button variant="success" className='btn-sm' onClick={() => handleJobPostingFolderClick()}>Save </Button>&nbsp;
                                        <Button variant="danger" className='btn-sm' >Cancel </Button>
                                    </div>
                                </div>
                            </TabPanel>

                        </TabContext>
                    </div>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleResumeClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* End resumeSave modal */}
            {/* shortlist candidate modal */}
            <Modal
                show={shortListshow}
                onHide={handleShortListClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Assign To Job Posting</span>
                    </Modal.Title>
                    <button onClick={handleShortListClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-5 mt-1'>
                            <label className='control-label font-13'>Client Name <span style={{ color: "red" }}>*</span> :</label>
                        </div>
                        <div className='col-7 mt-1'>
                            <Select
                                placeholder={"-- Select Clients --"}
                                onChange={handleSelectClients}
                                value={selectedOptionForClients}
                                isMulti={true}
                                options={clientsList}
                            />
                        </div>
                        <div className='col-5 mt-1'>
                            <label className='control-label font-13'>Assignment <span style={{ color: "red" }}>*</span> :</label>
                        </div>
                        <div className='col-7 mt-1'>
                            <Select
                                placeholder={"-- Select Assignment --"}
                                onChange={handleAssignmentSelect}
                                value={selectedOptionForAssignments}
                                isMulti={true}
                                options={assignmentOnClientList}
                            />
                        </div>
                        <div className='col-5 mt-1'>
                            <label className='control-label font-13'>Job Posting <span style={{ color: "red" }}>*</span> :</label>
                        </div>
                        <div className='col-7 mt-1'>
                            <Select
                                placeholder={"-- Select Job Posting --"}
                                onChange={handleJobPostingSelect}
                                value={selectedOptionForJobpostings}
                                isMulti={true}
                                options={jobpostingsOnAssignmentList}
                            />
                        </div>
                        <div className='col-5 mt-1'>
                            <label className='control-label font-13'> Status <span style={{ color: "red" }}>*</span> :</label>
                        </div>
                        <div className='col-7 mt-1'>
                            <select className='form-control' name='nationality'
                            >
                                <option value="">--- Select Status ---</option>
                                {
                                    statusLists.map((item, i) =>
                                        <option key={i} value={item.value}>{item.label}</option>)
                                }
                            </select>
                        </div>

                    </div>

                    <Modal.Footer>
                        <Button variant="danger" onClick={handleShortListClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                        <Button variant="primary" type='button' onClick={() => handleSaveShortListClick()} ><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* End shortlist candidate modal */}
            {/* export to Excel modal */}
            <Modal
                size='lg'
                show={excelshow}
                onHide={handleExcelClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'>Export To Excel</span>
                    </Modal.Title>
                    <button onClick={handleExcelClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <span><b>Personal Details</b></span>
                    <hr className=' mt-0' />
                    <div className='row mb-2'>
                        <div className='ml-3'>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Candidate ID</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Name</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Email ID</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Mobile No</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Contact No</label>
                        </div>
                        <div className='ml-3'>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Date Of Birth</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Gender</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Religion</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Present Address</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Permanent Address</label>
                        </div>
                    </div>
                    <span><b>Professional Details</b></span>
                    <hr className=' mt-0' />
                    <div className='row mb-2'>
                        <div className='ml-3'>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Key Skill</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Resume Title</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Work Experience</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Employer</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Current Location</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Salary</label>
                        </div>
                    </div>
                    <span><b>Qualification Details</b></span>
                    <hr className=' mt-0' />
                    <div className='row mb-2'>
                        <div className='ml-3'>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Qualification</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Qualification Specialization</label>
                        </div>
                    </div>
                    <span><b>Other Details</b></span>
                    <hr className=' mt-0' />
                    <div className='row mb-2'>
                        <div className='ml-3'>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Passport No</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Passport Details</label>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Recruiter Rating</label>
                        </div>
                    </div>
                    <span><b>Comments</b></span>
                    <hr className=' mt-0' />
                    <div className='row mb-2'>
                        <div className='ml-3'>
                            <input type='checkbox' className='ml-2' /> &nbsp;<label className='control-label'>Comments</label>
                            <input type='date' className='ml-2' />
                            <input type='date' className='ml-2' />
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleExcelClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                        <Button variant="primary" type='submit'><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* End export to Excel modal */}
            {/*  email candidates modal */}
            <Modal
                size='lg'
                show={emailshow}
                onHide={handleEmailClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'>Email Send</span>
                    </Modal.Title>
                    <button onClick={handleEmailClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row mb-2'>
                        <div className='col-3 mt-1'>
                            <label className='control-label'>Select Template :</label>
                        </div>
                        <div className='col-5 mt-1' style={{ marginLeft: "-2%" }}>
                            <select className='form-control' name='selectTemplate' id='selectTemplate' onChange={handleTemplateSelectChange}>
                                <option value="">-- Select Template --</option>
                                {
                                    emailTemplateList.map((item, i) =>
                                        <option key={i} value={item.EmailTemplateCode}>{item.EmailTemplateName}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className='col-3 mt-1'>
                            <Button variant="warning" className='btn-sm' type='button' onClick={() => handleAddNewTemplate()}>Add New</Button>
                        </div>
                        <div className='col-3 mt-1'>
                            <label className='control-label'>From :</label>
                        </div>
                        <div className='col-5 mt-1' style={{ marginLeft: "-2%" }}>
                            <input type='text' className='form-control' readOnly value={emailtemplateFrom} onChange={handleEmailFromChange} />
                        </div>
                        <div className='col-4 mt-1'></div>
                        <div className='col-3 mt-1'>
                            <label className='control-label'>To :</label>
                        </div>
                        <div className='col-8 mt-1' style={{ marginLeft: "-2%" }}>
                            {/* <input type='text' className='form-control' readOnly value={emailtemplateTo} onChange={handleEmailToChange} /> */}
                            <textarea rows="2" cols="25" className='form-control' value={emailtemplateTo} onChange={handleEmailToChange} readOnly />
                        </div>
                        <div className='col-1 mt-1'></div>
                        <div className='col-3 mt-1'>
                            <label className='control-label'>Subject :</label>
                        </div>
                        <div className='col-8 mt-1' style={{ marginLeft: "-2%" }}>
                            {/* <input type='text'  className='form-control' value={emailSubject} onChange={handleEmailSubjectChange} /> */}
                            <textarea rows="2" cols="25" className='form-control' value={emailSubject} onChange={handleEmailSubjectChange} />
                        </div>
                        <div className='col-1 mt-1'></div>
                        <div className='col-3 mt-1'>
                            <label className='control-label'>Body :</label>
                        </div>
                        <div className='col-8 mt-1' style={{ border: "1px solid black" }}>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={setEditorState}
                            />

                        </div>
                        <div className='col-1 mt-1'></div>

                    </div>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => handleSendEmailToCandidate()}> Send </Button>
                        <Button variant="warning" type='button' onClick={() => handleSaveTemplateAndSendEmail()}><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save Template And Send</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* email candidates modal */}
            {/* new template modal */}
            <Modal
                show={templateshow}
                onHide={handleEmailTempClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "5rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Add New Email Template</span>
                    </Modal.Title>
                    <button onClick={handleEmailTempClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row mb-2'>
                        <div className='col-5 mt-1'>
                            <label className='control-label'>Template Name <span style={{ color: "red" }}>*</span>:</label>
                        </div>
                        <div className='col-7 mt-1'>
                            <input type='text' className='form-control' onChange={handleNewEmailTemplateNameChange} />
                        </div>

                    </div>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleEmailTempClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                        <Button variant="primary" type='button' onClick={() => handleSaveNewEmailTemplate()}><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save</Button>
                    </Modal.Footer>

                </Modal.Body>
            </Modal>
            {/* end new template modal */}
            {/*  sms candidates modal */}
            <Modal
                size='lg'
                show={smsTempShow}
                onHide={handleSMSClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'>SMS Send</span>
                    </Modal.Title>
                    <button onClick={handleSMSClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row mb-2'>
                        <div className='col-3 mt-1'>
                            <label className='control-label'>Template :</label>
                        </div>
                        <div className='col-5 mt-1' style={{ marginLeft: "-2%" }}>
                            <select className='form-control' name='smsTemp' id='smsTemp' onChange={handleSMSTempSelectChange}>
                                <option value="">-- Select Template --</option>
                                {
                                    smsTemplateList.map((item, i) =>
                                        <option key={i} value={item.SmsTemplateCode}>{item.SmsTemplateName}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className='col-3 mt-1'>
                            <Button variant="warning" className='btn-sm' type='button' onClick={() => handleNewSMSTemplate()}>Add New</Button>
                        </div>
                        <div className='col-3 mt-1'>
                            <label className='control-label'>Number :</label>
                        </div>
                        <div className='col-5 mt-1' style={{ marginLeft: "-2%" }}>
                            <textarea rows="2" cols="25" className='form-control' readOnly value={smsNumber} onChange={handleSMSNumberChange} />
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-3 mt-1'>
                            <label className='control-label'>Message :</label>
                        </div>
                        <div className='col-8 mt-1' style={{ marginLeft: "-2%" }}>
                            <textarea rows="4" cols="25" className='form-control' value={smsMessage} onChange={handleSMSMessageChange} />
                        </div>
                    </div>
                    <Modal.Footer>
                        <Button variant="success" onClick={() => handleSendSMSToCandidate()}> Send </Button>
                        <Button variant="warning" type='button' onClick={() => handleSendSMSAndSaveTemplate()}><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save Template And Send</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* sms candidates modal */}
            {/* sms template modal */}
            <Modal
                show={newsmsTempShow}
                onHide={handleSMSTempClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "5rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Add New SMS Template</span>
                    </Modal.Title>
                    <button onClick={handleSMSTempClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row mb-2'>
                        <div className='col-5 mt-1'>
                            <label className='control-label'>Template Name <span style={{ color: "red" }}>*</span>:</label>
                        </div>
                        <div className='col-7 mt-1'>
                            <input type='text' className='form-control' onChange={handleNewSMSTemplateNameChange} />
                        </div>

                    </div>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleSMSTempClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                        <Button variant="primary" type='button' onClick={() => handleSaveNewSMSTemplate()}><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save</Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* end sms template modal */}
            {/* Add Comment modal */}
            <Modal
                show={commentShow}
                onHide={handleCommentClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Add Comments</span>
                    </Modal.Title>
                    <button onClick={handleCommentClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row mb-2'>
                        <div className='col-3 mt-1'>
                            <label className='control-label'>Comments <span style={{ color: "red" }}>*</span>:</label>
                        </div>
                        <div className='col-9 mt-1'>
                            <textarea rows="3" cols="25" className='form-control' />
                        </div>
                        <div className='col-3 mt-1'>
                        </div>
                        <div className='col-9 mt-1'>
                            <input type='checkbox' /> &nbsp;Can Client View &nbsp;
                            <input type='checkbox' /> &nbsp;Can Candidate View
                        </div>
                        <div className='col-3 mt-1'>
                        </div>
                        <div className='col-9 mt-1'>
                            <input type='file' /> &nbsp;<br></br>
                            <span style={{ fontSize: "10px" }}>Allowed file extensions are .jpg,.jpeg,.gif,.bmp,.png,.doc,.pdf,.docx,.tif. Maximum size of file 1024kb.</span>
                        </div>
                        <div className='col-5 mt-1'>
                        </div>
                        <div className='col-7 mt-1'>
                            <Button variant="warning" className='btn-sm' type='button' ><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save</Button>
                        </div>

                    </div>
                    <hr ></hr>
                    <div>
                        <span>Priyanka :

                            VACANCY SAUDI ARABIA - Industrial electrician/Cont

                            via email 06/08/2018 14:07:34</span>
                    </div>

                </Modal.Body>
            </Modal>
            {/* end Add Comment modal */}
            {/* Refine your search */}
            <MuiModal
                open={open}
                onClose={handleCancel}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={style}>
                    <form>
                        <input type="hidden" name='refineID' {...register("refineID")} />
                        <div className='row mb-2'>
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Keywords :<br /> (in entire resume) </label>
                            </div>
                            <div className='col-7 mt-1'>
                                {
                                    <input type="text" id='refinesearchInput' name='refinesearchName' className="form-control"
                                    // {...register("refinesearchName", {
                                    //     required: true
                                    // })}
                                    />
                                }
                                {errors.refinesearchName?.type === 'required' && <p className='text-danger mt-1'>Please enter search name</p>}
                            </div>
                            <div className='col-5 mt-1'>
                            </div>
                            <div className='col-7 mt-1'>
                                <input type="radio" name="Allword" className='allorany' id='refineallorany' checked={true}
                                // {...register("Allword", {
                                //     required: true,
                                // })}
                                /> All words &nbsp;
                                <input type="radio" name="Allword" className='allorany' id='refineallorany'
                                // {...register("Allword", {
                                //     required: true,
                                // })}
                                /> Any words
                            </div>

                        </div>

                        <div className='row mb-1'>
                            <div className='col-5 mt-1'></div>
                            <div className='col-7 mt-1'>
                                <Button variant="danger" onClick={handleCancel}>
                                    <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                                </Button>&nbsp;
                                <Button variant="primary" type='button' onClick={handleRefineSearch}><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Search</Button>
                            </div>
                        </div>

                    </form>
                </Box>
            </MuiModal>

            {/* Refine your search */}
            <LayoutAuthUser>
                <div className=''>
                    <Card>
                        <CardContent>
                            <div className='col-12 row ml-5'>
                                <div className='col-5'></div>
                                <div className='col-2'><Button variant="outline-success" onClick={() => handleSaveShow()}><i className="fa fa-floppy-o"></i>&nbsp;Save Search</Button></div>
                                <div className='col-2'><Button variant="outline-info" onClick={() => handleRefineShow()}><i className="fa fa-file-word-o" ></i>&nbsp; Refine Search</Button></div>
                                <div className='col-3'><Button variant="outline-primary" onClick={() => handleModifySearch()}><i className="fa fa-sign-in"></i>&nbsp; Modify Search</Button></div>
                            </div>
                            <hr style={{ borderBottom: "1px solid #1976d2", boxShadow: "0 0 10px 1px #1976d2", color: "#1976d2" }} />
                            <div style={{ fontSize: "12px" }}>Result For: &nbsp; "{searchResultText}",({searchResultAnyword} Words) </div>
                            <div className='col-12 row ml-5 mt-2'>
                                <div className='col-2' style={{ marginLeft: "-4%" }} ><span><Button variant="outline-success" onClick={() => handleSaveResume()}><i className="fa fa-floppy-o"></i>&nbsp;Save Resume</Button></span></div>
                                <div className='col-2' ><span><Button variant="outline-info" onClick={() => handleClickShortList()}><i className="fa fa-address-card-o" ></i>&nbsp; ShortList</Button></span></div>
                                <div className='col-2'><span><Button variant="outline-warning" onClick={() => handleExcelBtnClick()}><i className="fa fa-sign-in"></i>&nbsp; Export</Button></span></div>
                                <div className='col-2'><span><Button variant="outline-primary" onClick={() => handleEmailBtnClick()}><i className="fa fa-envelope-o"></i>&nbsp;Email</Button> </span></div>
                                <div className='col-2'><span><Button variant="outline-dark" onClick={() => handleSMSBtnClick()}><i className="fa fa-comments-o" ></i>&nbsp; SMS</Button></span></div>
                                <div className='col-2' ><span><Button variant="outline-success" title='Download Resume' onClick={() => handleDownloadResumeBtnClick()}><i className="fa fa-download"></i>&nbsp; Download</Button></span></div>

                            </div>

                            <hr />
                            <div style={{ cursor: "pointer" }}>
                                <span>
                                    <a className='theme-color' onClick={() => handleCheckClick(true)}>Check All</a>&nbsp;|&nbsp;
                                    <a className='theme-color' onClick={() => handleCheckClick(false)} >UnCheck All</a>
                                </span>
                            </div>
                            {
                                searchResult ? searchResult.map((item, i) =>
                                    <BCard className='mb-2' style={{ padding: "1px" }}>
                                        <div key={i}>
                                            <input type='checkbox' className='ml-1 resumeCheck' onClick={(e) => { handleCandidateResumeCheck(item.CandidateResumeID, e) }} />
                                            <span > <a href='' onClick={() => handleResumeTitleClick(item.CandidateResumeID, item.CandidateID)} style={{ textDecoration: "none", textTransform: "uppercase" }}>{item.CandidateResumeTitle}</a>
                                            </span>
                                            <span className='float-right' style={{ color: "green", marginRight: "1%" }}><i className="fa fa-user" ></i>&nbsp; {item.CandidateName}</span>
                                            <br />
                                            <p style={{ fontSize: "13px", marginLeft: "1%", marginBottom: "0rem" }} ><b>Key Skill :</b><span> &nbsp;{item.CandidateKeySkills}</span></p>
                                            <div className='col-12 row' style={{ marginLeft: "-2%" }}>
                                                <div className='col-4'>
                                                    <span style={{ fontSize: "13px" }} ><b>Age :</b>&nbsp;<span>{item.CandidateAge}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Education :</b>&nbsp;<span> {item.CandidateQualification}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Candidate ID :</b>&nbsp;<span>{item.CandidateID}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Last Modified On:</b>&nbsp;<span>{item.CandidateModifiedOn}</span></span>
                                                </div>
                                                <div className='col-4'>
                                                    <span style={{ fontSize: "13px" }} ><b>Employer :</b>&nbsp;<span>{item.CandidateCurrentEmployer},{item.CandidatePreviousEmployer}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Experience :</b>&nbsp;<span>{item.CandidateExperienceYear} Year(s)-{item.CandidateExperienceMonth} Month(s)</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Annual Salary :</b>&nbsp;<span>${item.AnnualSalary}</span></span><br />
                                                </div>
                                                <div className='col-4'>
                                                    <span style={{ fontSize: "13px" }} ><b>Email :</b>&nbsp;<span>{item.CandidateEmail}</span> <span>{item.IsEmailVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified" ></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Mobile :</b>&nbsp;<span>{item.CandidateMobile}</span><span>{item.IsMobileVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified"></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Location :</b>&nbsp;<span>{item.LocationName}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} >{item.IsViewed ? <p><b>Last Viewed On :</b> <span>{item.IsViewed}</span></p> : null}</span><br />
                                                </div>
                                            </div>
                                            <span className='float-right' style={{ marginRight: "27%", color: "#ffc107" }} onClick={() => handleAddCommentClick()}><MarkUnreadChatAltRoundedIcon /></span>
                                        </div>
                                    </BCard>
                                ) : <p className='text-center'>Opps ... No resume Found</p>
                            }
                            <div className='mt-2' style={{ marginLeft: "25rem" }}>
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next >"
                                    onPageChange={handlePageClick}
                                    pageCount={pageCount}
                                    previousLabel="< previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                        </CardContent>
                    </Card>

                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </LayoutAuthUser>
        </div>
    )
}
export default AdvancedSearchResult;