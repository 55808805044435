import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../../Layout/Layout';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { CandidateResumeFolderService } from '../../../Services/CandidateResumeFolderService';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import ReactPaginate from 'react-paginate';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import ListAltIcon from '@mui/icons-material/ListAlt';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import EmailIcon from '@mui/icons-material/Email';
import Modal from "react-bootstrap/Modal";
import SmsIcon from '@mui/icons-material/Sms';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import excelIcon from '../../../img/excel.png';
import MarkUnreadChatAltRoundedIcon from '@mui/icons-material/MarkUnreadChatAltRounded';
import { useNavigate } from 'react-router-dom';
import EmailTemplate from '../Templates/EmailTemplate';
import { EmailSendService } from '../../../Services/EmailSendService';
import SmsTemplate from '../Templates/SmsTemplate';
import JSZip from "jszip";
import { saveAs } from 'file-saver';
import { ResumeDownloadService } from '../../../Services/ResumeDownloadService';
import ResumeMoveToFolder from '../Templates/ResumeMoveToFolder';
import { CommentsService } from '../../../Services/CommentsService';
import ShortlistToOtherJob from '../Templates/ShortlistToOtherJob';
function CanResumeFolder() {
    const loc = useLocation();
    const navigate = useNavigate();
    const qSPFolder = loc.state ? loc.state.qsPFolder : null;
    const qSJPFolder = loc.state ? loc.state.qsJPFolder : null;
    const [emailShow, setemailShow] = useState(false);
    const handleEmailShow = () => setemailShow(true);
    const [smsShow, setsmsShow] = useState(false);
    const handleSMSShow = () => setsmsShow(true);
    const [resumeMoveShow, setresumeMoveShow] = useState(false);
    const handleResumeMoveShow = () => setresumeMoveShow(true);
    const [shortlistShow, setshortlistShow] = useState(false);
    const handleShortListShow = () => setshortlistShow(true);
    const [openLoader, setopenLoader] = React.useState(false);
    const [personalFolderResumes, setpersonalFolderResumes] = useState([]);
    const [jPFolderResumes, setjPFolderResumes] = useState([]);
    const [selected, setSelected] = useState([]);
    const [emailtemplateFrom, setemailtemplateFrom] = useState("");
    const [emailtemplateTo, setemailtemplateTo] = useState("");
    const [smsNumber, setsmsNumber] = useState("");
    const [moveResumeID, setmoveResumeID] = useState("");
    const [shortlistCandidateIDs, setshortlistCandidateIDs] = useState("");
    const itemPerPage = 4;
    const [pageCount, setpageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + itemPerPage;
    const personalFolderList = personalFolderResumes.slice(itemOffset, endOffset);
    const jPfitemPerPage = 5;
    const [jPfpageCount, setjPfpageCount] = useState(0);
    const [jPfitemOffset, setjPfItemOffset] = useState(0);
    const jPfendOffset = jPfitemOffset + jPfitemPerPage;
    const jPFolderList = jPFolderResumes.slice(jPfitemOffset, jPfendOffset);

    const handleSmsClose = () => {
        setsmsShow(false);
    }
    const handleEmailClose = () => {
        setemailShow(false);
    }
    const handleResumeMoveClose = () => {
        setresumeMoveShow(false);
    }
    const handleShortListClose = () => {
        setshortlistShow(false);
    }
    const handlejPFPageClick = (event) => {
        const newOffset = (event.selected * jPfitemPerPage) % jPFolderResumes.length;
        setjPfItemOffset(newOffset);
    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemPerPage) % personalFolderResumes.length;
        setItemOffset(newOffset);
    }
    const initCanResumeList = async () => {
        if (qSPFolder) {
            setopenLoader(true);
            const res = await CandidateResumeFolderService.getCanResumeFolder(1, 10, qSPFolder);
            setpersonalFolderResumes(res.resumeDetails);
            console.log(res.resumeDetails);
            setpageCount(Math.ceil(res.recordCount / itemPerPage));
            setopenLoader(false);
        } else {
            setopenLoader(true);
            const res = await CandidateResumeFolderService.getCanResumeFolder(1, 10, qSJPFolder);
            setjPFolderResumes(res.resumeDetails);
            console.log(res.resumeDetails);
            setjPfpageCount(Math.ceil(res.recordCount / jPfitemPerPage));
            setopenLoader(false);
        }
    }
    const handleAddCommentClick = () => {
    }
    const handleCheckClick = (isCheckAllRequest) => {
        let checkboxes = document.getElementsByClassName('resumeCheck');
        let newSelected = [];
        if (isCheckAllRequest) {
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = true;
                newSelected.push(checkboxes[i].value);
                //setresumeDeleteCanids(newSelected);
            }
        } else {
            for (let i = 0; i < checkboxes.length; i++) {
                checkboxes[i].checked = false;
                newSelected.push(checkboxes[i].value);
                //setresumeDeleteCanids(newSelected);
            }
        }
    }
    const handleEmailCandidateModalClick = async () => {
        let checkboxes = document.getElementsByClassName('resumeCheck');
        let newSelected = [];
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                newSelected.push(checkboxes[i].value);
            }
        }
        if (newSelected.length > 0) {
            setopenLoader(true);
            const useremail = await EmailSendService.getCurrentUserEmailID();
            setemailtemplateFrom(useremail.currentUserEmailID);
            var newcandidateId = newSelected.map(Number);
            const candidateresumes = [];
            if (qSPFolder) {
                personalFolderList.filter(function (f) {
                    for (let e = 0; e < newcandidateId.length; e++) {

                        if (f.candidateID === newcandidateId[e]) {
                            candidateresumes.push(f.candidateEmail);
                        }
                    }
                });
                setemailtemplateTo(candidateresumes);
                handleEmailShow();
            } else {
                jPFolderList.filter(function (f) {
                    for (let e = 0; e < newcandidateId.length; e++) {
                        if (f.candidateID === newcandidateId[e]) {
                            candidateresumes.push(f.candidateEmail);
                        }
                    }
                });
                console.log(candidateresumes);
                setemailtemplateTo(candidateresumes);
                handleEmailShow();
            }

        } else {
            ShowAlert(0, "Please select One candidate");
            setopenLoader(false);
        }
        setopenLoader(false);
    }
    const handleSMSCandidateModalClick = async () => {

        let checkboxes = document.getElementsByClassName('resumeCheck');
        let newSelected = [];
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                newSelected.push(checkboxes[i].value);
            }
        }
        if (newSelected.length > 0) {
            setopenLoader(true);
            var newcandidateId = newSelected.map(Number);
            const candidateresumes = [];
            if (qSPFolder) {
                personalFolderList.filter(function (f) {
                    for (let e = 0; e < newcandidateId.length; e++) {
                        if (f.candidateID === newcandidateId[e]) {
                            candidateresumes.push(f.candidateMobile);
                        }
                    }
                });
                setsmsNumber(candidateresumes);
                handleSMSShow();
            } else {
                jPFolderList.filter(function (f) {
                    for (let e = 0; e < newcandidateId.length; e++) {
                        if (f.candidateID === newcandidateId[e]) {
                            candidateresumes.push(f.candidateMobile);
                        }
                    }
                });
                setsmsNumber(candidateresumes);
                handleSMSShow();
            }
        } else {
            setopenLoader(false);
            ShowAlert(0, "Please select One candidate");
        }
        setopenLoader(false);
    }
    const handleDownloadResumeBtnClick = async () => {
        setopenLoader(true);
        let checkboxes = document.getElementsByClassName('resumeCheck');
        let newSelected = [];
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                newSelected.push(checkboxes[i].value);
            }
        }
        if (newSelected.length > 0) {
            var newcandidateId = newSelected.map(Number);
            if (newcandidateId.length > 0) {
                const res = await ResumeDownloadService.downloadResumes(newcandidateId.toString());
                console.log(res);
                if (res.isSuccess) {
                    const zip = new JSZip();
                    const folder = zip.folder("Resumes"); // folder name where all files will be placed in 
                    for (let i = 0; i < res.resumes.length; i++) {
                        folder.file(res.resumes[i].fileName, res.resumes[i].resumeData, { base64: true });
                    }
                    //Generate the zip file asynchronously
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            // Force down of the Zip file
                            saveAs(content, "CandidateResumes.zip");
                        });
                } else {
                    ShowAlert(0, res.message);
                }
            } else {
            }
        } else {
            ShowAlert(0, "Please select One candidate");
        }
        setopenLoader(false);
    }
    const handleMoveResumeBtnClick = async () => {
        setopenLoader(true);
        let checkboxes = document.getElementsByClassName('resumeCheck');
        let newSelected = [];
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                newSelected.push(checkboxes[i].value);
            }
        }
        if (newSelected.length > 0) {
            var newcandidateId = newSelected.map(Number);
            const candidateresumes = [];
            if (qSPFolder) {
                personalFolderList.filter(function (f) {
                    for (let e = 0; e < newcandidateId.length; e++) {
                        if (f.candidateID === newcandidateId[e]) {
                            candidateresumes.push(f.candidateResumeID);
                        }
                    }
                });
                setmoveResumeID(candidateresumes.toString());
                handleResumeMoveShow();
            } else {
                jPFolderList.filter(function (f) {
                    for (let e = 0; e < newcandidateId.length; e++) {
                        if (f.candidateID === newcandidateId[e]) {
                            candidateresumes.push(f.candidateResumeID);
                        }
                    }
                });
                setmoveResumeID(candidateresumes.toString());
                handleResumeMoveShow();
            }
        } else {
            ShowAlert(0, "Please select One candidate");
        }
        setopenLoader(false);
    }
    const handleMarkUnviewedBtnClick = async () => {
        setopenLoader(true);
        let checkboxes = document.getElementsByClassName('resumeCheck');
        let newSelected = [];
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                newSelected.push(checkboxes[i].value);
            }
        }
        if (newSelected.length > 0) {
            var newcandidateId = newSelected.map(Number);
            const candidateresumes = [];
            if (qSPFolder) {
                if (document.getElementById('removePViewBGColor')) {
                    document.getElementById('removePViewBGColor').style.backgroundColor = "#fff";
                    document.getElementById('plastViewed').style.display = "none";
                    document.getElementById('pviewedMessage').style.display = "none";
                    personalFolderList.filter(function (f) {
                        for (let e = 0; e < newcandidateId.length; e++) {
                            if (f.candidateID === newcandidateId[e]) {
                                candidateresumes.push(f.candidateID);
                            }
                        }
                    });
                    let canIDs = candidateresumes.map(String);
                    const res = await CommentsService.markUnView(canIDs);
                    console.log(res);
                } else {
                    ShowAlert(0, "You already Mark As Viewed.");
                }
            } else {
                if (document.getElementById('removeJPViewBGColor')) {
                    document.getElementById('removeJPViewBGColor').style.backgroundColor = "#fff";
                    document.getElementById('jplastViewed').style.display = "none";
                    document.getElementById('jpviewedMessage').style.display = "none";
                    jPFolderList.filter(function (f) {
                        for (let e = 0; e < newcandidateId.length; e++) {
                            if (f.candidateID === newcandidateId[e]) {
                                candidateresumes.push(f.candidateID);
                            }
                        }
                    });
                    const res = await CommentsService.markUnView(candidateresumes.toString());
                    console.log(res);
                } else {
                    ShowAlert(0, "You already Mark As Viewed.");
                }
            }
        } else {
            ShowAlert(0, "Please select One candidate.");
        }
        setopenLoader(false);
    }
    const handleShortlistCandidateBtnClick = async () => {
        setopenLoader(true);
        let checkboxes = document.getElementsByClassName('resumeCheck');
        let newSelected = [];
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                newSelected.push(checkboxes[i].value);
            }
        }
        if (newSelected.length > 0) {
            var newcandidateId = newSelected.map(Number);
            let canid= newcandidateId.toString();
            console.log(canid);
            setshortlistCandidateIDs(canid);
            handleShortListShow();
        } else {
            ShowAlert(0, "Please select One candidate.");
            setopenLoader(false);
        }
        setopenLoader(false);
    }
    const handleDeleteCandidateFromFolder = async () => {
        let checkboxes = document.getElementsByClassName('resumeCheck');
        let newSelected = [];
        for (let i = 0; i < checkboxes.length; i++) {
            if (checkboxes[i].checked) {
                newSelected.push(checkboxes[i].value);
            }
        }
        if (qSPFolder) {
            const response = await CandidateResumeFolderService.deleteCandidateFromFolder(newSelected, qSPFolder);
            if (response.isSuccess) {
                ShowAlert(1, response.message);
                initCanResumeList();
                for (let i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].checked) {
                        checkboxes[i].checked = false;
                    }
                }
            } else {
                ShowAlert(0, response.message);
            }
        } else {
            const response = await CandidateResumeFolderService.deleteCandidateFromFolder(newSelected, qSJPFolder);
            if (response.isSuccess) {
                ShowAlert(1, response.message);
                initCanResumeList();
                for (let i = 0; i < checkboxes.length; i++) {
                    if (checkboxes[i].checked) {
                        checkboxes[i].checked = false;
                    }
                }
            } else {
                ShowAlert(0, response.message);
            }
        }

    }
    const handleCandidateResumeCheck = (event) => {
        const selectedIndex = selected.indexOf(event);
        let newSelected = [];
        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, event);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    }
    const handleGetResumeDetailClick = (candidateResumeID, candidateID) => {
        const parms = btoa(JSON.stringify("candidateResumeID=" + candidateResumeID + "&candidateID=" + candidateID));
        navigate({
            pathname: '/client/resumeDetails',
            search: "?qs=" + parms,
        })
        //  navigate ({
        //     pathname:'/client/resumeDetails',
        //     search: "?candidateResumeID=" + candidateResumeID + "&candidateID=" + candidateID,
        // })
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    useEffect(() => {
        initCanResumeList();
    }, []);
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {/* Email modal */}
            <Modal
                size='lg'
                show={emailShow}
                onHide={handleEmailClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Add Folder</span>
                    </Modal.Title>
                    <button onClick={handleEmailClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <EmailTemplate
                        EmailFrom={emailtemplateFrom}
                        CandidateEmails={emailtemplateTo}
                    />

                </Modal.Body>
            </Modal>
            {/* End Email  modal */}
            {/* Sms modal */}
            <Modal
                size='lg'
                show={smsShow}
                onHide={handleSmsClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Send SMS</span>
                    </Modal.Title>
                    <button onClick={handleSmsClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <SmsTemplate
                        CandidateMobileNo={smsNumber}
                    />
                    <Modal.Footer>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* End Sms  modal */}
            {/* move to folder modal */}
            <Modal
                size='lg'
                show={resumeMoveShow}
                onHide={handleResumeMoveClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Send SMS</span>
                    </Modal.Title>
                    <button onClick={handleResumeMoveClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <ResumeMoveToFolder
                        CandidateResumeID={moveResumeID}
                    />
                    <Modal.Footer>
                        <button className='btn btn-danger btn-sm'>Close</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* End move to folder  modal */}
            {/* shortlist  modal */}
            <Modal
                show={shortlistShow}
                onHide={handleShortListClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> ShortList To Other Job</span>
                    </Modal.Title>
                    <button onClick={handleShortListClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <ShortlistToOtherJob
                        ShortlistCanID={shortlistCandidateIDs}
                    />
                    <Modal.Footer>
                        <button className='btn btn-danger btn-sm' onClick={handleShortListClose}>Close</button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* End shortlist  modal */}
            <Layout>

                <div className='col-12 row '>
                    <div className='col-3'></div>
                    <div className='col-6'>
                        <Card className='mb-2'>
                            <CardContent>
                                <ButtonGroup className='ml-5'>
                                    <Button variant="outline-success" className='btn-sm' title='Export To Excel'><img src={excelIcon} alt="Export To Excel" /></Button>&nbsp; &nbsp;
                                    <Button variant="outline-warning" onClick={() => handleEmailCandidateModalClick()} className='btn-sm ' title='Send Email'><EmailIcon /></Button>&nbsp;&nbsp;
                                    <Button variant="outline-info" onClick={() => handleSMSCandidateModalClick()} className='btn-sm ' title='Send SMS'><SmsIcon /></Button>&nbsp;&nbsp;
                                    <Button variant="outline-primary" onClick={() => handleDownloadResumeBtnClick()} className='btn-sm ' title='Download Resume'><DownloadForOfflineIcon /> </Button>&nbsp;&nbsp;
                                    <Button variant="outline-secondary" onClick={() => handleMoveResumeBtnClick()} className='btn-sm ' title='Move To Folder'><DriveFileMoveIcon /></Button>&nbsp;&nbsp;
                                    <Button variant="outline-dark" onClick={() => handleMarkUnviewedBtnClick()} className='btn-sm' title='Mark Unviewed'><VisibilityOffIcon /> </Button>&nbsp;&nbsp;
                                    <Button variant="outline-info" onClick={() => handleShortlistCandidateBtnClick()} className='btn-sm ' title='Shortlist For Other Job'><ListAltIcon /></Button>&nbsp;&nbsp;
                                    <Button variant="outline-danger" onClick={() => handleDeleteCandidateFromFolder()} className='btn-sm ' title='Delete Candidate From Folder'><DeleteSweepIcon /></Button>&nbsp;
                                </ButtonGroup>
                            </CardContent>
                        </Card>
                    </div>
                    <div className='col-3'></div>
                </div>
                <Card>
                    <CardContent>
                        <div className='mb-2' style={{ cursor: "pointer" }}>
                            <Button className=' btn-sm' variant="outline-success" onClick={() => handleCheckClick(true)}>Check All</Button>&nbsp;|&nbsp;
                            <Button className=' btn-sm' variant="outline-dark" onClick={() => handleCheckClick(false)} >UnCheck All</Button>
                        </div>
                        {
                            qSPFolder ? personalFolderList.map((item, i) =>
                                <Card className='mb-2' style={{ padding: "1px" }}>
                                    <div key={i}>
                                        {item.viewedDate ? <div style={{ backgroundColor: "#E8E7E7" }} id='removePViewBGColor'>
                                            <input type='checkbox' className='ml-1 resumeCheck' value={item.candidateID} onClick={(e) => { handleCandidateResumeCheck(item.candidateID, e) }} />
                                            <span > <a href='' onClick={() => handleGetResumeDetailClick(item.candidateResumeID, item.candidateID)} style={{ textDecoration: "none", textTransform: "uppercase" }}>{item.candidateResumeTitle}</a>
                                            </span>
                                            <span className='float-right' style={{ color: "green", marginRight: "1%" }}><i className="fa fa-user" ></i>&nbsp; {item.candidateFirstName}</span>
                                            <br />
                                            <p style={{ fontSize: "13px", marginLeft: "1%", marginBottom: "0rem" }} ><b>Key Skill :</b><span> &nbsp;{item.candidateKeySkills}</span></p>
                                            <div className='col-12 row' >
                                                <div className='col-4'>
                                                    <span style={{ fontSize: "13px" }} ><b>Age :</b>&nbsp;<span>{item.candidateAge}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Education :</b>&nbsp;<span> {item.candidateQualification}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Candidate ID :</b>&nbsp;<span>{item.candidateID}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Last Modified On:</b>&nbsp;<span>{item.candidateModifiedOn.split(' ')[0]}</span></span>
                                                </div>
                                                <div className='col-4'>
                                                    <span style={{ fontSize: "13px" }} ><b>Employer :</b>&nbsp;<span>{item.candidateCurrentEmployer},{item.candidatePreviousEmployer}</span></span><br />
                                                    {/* <span style={{ fontSize: "13px" }} ><b>Employer :</b>&nbsp;<span>{item.candidateCurrentEmployer ? item.candidateCurrentEmployer + "," + item.candidatePreviousEmployer : item.candidatePreviousEmployer}</span></span><br /> */}
                                                    <span style={{ fontSize: "13px" }} ><b>Experience :</b>&nbsp;<span>{item.candidateExperienceYear} Year(s)-{item.candidateExperienceMonth} Month(s)</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Annual Salary :</b>&nbsp;
                                                        <span> {item.maskAnnualSalary ? <span color='danger'>HIDDEN</span> : item.annualSalary == 0 ? <span> Not Mentioned</span> : "$" + item.annualSalary + " P/A"} </span></span><br />
                                                </div>
                                                <div className='col-4'>
                                                    <span style={{ fontSize: "13px" }} ><b>Email :</b>&nbsp;<span>{item.candidateEmail}</span> <span>{item.isEmailVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified" ></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Mobile :</b>&nbsp;<span>{item.candidateMobile}</span><span>{item.isMobileVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified"></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} ><b>Location :</b>&nbsp;<span>{item.locationName}</span></span><br />
                                                    <span style={{ fontSize: "13px" }} id='plastViewed'>{item.viewedDate ? <p>Last Viewed On : <span>{item.viewedDate.split(' ')[0]}</span></p> : null}</span><br />
                                                </div>
                                            </div>
                                            <span className='float-right' style={{ marginTop: "-2rem", marginRight: "20rem" }} id='pviewedMessage'><b>Mark As Unviewed</b></span>
                                            <div className=' row col-12'>
                                                <div className='col-9'></div>
                                                <div className='col-1'>

                                                    <span className='' style={{ marginRight: "27%", color: "#ffc107" }} onClick={() => handleAddCommentClick()}><MarkUnreadChatAltRoundedIcon /></span>
                                                </div>
                                                <div className='col-2'></div>
                                            </div>
                                        </div> :
                                            <div>
                                                <input type='checkbox' className='ml-1 resumeCheck' value={item.candidateID} onClick={(e) => { handleCandidateResumeCheck(item.candidateID, e) }} />
                                                <span > <a href='' onClick={() => handleGetResumeDetailClick(item.candidateResumeID, item.candidateID)} style={{ textDecoration: "none", textTransform: "uppercase" }}>{item.candidateResumeTitle}</a>
                                                </span>
                                                <span className='float-right' style={{ color: "green", marginRight: "1%" }}><i className="fa fa-user" ></i>&nbsp; {item.candidateFirstName}</span>
                                                <br />
                                                <p style={{ fontSize: "13px", marginLeft: "1%", marginBottom: "0rem" }} ><b>Key Skill :</b><span> &nbsp;{item.candidateKeySkills}</span></p>
                                                <div className='col-12 row' >
                                                    <div className='col-4'>
                                                        <span style={{ fontSize: "13px" }} ><b>Age :</b>&nbsp;<span>{item.candidateAge}</span></span><br />
                                                        <span style={{ fontSize: "13px" }} ><b>Education :</b>&nbsp;<span> {item.candidateQualification}</span></span><br />
                                                        <span style={{ fontSize: "13px" }} ><b>Candidate ID :</b>&nbsp;<span>{item.candidateID}</span></span><br />
                                                        <span style={{ fontSize: "13px" }} ><b>Last Modified On:</b>&nbsp;<span>{item.candidateModifiedOn.split(' ')[0]}</span></span>
                                                    </div>
                                                    <div className='col-4'>
                                                        <span style={{ fontSize: "13px" }} ><b>Employer :</b>&nbsp;<span>{item.candidateCurrentEmployer},{item.candidatePreviousEmployer}</span></span><br />
                                                        {/* <span style={{ fontSize: "13px" }} ><b>Employer :</b>&nbsp;<span>{item.candidateCurrentEmployer ? item.candidateCurrentEmployer + "," + item.candidatePreviousEmployer : item.candidatePreviousEmployer}</span></span><br /> */}
                                                        <span style={{ fontSize: "13px" }} ><b>Experience :</b>&nbsp;<span>{item.candidateExperienceYear} Year(s)-{item.candidateExperienceMonth} Month(s)</span></span><br />
                                                        <span style={{ fontSize: "13px" }} ><b>Annual Salary :</b>&nbsp;
                                                            <span> {item.maskAnnualSalary ? <span color='danger'>HIDDEN</span> : item.annualSalary == 0 ? <span> Not Mentioned</span> : "$" + item.annualSalary + " P/A"} </span></span><br />
                                                    </div>
                                                    <div className='col-4'>
                                                        <span style={{ fontSize: "13px" }} ><b>Email :</b>&nbsp;<span>{item.candidateEmail}</span> <span>{item.isEmailVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified" ></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                        <span style={{ fontSize: "13px" }} ><b>Mobile :</b>&nbsp;<span>{item.candidateMobile}</span><span>{item.isMobileVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified"></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                        <span style={{ fontSize: "13px" }} ><b>Location :</b>&nbsp;<span>{item.locationName}</span></span><br />
                                                        {/* <span style={{ fontSize: "13px" }} >{item.isViewed ? <p>Last Viewed On : <span>{item.isViewed}</span></p> : null}</span><br /> */}
                                                    </div>
                                                </div>
                                                <div className=' row col-12'>
                                                    <div className='col-9'></div>
                                                    <div className='col-1'>
                                                        <span className='' style={{ marginRight: "27%", color: "#ffc107" }} onClick={() => handleAddCommentClick()}><MarkUnreadChatAltRoundedIcon /></span>
                                                    </div>
                                                    <div className='col-2'></div>
                                                </div>
                                            </div>}

                                    </div>
                                </Card>
                            ) :
                                <div>
                                    {jPFolderList.map((item, i) =>
                                        <Card className='mb-2' style={{ padding: "1px" }}>
                                            <div key={i} >
                                                {item.viewedDate ? <div style={{ backgroundColor: "#E8E7E7" }} id='removeJPViewBGColor'>
                                                    <input type='checkbox' className='ml-1 resumeCheck' value={item.candidateID} onClick={(e) => { handleCandidateResumeCheck(item.candidateID, e) }} />
                                                    <span > <a href='' onClick={() => handleGetResumeDetailClick(item.candidateResumeID, item.candidateID)} style={{ textDecoration: "none", textTransform: "uppercase" }}>{item.candidateResumeTitle}</a>
                                                    </span>
                                                    <span className='float-right' style={{ color: "green", marginRight: "1%" }}><i className="fa fa-user" ></i>&nbsp; {item.candidateFirstName}</span>
                                                    <br />
                                                    <p style={{ fontSize: "13px", marginLeft: "1%", marginBottom: "0rem" }} ><b>Key Skill :</b><span> &nbsp;{item.candidateKeySkills}</span></p>
                                                    <div className='col-12 row' >
                                                        <div className='col-4'>
                                                            <span style={{ fontSize: "13px" }} ><b>Age :</b>&nbsp;<span>{item.candidateAge}</span></span><br />
                                                            <span style={{ fontSize: "13px" }} ><b>Education :</b>&nbsp;<span> {item.candidateQualification}</span></span><br />
                                                            <span style={{ fontSize: "13px" }} ><b>Candidate ID :</b>&nbsp;<span>{item.candidateID}</span></span><br />
                                                            <span style={{ fontSize: "13px" }} ><b>Last Modified On:</b>&nbsp;<span>{item.candidateModifiedOn.split(' ')[0]}</span></span>
                                                        </div>
                                                        <div className='col-4'>
                                                            <span style={{ fontSize: "13px" }} ><b>Employer :</b>&nbsp;<span>{item.candidateCurrentEmployer},{item.candidatePreviousEmployer}</span></span><br />
                                                            <span style={{ fontSize: "13px" }} ><b>Experience :</b>&nbsp;<span>{item.candidateExperienceYear} Year(s)-{item.candidateExperienceMonth} Month(s)</span></span><br />
                                                            <span style={{ fontSize: "13px" }} ><b>Annual Salary :</b>&nbsp;<span> {item.maskAnnualSalary ? <span color='danger'>HIDDEN</span> : item.annualSalary == 0 ? <span> Not Mentioned</span> : "$" + item.annualSalary + " P/A"}</span></span><br />
                                                        </div>
                                                        <div className='col-4'>
                                                            <span style={{ fontSize: "13px" }} ><b>Email :</b>&nbsp;<span>{item.candidateEmail}</span> <span>{item.isEmailVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified" ></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                            <span style={{ fontSize: "13px" }} ><b>Mobile :</b>&nbsp;<span>{item.candidateMobile}</span><span>{item.isMobileVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified"></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                            <span style={{ fontSize: "13px" }} ><b>Location :</b>&nbsp;<span>{item.locationName}</span></span><br />
                                                            <span style={{ fontSize: "13px" }} id='jplastViewed'>{item.viewedDate ? <p>Last Viewed On : <span>{item.viewedDate.split(' ')[0]}</span></p> : null}</span><br />
                                                        </div>
                                                    </div>
                                                    <span className='float-right' style={{ marginTop: "-2rem", marginRight: "20rem" }} id='jpviewedMessage'><b>Mark As Unviewed</b></span>
                                                    <div className=' row col-12'>
                                                        <div className='col-9'></div>
                                                        <div className='col-1'>
                                                            <span className='' style={{ marginRight: "27%", color: "#ffc107" }} onClick={() => handleAddCommentClick()}><MarkUnreadChatAltRoundedIcon /></span>
                                                        </div>
                                                        <div className='col-2'></div>
                                                    </div>
                                                </div>
                                                    :
                                                    <div>
                                                        <input type='checkbox' className='ml-1 resumeCheck' value={item.candidateID} onClick={(e) => { handleCandidateResumeCheck(item.candidateID, e) }} />
                                                        <span > <a href='' onClick={() => handleGetResumeDetailClick(item.candidateResumeID, item.candidateID)} style={{ textDecoration: "none", textTransform: "uppercase" }}>{item.candidateResumeTitle}</a>
                                                        </span>
                                                        <span className='float-right' style={{ color: "green", marginRight: "1%" }}><i className="fa fa-user" ></i>&nbsp; {item.candidateFirstName}</span>
                                                        <br />
                                                        <p style={{ fontSize: "13px", marginLeft: "1%", marginBottom: "0rem" }} ><b>Key Skill :</b><span> &nbsp;{item.candidateKeySkills}</span></p>
                                                        <div className='col-12 row' style={{ marginLeft: "-2%" }}>
                                                            <div className='col-4'>
                                                                <span style={{ fontSize: "13px" }} ><b>Age :</b>&nbsp;<span>{item.candidateAge}</span></span><br />
                                                                <span style={{ fontSize: "13px" }} ><b>Education :</b>&nbsp;<span> {item.candidateQualification}</span></span><br />
                                                                <span style={{ fontSize: "13px" }} ><b>Candidate ID :</b>&nbsp;<span>{item.candidateID}</span></span><br />
                                                                <span style={{ fontSize: "13px" }} ><b>Last Modified On:</b>&nbsp;<span>{item.candidateModifiedOn.split(' ')[0]}</span></span>
                                                            </div>
                                                            <div className='col-4'>
                                                                <span style={{ fontSize: "13px" }} ><b>Employer :</b>&nbsp;<span>{item.candidateCurrentEmployer},{item.candidatePreviousEmployer}</span></span><br />
                                                                <span style={{ fontSize: "13px" }} ><b>Experience :</b>&nbsp;<span>{item.candidateExperienceYear} Year(s)-{item.candidateExperienceMonth} Month(s)</span></span><br />
                                                                <span style={{ fontSize: "13px" }} ><b>Annual Salary :</b>&nbsp;<span> {item.maskAnnualSalary ? <span color='danger'>HIDDEN</span> : item.annualSalary == 0 ? <span> Not Mentioned</span> : "$" + item.annualSalary + " P/A"}</span></span><br />
                                                            </div>
                                                            <div className='col-4'>
                                                                <span style={{ fontSize: "13px" }} ><b>Email :</b>&nbsp;<span>{item.candidateEmail}</span> <span>{item.isEmailVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified" ></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                                <span style={{ fontSize: "13px" }} ><b>Mobile :</b>&nbsp;<span>{item.candidateMobile}</span><span>{item.isMobileVerified ? <i className="fa fa-check" style={{ color: "green" }} title="verified"></i> : <i className="fa fa-times" style={{ color: "red" }} title="Not verified"></i>}</span></span><br />
                                                                <span style={{ fontSize: "13px" }} ><b>Location :</b>&nbsp;<span>{item.locationName}</span></span><br />
                                                            </div>
                                                        </div>
                                                        <div className=' row col-12'>
                                                            <div className='col-9'></div>
                                                            <div className='col-1'>
                                                                <span className='' style={{ marginRight: "27%", color: "#ffc107" }} onClick={() => handleAddCommentClick()}><MarkUnreadChatAltRoundedIcon /></span>
                                                            </div>
                                                            <div className='col-2'></div>
                                                        </div>

                                                    </div>}
                                            </div>
                                        </Card>
                                    )}

                                    <div className='mt-2' style={{ marginLeft: "25rem" }}>
                                        <ReactPaginate
                                            breakLabel="..."
                                            nextLabel="next >"
                                            onPageChange={handlejPFPageClick}
                                            pageCount={jPfpageCount}
                                            previousLabel="< previous"
                                            pageClassName="page-item"
                                            pageLinkClassName="page-link"
                                            previousClassName="page-item"
                                            previousLinkClassName="page-link"
                                            nextClassName="page-item"
                                            nextLinkClassName="page-link"
                                            breakClassName="page-item"
                                            breakLinkClassName="page-link"
                                            containerClassName="pagination"
                                            activeClassName="active"
                                            renderOnZeroPageCount={null}
                                        />
                                    </div>
                                </div>
                        }
                        <div className='mt-2' style={{ marginLeft: "25rem" }}>
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={handlePageClick}
                                pageCount={pageCount}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </CardContent>
                </Card>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Layout>
        </div>
    )

}
export default CanResumeFolder;