import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import LayoutAuthUser from '../../../Layout/Layout';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthService } from '../../../Services/AuthService';
import { UserService } from '../../../Services/UserService';


function NotificationSetting() {
    const [openLoader, setopenLoader] = React.useState(false);
    const navigate = useNavigate();
    const [message, setmessage] = useState("");
    const { register, setValue, clearErrors, watch, formState: { errors }, handleSubmit } = useForm();
    const [notificationModes, setNotificationModes] = useState([]);



    const handleSaveNotificationSettings = async (data) => {
console.log(data);
    }
    const handleChangeCancel = () => {
        if (AuthService.isAuthenticatedUser()) {
            navigate("/mydashboard/profileDashboardTab");
        } else {
            navigate("/404");
        }
    }
    const initNotificationList = async () => {
        setopenLoader(true);
        const res = await UserService.getNotifiationSettings();
        console.log(res);
        setNotificationModes(res);
        setopenLoader(false);
    }
    useEffect(() => {
        if (AuthService.isAuthenticatedUser()) {
            initNotificationList();
        } else {
            navigate("/404");
        }
    }, []);


    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <LayoutAuthUser>
                <div className='' style={{ marginTop: "6rem", width: "45%", marginLeft: "25%" }}>
                    <h3 className='text-center'><b>Notifications </b><i className="fa fa-flag" style={{ fontSize: "28px",color:"red" }}></i></h3>
                    <Card sx={{ minWidth: 100 ,backgroundColor:"#F1F1F1"}}>
                        <CardContent>
                        <form method="POST" onSubmit={handleSubmit(handleSaveNotificationSettings)} className="mb-5">
                                {
                                    notificationModes.map((item, i) =>
                                        <div className='' key={i}><i className="fa fa-envelope-o"></i> <span style={{ color: '#1976d2' }}>
                                            <b>  {item.modeName} </b> </span>
                                            {
                                                item.oNotification.map((subNotics, subid) =>
                                                    <div key={subid}>
                                                        <b> {subNotics.notificationType}</b> <br />
                                                        {
                                                            subNotics.oChoiceList.map((choice, cid) =>
                                                                <span key={cid}>
                                                                    <input
                                                                        {...register(choice.choiceName
                                                                        )}
                                                                        value={cid.choiceID}
                                                                        defaultChecked={subNotics.oSelectedChoice.choiceID === choice.choiceID ? true : false}
                                                                        type="radio"
                                                                        name={choice.choiceName} />
                                                                    <span className='ml-1 mr-1'>{choice.choiceName}</span>
                                                                </span>
                                                            )
                                                        }
                                                    </div>
                                                )
                                            }
                                        </div>
                                    )}
                                <div className='mt-3 mb-2 float-left'>
                                <Button variant="success" type='submit'><i className="fa fa-floppy-o " aria-hidden="true"></i> Save</Button>&nbsp;
                                    <Button variant="danger" onClick={handleChangeCancel}>
                                        <i className="fa fa-times " aria-hidden="true" ></i> Close
                                    </Button> 
                                   
                                </div>
                            </form>
                        </CardContent>
                        </Card>
                        </div>
               
            </LayoutAuthUser>
        </div>
    )
}
export default NotificationSetting;