import React, { useState, useEffect } from 'react';
import { AuthService } from '../Services/AuthService';
import Layout from '../Layout/Layout';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
function HomePage() {
    const [openLoader, setopenLoader] = useState(false);
    return (
        <Layout>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <div style={{marginTop:"6%"}}>
                <h5 className='text-center'>
                    <span className='text-warning'>Hello &nbsp;</span>
                    <span className='text-success'>{AuthService.getCurrentUser().userName}</span>
                    <span className='text-warning'>&nbsp;Welcome To STS</span>
                </h5>
            </div>
        </Layout>

    )
}

export default HomePage