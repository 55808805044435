import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

function ExcelTemplate() {
    const handleCheckClick = (isCheckAllRequest) => {
        // let checkboxes = document.getElementsByClassName('resumeCheck');
        // let newSelected = [];
        // if (isCheckAllRequest) {
        //     for (let i = 0; i < checkboxes.length; i++) {
        //         checkboxes[i].checked = true;
        //         newSelected.push(checkboxes[i].value);
        //         //setresumeDeleteCanids(newSelected);
        //     }
        // } else {
        //     for (let i = 0; i < checkboxes.length; i++) {
        //         checkboxes[i].checked = false;
        //         newSelected.push(checkboxes[i].value);
        //         //setresumeDeleteCanids(newSelected);
        //     }
        // }
    }
    return (
        <div>
            <Card>
                <CardContent>
                    <div className='mb-2' style={{ cursor: "pointer" }}>
                        <b>Personal Details </b> &nbsp;
                        <a className=' btn-sm' variant="outline-success" onClick={() => handleCheckClick(true)}>Check All</a>&nbsp;|&nbsp;
                        <a className=' btn-sm' variant="outline-dark" onClick={() => handleCheckClick(false)} >UnCheck All</a>
                        <hr className='mt-1 mb-0'></hr>
                        <div className='personalDetail'>
                            <div>
                               <span style={{backgroundColor:"#e8f8f4",borderRadius:"1px"}}>&nbsp;<input type='checkbox' />  Candidate ID &nbsp;</span> 
                                <input type='checkbox' />  Name&nbsp;
                                <input type='checkbox' />  Email ID&nbsp;
                                <input type='checkbox' />  Mobile No&nbsp;
                                <input type='checkbox' />  Contact No
                            </div>
                            <div className='mt-1'>
                                <input type='checkbox' /> Date Of Birth &nbsp;
                                <input type='checkbox' /> Gender&nbsp;
                                <input type='checkbox' /> Religion&nbsp;
                                <input type='checkbox' /> Present Address&nbsp;
                                <input type='checkbox' /> Permanent Address
                            </div>
                        </div>
                        <div className='mt-2'>
                            <b>Professional Details </b> &nbsp;
                            <a className=' btn-sm' variant="outline-success" onClick={() => handleCheckClick(true)}>Check All</a>&nbsp;|&nbsp;
                            <a className=' btn-sm' variant="outline-dark" onClick={() => handleCheckClick(false)} >UnCheck All</a>
                            <hr className='mt-1 mb-0'></hr>
                            <div className='ProfessionalDetail'>
                                <div>
                                    <input type='checkbox' /> Key Skills &nbsp;
                                    <input type='checkbox' />  Resume Title&nbsp;
                                    <input type='checkbox' />  Work Experience&nbsp;
                                    <input type='checkbox' />  Employer&nbsp;
                                    <input type='checkbox' /> Location&nbsp;
                                    <input type='checkbox' />  Salary&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <b>Qualification  Details </b> &nbsp;
                            <a className=' btn-sm' variant="outline-success" onClick={() => handleCheckClick(true)}>Check All</a>&nbsp;|&nbsp;
                            <a className=' btn-sm' variant="outline-dark" onClick={() => handleCheckClick(false)} >UnCheck All</a>
                            <hr className='mt-1 mb-0'></hr>
                            <div className='qualificationDetail'>
                                <div>
                                    <input type='checkbox' /> Qualification &nbsp;
                                    <input type='checkbox' />  Qualification Specialization&nbsp;
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <b>Other Details </b> &nbsp;
                            <a className=' btn-sm' variant="outline-success" onClick={() => handleCheckClick(true)}>Check All</a>&nbsp;|&nbsp;
                            <a className=' btn-sm' variant="outline-dark" onClick={() => handleCheckClick(false)} >UnCheck All</a>
                            <hr className='mt-1 mb-0'></hr>
                            <div className='otherDetail'>
                                <div>
                                    <input type='checkbox' /> Passport Number &nbsp;
                                    <input type='checkbox' /> Passport Details&nbsp;
                                    <input type='checkbox' /> Recruiter Rating
                                </div>
                            </div>
                        </div>
                        <div className='mt-2'>
                            <b>Comments </b> &nbsp;
                            <a className=' btn-sm' variant="outline-success" onClick={() => handleCheckClick(true)}>Check All</a>&nbsp;|&nbsp;
                            <a className=' btn-sm' variant="outline-dark" onClick={() => handleCheckClick(false)} >UnCheck All</a>
                            <hr className='mt-1 mb-0'></hr>
                            <div className='comments mt-3'>
                                <div>
                                    <input type='checkbox' />Comments &nbsp;
                                    <input type='date' className='' /> &nbsp;
                                    <input type='date' />
                                </div>
                            </div>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    )
}
export default ExcelTemplate;