import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl()+"SocialMediaPosting/";


const SaveSocialMediaPost = async (color, assignmentID, jobIds,DescriptionText,userIDs,CompanyName,Address,Positions,IsForEmail) => {
 debugger;
    const socialMediaPost = {
        Color: color,
        EncAssignmentID: assignmentID,
        CurrentuserID: AuthService.getCurrentUser().userId,
        JobIDs: jobIds,
        DescriptionText:DescriptionText,
        UserIDs:userIDs,
        CompanyName:CompanyName,
        Address:Address,
        Positions:Positions,
        IsBulkEmail:IsForEmail
    }
    console.log(socialMediaPost);
    const res = await fetch(baseUrl + "SaveSocialmediaPost", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body:JSON.stringify(socialMediaPost)
    });
   
    const data = await res.json();
    return data;
}
const GETUserUsingAddressID = async (addressID) => {
    
    const res = await fetch(baseUrl + "UsersListUsingAddressID?addressID=" + addressID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GETAddressDDL = async () => {
    const res = await fetch(baseUrl + "GetAddressList", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    //console.log(data);
    return data;
}
export const SocialMediaPostService = {
    SaveSocialMediaPost,
    GETUserUsingAddressID,
    GETAddressDDL
}




