import React, { useState, Component, useEffect } from 'react';
import { Link } from "react-router-dom";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { AuthService } from '../../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import profileimg from '../../img/profile.jpg';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import AccountCircle from '@mui/icons-material/AccountCircle';
const NavMenu = () => {
    const navigate = useNavigate();
    const handleLogoutClick = () => {
        AuthService.logout();
        navigate('/login');
    }
    //const [Menus, setMenus] = useState([]);
    const settings = ['Edit Profile', 'Change Password', 'Logout'];
    const [Menus, setMenus] = React.useState([
        { name: 'Home', link: "/", childMenu: [] },
        { name: 'Assignment', link: "/assignment", childMenu: [] },
        { name: 'BackOffice User', link: "/backofficeuser" },
        { name: 'Manage Candidates', link: "/candidateList" },
        { name: 'My Folders', link: "/folder" },
        { name: 'Search', link: "/advanceSearch" }
    ]);

    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };
    const handleLoginClick = () => {
        console.log("login Clicked");
    };
    const handleOnParentMenuClick = (link) => {
        if (link) {
            navigate(link);
        }
    }

    useEffect(() => {
        // const initMenus = () => {
        //     const menus = AuthService.getUserMenus();
        //     setMenus(menus);
        //     console.log(menus);
        // }
        // initMenus();
    }, [])
    const title = AuthService.getCurrentUser().userName;
    return (

        <div>
            <AppBar position="fixed" >
                <Container maxWidth="xl">
                    <Toolbar disableGutters>


                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                onTouchMove={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {/* {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page}</Typography>

                                    </MenuItem>
                                ))} */}

                            </Menu>
                        </Box>

                        <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                        <Typography
                            variant="h5"
                            noWrap
                            component="a"
                            href=""
                            sx={{
                                mr: 2,
                                display: { xs: 'flex', md: 'none' },
                                flexGrow: 1,
                                fontFamily: 'monospace',
                                fontWeight: 700,
                                letterSpacing: '.3rem',
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            LOGO
                        </Typography>
                        {/* <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {Menus.map((m, i) => (
                                <Button onClick={() => handleOnParentMenuClick(m.MenuLink)} className={"dropdown1"}
                                    key={i}

                                    sx={{ my: 2, color: 'white', display: 'block' }}

                                >
                                    {
                                        <Link style={{ fontSize: '11px' }}
                                            className='cutom-parent-link'
                                            to={m.MenuLink}
                                        >
                                            {m.MenuName}
                                        </Link>
                                    }
                                    {
                                        m.Childmenu.length > 0 ?
                                            <div className="dropdown-content1">
                                                {m.Childmenu.map((c, i) =>
                                                    <span key={i}>
                                                        <Link to={c.MenuLink} >{c.MenuName}</Link>
                                                    </span>
                                                )}
                                            </div>

                                            : null
                                    }

                                </Button>

                            ))}
                            </Box> */}
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                            {Menus.map((m, i) => (
                                <Button onClick={() => handleOnParentMenuClick("")} className={"dropdown1"}
                                    key={i}

                                    sx={{ my: 2, color: 'white', display: 'block' }}

                                >
                                    {
                                        <Link style={{ fontSize: '11px' }}
                                            className='cutom-parent-link'
                                            to={m.link}
                                        >
                                            {m.name}
                                        </Link>
                                    }
                                    {/* {
                                        m.childMenu.length > 0 ?
                                            <div className="dropdown-content1">
                                                {m.childMenu.map((c, i) =>
                                                    <span key={i}>
                                                        <Link to={c.link} >{c.name}</Link>
                                                    </span>
                                                )}
                                            </div>

                                            : null
                                    } */}

                                </Button>

                            ))}

                        </Box>







                        <Box sx={{ flexGrow: 0 }}>


                            <li className="dropdown">
                                <AccountCircle/>
                            {/* <Avatar alt="Remy Sharp" src={profileimg}/> */}
                                <a className="dropbtn">{AuthService.getCurrentUser().userName}
                               
                                    <span>
                                        &nbsp;<i className="fa fa-caret-down down-arrow" aria-hidden="true"></i>
                                    </span>
                                </a>
                                <div className="dropdown-content">
                                    {/* <a href="/editmyprofile">Edit Profile</a> */}
                                    <a href="/changePassword">Change Password</a>
                                    {/* <a href="/settings">Settings</a> */}
                                    <a href="#" onClick={handleLogoutClick}>Logout</a>
                                </div>
                            </li>
                            <Menu
                                sx={{ mt: '45px' }}
                                id="menu-appbar"
                                anchorEl={anchorElUser}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElUser)}
                                onClose={handleCloseUserMenu}
                            >
                                {settings.map((setting) => (
                                    <MenuItem key={setting} onClick={handleCloseUserMenu}>
                                        <Typography textAlign="center">{setting}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box>

                    </Toolbar>
                </Container>
            </AppBar>
        </div>
    );
}
export default NavMenu;