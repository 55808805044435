import { AuthService } from './AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "Dashboard/";
const GetAssignmentLists = async (StartIndex) => {
    const paginateData = {
        StartIndex: StartIndex,
        PageSize: 10,
        UserID: AuthService.getCurrentUser().userId
    }
    const res = await fetch(baseUrl + "GetAssignmentList", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(paginateData)
    });
    const data = await res.json();
    return data;
}
const GetJobListByAssignmentID = async (assignmentID) => {
    const res = await fetch(baseUrl + "GetJobListByAssignmentID?encAssignmentId=" + assignmentID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const SaveOrEditAssignment = async (reqData) => {
    const assignmentData = {
        AssignmentCode: reqData.assignmentCode,
        AssignmentName: reqData.AssignmentName,
        ClientName: reqData.AssignmentName,
    }
    const res = await fetch(baseUrl + "SaveOrEditAssignment", {
        method: "post",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(assignmentData)
    });
    let data = await res.json();
    return data;
}
const DeleteAssignment = async (assignmentID) => {
    const res = await fetch(baseUrl + "DeleteAssignment?encAssignmentId=" + assignmentID, {
        method: "delete",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    let data = await res.json();
    return data;
}
const GetAssignmentById = async (assignmentId) => {
    const res = await fetch(baseUrl + "GetAssignmentById?encAssignmentId=" + assignmentId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    }
    );
    let data = await res.json();
    return data;
}

const GetAssignmentExcelData = async (assignmentID) => {
    const res = await fetch(baseUrl + "GetAssignmentExcelReport?encAssignmentId=" + assignmentID + "&userID=" + AuthService.getCurrentUser().userId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    }
    );
    let data = await res.json();
    return data;
}
const getUniqueAssignmentNameByClientID = async (clientID) => {
    const res = await fetch(baseUrl + "GetUniqueAssignmentNameByClientID?clientID=" +clientID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    }
    );
    let data = await res.json();
    return data;
}

export const DashboardService = {
    GetAssignmentLists,
    GetJobListByAssignmentID,
    SaveOrEditAssignment,
    DeleteAssignment,
    GetAssignmentById,
    GetAssignmentExcelData,
    getUniqueAssignmentNameByClientID
}