import { DomainService } from "./DomainService";
import { AuthService } from "./AuthService";

const baseUrl = DomainService.GetBaseUrl() + "JobPostingDetails/";
const getFilterAndStatusWithActionDetails = async (jobPostingID) => {
    const res = await fetch(baseUrl + "GetFilterWithStatus?userID=" + parseInt(AuthService.getCurrentUser().userId) + "&jobPostingID=" + jobPostingID + "&roleID=" + parseInt(AuthService.getCurrentUser().roleId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getJobpostingDDlByAssignmentID = async (assignmentID) => {
    const res = await fetch(baseUrl + "GetJobPostingDDl?assignmentID=" + assignmentID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getResumes = async (formData) => {
    const res = await fetch(baseUrl + "GetResumes", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(formData)
    });
    const data = await res.json();
    return data;
}
export const JobPostingDetailsService = {
    getFilterAndStatusWithActionDetails,
    getJobpostingDDlByAssignmentID,
    getResumes
}