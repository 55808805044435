import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../../Layout/Layout';
import 'react-toastify/dist/ReactToastify.css';
import Card from 'react-bootstrap/Card';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import '../../../css/3Dgrid.css';
import Table from 'react-bootstrap/Table';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import ReactPaginate from 'react-paginate';
import 'react-dual-listbox/lib/react-dual-listbox.css';
import DualListBox from 'react-dual-listbox';
import { CommonDropDownService } from '../../../Services/CommonDropDownService';
import { ClientFolderService } from '../../../Services/ClientFolderService';
import { AuthService } from '../../../Services/AuthService';
import excelIcon from '../../../img/excel.png';
import shareFolder from '../../../img/folder.png';
import deleteIcon from '../../../img/delete.png';
import FolderCopyIcon from '@mui/icons-material/FolderCopy';
import FolderOffIcon from '@mui/icons-material/FolderOff';
import PostAddRoundedIcon from '@mui/icons-material/PostAddRounded';
import CreateOutlinedIcon from '@mui/icons-material/CreateOutlined';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { ShareFolderService } from '../../../Services/ShareFolderService';
function Folders() {
    const navigate = useNavigate();
    const jPFitemPerPage = 10;
    const [jPFpageCount, setjPFpageCount] = useState(0);
    const [jPfitemOffset, setjPfItemOffset] = useState(0);
    const jPfendOffset = jPfitemOffset + jPFitemPerPage;
    const pFitemPerPage = 10;
    const [pFpageCount, setpFpageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(0);
    const endOffset = itemOffset + pFitemPerPage;
    const [shareFShow, setshareFShow] = useState(false);
    const handleShareFolderClose = () => setshareFShow(false);
    const handleShareFolderShow = () => setshareFShow(true);
    const [shareFolderName, setshareFolderName] = useState("");
    const [pFolderShow, setpFolderShow] = useState(false);
    const handlePFolderClose = () => setpFolderShow(false);
    const handlePFolderShow = () => setpFolderShow(true);
    const [jFolderShow, setjFolderShow] = useState(false);
    const handleJFolderClose = () => setjFolderShow(false);
    const handleJFolderShow = () => setjFolderShow(true);
    const [assignments, setAssignments] = useState([]);
    const [jobPostingList, setjobPostingList] = useState([]);
    const [personalFolders, setpersonalFolders] = useState([]);
    const [jPFolderShareList, setjPFolderShareList] = useState([]);
    const [jPFolderSharedList, setjPFolderSharedList] = useState([]);
    const [personalFolderShareList, setpersonalFolderShareList] = useState([]);
    const [personalFolderSharedList, setpersonalFolderSharedList] = useState([]);
    const [jPFolders, setjPFolders] = useState([]);
    const [openLoader, setopenLoader] = React.useState(false);
    const personalFolderList = personalFolders.slice(itemOffset, endOffset);
    const JPFolderList = jPFolders.slice(jPfitemOffset, jPfendOffset);
    const [pfolderID, setpfolderID] = useState(0);
    const [jpfolderID, setjpfolderID] = useState("");
    const [jpfolderName, setjpfolderName] = useState("");
    const [pfolderName, setpfolderName] = useState("");
    const [assignmentID, setassignmentID] = useState(0);
    const [jobpostingID, setjobpostingID] = useState(0);
    const handleJPCanFolderClick = (folderId, resumeCount) => {
        if (resumeCount > 0) {
            navigate('/canResumeFolder', { state: { qsJPFolder: folderId } });
        } else {
            ShowAlert(0, "Folder is Empty");
        }
    }
    const handlePersonalCanFolderClick = (folderId, resumeCount) => {
        if (resumeCount > 0) {
            navigate('/canResumeFolder', { state: { qsPFolder: folderId } });
        } else {
            ShowAlert(0, "Folder is Empty");
        }
    }
    const handlePersonalFolderClick = () => {
        handlePFolderShow();
    }
    const handleJobPostingFolderClick = () => {
        handleJFolderShow();
    }

    const handleShareFolderClick = async (fid, fName, jobpostingId) => {
        setshareFolderName(fName);
        if (jobpostingId != 0) {
            setopenLoader(true);
            const response = await ShareFolderService.getShareFolderCoordinator(fid, fName, jobpostingId);
            setjPFolderShareList(response.coordinators);
            setjPFolderSharedList(response.sharedCoordinators);
            console.log(jPFolderShareList);
            console.log(jPFolderSharedList);
            setopenLoader(false);
        } else {
            setopenLoader(true);
            const res = await ShareFolderService.getShareFolderCoordinator(fid, fName, jobpostingId);
            setpersonalFolderShareList(res.coordinators);
            setpersonalFolderSharedList(res.sharedCoordinators);
            setopenLoader(false);
        }
        handleShareFolderShow();
    }
    const handlePFExportToExcelClick = () => {
        ShowAlert(0, "Excel Under Maintainance");
    }
    const handleJPExportToExcelClick = () => {
        ShowAlert(0, "Excel Under Maintainance");
    }
    const handlePFolderEditClick = async (id) => {
        const res = await ClientFolderService.getFolderByID(id);
        console.log(res);
        setpfolderID(res.folderID);
        setpfolderName(res.folderName);
        handlePFolderShow();
    }
    const handleJPFolderEditClick = async (id) => {
        const res = await ClientFolderService.getFolderByID(id);
        console.log(res);
        setjpfolderID(res.folderID);
        setjpfolderName(res.folderName);
        // jobPostingList.filter(function (f) {
        //     if (res.jobPostingID === f.jobpostingId) {

        //     }
        // })
        //    setjobpostingID(res.jobPostingID);
        //    setassignmentID(res.assignmentID);
        handleJFolderShow();
    }
    const handleDeletePFolderClick = async (id, name) => {
        const res = await ClientFolderService.deleteMyPersonalFolder(id);
        if (res.isSuccess) {
            initPersonalFolderlist();
            ShowAlert(1, name + ' ' + res.message);
        } else {
            ShowAlert(0, res.message);
        }

    }
    const handleDeletejPFolderClick = async (id, name) => {
        const res = await ClientFolderService.deleteMyJobPostingFolder(id);
        if (res.isSuccess) {
            initJPFolderlist();
            ShowAlert(1, name + ' ' + res.message);
        } else {
            ShowAlert(0, res.message);
        }
    }
    const handleJPFPageClick = (event) => {
        const newOffset = (event.selected * jPFitemPerPage) % jPFolders.length;
        setjPfItemOffset(newOffset);
    }
    const handlePfPageClick = (event) => {
        const newOffset = (event.selected * pFitemPerPage) % personalFolders.length;
        setItemOffset(newOffset);

    }
    const handlePFolderNameChange = async (e) => {
        if (e.target.value) {
            setpfolderName(e.target.value);
        } else {
            setpfolderName("");
        }
    }
    const handlePersonalFolderSaveClick = async () => {
        if (pfolderID > 0) {
            const res = await ClientFolderService.saveorUpdatePersonalFolder(pfolderID, pfolderName, false);
            if (res.isSuccess) {
                ShowAlert(1, pfolderName + ' ' + res.message);
                initPersonalFolderlist();
                handlePFolderClose();
            } else {
                ShowAlert(0, res.message);
                handlePFolderClose();
            }
        } else {
            const res = await ClientFolderService.saveorUpdatePersonalFolder(pfolderID, pfolderName, true);
            if (res.isSuccess) {
                ShowAlert(1, pfolderName + ' ' + res.message);
                initPersonalFolderlist();
                handlePFolderClose();
            } else {
                ShowAlert(0, res.message);
                handlePFolderClose();
            }
        }
    }
    const handleJPFolderNameChange = async (e) => {
        if (e.target.value) {
            setjpfolderName(e.target.value);
        } else {
            setjpfolderName("");
        }
    }
    const handleAssignmentChange = async () => {
        let assignment = document.getElementById("assignmentid");
        let assignmentid = assignment.options[assignment.selectedIndex].value;
        let assignmenttext = assignment.options[assignment.selectedIndex].text;
        setassignmentID(assignmentid);
        if (assignmentid > 0) {
            const res = await CommonDropDownService.getJobPostingsById(assignmentid);
            res ? setjobPostingList(res) : setjobPostingList([]);
        } else {
            setjobPostingList([]);
        }
    }
    const handleJobpostingChange = async () => {
        let jobposting = document.getElementById("jobpostings");
        let jobpostingid = jobposting.options[jobposting.selectedIndex].value;
        let jobpostingname = jobposting.options[jobposting.selectedIndex].text;
        setjobpostingID(jobpostingid);
    }
    const handleJPFolderSaveClick = async () => {
        if (jpfolderID > 0) {
            const res = await ClientFolderService.saveorUpdateJobPostingFolder(jpfolderID, jpfolderName, jobpostingID, false);
            if (res.isSuccess) {
                ShowAlert(1, jpfolderName + ' ' + res.message);
                initJPFolderlist();
                handleJFolderClose();
            } else {
                ShowAlert(0, res.message);
                handleJFolderClose();
            }
        } else {
            const res = await ClientFolderService.saveorUpdateJobPostingFolder(0, jpfolderName, jobpostingID, true);
            if (res.isSuccess) {
                ShowAlert(1, jpfolderName + ' ' + res.message);
                initJPFolderlist();
                handleJFolderClose();
            } else {
                ShowAlert(0, res.message);
                handleJFolderClose();
            }
        }
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const initAssignmentlist = async () => {
        const res = await CommonDropDownService.getAssignments();
        setAssignments(res);
    }
    const initPersonalFolderlist = async () => {
        setopenLoader(true);
        const res = await ClientFolderService.getPersonalFolder();

        setpersonalFolders(res.folderList);
        setpFpageCount(Math.ceil(res.folderList.length / pFitemPerPage));
        setopenLoader(false);
    }
    const initJPFolderlist = async () => {
        setopenLoader(true);
        const res = await ClientFolderService.getJobPostingFolder();
        console.log(res);
        setjPFolders(res.folderList);
        setjPFpageCount(Math.ceil(res.folderList.length / jPFitemPerPage));
        setopenLoader(false);
    }
    const getSelectedOptions = (select) => {
        var result = [];
        var options = select.getElementsByClassName('ulist');
        for (var i = 0; i < options.length; i++) {
            if (options[i].selected)
                result.push(options[i]);
        };
        return result;
    }
    const handleBtnRightClick = (e) => {

    }
    const handleBtnLeftClick = (e) => {

    }
    useEffect(() => {
        if (AuthService.getApiAuthorizationConfig()) {
            console.log(AuthService.getCurrentUser());
        } else {

        }
        initAssignmentlist();
        initPersonalFolderlist();
        initJPFolderlist();
    }, []);
    return (
        <div>
            {/* personal modal */}
            <Modal
                show={pFolderShow}
                onHide={handlePFolderClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Add Folder</span>
                    </Modal.Title>
                    <button onClick={handlePFolderClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-5'>
                            <label className='control-label'>Folder Name <span style={{ color: "red" }}>*</span> :</label>
                        </div>
                        <div className='col-7'>
                            <input type='text' name='folderName' className='form-control' value={pfolderName} onChange={handlePFolderNameChange} />
                        </div>

                    </div>
                    <Modal.Footer>
                        <Button variant="danger" className='btn-sm' onClick={handlePFolderClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                        <Button variant="primary" className='btn-sm' onClick={() => handlePersonalFolderSaveClick()}> Save </Button>
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* End personal Folder modal */}
            {/* jobposting Folder modal */}
            <Modal
                show={jFolderShow}
                onHide={handleJFolderClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'>Add Folder</span>
                    </Modal.Title>
                    <button onClick={handleJFolderClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-5'>
                            <label className='control-label' >Create new folder <span style={{ color: "red" }}>*</span> :</label>
                        </div>
                        <div className='col-7'>
                            <input type='text' name='folderName' className='form-control' value={jpfolderName} onChange={handleJPFolderNameChange} />
                        </div>
                        <div className='col-5 mt-1'>
                            <label className='control-label'>Assignment<span style={{ color: "red" }}>*</span> :</label>
                        </div>
                        <div className='col-7 mt-1'>
                            <select className='form-control' name='assignmentName' id='assignmentid' onChange={handleAssignmentChange} >
                                <option value="">-- Select Assignment --</option>
                                {
                                    assignments.map((item, i) =>
                                        <option key={i} value={item.value}>{item.label}</option>
                                    )
                                }
                            </select>
                        </div>
                        <div className='col-5 mt-1'>
                            <label className='control-label'>Job Posting<span style={{ color: "red" }}>*</span> :</label>
                        </div>
                        <div className='col-7 mt-1' >
                            <select className='form-control' name='jobpostingName' id='jobpostings' onChange={handleJobpostingChange}>
                                <option value="" >-- Select Job --</option>
                                {
                                    jobPostingList.map((item, i) =>
                                        <option key={i} value={item.value} >{item.label}</option>
                                    )
                                }
                            </select>
                        </div>

                    </div>
                    <Modal.Footer>
                        <Button variant="danger" className='btn-sm' onClick={handleJFolderClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                        <Button variant="success" className='btn-sm' onClick={() => handleJPFolderSaveClick()}>Save </Button>&nbsp;
                    </Modal.Footer>
                </Modal.Body>
            </Modal>
            {/* End jobposting Folder modal */}
            {/* Share Folder modal */}
            <Modal
                show={shareFShow}
                onHide={handleShareFolderClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Share Folder</span>
                    </Modal.Title>
                    <button onClick={handleShareFolderClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <p><b>Folder Name : </b> {shareFolderName}</p>
                    <DualListBox
                        options={jPFolderShareList}
                        selected={jPFolderSharedList}
                    //onChange={this.onChange}
                    />
                    {/* <div className='row'>
                        <div className='col-5'>
                            <Table bordered hover size="sm">
                                <tbody>
                                    {
                                        jPFolderShareList ? jPFolderShareList.map((item, i) =>
                                            <tr key={i} value={item.boUserID}>
                                                <td >{item.name}</td>
                                            </tr>
                                        ) : personalFolderShareList.map((sitem, s) =>
                                            <tr key={s} value={sitem.boUserID}>
                                                <td >{sitem.name}</td>
                                            </tr>
                                        )
                                    }

                                </tbody>
                            </Table>

                        </div>
                        <div className='col-2'>
                            <Button variant="outline-info" className='btn-sm mt-4' > <ArrowCircleRight /> </Button><br></br>
                            <Button variant="outline-info" className='btn-sm mt-2' ><ArrowCircleLeft /></Button>
                        </div>
                        <div className='col-5' >
                            <Table bordered hover size="sm">
                                <tbody>
                                    {
                                        jPFolderSharedList ? jPFolderSharedList.map((item, i) =>
                                            <tr key={i} value={item.boUserID}>
                                                <td >{item.name}</td>
                                            </tr>
                                        ) : personalFolderSharedList.map((sditem, s) =>
                                            <tr key={s} value={sditem.boUserID}>
                                                <td >{sditem.name}</td>

                                            </tr>
                                        )
                                    }

                                </tbody>
                            </Table>

                        </div>

                    </div> */}
                    {/* <Modal.Footer>
                        <Button variant="danger" className='btn-sm' onClick={handlePFolderClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                        <Button variant="primary" className='btn-sm' > Save </Button>
                    </Modal.Footer> */}
                </Modal.Body>
            </Modal>
            {/* End Share Folder modal */}
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Layout>
                <Card>
                    <Card.Body>
                        <Tabs
                            defaultActiveKey="PERSONALFOLDER"
                            id="fill-tab-example"
                            className="mb-3"
                            fill
                            style={{ flex: "none" }}

                        >
                            <Tab eventKey="PERSONALFOLDER" title="PERSONAL FOLDER" >
                                <div>
                                    <Button variant="primary" className='btn-sm float-right mb-2' onClick={() => handlePersonalFolderClick()}>Add New Folder</Button>
                                </div>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%" }}>Status</th>
                                            <th>Folder Name</th>
                                            <th style={{ width: "10%" }}>Export To Excel</th>
                                            <th style={{ width: "10%" }}>Share Folder</th>
                                            <th style={{ width: "10%" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            personalFolderList ? personalFolderList.map((item, i) =>
                                                <tr key={i} value={item.folderId}>
                                                    <td >{item.isShared === "1" ? <FolderCopyIcon style={{ color: "green" }} titleAccess='Shared' /> : <FolderOffIcon style={{ color: "red" }} titleAccess='Not Shared' />} </td>
                                                    <td onClick={() => handlePersonalCanFolderClick(item.folderId, item.resumeCount)} style={{ cursor: "pointer" }}>{item.name}({item.resumeCount}&nbsp;Resumes)</td>
                                                    <td onClick={() => handlePFExportToExcelClick()}><img src={excelIcon} alt="Export To Excel" /></td>
                                                    <td onClick={() => handleShareFolderClick(item.folderId, item.name, 0)}><img src={shareFolder} /></td>
                                                    <td ><CreateOutlinedIcon color='warning' onClick={() => handlePFolderEditClick(item.folderId)} titleAccess='Edit Folder' />&nbsp;&nbsp;
                                                        &nbsp;&nbsp;{item.oFolderDetail.isOwner ? <img src={deleteIcon} title='Delete Folder' onClick={() => handleDeletePFolderClick(item.folderId, item.name)} /> : ''}</td>
                                                </tr>
                                            ) : null
                                        }

                                    </tbody>
                                </Table>
                                <div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="next >"
                                        onPageChange={handlePfPageClick}
                                        pageCount={pFpageCount}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                        renderOnZeroPageCount={null}
                                    />
                                </div>
                            </Tab>
                            <Tab eventKey="JOBPOSTINGFOLDER" title="JOB POSTING FOLDER">
                                <div>
                                    <Button variant="primary" className='btn-sm float-right mb-2' onClick={() => handleJobPostingFolderClick()}>Add New Folder</Button>
                                </div>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "10%" }}>Status</th>
                                            <th>Folder Name</th>
                                            <th>Assignment</th>
                                            <th>Job Posting</th>
                                            <th style={{ width: "10%" }}>Export To Excel</th>
                                            <th style={{ width: "10%" }} >Share Folder</th>
                                            <th style={{ width: "10%" }}>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            JPFolderList ? JPFolderList.map((item, i) =>
                                                <tr key={i} value={item.folderId}>
                                                    <td >{item.isShared === "1" ? <FolderCopyIcon style={{ color: "green" }} titleAccess='Shared' /> : <FolderOffIcon style={{ color: "red" }} titleAccess='Not Shared' />} </td>
                                                    <td onClick={() => handleJPCanFolderClick(item.folderId, item.resumeCount)} style={{ cursor: "pointer" }}>{item.name}({item.resumeCount}&nbsp;Resumes)</td>
                                                    <td>{item.assignmentCode}</td>
                                                    <td>{item.jobCode}</td>
                                                    <td onClick={() => handleJPExportToExcelClick()}><PostAddRoundedIcon color='primary' /></td>
                                                    <td onClick={() => handleShareFolderClick(item.folderId, item.name, item.jobpostingId)}><img src={shareFolder} /></td>
                                                    <td ><CreateOutlinedIcon color='warning' onClick={() => handleJPFolderEditClick(item.folderId, item.jobpostingId)} titleAccess='Edit Folder' />&nbsp;&nbsp;
                                                        &nbsp;&nbsp;{item.oFolderDetail.isOwner ? <img src={deleteIcon} title='Delete Folder' onClick={() => handleDeletejPFolderClick(item.folderId, item.name)} /> : ''}</td>
                                                </tr>
                                            ) : null
                                        }

                                    </tbody>
                                </Table>
                                <div>
                                    <ReactPaginate
                                        breakLabel="..."
                                        nextLabel="next >"
                                        onPageChange={handleJPFPageClick}
                                        pageCount={jPFpageCount}
                                        previousLabel="< previous"
                                        pageClassName="page-item"
                                        pageLinkClassName="page-link"
                                        previousClassName="page-item"
                                        previousLinkClassName="page-link"
                                        nextClassName="page-item"
                                        nextLinkClassName="page-link"
                                        breakClassName="page-item"
                                        breakLinkClassName="page-link"
                                        containerClassName="pagination"
                                        activeClassName="active"
                                    //renderOnZeroPageCount={null}
                                    />
                                </div>
                            </Tab>
                        </Tabs>

                    </Card.Body>
                </Card>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Layout>
        </div>
    )
}
export default Folders;