import { AuthService } from './AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "ShareFolder/";


const getShareFolderCoordinator = async (folderId, folderName, jobpostingID) => {
    const res = await fetch(baseUrl + "GetCoordinatorForShareFolder?folderId="+folderId+"&folderName="+folderName+"&jobpostingID="+jobpostingID+"&UserID=" + parseInt(AuthService.getCurrentUser().userId) , {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
export const ShareFolderService={
    getShareFolderCoordinator,
}