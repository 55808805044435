import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { DashboardService } from './Services/DashboardService';
import { AssignmentService } from "./Services/AssignmentService";
import { SocialMediaPostService } from "./Services/SocialMediaPostService";
import NewJobPosting from "./Components/Main/NewJobPosting";
import { AuthService } from './Services/AuthService';
import { SketchPicker } from "react-color";
import { ToastContainer, toast } from 'react-toastify';
import Layout from './Layout/Layout';
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import DataTable from 'react-data-table-component';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import ReactPaginate from 'react-paginate';
import './css/loader.css';
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import './css/colorPicker.css';
import JobPostingModal from './Components/Main/JobPostingModal';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { Button } from 'react-bootstrap';
function App() {
  const navigate = useNavigate();
  const isAuth = AuthService.isAuthenticatedUser();
  const [assignmnentName, setassignmnentName] = useState("");
  const [assignmentID, setassignmentID] = useState("");
  const [assignmentIsActive, setassignmentIsActive] = useState("");
  const [hasassignmnentNameErr, sethasassignmnentNameErr] = useState(false);
  const [assignmnentDetails, setassignmnentDetails] = useState("");
  const [selectedOptionForClient, setselectedOptionForClient] = useState({ label: "-- Select Client --", value: "Select Client" });
  const [selectedOptionForCordinator, setselectedOptionForCordinator] = useState({});
  const [isMaxCoordinatorsSelected, setisMaxCoordinatorsSelected] = useState(false);
  const [isShowClientddlErr, setisShowClientddlErr] = useState(false);
  const [isShowcoordinatorddlErr, setisShowcoordinatorddlErr] = useState(false);
  const [isAssignmentActive, setisAssignmentActive] = useState(1);
  const [hasAssignmnentDetails, sethasAssignmnentDetails] = useState(false);
  const [searchText, setsearchText] = useState("");
  const [clientList, setclientList] = useState([]);
  const [CoordinatorList, setCoordinatorList] = useState({});

  const [companyHeader, setcompanyHeader] = useState({});
  const [selectJob, setselectJob] = useState({});
  const [descrioptionText, setdescrioptionText] = useState("");
  const [fromJobAddress, setfromJobAddress] = useState({});
  const [fromJobAddressSelected, setfromJobAddressSelected] = useState({});
  const [formUserDDl, setformUserDDL] = useState({});

  const [selectedOptionForJobTitel, setselectedOptionForJobTitel] = useState([]);
  const [selectedOptionForColor, setselectedOptionForColor] = useState("rgba(241,112,19,11)");
  const [selectedOptionForAddress, setselectedOptionForAddress] = useState([]);
  const [selectedOptionForUser, setselectedOptionForUser] = useState([]);

  const [isShowHeaderErr, setisShowHeaderErr] = useState("");
  const [isShowJobDescriptionErr, setisShowJobDescriptionErr] = useState("");
  const [isShowJobTitleddlErr, setisShowJobTitleddlErr] = useState(false);
  // const [isShowColorddlErr, setisShowColorddlErr] = useState(false);
  const [isShowAddressddlErr, setisShowAddressddlErr] = useState(false);
  const [isShowUserddlErr, setisShowUserddlErr] = useState(false);
  const [jobTitleList, setjobTitleList] = useState([]);
  const [assignmentIdforSM, setassignmentIdforSM] = useState("");

  const [openLoader, setopenLoader] = React.useState(false);
  const { register, setValue, clearErrors, formState: { errors }, handleSubmit } = useForm();
  const [assignmentList, setassignmentList] = useState([]);
  const [assignmentListData, setassignmentListData] = useState([]);
  const [jobpostingList, setjobPostingList] = useState([]);
  const [show, setShow] = useState(false);
  const [modalTitle, setmodalTitle] = useState("Add");
  const [iconClass, seticonClass] = useState("");
  const [message, setmessage] = useState("");
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [newjobshow, setnewjobShow] = useState(false);
  const handleCloseNewjob = () => setnewjobShow(false);
  const handleShowNewjob = () => setnewjobShow(true);
  const [showPicker, setshowPicker] = useState(false);
  const handleClosejob = () => setshowjob(false);
  const handleShowjob = () => setshowjob(true);
  const [showjob, setshowjob] = useState(false);
  const [jobmodalTitle, setjobmodalTitle] = useState("Post");
  const [jobiconClass, setjobiconClass] = useState("");
  const [jobmessage, setjobmessage] = useState("");
  const [assignmentCode, setassignmentCode] = useState("");
  const [jPAssignmentID, setjPAssignmentID] = useState("");
  const [isBulkEmail, setisBulkEmail] = useState(false);
  const [showLinkModal, setshowLinkModal] = useState(false);
  const [QsEncId, SetQsEncId] = useState(false);
  const [copyLink, setCopyLink] = useState(false);
  const handleCloseLinkModal = () => setshowLinkModal(false);
  const handleOpenLinkModal = () => setshowLinkModal(true);
  const OnClick = () => {
    setshowPicker(true);
  }
  const OnClose = () => {
    setshowPicker(false);
  }


  const [pageCount, setpageCount] = useState(0);
  const [itemOffset, setItemOffset] = useState(10);
  const itemsPerPage = 10;
  const [startIndex, setstartIndex] = useState(0);

  const handleHeaderChange = (e) => {
    if (!IsEmptyOrNullValues(e.target.value)) {
      setcompanyHeader(e.target.value);
      setisShowHeaderErr(false);
    } else {
      setcompanyHeader("");
      setisShowHeaderErr(true);
    }
  }
  const handlePositionDescription = (e) => {
    if (!IsEmptyOrNullValues(e.target.value)) {
      setdescrioptionText(e.target.value);
      setisShowJobDescriptionErr(false);
    } else {
      setdescrioptionText("");
      setisShowJobDescriptionErr(true);
    }
  }
  const handleJobTitleChange = (selectedOption) => {
    let jobID = [];
    let jobName = [];
    if (selectedOption.length > 0) {
      for (let i = 0; i < selectedOption.length; i++) {
        jobID.push(selectedOption[i].value);
        jobName.push(selectedOption[i].label);
      }
      setselectJob(jobName);
      setselectedOptionForJobTitel(selectedOption);
      setisShowJobTitleddlErr(false);
    } else {
      setselectJob("");
      setselectedOptionForJobTitel(undefined);
      setisShowJobTitleddlErr(true);
    }
  }
  const handleColorChange = (selectedOption) => {
    console.log(selectedOption.hex);
    setselectedOptionForColor(selectedOption.hex);
    // if (selectedOption.length > 0) {

    //   console.log(selectedOption);

    //   setisShowColorddlErr(false);
    // } else {
    //   setselectedOptionForColor(undefined);
    //   setisShowColorddlErr(false);
    // }
  }
  const handleAddressChange = (selectedOption) => {

    let addressID = [];
    let addressNames = [];
    if (selectedOption.length > 0) {
      setselectedOptionForAddress(selectedOption);
      setisShowAddressddlErr(false);
      for (let i = 0; i < selectedOption.length; i++) {
        addressID.push(selectedOption[i].value);
        addressNames.push(selectedOption[i].label);
      }
      formUserDDLList(addressID);
      setfromJobAddressSelected(addressNames);
    } else {
      setfromJobAddressSelected(undefined);
      setselectedOptionForAddress({});
      setselectedOptionForAddress(undefined);
      setselectedOptionForAddress("");
      setformUserDDL(addressID);
      setisShowAddressddlErr(true);
    }
  }
  const formUserDDLList = async (addressID) => {

    const userListByADSID = await SocialMediaPostService.GETUserUsingAddressID(addressID);

    userListByADSID ? setformUserDDL(userListByADSID) : setformUserDDL({});
  }
  const handleUserChange = (selectedOption) => {
    if (selectedOption.length > 0) {
      setselectedOptionForUser(selectedOption);
      console.log(selectedOption);
      setisShowUserddlErr(false);
    } else {
      setisShowUserddlErr(true);
      setselectedOptionForUser(undefined);
    }
  }
  const handleCoordinatorChange = (selectedOption) => {
    setselectedOptionForCordinator(selectedOption);
    if (selectedOption.length === 0 || selectedOption.length === undefined) {
      setisShowcoordinatorddlErr(true);
    } else {
      setisShowcoordinatorddlErr(false);
      if (selectedOption.length > 10) {
        setisMaxCoordinatorsSelected(true);
      } else {
        setisMaxCoordinatorsSelected(false);
      }
    }
  }
  const handleClientChange = async (selectedOption) => {
    setselectedOptionForClient(selectedOption);
    if (selectedOption.value === "" || selectedOption.value === "Select Client" || selectedOption.value === undefined) {
      setisShowClientddlErr(true);
    } else {
      const res = await DashboardService.getUniqueAssignmentNameByClientID(parseInt(selectedOption.value));
      document.getElementById("assignmentNameID").value = res.apiData;
      sethasassignmnentNameErr(false);
      setisShowClientddlErr(false);
    }

  }
  const handleClientInputChange = (value) => {
    setsearchText(value);
  }
  const handleAssignmentNameChange = (e) => {
    const val = e.target.value;
    setassignmnentName(val);
    if (val === "" || val === undefined || val === null) {
      sethasassignmnentNameErr(true);
    } else {
      sethasassignmnentNameErr(false);
    }
  }
  const handleAssignmentDetailsChange = (e) => {
    const val = e.target.value;
    setassignmnentDetails(val);
    if (val === "" || val === undefined || val === null) {
      sethasAssignmnentDetails(true);
    } else {
      sethasAssignmnentDetails(false);
    }
  }
  const addAssignment = () => {
    setassignmnentName("");
    setselectedOptionForClient("");
    setassignmnentDetails("");
    setselectedOptionForCordinator("");
    setassignmentIsActive("");
    setassignmentID(0);
    setmodalTitle("Add");
    seticonClass("fa fa-plus icon-color1");
    handleShow();
    setTimeout(() => {
      document.getElementById("hdassignmentID").value = null;
    }, 200);
  }

  const saveAssignment = async (data) => {
    const getAssignmentName = document.getElementById("assignmentNameID").value;
    if (getAssignmentName === "" || getAssignmentName === undefined || getAssignmentName === null) {
      sethasassignmnentNameErr(true);
    } else {
      sethasassignmnentNameErr(false);
    }
    if (assignmnentDetails === "" || assignmnentDetails === undefined || assignmnentDetails === null) {
      sethasAssignmnentDetails(true);
    } else {
      sethasAssignmnentDetails(false);
    }
    if (selectedOptionForClient.value === "" || selectedOptionForClient.value === "Select Client" || selectedOptionForClient.value === undefined) {
      setisShowClientddlErr(true);
    } else {
      setisShowClientddlErr(false);
    }
    if (selectedOptionForCordinator.length === 0 || selectedOptionForCordinator.length === undefined) {
      setisShowcoordinatorddlErr(true);
    } else {
      setisShowcoordinatorddlErr(false);
    }
    if (isFormValid()) {
      if (!isMaxCoordinatorsSelected) {

        let coordinatorArr = [];
        for (let index = 0; index < selectedOptionForCordinator.length; index++) {
          coordinatorArr.push(parseInt(selectedOptionForCordinator[index].value));
        }
        let getEncAssignmentID = document.getElementById("hdassignmentID").value;
        if (getEncAssignmentID === null || getEncAssignmentID === undefined || getEncAssignmentID === "") {
          getEncAssignmentID = "";
        }
        const res = await AssignmentService.saveAssignmentDetails(getAssignmentName, assignmnentDetails, parseInt(selectedOptionForClient.value), coordinatorArr, isAssignmentActive, getEncAssignmentID);
        if (res.isSuccess) {
          ShowAlert(1, res.message);
          setselectedOptionForClient("Select Client");
          // navigate('/');
          // navigate({
          //     pathname:'/editassignment',
          //     search: '?qs=testQS',
          // })
          initAssignmentList();
          handleClose();
        } else {
          ShowAlert(0, res.message);
          handleClose();
        }
      }
    }

  }
  const deleteAssignment = async (assignmentID) => {

    const res = await DashboardService.DeleteAssignment(assignmentID);
    if (res.isSuccess) {
      setmessage(res.message);
      ShowAlert(1, res.message);
      handleClose();
    } else {
      setmessage(res.message);
      ShowAlert(0, res.message);
    }
  }
  const handleSocialMediaPostSubmit = async () => {
    // if (isSocialMediaFormValid) {}
    setopenLoader(true);
    CheckSocialMediaFromValidation();
    if (isSocialMediaFormValid()) {
      console.log(jobTitleList);
      let jobID = [];
      let jobName = [];
      for (let i = 0; i < jobTitleList.length; i++) {
        jobID.push(jobTitleList[i].value);
        jobName.push(jobTitleList[i].label);
      }
      let res = null;
      debugger;
      if (isBulkEmail) {
        res = await SocialMediaPostService.SaveSocialMediaPost(selectedOptionForColor, assignmentIdforSM, jobID, descrioptionText, selectedOptionForUser, companyHeader, fromJobAddressSelected, jobName, true);
      } else {
        res = await SocialMediaPostService.SaveSocialMediaPost(selectedOptionForColor, assignmentIdforSM, jobID, descrioptionText, selectedOptionForUser, companyHeader, fromJobAddressSelected, jobName, false);
      }
      if (res.isSuccess) {
        setopenLoader(false);
        ShowAlert(1, res.message);
        setdescrioptionText("");
        setselectedOptionForColor("rgba(241,112,19,11)");
        setselectedOptionForAddress("");
        setselectedOptionForUser("");
        handleClosejob();
      } else {
        ShowAlert(0, res.message);
        setdescrioptionText("");
        setselectedOptionForColor("rgba(241,112,19,11)");
        setselectedOptionForAddress("");
        setselectedOptionForUser("");
        handleClosejob();
      }
    }
    setopenLoader(false);
  }
  const editAssignment = async (assignmentID) => {

    const getassignment = await DashboardService.GetAssignmentById(assignmentID);
    console.log(getassignment);
    setassignmentCode(getassignment.assignmentCode);
    setassignmnentName(getassignment.assignmentName);
    setassignmnentDetails(getassignment.assignmentDetails);
    setselectedOptionForClient({ label: getassignment.clientName, value: getassignment.clientID });
    let selectdCoordinators = [];
    CoordinatorList.filter(function (filter) {
      if (getassignment.coordinatorsIDs) {
        for (let i = 0; i < getassignment.coordinatorsIDs.length; i++) {
          if (filter.value === getassignment.coordinatorsIDs[i]) {
            selectdCoordinators.push(filter);
          }
        }
      }
    })
    setselectedOptionForCordinator(selectdCoordinators);
    setmodalTitle("Edit");
    seticonClass("fa fa-pencil-square-o icon-color1");
    handleShow();
    setTimeout(() => {
      document.getElementById("assignmentNameID").value = getassignment.assignmentName;
      document.getElementById("hdassignmentID").value = getassignment.encAssignmentID;
    }, 200);
  }
  const socialMediaPostJobListModal = async (assignmentID) => {
    setisBulkEmail(false);
    setassignmentIdforSM(assignmentID);
    let joblist = [];
    const getjobList = await DashboardService.GetJobListByAssignmentID(assignmentID);
    for (let i = 0; i < getjobList.length; i++) {
      joblist.push({ "label": getjobList[i].jobTitle, "value": getjobList[i].encJobPostingId });
    }
    if (getjobList.length > 0) {
      handleShowjob();
    }
    else {
      ShowAlert(0, "No job available on this Assignment");
    }
    setjobTitleList(joblist);
    const getadsList = await SocialMediaPostService.GETAddressDDL();
    setfromJobAddress(getadsList);
  }
  const handleBulkEmailClick = async (assignmentID) => {
    setisBulkEmail(true);
    setassignmentIdforSM(assignmentID);
    let joblist = [];
    debugger;
    const getjobList = await DashboardService.GetJobListByAssignmentID(assignmentID);
    for (let i = 0; i < getjobList.length; i++) {
      joblist.push({ "label": getjobList[i].jobTitle, "value": getjobList[i].encJobPostingId });
    }
    setjobTitleList(joblist);
    if (getjobList.length > 0) {
      const getadsList = await SocialMediaPostService.GETAddressDDL();
      setfromJobAddress(getadsList);

      handleShowjob();
    }
    else {
      ShowAlert(0, "no jobs availbel on this assignment");
    }
  }
  const handleBulkWPMessageClick = async (encassignmentID) => {
    let joblist = [];
    const getjobList = await DashboardService.GetJobListByAssignmentID(assignmentID);
    for (let i = 0; i < getjobList.length; i++) {
      joblist.push({ "label": getjobList[i].jobTitle, "value": getjobList[i].jobPostingId });
    }
    if (getjobList.length > 0) {
      handleShowjob();
    }
    else {
      ShowAlert(0, "No jobs available on this Assignment");
    }
  }
  const isFormValid = () => {
    let errCount = 0;// (assignmnentName === "" || assignmnentName === undefined || assignmnentName === null) ? 1 : 0;
    //errCount += (assignmnentDetails === "" || assignmnentDetails === undefined || assignmnentDetails === null) ? 1 : 0;
    errCount += (selectedOptionForClient.value === "" || selectedOptionForClient.value === "Select Client" || selectedOptionForClient.value === undefined) ? 1 : 0;
    errCount += (selectedOptionForCordinator.length === 0 || selectedOptionForCordinator.length === undefined) ? 1 : 0;
    return errCount === 0 ? true : false;
  }
  const CheckSocialMediaFromValidation = () => {
    if (!IsEmptyOrNullValues(companyHeader)) {
      setisShowHeaderErr(false);
    } else {
      setisShowHeaderErr(true);
    }
    if (!IsEmptyOrNullValues(descrioptionText)) {
      setisShowJobDescriptionErr(false);
    } else {
      setisShowJobDescriptionErr(true);
    }
    // if (selectedOptionForJobTitel.length > 0) {
    //   setisShowJobTitleddlErr(false);
    // } else {
    //   setisShowJobTitleddlErr(true);
    // }
    // if (!IsEmptyOrNullValues(selectedOptionForColor.hex)) {
    //   setisShowColorddlErr(false);
    // } else {
    //   setisShowColorddlErr(true);
    // }
    if (selectedOptionForAddress.length > 0) {
      setisShowAddressddlErr(false);
    } else {
      setisShowAddressddlErr(true);
    }
    if (selectedOptionForUser.length > 0) {
      setisShowUserddlErr(false);
    } else {
      setisShowUserddlErr(true);
    }

  }
  const IsEmptyOrNullValues = (inputText) => {
    if (inputText === null || inputText === "" || inputText === undefined || inputText === 0) {
      return true;
    } else {
      return false;
    }
  }
  const isSocialMediaFormValid = () => {
    let errCount = 0;
    errCount += IsEmptyOrNullValues(companyHeader) ? 1 : 0;
    errCount += IsEmptyOrNullValues(descrioptionText) ? 1 : 0;
    // errCount += selectedOptionForJobTitel.length > 0 ? 0 : 1;
    errCount += selectedOptionForAddress.length > 0 ? 0 : 1;
    errCount += selectedOptionForUser.length > 0 ? 0 : 1;
    return errCount === 0 ? true : false;
  }
  const ShowAlert = (type, message) => {
    if (type === 1) {
      toast.success(message, {
        toastId: '',
      })
    } else {
      toast.error(message, {
        toastId: '',
      })
    }
  }
  const handleCancel = () => {
    window.location.reload();
  }
  const onClickHandler = async (e, i, assignmentID) => {
    const currentParentElement = e.currentTarget.parentElement;
    const hiddenElement = currentParentElement.nextSibling;
    let currentTargetElement = e.currentTarget;
    let previousElement = e.currentTarget.previousElementSibling;
    if (i === 1) {
      previousElement = e.currentTarget.nextElementSibling;
    }
    const index = hiddenElement.className.indexOf("collapse show");
    if (index > -1) {
      hiddenElement.classList.remove("show");
      currentTargetElement.classList.remove("plus");
      currentTargetElement.classList.add("minus");
      previousElement.classList.remove("minus");
      previousElement.classList.add("plus");
    } else {
      hiddenElement.classList.add("show");
      currentTargetElement.classList.add("minus");
      currentTargetElement.classList.remove("plus");
      previousElement.classList.add("plus");
      previousElement.classList.remove("minus");
      const data = await getJobPostingList(assignmentID);
      let currentTr = currentParentElement.nextSibling;
      let trFirstChild = currentTr.firstChild;
      let firstDiv = trFirstChild.firstChild;
      let keepChild = firstDiv.firstChild;
      firstDiv.innerHTML = "";
      firstDiv.appendChild(keepChild);
      for (let i = 0; i < data.length; i++) {
        let tdParentEL = document.createElement('div');
        tdParentEL.classList.add('div-table-row');

        let tdJobCodeEl = document.createElement('div');

        tdJobCodeEl.classList.add('div-table-col', 'mt-3');
        tdJobCodeEl.textContent = data[i].jobCode;

        let tdJobTitleEl = document.createElement('div');
        tdJobTitleEl.classList.add('div-table-col', 'job-title-body', 'mt-3');
        //tdJobTitleEl.textContent = data[i].jobTitle;

        //link
        let a = document.createElement('a');
        a.setAttribute('href', '#');
        a.setAttribute('style', 'cursor:text;');
        a.innerHTML = data[i].jobTitle;
        a.classList.add("title-body-link");
        tdJobTitleEl.appendChild(a);

        //applied
        let tdAppliedEl = document.createElement('div');
        tdAppliedEl.classList.add('div-table-col', 'mt-3');
        tdAppliedEl.textContent = data[i].applied;

        //start date
        let tdStartDateEl = document.createElement('div');
        tdStartDateEl.classList.add('div-table-col', 'mt-3');
        tdStartDateEl.textContent = data[i].startDate;

        //start date
        let tdEnddateEl = document.createElement('div');
        tdEnddateEl.classList.add('div-table-col', 'mt-3');
        tdEnddateEl.textContent = data[i].endDate;

        let tdJobPositionEl = document.createElement('div');
        tdJobPositionEl.classList.add('div-table-col', 'mt-3');
        tdJobPositionEl.textContent = data[i].positions;

        //button
        // let btn = document.createElement("button");
        // btn.classList.add('btn-info', 'mt-3');
        // btn.innerHTML = "View";
        // btn.onclick = function () {

        //   navigate("jobPostingDetails", { state: { assignID : assignmentID ,jpID: data[i].encJobPostingId  } })


        // };
        tdParentEL.appendChild(tdJobCodeEl);
        tdParentEL.appendChild(tdJobTitleEl);
        tdParentEL.appendChild(tdJobPositionEl);
        tdParentEL.appendChild(tdAppliedEl);
        tdParentEL.appendChild(tdStartDateEl);
        tdParentEL.appendChild(tdEnddateEl);
        // tdParentEL.appendChild(btn);
        firstDiv.appendChild(tdParentEL);
      }
      console.log(data);
      if (data.length === undefined || data.length === 0 || data.length === null) {
        let tdParentEL = document.createElement('div');
        tdParentEL.classList.add("cls-njpa");
        tdParentEL.textContent = "No Jobposting available";
        firstDiv.appendChild(tdParentEL);
      }
    }

  }
  const getJobPostingList = async (assignmentID) => {
    const getJobPostingList = await DashboardService.GetJobListByAssignmentID(assignmentID);
    getJobPostingList ? setjobPostingList(getJobPostingList) : setjobPostingList([]);
    return getJobPostingList;

  }
  const formatDate = (date) => {
    var d = new Date(date),
      month = '' + (d.getMonth()),
      day = '' + (d.getDate()),
      year = d.getFullYear();

    if (month.length < 2)
      month = '0' + month;
    if (day.length < 2)
      day = '0' + day;

    return [month, day, year].join('/');
  }

  const handlePageClick = (event) => {
    // const trShow = document.getElementsByClassName("show");
    // for (let i = 0; i < trShow.length; i++) {
    //   trShow[i].classList.remove("show");
    // }

    // const expandedEl = document.getElementsByClassName("expanded");
    // for (let i = 0; i < expandedEl.length; i++) {
    //   if (expandedEl[i].classList.contains("minus")) {
    //     expandedEl[i].classList.remove("minus");
    //     expandedEl[i].classList.remove("plus");
    //   }
    // }
    // const minusEl = document.getElementsByClassName("plus");
    // for (let i = 0; i < trShow.length; i++) {
    //   minusEl[i].style.display = "none !important";
    // }

    const newOffset = (event.selected * itemsPerPage) % assignmentList.recordsTotal;
    setstartIndex(event.selected);
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  }
  const handleExportToExcelClick = async (assignmentID) => {
    const excelApiData = await DashboardService.GetAssignmentExcelData(assignmentID);
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = excelApiData[0].assignmentName ? excelApiData[0].assignmentName : "ExportToExcel";
    const ws = XLSX.utils.json_to_sheet(excelApiData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }


  const handleRedirectToNewJob = (assignmentID, assignmentCode) => {
    setjPAssignmentID(assignmentID);
    handleShowNewjob();
    // navigate({
    //   pathname: '/newjobposting',
    //   search: "?assignmentID=" + assignmentID + "&assignmentCode=" + assignmentCode,

    // })
  }
  const handleRedirectToprojectdetailsPage = (encAssignmentID) => {
    navigate({
      pathname: "/projectdetails",
      search: "?qs=" + encAssignmentID
    })
  }
  const handleCreatLink = (encAssignmentID) => {
    setshowLinkModal(true)
    // let encId = "https://can.huntsjob.com/search/AdvanceSearchResult?qs_aid=" + encAssignmentID;
    let encId = "https://can.huntsjob.com/JobsByAssignment?qs_aid=" + encAssignmentID;
    SetQsEncId(encId);
  }
  const handleCopyToClipboard = () => {
    const link = document.getElementById("LinkCopyTOClipboard").innerText;
    navigator.clipboard.writeText(link);
    ShowAlert(1, "link copy to clipboard");

  }
  const handleredirectToCopiedLink = () => {
    const link = document.getElementById("LinkCopyTOClipboard").innerText;
    window.open(link, '_blank');
    // window.location.href=data;
  }
  const CloseCandidateModal = (value) => {
    if (value === 1) {
      setopenLoader(false);
      handleCloseNewjob();
      initAssignmentList();
    }
  }
  const CloseChildModal = (value, isRefreshList) => {
    if (value === 1) {
      setopenLoader(false);
      handleCloseNewjob();
      navigate('/assignment');
      initAssignmentList();
    }
    if (isRefreshList) {
      initAssignmentList();
      navigate('/assignment');
    }
  }
  const clearAssignmentForm = () => {
    sethasassignmnentNameErr(false);
    sethasAssignmnentDetails(false);
    setisShowcoordinatorddlErr(false);
    setisShowClientddlErr(false);
    setShow(false);
  }
  const initAssignmentList = async () => {
    setopenLoader(true);
    const Assignment = await DashboardService.GetAssignmentLists(startIndex);
    setassignmentListData(Assignment.data);
    setassignmentList(Assignment);
    setpageCount(Math.ceil(Assignment.recordsTotal / itemsPerPage));
    setopenLoader(false);
  }

  const closeJobPostingModal = (val) => {
    if (val === 1) {
      setnewjobShow(false);
    }
  }


  useEffect(() => {

    const initClientList = async () => {
      const clients = await AssignmentService.getClientList(searchText);
      if (clients) {
        setclientList(clients);
      } else {
        setclientList("")
      }
    }
    const initCoordinatorList = async () => {
      const Coordinators = await AssignmentService.getCoordinatorList(searchText);
      if (Coordinators) {
        setCoordinatorList(Coordinators);
      } else {
        setCoordinatorList("")
      }
    }
    if (isAuth) {
      if (AuthService.getCurrentUser().userTypeId === "CANDIDATES") {
        navigate('/401');
      }
      initClientList();
      initCoordinatorList();
      initAssignmentList();
      const endOffset = itemOffset + itemsPerPage;
    } else {
      navigate('/login');
    }

    initClientList();
    initCoordinatorList();
    initAssignmentList();
    const endOffset = itemOffset + itemsPerPage;
  }, [searchText, startIndex, itemOffset]);


  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal className='mt-5'
        show={show}
        size="xl"
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>

          <Modal.Title>
            <span className='text-success' style={{ fontSize: "20px" }}><i className={iconClass} aria-hidden="true"></i> {modalTitle} Assignment</span>
          </Modal.Title>
          <button onClick={() => clearAssignmentForm()} type="button" className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h6 className='mt-2 text-center mb-3'>
            ASSIGNMENT DETAILS
          </h6>
          <form method='POST' onSubmit={handleSubmit(saveAssignment)}>
            <input type="hidden" name='EncAssignmentID' id='hdassignmentID' />
            <div className='row'>

              <div className='col-5 mt-1'>
                <label className='control-label'>
                  <span className='font-12'>Client </span> <span className='text-danger'>*</span> :
                </label>
              </div>
              <div className='col-7 font-12 mt-1'>
                <Select
                  defaultValue={{ label: "-- Select Client --", value: "Select Client" }}
                  onChange={handleClientChange}
                  options={clientList}
                  onInputChange={handleClientInputChange}
                  value={selectedOptionForClient}
                />
                {isShowClientddlErr ? <p className='text-danger mt-1' >Please select client.</p> : ""}
              </div>

              <div className='col-5 mt-3'>
                <span className='font-12'>Assignment Name  </span> <span className='text-danger'>*</span> :
              </div>
              <div className='col-7 mt-3 font-12'>
                <input type="text"
                  id='assignmentNameID'
                  readOnly={true}
                  className="form-control"
                // onChange={handleAssignmentNameChange}
                />
                {hasassignmnentNameErr ? <p className='text-danger mt-1'>Please enter assignment name.</p> : ""}
              </div>



              <div className='col-5 mt-3'>
                <span className='font-12'>Assignment Details  </span> <span className='text-danger'>*</span> :
                <span style={{ color: "red", fontSize: "12px" }}>
                  <br />
                  *Enter the required job category
                  <br />
                  *Enter the requirement numbers against each job category
                  <br />
                  *Enter job location
                </span>
              </div>
              <div className='col-7 font-12 mt-3'>
                <textarea className="form-control"
                  rows="4"
                  cols="25"
                  onChange={handleAssignmentDetailsChange}
                  value={assignmnentDetails}
                />
                {hasAssignmnentDetails ? <p className='text-danger mt-1'>Please enter assignmnet details.</p> : ""}
              </div>

              <div className='col-5 mt-3'>
                <label className='control-label'>
                  <span className='font-12'>Assignment Co-Ordinator </span> <span className='text-danger'>*</span> :
                </label>
              </div>
              <div className='col-7 font-12 mt-3'>
                <Select
                  onChange={handleCoordinatorChange}
                  isMulti={true}
                  options={CoordinatorList}
                  value={selectedOptionForCordinator}
                />
                {isShowcoordinatorddlErr ? <p className='text-danger mt-1' > Please select assignment coordinator.</p> : ""}
                {isMaxCoordinatorsSelected ? <p className='text-danger mt-1' > You can't select more than 10 coordinator .</p> : ""}
                <span className='mt-1'>* You can select multiple max 10 Coordinators</span>
              </div>

              <div className='col-5 mt-3'>
                <span className='font-12'>Assignment Active </span>  :
              </div>
              <div className='col-7 font-12 mt-3'>
                <input type="radio" defaultChecked={true} value="1" name="AssignmentActive" onChange={(e) => { setisAssignmentActive(parseInt(e.target.value)) }} /> <span>Yes</span>
                <input type="radio" value="0" className='ml-1' onChange={(e) => { setisAssignmentActive(parseInt(e.target.value)) }} name="AssignmentActive" /><span> No</span>
              </div>
              <div className='col-7'></div>

              <div className='ml-2 mt-3'>
                <button className='btn btn-sm btn-success mr-1'><i className='fa fa-floppy-o'></i> Submit</button>
                <button type='button' onClick={() => clearAssignmentForm()} className='btn btn-sm btn-warning'><i className='fa fa-ban'></i> Cancel</button>
              </div>

            </div>
          </form>
        </Modal.Body>

      </Modal>

      {/*social media jobposting modal */}
      <Modal
        style={{ marginTop: "2%" }}
        show={showjob}
        onHide={handleClosejob}
        backdrop="static"
        keyboard={false}
        scrollable
      >
        <Modal.Header>

          <Modal.Title>
            <span className='text-success' style={{ fontSize: "20px" }}><i className={jobiconClass} aria-hidden="true"></i> {jobmodalTitle} Jobs</span>
          </Modal.Title>
          <button onClick={handleClosejob} type="button" className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <h6 className='mt-1 text-center mb-1'>
            JOB TEMPLATE
          </h6>
          <form method='POST' onSubmit={handleSubmit(handleSocialMediaPostSubmit)}>
            <div className='row'>
              <div className='col-5 mt-3'>
                <label className='control-label'>
                  <span className='font-12'>Header Text </span> <span className='text-danger'>*</span> :
                </label>
              </div>
              <div className='col-7 font-12 mt-3'>
                <textarea className="form-control"
                  rows="2"
                  cols="25"
                  onChange={handleHeaderChange}
                // value={addCompany}
                />
                {isShowHeaderErr ? <p className='text-danger mt-1' >Please Enter Header Description.</p> : ""}
                <span className='mt-1'>* You can select  max 150 Character </span>
              </div>
              {/* <div className='col-5 mt-3'>
                <label className='control-label'>
                  <span className='font-12'>Select Jobs </span> <span className='text-danger'>*</span> :
                </label>
              </div>
              <div className='col-7 font-12 mt-3'>
                <Select
                  onChange={handleJobTitleChange}
                  isMulti={true}
                  options={jobTitleList}
                  //onInputChange={handleClientInputChange}
                  value={selectedOptionForJobTitel}
                />
                {isShowJobTitleddlErr ? <p className='text-danger mt-1' >Please select job.</p> : ""}
                <span className='mt-1'>* You can select multiple Jobs</span>
              </div> */}

              <div className='col-5 mt-3'>
                <label className='control-label'>
                  <span className='font-12'>Position Description </span> <span className='text-danger'>*</span> :
                </label>
              </div>
              <div className='col-7 font-12 mt-3'>
                <textarea className="form-control"
                  rows="2"
                  cols="25"
                  value={descrioptionText}
                  onChange={handlePositionDescription}
                />
                {isShowJobDescriptionErr ? <p className='text-danger mt-1' >Please Enter Position Description.</p> : ""}
                <span className='mt-1'>* You can select  max 150 Character </span>
              </div>


              <div className='col-5 mt-3'>
                <label className='control-label'>
                  <span className='font-12'>Colors </span> <span className='text-danger'>*</span> :
                </label>
              </div>
              <div className='col-7 font-12 mt-3'>
                <div>
                  <div className='swatch' onClick={OnClick}>
                    {/* <input type="text" className="form-control" /> */}
                    <div className='color' style={{ background: selectedOptionForColor }} />
                  </div>
                  {showPicker ? <div className='popover'>
                    <div className='cover' onClick={OnClose} />
                    <SketchPicker
                      color={selectedOptionForColor}
                      onChange={handleColorChange}
                      value={selectedOptionForColor} />
                  </div> : null}
                </div>
                {/* {isShowColorddlErr ? <p className='text-danger mt-1' >Please select color.</p> : ""} */}
              </div>


              <div className='col-5 mt-3'>
                <label className='control-label'>
                  <span className='font-12'>Addresses </span> <span className='text-danger'>*</span> :
                </label>
              </div>
              <div className='col-7 font-12 mt-3'>
                <Select
                  onChange={handleAddressChange}
                  options={fromJobAddress}
                  isMulti={true}
                  value={selectedOptionForAddress}
                  closeMenuOnSelect={true}
                  isSearchable={true}
                />
                {isShowAddressddlErr ? <p className='text-danger mt-1' > Please select Address .</p> : ""}
                <span className='mt-1'>* You can select multiple Address</span>
              </div>

              <div className='col-5 mt-3'>
                <label className='control-label'>
                  <span className='font-12'>Select User To Assign </span> <span className='text-danger'>*</span> :
                </label>
              </div>
              <div className='col-7 font-12 mt-3'>
                <Select
                  onChange={handleUserChange}
                  isMulti={true}
                  options={formUserDDl}
                  //onInputChange={handleClientInputChange}
                  value={selectedOptionForUser}
                />
                {isShowUserddlErr ? <p className='text-danger mt-1' >Please select user.</p> : ""}
                <span className='mt-1'>* You can select multiple Users</span>
              </div>

              <div className='col-7'></div>

              <div className='ml-2 mt-3'>
                <button className='btn btn-sm btn-success mr-1'><i className='fa fa-floppy-o'></i> Submit</button>
                <button type='button' onClick={handleClosejob} className='btn btn-sm btn-warning'><i className='fa fa-ban'></i> Cancel</button>
              </div>

            </div>
          </form>
        </Modal.Body>

      </Modal>
      {/*New jobposting modal */}
      <Modal
        style={{ marginTop: "3rem" }}
        scrollable
        size='lg'
        show={newjobshow}
        onHide={handleCloseNewjob}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header>

          <Modal.Title>
            <span className='text-success' style={{ fontSize: "20px" }}><i className={jobiconClass} aria-hidden="true"></i> {jobmodalTitle} Jobs</span>
          </Modal.Title>
          <button onClick={handleCloseNewjob} type="button" className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <JobPostingModal
            EncAssignmentID={jPAssignmentID}
            TriggerJobPostingModalClose={closeJobPostingModal}
            TriggerModalClose={CloseChildModal}
            TriggerCloseModal={CloseCandidateModal}
          />
        </Modal.Body>

      </Modal>
      {/*Create Link modal */}
      <Modal
        style={{ marginTop: "3rem" }}
        show={showLinkModal}
        size='lg'
        keyboard={false}
      >
        <Modal.Header>

          <Modal.Title>
            <span className='text-success' style={{ fontSize: "20px" }}>Assignment Link</span>
          </Modal.Title>
          <button onClick={handleCloseLinkModal} type="button" className="close">
            <span aria-hidden="true" title='click here to Close'>×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='row'>
            <div className='col-md-10'>
              <p id='LinkCopyTOClipboard'>{QsEncId}</p>
            </div>
            <div className='col-md-1'>
              <button type="button" title='Copy Link' className="btn btn-info btn-sm " onClick={handleCopyToClipboard}><i className="fa fa-clipboard" aria-hidden="true"></i></button>
            </div>
            <div className='col-md-1'>
              <button type="button" title='Go to the Link' className='btn btn-success btn-sm ' onClick={handleredirectToCopiedLink} ><i className="fa fa-external-link" aria-hidden="true"></i></button>
            </div>
          </div>
        </Modal.Body>

      </Modal>
      <Layout>
        <ToastContainer
          position="top-center"
          autoClose={7000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <div className='float-right'>
          <button className='btn btn-success float-right mt-1 mb-1' onClick={() => addAssignment()}> <i className="fa fa-plus icon-color" aria-hidden="true"></i> Add Assignment</button>
        </div>

        <table className='table'>
          <thead>
            <tr>
              <th></th>
              <th scope="col">Assignment Code</th>
              <th scope="col">Assignment Name</th>
              <th scope="col">Client Name</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          {
            assignmentListData.map((item, index) =>
              <tbody key={500 + index}>
                <tr key={index} >
                  <td className='minus' onClick={(e) => onClickHandler(e, 1, item.encAssignmentID)}><i className="fa fa-minus-circle font-18" aria-hidden="true"></i></td>
                  <td className='plus expanded' onClick={(e) => onClickHandler(e, -1, item.encAssignmentID)} ><i className="fa fa-plus-circle font-18" aria-hidden="true"></i></td>
                  <td>{item.assignmentCode}</td>
                  <td>{item.assignmentName}</td>
                  <td>{item.clientName}</td>
                  <td>
                    <button style={{ cursor: "text" }} className={item.assignmentIsActive ? 'btn btn-sm btn-success' : 'btn btn-sm btn-danger'} title={item.assignmentIsActive ? "Active" : "InActive"}>
                      <i className="fa fa-power-off" aria-hidden="true"></i>
                    </button>
                    {/* <button className='btn btn-sm btn-success ml-1' title='View' onClick={() => console.log(item.encAssignmentID)}>
                      <i className="fa fa-info-circle" aria-hidden="true"></i>
                    </button> */}
                    {/* <button className='btn btn-sm btn-success ml-1' title='Export to excel' onClick={(e) => handleExportToExcelClick(item.encAssignmentID)}>
                      <i className="fa fa-file-excel-o" aria-hidden="true"></i>
                    </button> */}

                    <button className='btn btn-sm btn-success ml-1' title='Edit' onClick={() => editAssignment(item.encAssignmentID)}>
                      <i className="fa fa-pencil-square-o" aria-hidden="true"></i>
                    </button>
                    <button className='btn btn-sm btn-success ml-1' title='Facebook Post' onClick={() => socialMediaPostJobListModal(item.encAssignmentID)}>
                      <i className="fa fa-facebook-official" aria-hidden="true"></i>
                    </button>
                    <button className='btn btn-sm btn-success ml-1' title='Send Bulk Email' onClick={() => handleBulkEmailClick(item.encAssignmentID)}>
                      <i className="fa fa-envelope" aria-hidden="true"></i>
                    </button>
                    <button className='btn btn-sm btn-success ml-1' title='Send Message' style={{ cursor: "none" }} onClick={() => handleBulkWPMessageClick(item.encAssignmentID)}>
                      <i className="fa fa-comment" aria-hidden="true"></i>
                    </button>

                    <button className='btn btn-sm btn-success ml-1' title='Post New Job' onClick={() => handleRedirectToNewJob(item.encAssignmentID, item.assignmentCode)}>
                      <i className="fa fa-bullhorn" aria-hidden="true"></i>
                    </button>
                    <button className='btn btn-sm btn-success ml-1' title='Client' onClick={() => handleRedirectToprojectdetailsPage(item.encAssignmentID)}>
                      <i className="fa fa-user-circle" aria-hidden="true"></i>
                    </button>
                    <button className='btn btn-sm btn-success ml-1' title='Link' onClick={() => handleCreatLink(item.encAssignmentID)}>
                      <i className="fa fa-link" aria-hidden="true"></i>
                    </button>
                  </td>
                </tr>
                <tr key={index + 100} className="collapse">
                  <td colSpan="6">
                    <div className="div-table">
                      <div className="div-table-row">

                        <div className="div-table-col">Job Code</div>
                        <div className="div-table-col job-title-header">Job Title</div>
                        <div className="div-table-col">Positions</div>
                        <div className="div-table-col">Applied</div>
                        <div className="div-table-col">Start Date</div>
                        <div className="div-table-col">End Date</div>
                        {/* <div className="div-table-col">Actions</div> */}
                      </div>


                    </div>
                  </td>
                </tr>
              </tbody>
            )
          }
        </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel="next >"
          onPageChange={handlePageClick}
          pageCount={pageCount}
          previousLabel="< previous"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
        />


      </Layout>
    </div>
  );
}
export default App;