import React, { useState, useEffect } from 'react';
import { AdminService } from '../../../Services/AdminService';
import ReactPaginate from 'react-paginate';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import { AuthService } from '../../../Services/AuthService';
import Layout from '../../../Layout/Layout';
import { useNavigate } from 'react-router-dom';
import Modal from "react-bootstrap/Modal";
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useForm } from 'react-hook-form';
import { CommonDropDownService } from '../../../Services/CommonDropDownService';
function Client() {
    const navigate = useNavigate();
    const [pageCount, setpageCount] = useState(0);
    const [itemOffset, setItemOffset] = useState(10);
    const [openLoader, setopenLoader] = useState(false);
    const [searchText, setsearchText] = useState("");
    const itemsPerPage = 10;
    const [startIndex, setstartIndex] = useState(0);
    const [clientListData, setclientListData] = useState([]);
    const [clientRecords, setclientRecords] = useState(0);
    const [assignmentList, setassignmentList] = useState([]);
    const [clientsByID, setclientsByID] = useState([]);
    const [coordinatorByID, setcoordinatorByID] = useState([]);
    const [headingTitle, setheadingTitle] = useState("Client List");
    const [isShowBackBtn, setisShowBackBtn] = useState(false);
    const [clientIDforassignment, setclientIDforassignment] = useState("");

    const [show, setShow] = useState(false);
    const { register, setValue, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const [assignmnentName, setassignmnentName] = useState("");
    const [assignmnentNameOnClient, setassignmnentNameOnClient] = useState("");
    const [hasassignmnentNameErr, sethasassignmnentNameErr] = useState(false);
    const [assignmnentDetails, setassignmnentDetails] = useState("");
    const [isAssignmentActive, setisAssignmentActive] = useState(1);
    const [hasAssignmnentDetails, sethasAssignmnentDetails] = useState(false);
    const [selectedOptionForClient, setselectedOptionForClient] = useState({ label: "-- Select Client --", value: "Select Client" });
    const [selectedOptionForCordinator, setselectedOptionForCordinator] = useState([]);
    const [isMaxCoordinatorsSelected, setisMaxCoordinatorsSelected] = useState(false);
    const [isShowcoordinatorddlErr, setisShowcoordinatorddlErr] = useState(false);
    const handleClose = async () => {
        AssignmentNameOnClient();
        refreshAssignmentListByClientID();
        setShow(false);
        sethasAssignmnentDetails(false);
        setisShowcoordinatorddlErr(false);
        setassignmnentName("");
        setselectedOptionForClient([]);
        setselectedOptionForClient(undefined);
        setassignmnentDetails("");
        setselectedOptionForCordinator([]);
        setselectedOptionForCordinator(undefined);

    }
    const AssignmentNameOnClient = async () => {
        const res = await AdminService.getassignmentNameOnClientID(clientIDforassignment);
        setassignmnentNameOnClient(res);
    }
    const refreshAssignmentListByClientID = async () => {
        const apiRes = await AdminService.getAssignmentListByClientID(clientIDforassignment);
        if (apiRes.apiData) {
            setassignmentList(JSON.parse(apiRes.apiData));
        }
    }
    const handleShow = () => {
        clientListData.filter(function (f) {
            if (clientIDforassignment === f.EncClientID) {

                setselectedOptionForClient(f.ClientName);
            }
        })
        setShow(true);
    }
    const handleAssignmentNameChange = (e) => {
        const val = e.target.value;
        setassignmnentName(val);
        if (val === "" || val === undefined || val === null) {
            sethasassignmnentNameErr(true);
        } else {
            sethasassignmnentNameErr(false);
        }
    }
    const handleClientChange = async (e) => {
        let val = e.target.value;
        if (val) {
            setselectedOptionForClient(val);

        } else {
            setselectedOptionForClient("");
        }

    }
    const handleAssignmentDetailsChange = (e) => {
        const val = e.target.value;
        setassignmnentDetails(val);
        if (val === "" || val === undefined || val === null) {
            sethasAssignmnentDetails(true);
        } else {
            sethasAssignmnentDetails(false);
        }
    }
    const handleCoordinatorChange = (selectedOption) => {

        if (selectedOption.length > 0) {
            setisShowcoordinatorddlErr(false);
            setselectedOptionForCordinator(selectedOption);
        } else {
            setisShowcoordinatorddlErr(true);
            setselectedOptionForCordinator([]);
            setselectedOptionForCordinator(undefined);
        }
        if (selectedOption.length > 10) {
            setisMaxCoordinatorsSelected(true);
        } else {
            setisMaxCoordinatorsSelected(false);
        }

    }

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % clientRecords;
        setstartIndex(event.selected);
        setItemOffset(newOffset);
    }
    const handleClientClick = async (encClientID) => {
        setopenLoader(true);
        setclientIDforassignment(encClientID);
        const apiRes = await AdminService.getAssignmentListByClientID(encClientID);

        if (apiRes.apiData) {
            setassignmentList(JSON.parse(apiRes.apiData));
        }
        setheadingTitle("Assignment List");
        setpageCount(0);
        setisShowBackBtn(true);
        setopenLoader(false);
    }
    const handleAssignmentViewClick = (assignmentID) => {
        navigate({
            pathname: "/projectdetails",
            search: "?qs=" + assignmentID
        })
    }
    const handleSearchChange = (value) => {
        if (value !== null && value !== "" && value !== undefined) {
            setsearchText(value);
        } else {
            setsearchText("");
        }
    }
    const initClientList = async (IsReset) => {
        if (IsReset) {
            setheadingTitle("Client List");
            setisShowBackBtn(false);
        }
        setopenLoader(true);
        const apiRes = await AdminService.getClientListByUserID(startIndex + 1, searchText);
        if (apiRes.apiData) {
            setclientListData(JSON.parse(apiRes.apiData));
            setclientRecords(apiRes.recordCount);
            setpageCount(apiRes.recordCount / 10);
        }
        setopenLoader(false);
    }

    // const initClientsByUserID = async () => {
    //     const clientbyUser = await CommonDropDownService.getClientsByUserID();
    //     setclientsByID(clientbyUser);
    // }
    const initCoOrdinatorByUserID = async () => {
        const coordinatorbyUser = await CommonDropDownService.getCoOrdinatorByUserID();
        setcoordinatorByID(coordinatorbyUser);
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const isFormValid = () => {
        let errCount = (assignmnentNameOnClient === "" || assignmnentNameOnClient === undefined || assignmnentNameOnClient === null) ? 1 : 0;
        errCount += (assignmnentDetails === "" || assignmnentDetails === undefined || assignmnentDetails === null) ? 1 : 0;
        //errCount += (selectedOptionForClient.value === "" || selectedOptionForClient.value === "Select Client" || selectedOptionForClient.value === undefined) ? 1 : 0;
        errCount += (selectedOptionForCordinator.length === 0 || selectedOptionForCordinator.length === undefined) ? 1 : 0;
        return errCount === 0 ? true : false;
    }
    const handleSaveAssignment = async () => {
        if (assignmnentNameOnClient === "" || assignmnentNameOnClient === undefined || assignmnentNameOnClient === null) {
            sethasassignmnentNameErr(true);
        } else {
            sethasassignmnentNameErr(false);
        }
        if (assignmnentDetails === "" || assignmnentDetails === undefined || assignmnentDetails === null) {
            sethasAssignmnentDetails(true);
        } else {
            sethasAssignmnentDetails(false);
        }
        if (selectedOptionForCordinator.length === 0 || selectedOptionForCordinator.length === undefined) {
            setisShowcoordinatorddlErr(true);
        } else {
            setisShowcoordinatorddlErr(false);
        }
        if (isFormValid()) {
            if (!isMaxCoordinatorsSelected) {
                let coordinatorArr = [];
                for (let index = 0; index < selectedOptionForCordinator.length; index++) {
                    coordinatorArr.push(parseInt(selectedOptionForCordinator[index].value));
                }
                const res = await AdminService.saveAssignment(assignmnentNameOnClient, assignmnentDetails, clientIDforassignment, coordinatorArr, isAssignmentActive);
                //const res = await AdminService.saveAssignment(assignmnentName, assignmnentDetails, parseInt(selectedOptionForClient.value), coordinatorArr, isAssignmentActive);
                if (res.isSuccess) {
                    ShowAlert(1, res.message);
                    setselectedOptionForClient("Select Client");
                    handleClose();
                } else {
                    ShowAlert(0, res.message);
                    handleClose();
                }
            }
        }
    }
    useEffect(() => {
        if (AuthService.isAuthenticatedUser()) {
            initClientList();
            ///initClientsByUserID();
            initCoOrdinatorByUserID();
        } else {

        }
    }, [startIndex, searchText])

    return (
        <Layout>
            <div className='' style={{ marginTop: "6rem" }}>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={openLoader}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Modal className='mt-5'
                    size='lg'
                    show={show}
                    onHide={handleClose}
                    backdrop="static"

                >
                    <Modal.Header>

                        <Modal.Title>
                            <span className='text-success' style={{ fontSize: "20px" }}> Add Assignment</span>
                        </Modal.Title>
                        <button onClick={handleClose} type="button" className="close">
                            <span aria-hidden="true">×</span>
                            <span className="sr-only">Close</span>
                        </button>
                    </Modal.Header>
                    <Modal.Body>
                        <h6 className='mt-1 text-center mb-3'>
                            ASSIGNMENT DETAILS
                        </h6>

                        <div className='row'>
                            <div className='col-5'>
                                <span className='font-12'>Assignment Name  </span> <span className='text-danger'>*</span> :
                            </div>
                            <div className='col-7 font-12'>
                                <input type="text" readOnly
                                    className="form-control"
                                    onChange={(e) => handleAssignmentNameChange(e)}
                                    value={assignmnentNameOnClient}
                                />
                                {/* {hasassignmnentNameErr ? <p className='text-danger mt-1'>Please enter assignment name.</p> : ""} */}
                            </div>

                            <div className='col-5 mt-3'>
                                <label className='control-label'>
                                    <span className='font-12'>Client </span> <span className='text-danger'>*</span> :
                                </label>
                            </div>
                            <div className='col-7 font-12 mt-3'>
                                <input type='text' className='form-control' readOnly value={selectedOptionForClient} onChange={(e) => handleClientChange(e)} />
                                {/* <Select
                                        defaultValue={{ label: "-- Select Client --", value: "Select Client" }}
                                        
                                        options={clientsByID}
                                        //onInputChange={handleClientInputChange}
                                        value={selectedOptionForClient}
                                    />
                                    {isShowClientddlErr ? <p className='text-danger mt-1' >Please select client.</p> : ""} */}
                            </div>

                            <div className='col-5 mt-3'>
                                <span className='font-12'>Assignment Details  </span> <span className='text-danger'>*</span> :
                            </div>
                            <div className='col-7 font-12 mt-3'>
                                <textarea className="form-control"
                                    rows="3"
                                    cols="25"
                                    onChange={handleAssignmentDetailsChange}
                                    value={assignmnentDetails}
                                />
                                {hasAssignmnentDetails ? <p className='text-danger mt-1'>Please enter assignmnet details.</p> : ""}
                            </div>

                            <div className='col-5 mt-3'>
                                <label className='control-label'>
                                    <span className='font-12'>Assignment Co-Ordinator </span> <span className='text-danger'>*</span> :
                                </label>
                            </div>
                            <div className='col-7 font-12 mt-3'>
                                <Select
                                    onChange={handleCoordinatorChange}
                                    isMulti={true}
                                    options={coordinatorByID}
                                    value={selectedOptionForCordinator}
                                />
                                {isShowcoordinatorddlErr ? <p className='text-danger mt-1' > Please select assignment coordinator.</p> : ""}
                                {isMaxCoordinatorsSelected ? <p className='text-danger mt-1' > You can't select more than 10 coordinator .</p> : ""}
                                <span className='mt-1'>* You can select multiple max 10 Coordinators</span>
                            </div>

                            <div className='col-5 mt-3'>
                                <span className='font-12'>Assignment Active </span>  :
                            </div>
                            <div className='col-7 font-12 mt-3'>
                                <input type="radio" defaultChecked={true} value="1" name="AssignmentActive" onChange={(e) => { setisAssignmentActive(parseInt(e.target.value)) }} /> <span>Yes</span>
                                <input type="radio" value="0" className='ml-1' onChange={(e) => { setisAssignmentActive(parseInt(e.target.value)) }} name="AssignmentActive" /><span> No</span>
                            </div>
                            <div className='col-7'></div>

                            <div className='ml-2 mt-3'>
                                <button className='btn btn-sm btn-success mr-1' onClick={() => handleSaveAssignment()}><i className='fa fa-floppy-o'></i> Submit</button>
                                <button type='button' onClick={handleClose} className='btn btn-sm btn-warning'><i className='fa fa-ban'></i> Cancel</button>
                            </div>

                        </div>

                    </Modal.Body>

                </Modal>


                <div className='row'>
                    <div className='col-md-2'></div>
                    <div className='col-md-4'>
                        <div className='row'>
                            <div className='col-md-8'>
                                {
                                    isShowBackBtn ? null : <input type="text"
                                        style={{ marginLeft: "13%" }}
                                        placeholder="Type here to search"
                                        onChange={(e) => handleSearchChange(e.target.value)}
                                        className="form-control" />
                                }
                            </div>
                            <div className='col-md-4'>
                                {
                                    isShowBackBtn ? null : <button type='button' title='clear search' className='btn btn-warning'
                                        onClick={() => setsearchText("")}
                                    >X
                                    </button>
                                }
                            </div>


                        </div>
                    </div>
                    <div className='col-md-3'>
                        <h6 className='text-left text-primary' style={{ marginLeft: "-14%" }} >{headingTitle}</h6>
                    </div>
                    <div className='col-md-3'></div>

                </div>


                <div className='row mt-3 mb-3'>

                    <div className='col-md-2'></div>
                    <div className='col-md-7'>
                        <div className=''>
                            <ul>
                                {
                                    isShowBackBtn ?
                                        <div>
                                            <div className='row'>
                                                <div className='col-md-9'></div>

                                                <div className='col-md-3 mb-2'>
                                                    <button className='btn btn-primary btn-sm ' onClick={handleShow}>Add Assignment</button>
                                                </div>

                                            </div>
                                            <TableContainer component={Paper}>
                                                <Table size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell className='font-weight-bold'>Assignment List</TableCell>

                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {assignmentList.map((row, i) => (
                                                            <TableRow

                                                                onClick={() => { handleClientClick(row.EncClientID) }}
                                                                style={{ cursor: "pointer" }}
                                                                key={row.ClientName}
                                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                            >
                                                                <TableCell key={i} component="th" scope="row"
                                                                    onClick={() => handleAssignmentViewClick(row.EncAssignmentID)}
                                                                >
                                                                    {row.AssignmentName}
                                                                </TableCell>

                                                            </TableRow>
                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                        </div>
                                        :
                                        <TableContainer component={Paper}>
                                            <Table size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell className='font-weight-bold'>Client Name</TableCell>

                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {clientListData.map((row, i) => (
                                                        <TableRow

                                                            onClick={() => { handleClientClick(row.EncClientID) }}
                                                            style={{ cursor: "pointer" }}
                                                            key={row.ClientName}
                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                        >
                                                            <TableCell key={i} component="th" scope="row">
                                                                {row.ClientName}
                                                            </TableCell>

                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>


                                }
                                {
                                    isShowBackBtn ?
                                        <li style={{ listStyle: "none", marginLeft: "-5%", cursor: "pointer" }}>
                                            <i className="fa fa-arrow-left" onClick={() => initClientList(true)}  ></i></li>
                                        : null
                                }
                            </ul>
                        </div>
                    </div>
                    <div className='col-md-3'></div>

                    <div className='col-md-1'></div>
                    <div className='col-md-9'>
                        <ReactPaginate
                            breakLabel="..."
                            nextLabel="next >"
                            onPageChange={handlePageClick}
                            pageCount={pageCount}
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                        />
                    </div>
                    <div className='col-md-2'>
                        {
                            isShowBackBtn ? null : <span
                                style={{ boxShadow: " 0px 3px 2px 1px #dde3e3", marginLeft: "-67%" }}
                                className='text-primary'>
                                Record(s) Total: &nbsp;{clientRecords}
                            </span>
                        }

                    </div>
                </div>

            </div>
            <ToastContainer
                position="top-center"
                autoClose={3000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Layout>
    );
}
export default Client;