import React from 'react';
import NavMenu from './NavMenu/NavMenu';
const Layout = ({ children }) => {
    return (
        <div>
            <NavMenu />
            <div className='container-fluid' style={{marginTop:"6rem"}}>
                {children}
            </div>
           
        </div>
    );
}
export default Layout;