import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import profilePic from '../../../img/profilepic.png'
import Table from 'react-bootstrap/Table';


function CandidateProfile() {



    return (
        <div>
            <div className='row col-12 mt-2'>   
                <div className='col-4 '>
                    <img src={profilePic} />
                </div>
                <div className='col-4'>
                    <span style={{ fontSize: "13px" }} ><b>Candidate Name :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Candidate ID :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Address :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Contact Number :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Mobile Number :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Email ID :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Date Of Birth :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Gender :</b>&nbsp;</span><br />
                </div>
                <div className='col-4' style={{ color: "grey" }}>
                    <span>Ghufran Ali Arshad { }</span><br />
                    <span>429128{ }</span><br />
                    <span>abu dhabi UAE{ }</span><br />
                    <span>00923338163088{ }</span><br />
                    <span>3338163088 { }</span><br />
                    <span> ghufranali@gmail.com{ }</span><br />
                    <span>26/12/1981{ }</span><br />
                    <span>  Male{ }</span><br />
                </div>

            </div>
            <h6 style={{fontFamily: "Times New Roman, Times, serif"}}>SKILL SET</h6>
            <hr ></hr>
            <div className='row col-12'>
                <div className='col-2'>
                    <span style={{ fontSize: "13px" }} ><b>Title  :</b></span>
                </div>
                <div className='col-10' style={{ fontSize: "smaller" }}>
                    <span>plumber cum supervisor, plumber foreman, plumbing chargehand, plumber, electrician, plumber cum elec</span>
                </div>
                <div className='col-2'>
                    <span style={{ fontSize: "13px" }} ><b>Key Skill :</b></span>
                </div>
                <div className='col-10' style={{ fontSize: "smaller" }}>
                    <span> plumber, electrician, auto cad</span>
                </div>
            </div>
            <h6 style={{fontFamily: "Times New Roman, Times, serif"}}>PROFESSIONAL DETAILS :</h6>
            <hr ></hr>
            <div className='row col-12'>

                <div className='col-4'>
                    <span style={{ fontSize: "13px" }} ><b>Work Experience  :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Functional Area :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Area Of Specailization :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Current Employer :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Current Location :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Industry :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Yearly Salary :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Passport Number :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Passport Details :</b>&nbsp;</span><br />
                    <span style={{ fontSize: "13px" }} ><b>Overseas Experience :</b>&nbsp;</span><br />
                </div>
                <div className='col-4' style={{ color: "grey" }} >
                    <span>5 year(s) - 1 month(s){ }</span><br />
                    <span>  maintenance{ }</span><br />
                    <span>mep{ }</span><br />
                    <span>NA{ }</span><br />
                    <span>abu dhabi { }</span><br />
                    <span> construction{ }</span><br />
                    <span>Not mentioned{ }</span><br />
                    <span>AY1917951{ }</span><br />
                    <span>NA{ }</span><br />
                    <span>5 Years{ }</span><br />
                </div>
                <div className='col-4 '>

                </div>

            </div>
            <h6 className='mt-2' style={{fontFamily: "Times New Roman, Times, serif"}}>PREFERRED JOB DETAILS :</h6>
            <hr ></hr>
            <div className=' col-12'>
                <span style={{ fontSize: "13px" }} ><b>Position  :</b>&nbsp;<span> NA{ }</span></span><br />
                <span style={{ fontSize: "13px" }} ><b>Salary (in USD) :</b>&nbsp;<span> NA{ }</span></span><br />
                <span style={{ fontSize: "13px" }} ><b>Location :</b>&nbsp;<span> NA{ }</span></span><br />
                <span style={{ fontSize: "13px" }} ><b>Summary :</b>&nbsp;<span> NA{ }</span></span><br />
            </div>
            <h6 className='mt-2' style={{fontFamily: "Times New Roman, Times, serif"}}>QUALIFICATION DETAILS :</h6>
            <hr ></hr>
            <Table striped bordered hover >
                <thead>
                    <tr>
                        <th >Qualification</th>
                        <th>Specialization</th>
                        <th >Institute</th>
                        <th >Passing Year</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>10th</td>
                        <td>10th</td>
                        <td>Higher Secondary School</td>
                        <td>1998</td>
                    </tr>
                    <tr>
                        <td>12th</td>
                        <td>Science</td>
                        <td>Council of Higher Secondary School</td>
                        <td>2000</td>
                    </tr>
                </tbody>
            </Table>
            <h6 className='mt-2'>Resumes :</h6>

            <hr ></hr>
            <div className=' col-12'>
                <p>curriculum vitae of mr. ghufran ali arshad   objective to seek a responsible, challenging and rewarding position with a leading organization that ensures long term career development and growing opportunity.</p>
                <p>personal profile</p>
                <p>name ghufran ali arshad. name of father ch. arshad mahmood. religion islam. date of birth 26-12-1981. domicile gujranwala. (punjab) passport number ay1917951. n.i.c. number 34101-2520795-5. driving license m/cycle &amp; m/car. (pakistan) home address st. # 1, allah bakhsh colony,  race course road, near nagar phatak,  gujranwala, pakistan.  mob. imo. whatsapp. +923338163088 skype id. ghufranali e-mail: ghufranali@gmail.com   academic qualification   1. matric science, (gujranwala board). 2. general electrical, (punjab board of technical education, lahore). 3. auto cad, (punjab board of technical education, lahore).tevta 4. diploma in computer microsoft office user specialist &amp; installations etc. 5. plumbing course, (navtec).    job experience duties uae</p>
                <p>5 years, experience from 2010to 2015 working as electrician + plumber technician in khidmah (l.l.c)abu dhabi uae&amp; several projects just like abu dhabi western region musanada, awqaf mosques, municipality buildings, villas&amp;fdf schooldelma i land&amp;moreeb dune tall moreeb liwa abu dhabi&amp; al raha garden abu dhabi city villas, comma area &amp; eastern region abu dhabi food control authority al ain buildings, clinics.</p>
                <p>job experience pakistan   &#61656; two year in fico hi-tech (pvt) limited as electrician. &#61656; one year in goldamatic upvc pipes as electrician + plumber. &#61656; one year working as computer operator in rack master&amp;auto cad.</p>
                <p>professional experience as technician in gulf</p>
                <p>khidmah (l.l.c) abu dhabi uae, which give report to supervisor by email and update about the all day detail, get work order from customer care and then update to customer care after complete the job, and arrange the teams with proper schedule and provided the material to all the team member and make material quotation or work schedule.  with knowledge of electrician, plumber, domestic water pumps repairs,installation of building wiring, db, water pump, lighting, and also working in maintenance of split, window ac and building services or in similar field etc.</p>
                <p>relation with musanada abu dhabi uae</p>
                <p>i am working under musanada projects, just like all western regions abu dhabi,municipality,awqafmusanada mosque,buildings,villas&amp;fdf school(family development foundation) delma i land &amp;moreeb dune tallmoreebliwa &amp;eastern regions abu dhabi food control authority al ain buildings&amp; clinics.</p>
                <p>computer skills</p>
                <p>ms-office , adobe photoshop , coral draw, adobe reader, windows, xp, vista, 07, 08, 10 internet browsing.</p>
                <p>extra curricular activities   karate. 1.gujranwala board pakistan, sr. no.3591, 3rd position.  2.gujranwala board pakistan, sr. no.3640, 2nd position. judo. punjab judo championship 20 june, 2008 lahore brown medal. jiu jitsu. uae abu dhabi world professional jiu jitsu champion ship 2012 4rt position.</p>
                <p>languages</p>
                <p>english, arabic, urdu, punjabi, hindi</p>
                <p>reference   will be furnished upon request further details upon request</p>
            </div>
        </div>
    )
}
export default CandidateProfile;