import { AuthService } from './AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "ResumeDownload/";


const downloadResumes = async (CandidateIDs) => {
    const resumeData = {
        CandidateIDs: CandidateIDs,
        ReferenceID: parseInt(AuthService.getCurrentUser().referenceID),
        UserID: parseInt(AuthService.getCurrentUser().userId),
        RoleID: parseInt(AuthService.getCurrentUser().roleId),
        LoginID:AuthService.getCurrentUser().loginId,
    }
    const res = await fetch(baseUrl + "DownloadResumes", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(resumeData)
    });
    const data = await res.json();
    return data;
}
export const ResumeDownloadService={
    downloadResumes
}