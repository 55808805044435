import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "Assignment/";


const getClientList = async (searchText) => {
    const res = await fetch(baseUrl + "GetClientList?userID=" + AuthService.getCurrentUser().userId + "&searchText=" + searchText, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getCoordinatorList = async (searchText) => {
    const res = await fetch(baseUrl + "GetAssignmentCoordinatorList?userID=" + AuthService.getCurrentUser().userId + "&searchText=" + searchText, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const saveAssignmentDetails = async ( AssignmentName, AssignmentDetails, ClientID, CoordinatorIDs, IsAssignmentActive , EncAssignmentID) => {
    const assignmentData = {
        AssignmentID: 0,
        EncAssignmentID:EncAssignmentID,
        AssignmentName: AssignmentName,
        AssignmentDetails: AssignmentDetails,
        ClientID: ClientID,
        CoordinatorIDs: CoordinatorIDs,
        IsAssignmentActive: IsAssignmentActive === 0 ? false : true,
        ReferenceID:parseInt(AuthService.getCurrentUser().referenceID)
    }
    const res = await fetch(baseUrl + "SaveAssignment", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(assignmentData)
    });
    const data = await res.json();
    return data;
}


const getAssignmentListByClientID = async (clientID) => {
    const res = await fetch(baseUrl + "GetAssignmentListByClientID?encClientID=" + clientID+"&userID="+parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getClientListByUserID = async (startIndex,searchText) => {
    const res = await fetch(baseUrl + "GetClientListByUserID?startIndex=" + startIndex+"&userID="+parseInt(AuthService.getCurrentUser().userId)+"&searchText="+searchText, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

export const AssignmentService = {
    getClientList,
    getCoordinatorList,
    saveAssignmentDetails,
    getAssignmentListByClientID,
    getClientListByUserID
}