import { AuthService } from './AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "SmsTemplate/";

const getSmsTemplate = async () => {
    const res = await fetch(baseUrl + "GetSMSTemplates?UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
export const SmsSendService={
    getSmsTemplate
}