import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() +"NewJobPosting/";


const getLocationList = async () => {
    const res = await fetch(baseUrl + "GetLocationDDlList", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getIndustryList = async () => {
    const res = await fetch(baseUrl + "GetIndustryDDlList", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getFunctionalAreaList = async () => {
    const res = await fetch(baseUrl + "GetFunctionalAreaDDlList", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getFunctionalAreaSpecializationList = async (FunctionalAreaIDs) => {
    const res = await fetch(baseUrl + "GetFunctionalSpecializationDDlList?FunctionalAreaIDs="+FunctionalAreaIDs, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getQualificationList = async () => {
    const res = await fetch(baseUrl + "GetQualificationDDlList", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getQualificationSpecializationList = async (QualificationIDs) => {
    const res = await fetch(baseUrl + "GetQualificationSpecializationDDlList?QualificationIDs="+QualificationIDs, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getJobCoordinatorList = async () => {
    const res = await fetch(baseUrl + "GetJobCoordinatorDDlList?userID="+AuthService.getCurrentUser().userId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const saveNewJobPosting = async (formData) => {
    const res = await fetch(baseUrl + "SaveNewJobPosting",{
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body:JSON.stringify(formData)
    });
    const data = await res.json();
    return data;
}

const getExistingJobList = async (page,pageSize,searchText) => {
    const pagedata ={
        StartIndex:page,
        PageSize:pageSize,
        SearchText:searchText,
        UserID:AuthService.getCurrentUser().userId
    }
    const res = await fetch(baseUrl + "GetExistingJobList",{
        method:"POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(pagedata)
    });
    let data = await res.json();
    return data;
}

const getJobPostingDetailsById = async (jobpostingID) => {
    const res = await fetch(baseUrl + "GetJobPostingDetails?encJobPostingID="+jobpostingID,{
        method:"GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    let data = await res.json();
    return data;
}

export const NewJobPostingService = {
    getLocationList,
    getIndustryList,
    getFunctionalAreaList,
    getFunctionalAreaSpecializationList,
    getQualificationList,
    getQualificationSpecializationList,
    getJobCoordinatorList,
    saveNewJobPosting,
    getExistingJobList,
    getJobPostingDetailsById
}