import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "CommentProvider/";

const getAllComments = async (StartIndex, candidateID) => {
    const res = await fetch(baseUrl + "GetAllComment?StartIndex=" + StartIndex + "&candidateID=" + candidateID + "&UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },

    });

    const data = await res.json();
    return data;
}
const markUnView = async (CandidateIDs) => {
    const res = await fetch(baseUrl + "MarkAsUnviewed?CandidateIDs=" + CandidateIDs + "&UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },

    });

    const data = await res.json();
    return data;
}

export const CommentsService={
    getAllComments,
    markUnView
}