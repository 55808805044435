import React, { useEffect, useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Layout from '../../../Layout/Layout';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Table from 'react-bootstrap/Table';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import profilePic from '../../../img/profilepic.png'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import profileIcon from '../../../img/user.png';
import commentIcon from '../../../img/comments.png';
import attachmentIcon from '../../../img/attached-file.png';
import auditIcon from '../../../img/audit.png';
import docIcon from '../../../img/folders.png';
import ReactPaginate from 'react-paginate';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import AddIcCallOutlinedIcon from '@mui/icons-material/AddIcCallOutlined';
import PlaylistAddCheckOutlinedIcon from '@mui/icons-material/PlaylistAddCheckOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import { CandidateResumeDetailService } from '../../../Services/CandidateResumeDetailService';
import { AuthService } from '../../../Services/AuthService';

function CandidateResumeDetails() {
    const [withDoctotalcomment, setwithDoctotalcomment] = useState(0);
    const [withoutDoctotalcomment, setwithoutDoctotalcomment] = useState(0);
    const withDocitemPerPage = 2;
    const [withDocitemOffset, setwithDocItemOffset] = useState(10);
    const [withDocstartIndex, setwithDocstartIndex] = useState(0);
    const [withDocpageCount, setwithDocpageCount] = useState(0);
    const withoutDocitemPerPage = 2;
    const [withoutDocitemOffset, setwithoutDocItemOffset] = useState(10);
    const [withoutDocstartIndex, setwithoutDocstartIndex] = useState(0);
    const [withoutDocpageCount, setwithoutDocpageCount] = useState(0);
    const navigate = useNavigate();
    const search = useLocation().search;
    const [openLoader, setopenLoader] = React.useState(false);
    const [resumeDetails, setresumeDetails] = useState([]);
    const [candidatePicture, setcandidatePicture] = useState([]);
    const [qualificationDetails, setqualificationDetails] = useState([]);
    const [isFileValid, setisFileValid] = useState(true);
    const [fileValidationErrorMessage, setfileValidationErrorMessage] = useState("");
    const [commentText, setcommentText] = useState("");
    const [canFeedWithDoc, setcanFeedWithDoc] = useState([]);
    const [canFeedWithoutDoc, setcanFeedWithoutDoc] = useState([]);
    const [candidateAuditLog, setcandidateAuditLog] = useState("");
    const maxFileSize = 1024 * 1024;

    const handleWithDocPageClick = (event) => {
        const newOffset = (event.selected * withDocitemPerPage) % withDoctotalcomment;
        setwithDocstartIndex(event.selected);
        setwithDocItemOffset(newOffset);
    }
    const handleWithoutDocPageClick = (event) => {
        const newOffset = (event.selected * withoutDocitemPerPage) % withoutDoctotalcomment;
        setwithoutDocstartIndex(event.selected);
        setwithoutDocItemOffset(newOffset);
    }
    const getQueryValue = (variable) => {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }

    const getSearchParms = () => {
        const qs = new URLSearchParams(search).get('qs');
        if (qs) {
            const urlDecodedSearchParms = JSON.parse(atob(qs));
            return urlDecodedSearchParms;
        } else {
            return null;
        }
    }
    let CandidateID = getSearchParms().split('&')[1].split('=')[1];
    let CandidateResumeID = getSearchParms().split('&')[1].split('=')[1];
    const handleCommentsChange = (e) => {
        let val = e.target.value;
        if (val) {
            setcommentText(val);
        } else {
            setcommentText("");
        }
    }
    const handleAttachmentUploadChange = (e) => {
        let file = e.target.files[0];
        if (file) {
            if (file.size > 0) {
                if (file.size > maxFileSize) {
                    setisFileValid(false);
                    setfileValidationErrorMessage("Attachment size must be less than 1024kb");
                    return;
                }
                let filename = file.name;
                let fileExt = filename.split('.').pop().toLowerCase();
                if (fileExt === 'doc' || fileExt === "docx" || fileExt === "pdf") {
                    setisFileValid(true);
                    setfileValidationErrorMessage("");
                } else {
                    setisFileValid(false);
                    setfileValidationErrorMessage("Attachment should be in .doc,.docx or .pdf format");
                }
            }
        } else {
            setisFileValid(false);

        }
    }
    const handleSaveCommentsClick = async () => {
        let file = document.getElementsByClassName('fileAttachment');
        let attachedFile = file[0].files[0];
        let formData1 = new FormData();
        if (attachedFile == undefined || attachedFile == null) {
            const res = await CandidateResumeDetailService.saveCommentWithoutDocument(commentText, CandidateID);
            if (res.isSuccess) {
                ShowAlert(1, res.message);
                initGetcandidateFeeds();
                initCommentWithDoc();
                initCommentWithoutDoc();
                setcommentText("");
                setcommentText(null);
            } else {
                ShowAlert(0, res.message);
                setcommentText("");
            }
        } else {
            formData1.append("Comments", commentText);
            formData1.append("FileAttactment", attachedFile);
            formData1.append("CandidateID", parseInt(CandidateID));
            formData1.append("UserID", parseInt(AuthService.getCurrentUser().userId));
            const res = await CandidateResumeDetailService.saveCommentWithDocument(formData1);
            if (res.data.isSuccess) {
                ShowAlert(1, res.data.message);
                initGetcandidateFeeds();
                initCommentWithDoc();
                initCommentWithoutDoc();
                setcommentText("");
                setcommentText(null);
            } else {
                ShowAlert(0, res.data.message);
                setcommentText("");
            }
        }


    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const handlePrintClick = async () => {
        window.print();
        // var win = window.open();
        // win.self.focus();
        // win.document.open();
        // if (document.getElementById("displayResumeDiv").style.display = "block") {
        //     document.getElementById("resumesdiv").style.display = "none";
        //     win.document.write(document.getElementById("modifiedResumeDiv").innerHTML);
        //     win.print();
        //     win.close();
        // }

    }
    const initCandidateAllDoc = async () => {
        setopenLoader(true);
        const res = await CandidateResumeDetailService.getCandidateDoc(CandidateID);
        console.log(res);
        setopenLoader(false);
    }
    const initCommentWithDoc = async () => {
        setopenLoader(true);
        const res = await CandidateResumeDetailService.getCommentWithDoc(CandidateID, withDocstartIndex + 1, withDocitemPerPage);
        setcanFeedWithDoc(res.candidateComments);
        setwithDoctotalcomment(res.recordCount);
        setwithDocpageCount(Math.ceil(res.recordCount / withDocitemPerPage));
        setopenLoader(false);
    }
    const initCommentWithoutDoc = async () => {
        setopenLoader(true);
        const res = await CandidateResumeDetailService.getCommentWithoutDoc(CandidateID, withoutDocstartIndex + 1, withoutDocitemPerPage);
        setwithoutDoctotalcomment(res.recordCount);
        setcanFeedWithoutDoc(res.candidateComments);
        setwithoutDocpageCount(Math.ceil(res.recordCount / withoutDocitemPerPage));
        setopenLoader(false);
    }
    const initCandidateDetails = async () => {
        setopenLoader(true);
        const res = await CandidateResumeDetailService.getResumeDetailsBycandidateID(CandidateResumeID, CandidateID);
        console.log(res);
        setresumeDetails(res);
        setcandidatePicture(res.candidatePhoto ? "data:image/png;base64," + res.candidatePhoto : null);
        setqualificationDetails(res.qualifications);
        setopenLoader(false);
    }
    const initGetcandidateFeeds = async () => {
        setopenLoader(true);
        const res = await CandidateResumeDetailService.getCandidateFeed(CandidateID);
        if (res.isSuccess) {
            setcandidateAuditLog(res.apiData);
        } else {
            ShowAlert(0, res.message);
            setcandidateAuditLog("");
        }
        setopenLoader(false);
    }
    useEffect(() => {
        if (AuthService.getApiAuthorizationConfig()) {
        } else {
        }
        initCandidateAllDoc();
        initCandidateDetails();
        initGetcandidateFeeds();
        initCommentWithDoc();
        initCommentWithoutDoc();
    }, [withDocstartIndex, withDocitemPerPage, withoutDocstartIndex, withoutDocitemPerPage]);
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Layout>
                <Card>
                    <CardContent>
                        <div className='row col-12 mb-2'>
                            <div className='col-3'></div>
                            <div className='col-5 ' style={{ fontFamily: "Times New Roman, Times, serif" }}><b style={{ textTransform: "uppercase" }}>{resumeDetails.candidateName}</b></div>
                            <div className='col-4'>
                                <ButtonGroup className='ml-5'>
                                    <Button variant="outline-success" onClick={() => handlePrintClick()} className='btn-sm' title='Print'><PrintOutlinedIcon /></Button>&nbsp; &nbsp;
                                    <Button variant="outline-warning" className='btn-sm ' title='Contact'><AddIcCallOutlinedIcon /></Button>&nbsp;&nbsp;
                                    <Button variant="outline-info" className='btn-sm ' title='Shortlist'><PlaylistAddCheckOutlinedIcon /></Button>&nbsp;&nbsp;
                                    <Button variant="outline-primary" className='btn-sm ' title='Download Resume'><DownloadForOfflineOutlinedIcon /></Button>&nbsp;&nbsp;
                                </ButtonGroup>
                            </div>
                        </div>
                        <Tab.Container id="left-tabs-example" defaultActiveKey="first">
                            <Row>
                                <Col sm={3}>
                                    <Card>
                                        <CardContent>
                                            <Nav variant="pills" className="flex-column">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="first"><img src={profileIcon} /> &nbsp;Profile</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="second"><img src={commentIcon} /> &nbsp;Comment</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="third"><img src={attachmentIcon} /> &nbsp;Attachment</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fourth"><img src={auditIcon} /> &nbsp;Audit Log</Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fifth"><img src={docIcon} /> &nbsp;Documents</Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </CardContent>
                                    </Card>

                                </Col>

                                <Col sm={9}>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="first">
                                            <div id='displayResumeDiv'>
                                                <div id='modifiedResumeDiv'>
                                                    <Card>
                                                        <CardContent>
                                                            {/* <CandidateProfile /> */}
                                                            <div>
                                                                <div className='row col-12 mt-2'>
                                                                    <div className='col-3 '>
                                                                        <img width={150} height={150} style={{ borderRadius: "50%" }}
                                                                            src={candidatePicture ? candidatePicture : profilePic} alt="profileImg" />
                                                                    </div>
                                                                    <div className=' col-9'>
                                                                        <div className='row col-12 '>
                                                                            <div className='col-3'>
                                                                                <span style={{ fontSize: "13px" }} ><b>Candidate Name :</b>&nbsp;</span>
                                                                            </div>
                                                                            <div className='col-9' style={{ color: "grey" }}>
                                                                                <span className='text-capitalize'>{resumeDetails.candidateFullName}</span>
                                                                            </div>
                                                                            <div className='col-3'>
                                                                                <span style={{ fontSize: "13px" }} ><b>Candidate ID :</b>&nbsp;</span>
                                                                            </div>
                                                                            <div className='col-9' style={{ color: "grey" }}>
                                                                                <span >{resumeDetails.candidateID}</span>
                                                                            </div>
                                                                            <div className='col-3'>
                                                                                <span style={{ fontSize: "13px" }} ><b>Address :</b>&nbsp;</span>
                                                                            </div>
                                                                            <div className='col-9' style={{ color: "grey" }}>
                                                                            <span>{ resumeDetails.presentAddress }</span>
                                                                                {/* <span >{resumeDetails.presentAddress.includes("<br>")?<span dangerouslySetInnerHTML={{ __html: resumeDetails.presentAddress }} /> :<span>{ resumeDetails.presentAddress }</span>}</span> */}
                                                                              
                                                                            </div>
                                                                            <div className='col-3'>
                                                                                <span style={{ fontSize: "13px" }} ><b>Contact Number :</b>&nbsp;</span>
                                                                            </div>
                                                                            <div className='col-9' style={{ color: "grey" }}>
                                                                                <span >{resumeDetails.telephoneNo}</span>
                                                                            </div>
                                                                            <div className='col-3'>
                                                                                <span style={{ fontSize: "13px" }} ><b>Mobile Number :</b>&nbsp;</span>
                                                                            </div>
                                                                            <div className='col-9' style={{ color: "grey" }}>
                                                                                <span >{resumeDetails.mobileNo}&nbsp;{resumeDetails.isMobileNumberVerified ? <i className="fa fa-check-circle" style={{ color: "green" }} title="Verified"></i> : null}</span>
                                                                            </div>
                                                                            <div className='col-3'>
                                                                                <span style={{ fontSize: "13px" }} ><b>Email ID :</b>&nbsp;</span>
                                                                            </div>
                                                                            <div className='col-9' style={{ color: "grey" }}>
                                                                                <span >{resumeDetails.emailID}&nbsp; {resumeDetails.isEmailVerified ? <i className="fa fa-check-circle" style={{ color: "green" }} title="Verified"></i> : null}</span>
                                                                            </div>
                                                                            <div className='col-3'>
                                                                                <span style={{ fontSize: "13px" }} ><b>Date Of Birth :</b>&nbsp;</span>
                                                                            </div>
                                                                            <div className='col-9' style={{ color: "grey" }}>
                                                                                <span >{resumeDetails.dateOfBirth}</span>
                                                                            </div>
                                                                            <div className='col-3'>
                                                                                <span style={{ fontSize: "13px" }} ><b>Gender :</b>&nbsp;</span>
                                                                            </div>
                                                                            <div className='col-9' style={{ color: "grey" }}>
                                                                                <span >{resumeDetails.gender}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <h6 className='ml-3' style={{ fontFamily: "Times New Roman, Times, serif" }}>SKILL SET</h6>
                                                                <hr ></hr>
                                                                <div className='row col-12'>
                                                                    <div className='col-2'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Title  :</b></span>
                                                                    </div>
                                                                    <div className='col-10' style={{ fontSize: "smaller" }}>
                                                                        <span>{resumeDetails.candidateResumeTitle}</span>
                                                                    </div>
                                                                    <div className='col-2'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Key Skill :</b></span>
                                                                    </div>
                                                                    <div className='col-10' style={{ fontSize: "smaller" }}>
                                                                        <span>{resumeDetails.keySkill}</span>
                                                                    </div>
                                                                </div>
                                                                <h6 className='ml-3 mt-3' style={{ fontFamily: "Times New Roman, Times, serif" }}>PROFESSIONAL DETAILS :</h6>
                                                                <hr ></hr>
                                                                <div className='row col-12'>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Work Experience  :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span> {resumeDetails.experienceYear}year(s) - {resumeDetails.experienceMonth} month(s)</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Functional Area :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span>  {resumeDetails.functionalArea}</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Area Of Specailization :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span>{resumeDetails.functionalAreaSpecialization}</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Current Employer :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span>{resumeDetails.currentEmployer}</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Current Location :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span>{resumeDetails.currentLocation}</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Industry :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span> {resumeDetails.currentIndustry}</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Yearly Salary :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span>{resumeDetails.yearlySalary == "Hidden" ? <span style={{ color: "red" }}>Hidden</span> : <span>{resumeDetails.yearlySalary}</span>}</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Passport Number :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span>{resumeDetails.passportNo}</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Passport Details :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span>{resumeDetails.passportDetails}</span>
                                                                    </div>
                                                                    <div className='col-3'>
                                                                        <span style={{ fontSize: "13px" }} ><b>Overseas Experience :</b>&nbsp;</span>
                                                                    </div>
                                                                    <div className='col-9' style={{ color: "#6c6c6c" }} >
                                                                        <span>{resumeDetails.overseasExperience}</span>
                                                                    </div>
                                                                </div>
                                                                <h6 className='mt-4 ml-3' style={{ fontFamily: "Times New Roman, Times, serif" }}>PREFERRED JOB DETAILS :</h6>
                                                                <hr ></hr>
                                                                <div className=' col-12'>
                                                                    <span style={{ fontSize: "13px" }} ><b>Position  :</b>&nbsp;<span> NA{ }</span></span><br />
                                                                    <span style={{ fontSize: "13px" }} ><b>Salary (in USD) :</b>&nbsp;<span> NA{ }</span></span><br />
                                                                    <span style={{ fontSize: "13px" }} ><b>Location :</b>&nbsp;<span> NA{ }</span></span><br />
                                                                    <span style={{ fontSize: "13px" }} ><b>Summary :</b>&nbsp;<span> NA{ }</span></span><br />
                                                                </div>
                                                                <h6 className='mt-2 ml-3' style={{ fontFamily: "Times New Roman, Times, serif" }}>QUALIFICATION DETAILS :</h6>
                                                                <hr ></hr>
                                                                <Table striped bordered hover >
                                                                    <thead>
                                                                        <tr>
                                                                            <th style={{ color: "black" }}>Qualification</th>
                                                                            <th style={{ color: "black" }}>Specialization</th>
                                                                            <th style={{ color: "black" }}>Institute</th>
                                                                            <th style={{ color: "black" }}>Passing Year</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {
                                                                            qualificationDetails ? qualificationDetails.map((item, i) =>
                                                                                <tr key={i} value={item.canResumeQualificationID}>
                                                                                    <td>{item.qualificationName}</td>
                                                                                    <td>{item.qualificationSpecializationName}</td>
                                                                                    <td>{item.qualificationInstituteName}</td>
                                                                                    <td>{item.qualificationYearOfPassing}</td>
                                                                                </tr>
                                                                            ) : null
                                                                        }
                                                                    </tbody>
                                                                </Table>
                                                            </div>
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                                <div id='resumesdiv'>
                                                    <h4 className='text-center text-uppercase' ><b>Resumes</b></h4>
                                                    <Card>
                                                        <CardContent>
                                                            <span dangerouslySetInnerHTML={{ __html: resumeDetails.candidateResumeHTML }} />
                                                        </CardContent>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="second">
                                            <Card>
                                                <CardContent>
                                                    <div className='row col-12'>
                                                        <div className='col-3'>
                                                            <label className='form-label'>Comments :</label>
                                                        </div>
                                                        <div className='col-9'>
                                                            <input className='form-control' type='textarea' rows="2" cols="25" onChange={handleCommentsChange} />
                                                            <span style={{ color: "grey", fontSize: "13px" }}><p>Please enter comments  maximum 255 characters</p></span>
                                                        </div>
                                                        <div className='col-3'>
                                                            <label className='form-label'>Attachment :</label>
                                                        </div>
                                                        <div className='col-9'>
                                                            <input type='file' className='fileAttachment' accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                onChange={handleAttachmentUploadChange}
                                                            />
                                                            <span style={{ color: "grey", fontSize: "13px" }}><p>Allowed file extensions are .jpg,.jpeg,.gif,.bmp,.png,.doc,.pdf,.docx,.tif. Maximum size of file 1024kb.</p></span>
                                                            {isFileValid ? "" : <p className='text-danger mt-1'>{fileValidationErrorMessage}</p>}
                                                        </div>
                                                        <div className='col-5'></div>
                                                        <div className='col-2 mb-2'>
                                                            <Button variant="outline-success" className='btn-sm' onClick={() => { handleSaveCommentsClick() }}>Submit</Button>
                                                        </div>
                                                        <div className='col-5'></div>
                                                        <div >
                                                            {
                                                                canFeedWithoutDoc ? canFeedWithoutDoc.map((item, i) =>
                                                                    <div className="commcan" key={i} value={item.candidateCommentID}>
                                                                        <div className="ccanbox">
                                                                            <span>{item.modifiedByLoginID}</span><br></br>
                                                                            <span>{item.comments}</span>
                                                                            <div className="ff">
                                                                                <div className="fl"> {item.source} &nbsp;{item.commentDate}</div>
                                                                                {/* <div className="fr"> <a className="bluelink" href="">Delete</a></div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null
                                                            }

                                                        </div>
                                                        <div className='mt-2'>
                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel="next >"
                                                                onPageChange={handleWithoutDocPageClick}
                                                                pageCount={withoutDocpageCount}
                                                                previousLabel="< previous"
                                                                pageClassName="page-item"
                                                                pageLinkClassName="page-link"
                                                                previousClassName="page-item"
                                                                previousLinkClassName="page-link"
                                                                nextClassName="page-item"
                                                                nextLinkClassName="page-link"
                                                                breakClassName="page-item"
                                                                breakLinkClassName="page-link"
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                renderOnZeroPageCount={null}
                                                            />
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="third">
                                            <Card>
                                                <CardContent>
                                                    <div className='row col-12'>
                                                        <div className='col-3'>
                                                            <label className='form-label'>Comments :</label>
                                                        </div>
                                                        <div className='col-9'>
                                                            <input className='form-control' type='textarea' rows="2" cols="25" onChange={handleCommentsChange} />
                                                            <span style={{ color: "grey", fontSize: "13px" }}><p>Please enter comments  maximum 255 characters</p></span>
                                                        </div>
                                                        <div className='col-3'>
                                                            <label className='form-label'>Attachment :</label>
                                                        </div>
                                                        <div className='col-9'>
                                                            <input type='file' className='fileAttachment' accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                                                                onChange={handleAttachmentUploadChange}
                                                            />
                                                            <span style={{ color: "grey", fontSize: "13px" }}><p>Allowed file extensions are .jpg,.jpeg,.gif,.bmp,.png,.doc,.pdf,.docx,.tif. Maximum size of file 1024kb.</p></span>
                                                            {isFileValid ? "" : <p className='text-danger mt-1'>{fileValidationErrorMessage}</p>}
                                                        </div>
                                                        <div className='col-5'></div>
                                                        <div className='col-2 mb-2'>
                                                            <Button variant="outline-success" className='btn-sm' onClick={() => { handleSaveCommentsClick() }}>Submit</Button>
                                                        </div>
                                                        <div >
                                                            {
                                                                canFeedWithDoc ? canFeedWithDoc.map((item, i) =>
                                                                    <div className="commcan" key={i} value={item.candidateCommentID}>
                                                                        <div className="ccanbox">
                                                                            <span>{item.modifiedByLoginID}</span><br></br>
                                                                            <span>{item.comments}</span>
                                                                            <div className="ff">
                                                                                <div className="fl"> {item.source} &nbsp;{item.commentDate}</div>
                                                                                {/* <div className="fl pl20">
                                                                                    <a href="">Download</a>
                                                                                </div> */}
                                                                                {/* <div className="fr"> <a className="bluelink" href="">Delete</a></div> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ) : null
                                                            }
                                                        </div>
                                                        <div className='mt-2'>
                                                            <ReactPaginate
                                                                breakLabel="..."
                                                                nextLabel="next >"
                                                                onPageChange={handleWithDocPageClick}
                                                                pageCount={withDocpageCount}
                                                                previousLabel="< previous"
                                                                pageClassName="page-item"
                                                                pageLinkClassName="page-link"
                                                                previousClassName="page-item"
                                                                previousLinkClassName="page-link"
                                                                nextClassName="page-item"
                                                                nextLinkClassName="page-link"
                                                                breakClassName="page-item"
                                                                breakLinkClassName="page-link"
                                                                containerClassName="pagination"
                                                                activeClassName="active"
                                                                renderOnZeroPageCount={null}
                                                            />
                                                        </div>
                                                    </div>
                                                </CardContent>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fourth">
                                            <Card>
                                                <CardContent>
                                                    <div >{candidateAuditLog ?
                                                        <span dangerouslySetInnerHTML={{ __html: candidateAuditLog }} /> : <p>Candidate do not have any Audit Log.</p>
                                                    }
                                                        <br />
                                                        {/* <div className="commcan">
                                                            <div className="ccanbox">
                                                                <p>
                                                                    <span ></span></p><div> <span>Dattatray Raj commented to</span> <span className="bluelink">
                                                                        <a href="">Sudhakar Bharathy</a></span> <span>: </span> <span> <br />
                                                                        <span className="fcomestb " > gdsg</span></span></div><div className="rowGap10"></div><div><span> On 14/01/2023</span></div><p></p>
                                                            </div>
                                                        </div> */}
                                                    </div>

                                                </CardContent>
                                            </Card>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fifth">
                                            <Card>
                                                <CardContent>
                                                    <p>Candidate do not have any public document.</p>
                                                </CardContent>
                                            </Card>
                                        </Tab.Pane>
                                    </Tab.Content>
                                </Col>
                            </Row>
                        </Tab.Container>
                    </CardContent>
                </Card>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Layout >
        </div >
    )
}
export default CandidateResumeDetails;