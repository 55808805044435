import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "CandidateSearch/";

const getCandidatesList = async (formData) => {
    
    const res = await fetch(baseUrl + "GetCandidateSearchList", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body:JSON.stringify(formData)
    });
    const data = await res.json();
    return data;
}
export const CandidateSearchService={
    getCandidatesList
}