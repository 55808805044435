import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from "react-bootstrap/Modal";
import { AuthService } from '../../../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { Editor } from "react-draft-wysiwyg";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { EmailSendService } from '../../../Services/EmailSendService';

function EmailTemplate(props) {

    const [emailTemplateList, setemailTemplateList] = useState([]);
    const [emailtemplateFrom, setemailtemplateFrom] = useState("");
    const [emailtemplateTo, setemailtemplateTo] = useState("");
    const [emailTemplateCode, setemailTemplateCode] = useState("");
    const [emailSubject, setemailSubject] = useState("");
    // react text editor 
    const [newEmailTempName, setnewEmailTempName] = useState("");
    const [openLoader, setopenLoader] = React.useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const rawContentState = convertToRaw(editorState.getCurrentContent());
    const markup = draftToHtml(rawContentState);
    const [emailBodyState, setEmailBodyState] = useState("");
    const [templateshow, settemplateShow] = useState(false);

    const handleEmailTempShow = () => settemplateShow(true);

    // react text editor 
    const handleAddNewTemplate = () => {
        handleEmailTempShow();
    }
    const handleEmailTempClose = () => {
        setemailSubject("");
        setEditorState(EditorState.createEmpty());
        settemplateShow(false);
    }

    const handleNewEmailTemplateNameChange = (e) => {
        if (e.target.value) {
            setnewEmailTempName(e.target.value);
        } else {
            setnewEmailTempName("");
        }
    }

    const handleSendEmailToCandidate = async () => {
        ShowAlert(1, "Email Sent");
        // setopenLoader(true);
        // const candidateIds = [];
        // searchResult.filter(function (filter) {
        //     for (let i = 0; i < selected.length; i++) {
        //         if (selected[i] === filter.CandidateResumeID) {
        //             candidateIds.push({ canID: filter.CandidateID })
        //         }
        //     }
        // });
        // let val = "";
        // for (var i = 0; i < candidateIds.length; i++) {
        //     val += candidateIds[i].canID + ",";
        // }
        // let candidateIDs = val.substring(0, val.length - 1);
        // const res = await EmailSendService.sendEmailtoCandidate(candidateIDs, 0, emailtemplateTo, emailtemplateFrom, emailSubject, markup);
        // if (res.isSuccess) {
        //     ShowAlert(1, res.message);
        // } else {
        //     ShowAlert(0, res.message);
        // }
        // setopenLoader(false);
    }
    const handleSaveTemplateAndSendEmail = async () => {
        ShowAlert(1, " Save Template Email Sent");
        // setopenLoader(true);
        // const tempRes = await EmailSendService.createNewEmailTemplate(emailTemplateCode, newEmailTempName, "", emailSubject, markup);
        // if (tempRes.isSuccess) {
        //     const candidateIds = [];
        //     searchResult.filter(function (filter) {
        //         for (let i = 0; i < selected.length; i++) {
        //             if (selected[i] === filter.CandidateResumeID) {
        //                 candidateIds.push({ canID: filter.CandidateID })
        //             }
        //         }
        //     });
        //     let val = "";
        //     for (var i = 0; i < candidateIds.length; i++) {
        //         val += candidateIds[i].canID + ",";
        //     }
        //     let candidateIDs = val.substring(0, val.length - 1);
        //     const res = await EmailSendService.sendEmailtoCandidate(candidateIDs, 0, emailtemplateTo, emailtemplateFrom, emailSubject, markup);
        //     if (res.isSuccess) {
        //         ShowAlert(1, "Email Send to candidates and template Saved");
        //         handleEmailClose();
        //     } else {
        //         ShowAlert(0, res.message);
        //     }

        // } else {
        //     ShowAlert(0, "Email Not Sent");
        // }
        // setopenLoader(false);
    }
 
    const handleSaveNewEmailTemplate = async () => {
        setopenLoader(true);
        const res = await EmailSendService.createNewEmailTemplate("0", newEmailTempName, "", "", "");
        if (res.isSuccess) {
            ShowAlert(1, res.message);

            setemailSubject("");
            setEmailBodyState("");
            setEditorState(EditorState.createEmpty());
            handleEmailTempClose();
        } else {
            ShowAlert(0, res.message);
        }
        setopenLoader(false);
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const handleTemplateSelectChange = async () => {
        let templates = document.getElementById("selectTemplate");
        let templateCode = templates.options[templates.selectedIndex].value;
        let templateName = templates.options[templates.selectedIndex].text;
        const res = await EmailSendService.getTemplateBody(templateCode);
        setemailTemplateCode(templateCode);
        if (res.templateBody) {
            setemailSubject(res.templateSubject);
            const blocksFromHTML = convertFromHTML(res.templateBody);
            const state = ContentState.createFromBlockArray(
                blocksFromHTML.contentBlocks,
                blocksFromHTML.entityMap,
            );
            setEditorState(EditorState.createWithContent(state));
        } else {
            setemailSubject("");
            setEmailBodyState("");
            setEditorState(EditorState.createEmpty());
        }
    }
    const handleEmailSubjectChange = (e) => {
        if (e.target.value) {
            setemailSubject(e.target.value);
        } else {
            setemailSubject("");
        }
    }
    const handleEmailFromChange = (e) => {
        if (e.target.value) {
            setemailtemplateFrom(e.target.value);
        } else {
            setemailtemplateFrom("");
        }
    }
    const handleEmailToChange = (e) => {
        if (e.target.value) {
            setemailtemplateTo(e.target.value);
        } else {
            setemailtemplateTo("");
        }
    }
    const initEmailTemplates = async () => {
        const res = await EmailSendService.getEmailTemplate();
        setemailTemplateList(JSON.parse(res.emailTemplate));
    }
    useEffect(() => {
        setemailtemplateFrom(props.EmailFrom);
        setemailtemplateTo(props.CandidateEmails);
        initEmailTemplates();
    }, [])

    return (
        <div>
            {/* new template modal */}
            <Modal
                show={templateshow}
                onHide={handleEmailTempClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "5rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'> Add New Email Template</span>
                    </Modal.Title>
                    <button onClick={handleEmailTempClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>

                    <div className='row mb-2'>
                        <div className='col-5 mt-1'>
                            <label className='control-label'>Template Name <span style={{ color: "red" }}>*</span>:</label>
                        </div>
                        <div className='col-7 mt-1'>
                            <input type='text' className='form-control' onChange={handleNewEmailTemplateNameChange} />
                        </div>

                    </div>
                    <Modal.Footer>
                        <Button variant="danger" onClick={handleEmailTempClose}>
                            <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                        </Button>
                        <Button variant="primary" type='button' onClick={() => handleSaveNewEmailTemplate()}><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save</Button>
                    </Modal.Footer>

                </Modal.Body>
            </Modal>
            {/* end new template modal */}
            <div className='row mb-2'>
                <div className='col-3 mt-1'>
                    <label className='control-label'>Select Template :</label>
                </div>
                <div className='col-6 mt-1' >
                    <select className='form-control' name='selectTemplate' id='selectTemplate' onChange={handleTemplateSelectChange}>
                        <option value="">-- Select Template --</option>
                        {
                            emailTemplateList.map((item, i) =>
                                <option key={i} value={item.EmailTemplateCode}>{item.EmailTemplateName}</option>
                            )
                        }
                    </select>
                </div>
                <div className='col-3 mt-1'>
                    <Button variant="warning" className='btn-sm' type='button' onClick={() => handleAddNewTemplate()}>Add New</Button>
                </div>
                <div className='col-3 mt-1'>
                    <label className='control-label'>From :</label>
                </div>
                <div className='col-5 mt-1' >
                    <input type='text' className='form-control' readOnly value={emailtemplateFrom} onChange={handleEmailFromChange} />
                </div>
                <div className='col-4 mt-1'></div>
                <div className='col-3 mt-1'>
                    <label className='control-label'>To :</label>
                </div>
                <div className='col-8 mt-1' >
                    <textarea rows="2" cols="25" className='form-control' value={emailtemplateTo} onChange={handleEmailToChange} readOnly />
                </div>
                <div className='col-1 mt-1'></div>
                <div className='col-3 mt-1'>
                    <label className='control-label'>Subject :</label>
                </div>
                <div className='col-8 mt-1' >
                    <textarea rows="2" cols="25" className='form-control' value={emailSubject} onChange={handleEmailSubjectChange} />
                </div>
                <div className='col-1 mt-1'></div>
                <div className='col-3 mt-1'>
                    <label className='control-label'>Body :</label>
                </div>
                <div className='col-8 mt-1' style={{ border: "1px solid black" }}>
                    <Editor
                        editorState={editorState}
                        toolbarClassName="toolbarClassName"
                        wrapperClassName="wrapperClassName"
                        editorClassName="editorClassName"
                        onEditorStateChange={setEditorState}
                    />

                </div>
                <div className='col-1 mt-1'></div>
                <div className='col-6 mt-2'></div>
                <div className='col-6 mt-2'>
                    <Button variant="success" onClick={() => handleSendEmailToCandidate()}> Send </Button>&nbsp;
                    <Button variant="warning" type='button' onClick={() => handleSaveTemplateAndSendEmail()}><i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save Template And Send</Button>
                </div>
            </div>

        </div>
    )
}
export default EmailTemplate;