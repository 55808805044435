import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
import axios from 'axios';
const baseUrl = DomainService.GetBaseUrl() + "CandidateEntry/";

const GetCountryDropDown = async () => {
    const res = await fetch(baseUrl + "GetCountryDropDown", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetCurrentLocationDropDown = async () => {
    const res = await fetch(baseUrl + "GetCurrentLocationDropDown", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetQualificationDropDown = async () => {
    const res = await fetch(baseUrl + "GetQualificationDropDown", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const GetQualificationSpecializationDropDown = async (qualificationIDs) => {
    const res = await fetch(baseUrl + "GetQualificationSpecializationDropDown?qualificationIDs=" + qualificationIDs, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetInstituteDropDown = async () => {
    const res = await fetch(baseUrl + "GetInstituteDropDown", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetNationalityDropDown = async () => {
    const res = await fetch(baseUrl + "GetNationalityDropDown", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetReligionDropdown = async () => {
    const res = await fetch(baseUrl + "GetReligionDropdown", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetMaritalStatusDropDown = async () => {
    const res = await fetch(baseUrl + "GetMaritalStatusDropDown", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetFunctionalAreaDropDownList = async () => {
    const res = await fetch(baseUrl + "GetFunctionalAreaDropDownList", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetFunctionalAreaSpecializationDropDownList = async (functionalAreaIds) => {
    const res = await fetch(baseUrl + "GetFunctionalAreaSpecializationDropDownList?functionalAreaIds=" + functionalAreaIds, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const GetCurrentIndustryDropDown = async () => {
    const res = await fetch(baseUrl + "GetCurrentIndustryDropDown", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const saveCandidateData = async (formdata) => {
    console.log(formdata);

    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
            },
        };
        const res = await axios.post(baseUrl + "SaveCandidateEntry", formdata, config).then((response) => {
            console.log(response);
            return response;
        });
       return res;
    } catch (error) {
        console.log(error);
    }
}
const getCandidateByID = async (canID) => {
    const res = await fetch(baseUrl + "CandidateById?candidateID=" + canID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getCandidateResumeForDownload = async (resumeID) => {
    const res = await fetch(baseUrl + "GetResumeDetailsForDownload?encResumeID=" + resumeID+"&referenceID="+parseInt(AuthService.getCurrentUser().referenceID)+"&userTypeID="+AuthService.getCurrentUser().userTypeId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
export const CandidateEntryService = {
    getCandidateByID,
    GetCountryDropDown,
    GetCurrentLocationDropDown,
    GetQualificationDropDown,
    GetQualificationSpecializationDropDown,
    GetInstituteDropDown,
    GetNationalityDropDown,
    GetReligionDropdown,
    GetMaritalStatusDropDown,
    GetFunctionalAreaDropDownList,
    GetFunctionalAreaSpecializationDropDownList,
    GetCurrentIndustryDropDown,
    saveCandidateData,
    getCandidateResumeForDownload
}