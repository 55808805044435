import React, { useState, useEffect } from 'react';
import { AuthService } from '../../Services/AuthService';
import Select from 'react-select';
import { NewJobPostingService } from '../../Services/NewJobPostingService';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { ToastContainer, toast } from 'react-toastify';
import DataTable from 'react-data-table-component';
import { Navigate } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { CryptoService } from '../../Services/CryptoService';

function NewJobPosting() {
    const navigate = useNavigate();
    const isAuth = AuthService.isAuthenticatedUser();
    const { handleSubmit } = useForm();
    const [locationList, setlocationList] = useState({});
    const [industryList, setindustryList] = useState({});
    const [functionalAreaList, setfunctionalAreaList] = useState({});
    const [functionalAreaSpecializationList, setfunctionalAreaSpecializationList] = useState({});
    const [qualificationList, setqualificationList] = useState({});
    const [qualificationSpecializationList, setqualificationSpecializationList] = useState({});
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setendDate] = useState(new Date());
    const [isJobActive, setisJobActive] = useState(1);
    const [isHotJob, setisHotJob] = useState(1);
    const [jobCoordinatorList, setjobCoordinatorList] = useState({});

    //Form-Field state Constant
    const [formJobTitle, setformJobTitle] = useState("");
    const [formJobSummary, setformJobSummary] = useState("");
    const [formJobDescription, setformJobDescription] = useState("");
    const [formNumberOfJobPositions, setformNumberOfJobPositions] = useState(0);
    const [formKeySkill, setformKeySkill] = useState("");
    const [formYearForm, setformYearForm] = useState(0);
    const [formYearTo, setformYearTo] = useState(0);
    const [formYearlySalaryFrom, setformYearlySalaryFrom] = useState(0);
    const [formYearlySalaryTo, setformYearlySalaryTo] = useState(0);
    const [formStartDate, setformStartDate] = useState("");
    const [formEndDate, setformEndDate] = useState("");
    const [formResponseEmailId, setformResponseEmailId] = useState("");
    //Form-Field-Dropdown state Constant
    const [formLocations, setformLocation] = useState({});
    const [formIndustries, setformIndustries] = useState({});
    const [formFunctionalAreas, setformFunctionalAreas] = useState({});
    const [formFunctionalSpecializations, setformFunctionalSpecializations] = useState({});
    const [formQualifications, setformQualifications] = useState({});
    const [formQualificationSpecializations, setformQualificationSpecializations] = useState({});
    const [formJobCoordinators, setformJobCoordinators] = useState({});


    //For dropdown-selected Names
    const [locationLabel, setlocationLabel] = useState("");

    //For dropdown-selected state constants
    const [formLocationSelected, setformLocationSelected] = useState(locationList[0]);
    const [formIndustrySelected, setformIndustrySelected] = useState(formIndustries[0]);
    const [formFunctionalAreasSelected, setformFunctionalAreasSelected] = useState(formFunctionalAreas[0]);
    const [formFunctionalSpecializationsSelected, setformFunctionalSpecializationsSelected] = useState(formFunctionalSpecializations[0]);
    const [formQualificationsSelected, setformQualificationsSelected] = useState(formQualifications[0]);
    const [formQualificationSpecializationsSelected, setformQualificationSpecializationsSelected] = useState(formQualificationSpecializations[0]);
    const [formJobCoordinatorsSelected, setformJobCoordinatorsSelected] = useState(formJobCoordinators[0]);
    //validation State Constant
    const [hasJobTitleShowError, sethasJobTitleShowError] = useState(false);
    const [hasJobSummaryShowError, sethasJobSummaryShowError] = useState(false);
    const [hasJobDescriptionShowError, sethasJobDescriptionShowError] = useState(false);
    const [hasNumberOfShowPositionShowError, sethasNumberOfShowPositionShowError] = useState(false);
    const [hasKeySkillForJobShowError, sethasKeySkillForJobShowError] = useState(false);
    const [hasYearFromShowError, sethasYearFromShowError] = useState(false);
    const [hasYearToShowError, sethasYearToShowError] = useState(false);
    const [hasYearlySalaryFromShowError, sethasYearlySalaryFromShowError] = useState(false);
    const [hasYearlySalaryToShowError, sethasYearlySalaryToShowError] = useState(false);
    const [hasStartDateShowError, sethasStartDateShowError] = useState(false);
    const [hasEndDateShowError, sethasEndDateShowError] = useState(false);
    //validation-Dropdown State Constant
    const [hasLocationShowError, sethasLocationShowError] = useState(false);
    const [hasIndustryShowError, sethasIndustryShowError] = useState(false);
    const [hasFunctionalAreaShowError, sethasFunctionalAreaShowError] = useState(false);
    const [hasFunctionalSpecializationShowError, sethasFunctionalSpecializationShowError] = useState(false);
    const [hasQualificationShowError, sethasQualificationShowError] = useState(false);
    const [hasQualificationSpecializationShowError, sethasQualificationSpecializationShowError] = useState(false);
    const [hasJobCoordinatorShowError, sethasJobCoordinatorShowError] = useState(false);

    // EXISTING JOBPOSTING STATE CONSTANT
    const [page, setPage] = useState(0);
    const countPerPage = 10;
    const [existingJobList, setexistingJobList] = useState([]);
    const [searchText, setsearchText] = useState("");
    const [searchText1, setsearchText1] = useState("");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const columns = [
        {
            name: 'Job Code',
            selector: row => `${row.jobCode}`,
            sortable: true,
            width: 100,
        },
        {
            name: 'Job Title',
            selector: row => `${row.jobTitle}`,
            sortable: true,
            width: 100,
        },
        {
            name: 'Client',
            selector: row => `${row.clientName}`,
            sortable: true,
            width: 50,
        },
        {
            name: 'Action',
            selector: row => (

                <button className='btn btn-primary' onClick={() => handleExistingJobPostingSelect(row.encJobPostingID)}>
                    select
                </button>

            )
        }
    ];

    const handleExistingJobPostingSelect = async (encJobPostingID) => {
        handleClose();
        const jobPostingDetails = await NewJobPostingService.getJobPostingDetailsById(encJobPostingID);
        if (jobPostingDetails) {
            setformJobTitle(jobPostingDetails.jobTitle);
            setformJobSummary(jobPostingDetails.jobSummary);
            setformJobDescription(jobPostingDetails.jobDescription);
            setformNumberOfJobPositions(jobPostingDetails.numberOfJobPositions);
            setformKeySkill(jobPostingDetails.keySkillForJob);
            if (jobPostingDetails.locations) {
                for (let i = 0; i < jobPostingDetails.locations.length; i++) {
                    setformLocationSelected({ label: jobPostingDetails.locations[i].label, value: jobPostingDetails.locations[i].value });
                }
            }
            if (jobPostingDetails.industries) {
                for (let i = 0; i < jobPostingDetails.industries.length; i++) {
                    setformIndustrySelected({ label: jobPostingDetails.industries[i].label, value: jobPostingDetails.industries[i].value });
                }
            }
            if (jobPostingDetails.functionalAreas) {
                for (let i = 0; i < jobPostingDetails.functionalAreas.length; i++) {
                    setformFunctionalAreasSelected({ label: jobPostingDetails.functionalAreas[i].label, value: jobPostingDetails.functionalAreas[i].value });
                }
            }
            if (jobPostingDetails.functionalSpecializations) {
                for (let i = 0; i < jobPostingDetails.functionalSpecializations.length; i++) {
                    setformFunctionalSpecializationsSelected({ label: jobPostingDetails.functionalSpecializations[i].label, value: jobPostingDetails.functionalSpecializations[i].value });
                }
            }
            if (jobPostingDetails.qualifications) {
                for (let i = 0; i < jobPostingDetails.qualifications.length; i++) {
                    setformQualificationsSelected({ label: jobPostingDetails.qualifications[i].label, value: jobPostingDetails.qualifications[i].value });
                }
            }
            if (jobPostingDetails.qualificationsSpecializations) {
                for (let i = 0; i < jobPostingDetails.qualificationsSpecializations.length; i++) {
                    setformQualificationSpecializationsSelected({ label: jobPostingDetails.qualificationsSpecializations[i].label, value: jobPostingDetails.qualificationsSpecializations[i].value });
                }
            }
            setformYearForm(jobPostingDetails.experienceFrom);
            setformYearTo(jobPostingDetails.experienceTo);
            setformYearlySalaryFrom(jobPostingDetails.yearlySalaryFrom);
            setformYearlySalaryTo(jobPostingDetails.yearlySalaryTo);
            setformStartDate(jobPostingDetails.startDate);
            setStartDate(new Date(jobPostingDetails.startDate));
            setendDate(new Date(jobPostingDetails.endDate))
            setformEndDate(jobPostingDetails.endDate);
            if (jobPostingDetails.jobCoordinators) {
                for (let i = 0; i < jobPostingDetails.jobCoordinators.length; i++) {
                    setformJobCoordinatorsSelected({ label: jobPostingDetails.jobCoordinators[i].label, value: jobPostingDetails.jobCoordinators[i].value });
                }
            }
            setformResponseEmailId(jobPostingDetails.responseEmailID);
            setisHotJob(jobPostingDetails.isHotJob === true ? 1 : 0);
            handleIsHotJobChange(jobPostingDetails.isHotJob === true ? 1 : 0);
            setisJobActive(jobPostingDetails.isJobActive === true ? 1 : 0);
            handleIsJobActiveChange(jobPostingDetails.isJobActive === true ? 1 : 0);
        }

    }
    const getQueryValue = (variable) => {
        const query = window.location.search.substring(1);
        const vars = query.split("&");
        for (var i = 0; i < vars.length; i++) {
            var pair = vars[i].split("=");
            if (pair[0] == variable) { return pair[1]; }
        }
        return (false);
    }
    //console.log(getQueryValue('assignmentCode'));
    // const ID = CryptoService.CryptoJSDecrypt(JSON.stringify(getQueryValue('assignmentID')));
    // console.log(ID);

    const handleJobPostingSubmit = async () => {
        CheckFormFieldValidation();
        if (IsFormValid()) {
            const formatFormData = {
                JobPostingID: 0,
                AssignmentID: getQueryValue('assignmentID'),
                JobTitle: formJobTitle,
                JobSummary: formJobSummary,
                JobDescription: formJobDescription,
                NumberOfJobPositions: formNumberOfJobPositions,
                KeySkillForJob: formKeySkill,
                Locations: formLocations,
                Industries: formIndustries,
                FunctionalAreas: formFunctionalAreas,
                FunctionalSpecializations: formFunctionalSpecializations,
                Qualifications: formQualifications,
                QualificationsSpecializations: formQualificationSpecializations,
                ExperienceFrom: formYearForm,
                ExperienceTo: formYearTo,
                YearlySalaryFrom: formYearlySalaryFrom,
                YearlySalaryTo: formYearlySalaryTo,
                StartDate: formStartDate,
                EndDate: formEndDate,
                IsJobActive: isJobActive === 0 ? false : true,
                IsHotJob: isHotJob === 0 ? false : true,
                JobCoordinators: formJobCoordinators,
                ResponseEmailID: formResponseEmailId,
                CurrentUserID: parseInt(AuthService.getCurrentUser().userId),
               
            }
            console.log(formatFormData);
            const res = await NewJobPostingService.saveNewJobPosting(formatFormData);
            console.log(res);
            if (res.isSuccess) {
                ShowAlert(1, res.message);
                setTimeout(() => {
                    navigate("/");
                }, 5000);
               
            } else {
                ShowAlert(0, res.message);
            }
        }
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const handleLocationChange = (selectedOptions) => {
        if (selectedOptions.length > 0) {
            setformLocation(selectedOptions);
            
            sethasLocationShowError(false);
            setformLocationSelected(selectedOptions);
            
          
           
        } else {
            setformLocation({});
            sethasLocationShowError(true);
            setformLocationSelected(undefined);
            
        }
    }
    const handleIndustryChange = (selectedOptions) => {
        if (selectedOptions.length > 0) {
            setformIndustries(selectedOptions);
            sethasIndustryShowError(false);
            setformIndustrySelected(selectedOptions);
        } else {
            setformIndustries({});
            sethasIndustryShowError(true);
            setformIndustrySelected(undefined);
        }
    }
    const handleFunctionalAreaChange = (selectedOptions) => {
        let functionalAreaIDs = [];
        if (selectedOptions.length > 0) {
            setformFunctionalAreas(selectedOptions);
            sethasFunctionalAreaShowError(false);
            for (let i = 0; i < selectedOptions.length; i++) {
                functionalAreaIDs.push(selectedOptions[i].value);
            }
            setformFunctionalAreasSelected(selectedOptions);
            getFunctionalAreaSpecializationList(functionalAreaIDs);
        } else {
            functionalAreaIDs = [];
            sethasFunctionalAreaShowError(true);
            setformFunctionalAreas({});
            setfunctionalAreaSpecializationList(functionalAreaIDs);
            setformFunctionalAreasSelected(undefined);
        }

    }
    const getFunctionalAreaSpecializationList = async (functionalAreaIDs) => {
        const functionalAreaSpecializations = await NewJobPostingService.getFunctionalAreaSpecializationList(functionalAreaIDs);
        functionalAreaSpecializations ? setfunctionalAreaSpecializationList(functionalAreaSpecializations) : setfunctionalAreaSpecializationList({});
    }
    const handleFunctionalAreaSpecializationChange = (selectedOptions) => {
        if (selectedOptions.length > 0) {
            setformFunctionalSpecializations(selectedOptions);
            sethasFunctionalSpecializationShowError(false);
            setformFunctionalSpecializationsSelected(selectedOptions);
        } else {
            setformFunctionalSpecializations({});
            sethasFunctionalSpecializationShowError(true);
            setformFunctionalSpecializationsSelected(undefined);
        }
    }
    const handleQualificationChange = (selectedOptions) => {
        let qualificationIDs = [];
        if (selectedOptions.length > 0) {
            for (let i = 0; i < selectedOptions.length; i++) {
                qualificationIDs.push(selectedOptions[i].value);
            }
            setformQualifications(selectedOptions);
            setformQualificationsSelected(selectedOptions);
            sethasQualificationShowError(false);
            getQualificationSpecializationList(qualificationIDs);
        } else {
            sethasQualificationShowError(true);
            setformQualifications({});
            qualificationIDs = [];
            setqualificationSpecializationList(qualificationIDs);
            setformQualificationsSelected(undefined);
        }
    }
    const getQualificationSpecializationList = async (qualificationIDs) => {
        const qualificationSpecializations = await NewJobPostingService.getQualificationSpecializationList(qualificationIDs);
        qualificationSpecializations ? setqualificationSpecializationList(qualificationSpecializations) : setqualificationSpecializationList({});
    }
    const handleQualificationSpecializationChange = (selectedOptions) => {
        if (selectedOptions.length > 0) {
            setformQualificationSpecializations(selectedOptions);
            sethasQualificationSpecializationShowError(false);
            setformQualificationSpecializationsSelected(selectedOptions);
        } else {
            setformQualificationSpecializations({});
            sethasQualificationSpecializationShowError(true);
            setformQualificationSpecializationsSelected(undefined);
        }
    }
    const handleStartDateSelect = (date) => {
        setformStartDate(formatDate(date));
        setStartDate(date);
    }
    const handleEndDateSelect = (date) => {
        setformEndDate(formatDate(date));
        setendDate(date);
    }
    const handleJobCoordinatorChange = (selectedOptions) => {
        if (selectedOptions.length > 0) {
            setformJobCoordinators(selectedOptions);
            sethasJobCoordinatorShowError(false);
            setformJobCoordinatorsSelected(selectedOptions);
        } else {
            setformJobCoordinators({});
            sethasJobCoordinatorShowError(true);
            setformJobCoordinatorsSelected(undefined);
        }
    }
    const handleJobTitleChange = (e) => {
        if (!IsEmptyOrNullValues(e.target.value)) {
            setformJobTitle(e.target.value);
            sethasJobTitleShowError(false);
        } else {
            setformJobTitle("");
            sethasJobTitleShowError(true);
        }
    }
    const handleJobSummaryChange = (e) => {
        if (!IsEmptyOrNullValues(e.target.value)) {
            setformJobSummary(e.target.value);
            sethasJobSummaryShowError(false);
        } else {
            setformJobSummary("");
            sethasJobSummaryShowError(true);
        }
    }
    const handleJobDescriptionChange = (e) => {
        if (!IsEmptyOrNullValues(e.target.value)) {
            setformJobDescription(e.target.value);
            sethasJobDescriptionShowError(false);
        } else {
            setformJobDescription("");
            sethasJobDescriptionShowError(true);
        }
    }
    const handleNumberOfJobPositionChange = (e) => {
        if (!IsEmptyOrNullValues(e.target.value)) {
            setformNumberOfJobPositions(parseInt(e.target.value));
            sethasNumberOfShowPositionShowError(false);
        } else {
            setformNumberOfJobPositions(0);
            sethasNumberOfShowPositionShowError(true);
        }
    }
    const handleKeySkillChange = (e) => {
        if (!IsEmptyOrNullValues(e.target.value)) {
            setformKeySkill(e.target.value);
            sethasKeySkillForJobShowError(false);
        } else {
            setformKeySkill("");
            sethasKeySkillForJobShowError(true);
        }
    }
    const handleExperienceYearFromChange = (e) => {
        if (!IsEmptyOrNullValues(e.target.value)) {
            sethasYearFromShowError(false);
            setformYearForm(parseInt(e.target.value));
        } else {
            sethasYearFromShowError(true);
            setformYearForm(0);
        }
    }
    const handleExperienceYearToChange = (e) => {
        if (!IsEmptyOrNullValues(e.target.value)) {
            sethasYearToShowError(false);
            setformYearTo(parseInt(e.target.value));
        } else {
            sethasYearToShowError(true);
            setformYearTo(0);
        }
    }
    const handleYearlySalaryFromChange = (e) => {
        if (!IsEmptyOrNullValues(e.target.value)) {
            sethasYearlySalaryFromShowError(false);
            setformYearlySalaryFrom(parseInt(e.target.value))
        } else {
            sethasYearlySalaryFromShowError(true);
            setformYearlySalaryFrom(0)
        }
    }
    const handleYearlySalaryToChange = (e) => {
        if (!IsEmptyOrNullValues(e.target.value)) {
            sethasYearlySalaryToShowError(false);
            setformYearlySalaryTo(parseInt(e.target.value))
        } else {
            sethasYearlySalaryToShowError(true);
            setformYearlySalaryTo(0)
        }
    }
    const handleStartDateChange = (date) => {
        if (date != "") {
            setformStartDate(formatDate(date));
            sethasStartDateShowError(false);
            setStartDate(date);
        } else {
            setformStartDate("");
            sethasStartDateShowError(true);
        }
    }
    const handleEndDateChange = (date) => {
        if (date != "") {
            setformEndDate(formatDate(date));
            sethasEndDateShowError(false);
            setendDate(date);
        } else {
            setformEndDate("");
            sethasEndDateShowError(true);
        }
    }
    const handleIsJobActiveChange = (e) => {
        if (e === 1 || e === 0) {
            setisJobActive(parseInt(e));
        } else {
            setisJobActive(parseInt(e.target.value));
        }
    }
    const handleIsHotJobChange = (e) => {
        if (e === 1 || e === 0) {
            setisHotJob(e);
        } else {
            setisHotJob(parseInt(e.target.value));
        }
    }
    const IsEmptyOrNullValues = (inputText) => {
        if (inputText === null || inputText === "" || inputText === undefined || inputText === 0 || Object.keys(inputText).length === 0) {
            return true;
        } else {
            return false;
        }
    }

    const CheckFormFieldValidation = () => {
        if (!IsEmptyOrNullValues(formJobTitle)) {
            sethasJobTitleShowError(false);
        } else {
            sethasJobTitleShowError(true);
        }
        if (!IsEmptyOrNullValues(formJobSummary)) {
            sethasJobSummaryShowError(false);
        } else {
            sethasJobSummaryShowError(true);
        }
        if (!IsEmptyOrNullValues(formJobDescription)) {
            sethasJobDescriptionShowError(false);
        } else {
            sethasJobDescriptionShowError(true);
        }
        if (formNumberOfJobPositions > 0) {
            sethasNumberOfShowPositionShowError(false);
        } else {
            sethasNumberOfShowPositionShowError(true);
        }
        if (!IsEmptyOrNullValues(formKeySkill)) {
            sethasKeySkillForJobShowError(false);
        } else {
            sethasKeySkillForJobShowError(true);
        }
        if (formLocations.length > 0) {
            sethasLocationShowError(false);
        } else {
            sethasLocationShowError(true);
        }
        if (formIndustries.length > 0) {
            sethasIndustryShowError(false);
        } else {
            sethasIndustryShowError(true);
        }
        if (formFunctionalAreas.length > 0) {
            sethasFunctionalAreaShowError(false);
        } else {
            sethasFunctionalAreaShowError(true);
        }
        if (formFunctionalSpecializations.length > 0) {
            sethasFunctionalSpecializationShowError(false);
        } else {
            sethasFunctionalSpecializationShowError(true);
        }
        if (formQualifications.length > 0) {
            sethasQualificationShowError(false);
        } else {
            sethasQualificationShowError(true);
        }
        if (formQualificationSpecializations.length > 0) {
            sethasQualificationSpecializationShowError(false);
        } else {
            sethasQualificationSpecializationShowError(true);
        }
        if (formJobCoordinators.length > 0) {
            sethasJobCoordinatorShowError(false);
        } else {
            sethasJobCoordinatorShowError(true);
        }
        if (formYearForm > 0) {
            sethasYearFromShowError(false);
        } else {
            sethasYearFromShowError(true);
        }
        if (formYearTo > 0) {
            sethasYearToShowError(false);
        } else {
            sethasYearToShowError(true);
        }
        if (formYearlySalaryFrom > 0) {
            sethasYearlySalaryFromShowError(false);
        } else {
            sethasYearlySalaryFromShowError(true);
        }
        if (formYearlySalaryTo > 0) {
            sethasYearlySalaryToShowError(false);
        } else {
            sethasYearlySalaryToShowError(true);
        }
        if (!IsEmptyOrNullValues(formStartDate)) {
            sethasStartDateShowError(false);
        } else {
            sethasStartDateShowError(true);
        }
        if (!IsEmptyOrNullValues(formEndDate)) {
            sethasEndDateShowError(false);
        } else {
            sethasEndDateShowError(true);
        }

    }
    const formatDate = (date) => {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + (d.getDate() + 1),
            year = d.getFullYear();

        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        console.log([day,month,year].join('/'));
        return [day,month, year].join('/');
    }
    const IsFormValid = () => {
        let errCount = 0;
        errCount += IsEmptyOrNullValues(formJobTitle) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formJobSummary) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formJobDescription) ? 1 : 0;
        errCount += formNumberOfJobPositions > 0 ? 0 : 1;
        errCount += IsEmptyOrNullValues(formKeySkill) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formLocations) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formIndustries) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formFunctionalAreas) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formFunctionalSpecializations) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formQualifications) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formQualificationSpecializations) ? 1 : 0;
        errCount += formYearForm > 0 ? 0 : 1;
        errCount += formYearTo > 0 ? 0 : 1;
        errCount += formYearlySalaryFrom > 0 ? 0 : 1;
        errCount += formYearlySalaryTo > 0 ? 0 : 1;
        errCount += IsEmptyOrNullValues(formStartDate) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formEndDate) ? 1 : 0;
        errCount += IsEmptyOrNullValues(formJobCoordinators) ? 1 : 0;
        console.log(errCount);
        return errCount === 0 ? true : false;
    }
    const handleSelectExistingFilterBtnClick = () => {
        setsearchText(searchText1);
    }
    useEffect(() => {
        const initLocationList = async () => {
            const locations = await NewJobPostingService.getLocationList();
            locations ? setlocationList(locations) : setlocationList({});
        }
        const initIndustryList = async () => {
            const industries = await NewJobPostingService.getIndustryList();
            industries ? setindustryList(industries) : setindustryList({});
        }
        const initFunctionalAreaList = async () => {
            const functionalAreas = await NewJobPostingService.getFunctionalAreaList();
            functionalAreas ? setfunctionalAreaList(functionalAreas) : setfunctionalAreaList({});
        }
        const initQualificationList = async () => {
            const qualifications = await NewJobPostingService.getQualificationList();
            qualifications ? setqualificationList(qualifications) : setqualificationList({});
        }
        const initJobCoordinatorList = async () => {
            const jobCoordinators = await NewJobPostingService.getJobCoordinatorList();
            jobCoordinators ? setjobCoordinatorList(jobCoordinators) : setjobCoordinatorList({});
        }
        const initExistingJobList = async () => {
            const getExistingJobList = await NewJobPostingService.getExistingJobList(page, countPerPage, searchText);
            // const data = getExistingJobList.data;
            // const existingJobFilter = [];
            // for (let i = 0; i < data.length; i++) {
            //     existingJobFilter.push({ clientName: AddNewLine(data[i].clientName), encJobPostingID: data[i].encJobPostingID, jobCode: data[i].jobCode, jobTitle: data[i].jobTitle, clientName: AddNewLine(data[i].clientName) });
            // }
            //getExistingJobList.data = existingJobFilter;
            getExistingJobList ? setexistingJobList(getExistingJobList) : setexistingJobList([]);
        }
        if (isAuth) {
            initLocationList();
            initIndustryList();
            initFunctionalAreaList();
            initQualificationList();
            initJobCoordinatorList();
            initExistingJobList();
        }
    }, [page, searchText]);
    return (
        <div className="mt-3">
            <Modal
                size="lg"
                aria-labelledby="example-modal-sizes-title-lg"
                centered
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header>

                    <Modal.Title>
                        <span className='text-primary'><i className="fa fa-list-alt" aria-hidden="true"></i>  Existing jobs</span>

                    </Modal.Title>
                    <input type="text" value={searchText1} onChange={(e) => setsearchText1(e.target.value)} placeholder='Filter' className='form-control float-right' style={{ width: "23%", marginLeft: "40%" }} />
                    <button className='btn btn-md btn-primary ml-1' onClick={handleSelectExistingFilterBtnClick}>Go</button>
                    <button onClick={handleClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <DataTable
                        columns={columns}
                        data={existingJobList.data}
                        highlightOnHover
                        pagination
                        paginationServer
                        paginationTotalRows={existingJobList.recordsTotal}
                        paginationPerPage={countPerPage}
                        paginationComponentOptions={{
                            noRowsPerPage: true
                        }}
                        onChangePage={page => setPage(page)}
                    />

                </Modal.Body>

            </Modal>
            <div className='container'>
                <div className='row'>
                    <div className='col-4'>
                        <h6 > JOB POSTING </h6>
                    </div>
                    <div className='col-8'>
                        <button className='btn btn-primary float-right' onClick={() => handleShow()}> <i className="fa fa-share" aria-hidden="true"></i> Copy from Existing Jobs</button>

                    </div>
                </div>

                <hr />
                <form onSubmit={handleSubmit(handleJobPostingSubmit)}>

                    <div className='row'>

                        <div className='col-2'></div>
                        <div className='col-2'>
                            <label className='control-label font-13'>Job Title <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4'>
                            <input type="text" name='JobTitle' value={formJobTitle} className="form-control" onChange={handleJobTitleChange} />
                            {hasJobTitleShowError ? <p className='text-danger mt-1'>Please enter job title</p> : ""}
                        </div>
                        <div className='col-4'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Job Summary <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <input type="text" name='JobSummary' value={formJobSummary} className="form-control" onChange={handleJobSummaryChange} />
                            {hasJobSummaryShowError ? <p className='text-danger mt-1'>Please enter job summary</p> : ""}
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Job Description <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <textarea rows={5} cols={10} value={formJobDescription} className="form-control" onChange={handleJobDescriptionChange}></textarea>
                            {hasJobDescriptionShowError ? <p className='text-danger mt-1'>Please enter job description</p> : ""}
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Number of Job Positions  <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <input type="text"
                                value={formNumberOfJobPositions === 0 ? "" : formNumberOfJobPositions}
                                onKeyPress={(event) => {
                                    if (!/[0-9]/.test(event.key)) {
                                        event.preventDefault();
                                    }
                                }} name='JobPositions' className="form-control" onChange={handleNumberOfJobPositionChange} />
                            {hasNumberOfShowPositionShowError ? <p className='text-danger mt-1'>Please enter number of job positions</p> : ""}
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Key Skills for Job  <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <input type="text"
                                value={formKeySkill}
                                name='keySkill' className="form-control" onChange={handleKeySkillChange} />
                            {hasKeySkillForJobShowError ? <p className='text-danger mt-1'>Please enter key skill</p> : ""}
                            <span className='font-11'>* Eg - Product Manager, Business Head</span>
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Locations <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <Select
                                isClearable={true}
                                onChange={handleLocationChange}
                                isMulti={true}
                                options={locationList}
                                value={formLocationSelected}
                                placeholder={<div>Select Locations</div>}
                            />
                            {hasLocationShowError ? <p className='text-danger mt-1'>Please select locations</p> : ""}
                            <span className='font-11'>* You can select multiple max 10 Locations.</span>
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Industries  <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <Select
                                onChange={handleIndustryChange}
                                isMulti={true}
                                options={industryList}
                                value={formIndustrySelected}
                                placeholder={<div>Select Industry</div>}
                            />
                            {hasIndustryShowError ? <p className='text-danger mt-1'>Please select industries</p> : ""}
                            <span className='font-11'>* You can select multiple max 10 Industries.</span>
                        </div>
                        <div className='col-4 mt-1'></div>


                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Functional Area  <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <Select
                                onChange={handleFunctionalAreaChange}
                                isMulti={true}
                                options={functionalAreaList}
                                value={formFunctionalAreasSelected}
                                placeholder={<div>Select Functional Area</div>}
                            />
                            {hasFunctionalAreaShowError ? <p className='text-danger mt-1'>Please select functionalAres</p> : ""}
                            <span className='font-11'>* You can select multiple max 10 Functional Areas.</span>
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Specialization   <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <Select
                                onChange={handleFunctionalAreaSpecializationChange}
                                isMulti={true}
                                options={functionalAreaSpecializationList}
                                value={formFunctionalSpecializationsSelected}
                                placeholder={<div>Select Functional Specialization </div>}
                            />
                            {hasFunctionalSpecializationShowError ? <p className='text-danger mt-1'>Please select functional specializations</p> : ""}
                            <span className='font-11'>* You can select multiple max 10 Functional Specializations.</span>
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Qualification    <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <Select
                                onChange={handleQualificationChange}
                                isMulti={true}
                                options={qualificationList}
                                value={formQualificationsSelected}
                                placeholder={<div> Select Qualifications </div>}
                            />
                            {hasQualificationShowError ? <p className='text-danger mt-1'>Please select qualifications</p> : ""}
                            <span className='font-11'>* You can select multiple max 10 Qualification .</span>
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Specilization <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <Select
                                onChange={handleQualificationSpecializationChange}
                                isMulti={true}
                                options={qualificationSpecializationList}
                                value={formQualificationSpecializationsSelected}
                                placeholder={<div> Select Qualifications Specilizations </div>}
                            />
                            {hasQualificationSpecializationShowError ? <p className='text-danger mt-1'>Please select qualificationSpecializations </p> : ""}
                            <span className='font-11'>* You can select multiple max 10 Qualification Specializations.</span>
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Experience  <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-1 mt-1 font-13'>
                            From :
                        </div>
                        <div className='col-2 mt-1 ' style={{ marginLeft: "-4%" }}>
                            <input type="number" placeholder='[ Year From ]'
                                value={formYearForm === 0 ? "" : formYearForm}
                                onChange={handleExperienceYearFromChange}
                                className="form-control"
                                style={{ width: "75%", fontSize: "11px" }} />
                            {hasYearFromShowError ? <p className='text-danger mt-1'>Please select year from</p> : ""}
                        </div>
                        <div className='col-1 mt-1 font-13' style={{ marginLeft: "-4%" }}>
                            To :
                        </div>
                        <div className='col-2 mt-1' style={{ marginLeft: "-5%" }}>
                            <input type="number"
                                value={formYearTo === 0 ? "" : formYearTo}
                                placeholder='[ Year To ]'
                                onChange={handleExperienceYearToChange}
                                className="form-control"
                                style={{ width: "75%", fontSize: "11px" }} />

                            {hasYearToShowError ? <p className='text-danger mt-1'>Please select year to</p> : ""}
                        </div>

                        <div className='col-2 mt-1'></div>



                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Yearly Salary  <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-1 mt-1 font-13'>
                            From :
                        </div>
                        <div className='col-2 mt-1' style={{ marginLeft: "-4%" }}>
                            <input type="number"
                                value={formYearlySalaryFrom === 0 ? "" : formYearlySalaryFrom}
                                placeholder='[ Salary From ]'
                                onChange={handleYearlySalaryFromChange}
                                className="form-control"
                                style={{ width: "75%", fontSize: "11px" }} />
                            {hasYearlySalaryFromShowError ? <p className='text-danger mt-1'>Please select  salary from</p> : ""}
                        </div>
                        <div className='col-1 mt-1 font-13' style={{ marginLeft: "-4%" }}>
                            To :
                        </div>
                        <div className='col-2 mt-1' style={{ marginLeft: "-5%" }}>
                            <input type="number"
                                value={formYearlySalaryTo === 0 ? "" : formYearlySalaryTo}
                                placeholder='[ Salary To ]'
                                onChange={handleYearlySalaryToChange}
                                className="form-control"
                                style={{ width: "75%", fontSize: "11px" }} />
                            {hasYearlySalaryToShowError ? <p className='text-danger mt-1'>Please select  salary to</p> : ""}
                        </div>

                        <div className='col-2 mt-1'></div>


                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Start Date <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <DatePicker
                                startDate={startDate}
                                selected={startDate}
                                onSelect={handleStartDateSelect}
                                onChange={handleStartDateChange}
                                dateFormat="dd/MM/yyyy"
                            />
                            {hasStartDateShowError ? <p className='text-danger mt-1'>Please select  start date</p> : ""}
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>End Date <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <DatePicker
                                wrapperClassName='datePicker'
                                onSelect={handleEndDateSelect}
                                onChange={handleEndDateChange}
                                dateFormat="dd/MM/yyyy"
                                startDate={endDate}
                                selected={endDate}
                            />
                            {hasEndDateShowError ? <p className='text-danger mt-1'>Please select  end date</p> : ""}
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Job Active <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            {isJobActive === 1 ? <span> <input type="radio" defaultChecked={true} name='jobActive' value='1' onChange={handleIsJobActiveChange} /> <span> Yes </span></span> : ""}
                            {isJobActive === 1 ? "" : <span> <input type="radio" defaultChecked={false} name='jobActive' value='1' onChange={handleIsJobActiveChange} /> <span> Yes </span></span>}
                            {isJobActive === 0 ? <span style={{ marginLeft: "1%" }}> <input type="radio" defaultChecked={true} name='jobActive' value='0' onChange={handleIsJobActiveChange} /> <span> No </span></span> : ""}
                            {isJobActive === 0 ? "" : <span style={{ marginLeft: "1%" }}> <input type="radio" defaultChecked={false} name='jobActive' value='0' onChange={handleIsJobActiveChange} /> <span> No </span></span>}
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Hot Active <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            {isHotJob === 1 ? <span> <input type="radio" defaultChecked={true} name='hotjob' value='1' onChange={handleIsHotJobChange} />  <span> Yes </span> </span> : ""}
                            {isHotJob === 1 ? "" : <span> <input type="radio" defaultChecked={false} name='hotjob' value='1' onChange={handleIsHotJobChange} /> <span> Yes </span> </span>}

                            {isHotJob === 0 ? <span style={{ marginLeft: "1%" }}> <input type="radio" defaultChecked={true} name='hotjob' value='0' onChange={handleIsHotJobChange} />  <span> No</span></span> : ""}
                            {isHotJob === 0 ? "" : <span style={{ marginLeft: "1%" }}> <input type="radio" defaultChecked={false} name='hotjob' value='0' onChange={handleIsHotJobChange} /> <span> No</span></span>}

                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2'></div>
                        <div className='col-2 mt-1'>
                            <label className='control-label mt-1 font-13'>Job Co- Ordinator <span className='text-danger'>*</span>:</label>
                        </div>
                        <div className='col-4 mt-1'>
                            <Select
                                onChange={handleJobCoordinatorChange}
                                isMulti={true}
                                options={jobCoordinatorList}
                                value={formJobCoordinatorsSelected}
                                placeholder={<div> Select Job Coordinators </div>}
                            />
                            {hasJobCoordinatorShowError ? <p className='text-danger mt-1'>Please select jobcoordinators</p> : ""}
                            <span className='font-11'>* You can select multiple max 10 Cordinators .</span>
                        </div>
                        <div className='col-4 mt-1'></div>

                        <div className='col-2 mt-2'></div>
                        <div className='col-2 mt-2'>
                            <label className='control-label font-13'>Response E-mail ID :</label>
                        </div>
                        <div className='col-4 mt-2'>
                            <input type="text" name='Response-E-mail-ID'
                                value={formResponseEmailId}
                                onChange={(e) => setformResponseEmailId(e.target.value)}
                                className="form-control" />
                            <span className='font-11'> You can enter multiple email ids separated by semicolon.</span>
                        </div>
                        <div className='col-4 mt-2'></div>
                        <div className='col-4 mt-3 mb-5'></div>
                        <div className='col-4 mt-3 mb-5'>
                            <button type='submit' className='btn btn-sm btn btn-outline-primary mr-1'><i className='fa fa-floppy-o'></i> Submit</button>
                            <button type='button' onClick={() => window.location.reload()} className='btn btn-sm btn-outline-warning'><i className='fa fa-ban'></i> Cancel</button>
                        </div>
                        <div className='col-4 mt-3 mb-5'></div>
                    </div>

                </form>
            </div>
            <ToastContainer
                position="top-center"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    );
}
export default NewJobPosting;