import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import './index.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import App from './App';
import './App.css';
import Login from './Components/Login';
import NewAssignment from './Components/Main/NewAssignment';
import EditAssignmnent from './Components/Main/EditAssignment';
import NewJobPosting from './Components/Main/NewJobPosting';
import CandidateEntry from './Components/Main/CandidateEntry';
import Error404 from './Components/Error/Error404';
import Error401 from './Components/Error/Error401';
import JobPostingDetails from './Components/Main/JobPostingDetails';
import CandidateList from './Components/Main/CandidateList';
import Dashboard from './Components/Main/Dashboard/Dashboard';
import AdvanceSearch from './Components/Main/Search/AdvanceSearch';
import QuickSearch from './Components/Main/Search/QuickSearch';
import AdvancedSearchResult from './Components/Main/Search/AdvancedSearchResult';
import SavedSearch from './Components/Main/Search/SavedSearch';
import Folders from './Components/Main/MyFolders/Folders';
import CanResumeFolder from './Components/Main/MyFolders/CanResumeFolder';
import CandidateResumeDetails from './Components/Main/CandidateResumeDetail/CandidateResumeDetails';
import CandidateProfile from './Components/Main/CandidateResumeDetail/CandidateProfile';
import BackOfficeUser from './Components/Main/BackOfficeUser';
import ExcelTemplate from './Components/Main/Templates/ExcelTemplate';
import Client from './Components/Main/Client/Client';
import ChangePassword from './Components/Main/Security/ChangePassword';
import NotificationSetting from './Components/Main/Security/NotificationSetting';
import Clients from './Components/Main/JobPosting/Client';
import DynamicTable from './Components/Main/JobPosting/DynamicTable';
import HomePage from './Components/HomePage';
import ShortlistToOtherJob from './Components/Main/Templates/ShortlistToOtherJob';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  
    <BrowserRouter>
      <Routes>
      <Route path='/' element={<HomePage />}/>
        <Route path="/assignment" element={<App />} />
        <Route path="/jobassignment" element={<NewAssignment />} />
        <Route path="/editassignment" element={<EditAssignmnent />} />
        <Route path="/newjobposting" element={<NewJobPosting />} />
        <Route path="/candidateentry" element={<CandidateEntry />} />
        <Route path="/candidateList" element={<CandidateList />} />
        <Route path="/advanceSearch" element={<AdvanceSearch />} />
        <Route path="/advancedSearchResult" element={<AdvancedSearchResult />} />
        <Route path="/quickSearch" element={<QuickSearch />} />
        <Route path="/backofficeuser" element={<BackOfficeUser />} />
        <Route path="/savedSearch" element={<SavedSearch />} />
        <Route path='/folder' element={<Folders />}/>
        <Route path='/email' element={<ExcelTemplate />}/>
        <Route path='/canResumeFolder' element={<CanResumeFolder />}/>
        <Route path='/client/resumeDetails' element={<CandidateResumeDetails />}/>
        <Route path='/client/candidateProfile' element={<CandidateProfile />}/>
        <Route path='/client' element={<Clients />}/>
        <Route path="/shortlist" element={<ShortlistToOtherJob />} />
        <Route path='/projectdetails' element={<DynamicTable />}/>
        <Route path="/settings" element={<NotificationSetting />} />
        <Route path="/changePassword" element={<ChangePassword />} />
        <Route path="/login" element={<Login />} />
        <Route path="/jobPostingDetails" element={<JobPostingDetails />} />

        <Route path="/clients" element={<Client />} />
        
        <Route path="/404" element={<Error404 />} />
        <Route path="/401" element={<Error401 />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
    </BrowserRouter>
);

