

function Error401() {
    return (
        <div className="page-wrap d-flex flex-row align-items-center">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-12 text-center">
                        <span className="display-1 d-block">401</span>
                        <div className="mb-4 lead">You dont have access to view this page</div>
                        {/* <a href="#" className="btn btn-link">Back to Home</a> */}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Error401;