import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { set, useForm } from "react-hook-form";
import Layout from '../../Layout/Layout';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import { AuthService } from '../../Services/AuthService';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { BackOfficeUserService } from '../../Services/BackOfficeUserService';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ReactPaginate from 'react-paginate';
import { CardContent } from '@mui/material';
function BackOfficeUser() {
    const { register, setValue, clearErrors, watch, formState: { errors }, handleSubmit } = useForm();
    const [isPasswordmatch, setisPasswordmatch] = useState(false);
    const [passwordStrength, setpasswordStrength] = useState(0);
    const [backOfficeUserList, setbackOfficeUserList] = useState([]);
    const [totalBoUserCount, settotalBoUserCount] = useState(0);
    const [roleDropdown, setroleDropdown] = useState([]);
    const [agencyDropdown, setagencyDropdown] = useState([]);
    const [agencyvalue, setagencyvalue] = useState("");
    const [rolevalue, setrolevalue] = useState("");
    const [existbouserID, setbouserID] = useState(0);
    const [newAgencyID, setnewAgencyID] = useState("");
    const [newRoleID, setnewRoleID] = useState("");
    const [pageCount, setpageCount] = useState(0);
    const navigate = useNavigate();
    const [show, setShow] = useState(false);
    const [itemOffset, setItemOffset] = useState(10);
   // const [startIndex, setstartIndex] = useState(0);
    const handleShow = () => setShow(true);
    const [openLoader, setopenLoader] = React.useState(false);
    const itemPerPage = 10;
    const [AvLoginMsg, setAvLoginMsg] = useState("");
    const [AvLoginColor, setAvLoginColor] = useState("");
    const [IsEdit, setIsEdit] = useState(false);
    //const [searchInput, setSearchInput] = useState("");
    const [RecordCount, setRecordCount] = useState(0);
    const handleClose = () => {
        setShow(false);
        setValue("BOUserID", 0);
        setValue("loginID", "");
        setValue("name", "");
        setValue("emailid", "");
        setValue("rolename", "");
        setValue("agency", "");
    }
    const handlePageClick = (event) => {
        let currentPageSize = document.getElementById("pageDDId").value;
        let mySearchInput = document.getElementById("mySearchInput").value;
        if(mySearchInput === null || mySearchInput === undefined || mySearchInput ===""){
            mySearchInput = null;
        }
    const newOffset = (event.selected * currentPageSize) % totalBoUserCount;
        initBackOfficeUser(event.selected, mySearchInput, parseInt(currentPageSize));
        setItemOffset(newOffset);
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const handleAddNewAgencyUser = () => {
        setIsEdit(false);
        setValue("BOUserID", 0);
        setValue("loginID", "");
        setValue("name", "");
        setValue("emailid", "");
        setValue("rolename", "");
        setValue("agency", "");
        handleShow();
    }
    const handleAgencyOnChange = (e) => {
        let val = e.target.value;
        if (val) {
            setagencyvalue(val);
        } else {
            setagencyvalue("");
        }
    }
    const handleRoleOnChange = (e) => {
        let val = e.target.value;
        if (val) {
            setrolevalue(val);
        } else {
            setrolevalue("");
        }
    }
    const handleSaveBoUser = async (formData) => {
        setopenLoader(true);
        let agencies = document.getElementById("agencyId");
        let agencyid = agencies.options[agencies.selectedIndex].value;
        let agencyname = agencies.options[agencies.selectedIndex].text;
        let roles = document.getElementById("roleId");
        let roleid = roles.options[roles.selectedIndex].value;
        let rolename = roles.options[roles.selectedIndex].text;
        let mySearchInput = document.getElementById("mySearchInput").value;
        let index = 0;
        const indexEL = document.getElementsByClassName("pagination");
        for (let i = 0; i < indexEL[0].children.length; i++) {
            if (indexEL[0].children[i].classList.contains("active")) {
                index = parseInt(indexEL[0].children[i].innerText);
            }
        }
        index = index - 1;
        index = index < 0 ? 0 : index;
        if (formData.BOUserID > 0) {
            const formData1 = {
                UserID: parseInt(AuthService.getCurrentUser().userId),
                BoUserID: formData.BOUserID,
                AgencyID: parseInt(agencyid),
                LogInID: formData.loginID,
                //Password: formData.NewPassword,
                BoUserName: formData.name,
                BoUserEmailID: formData.emailid,
                RoleID: parseInt(roleid)
            }
            const res = await BackOfficeUserService.saveBackOfficeUser(formData1);
            if (res.isSuccess) {
                ShowAlert(1, res.message);
                initBackOfficeUser(index,mySearchInput);
                handleClose();
            } else {
                ShowAlert(0, res.message);
                handleClose();
            }
            handleClose();
        } else {
            if (formData.NewPassword === formData.ConfirmNewPassword) {
                const formData1 = {
                    UserID: parseInt(AuthService.getCurrentUser().userId),
                    BoUserID: 0,
                    AgencyID: parseInt(agencyid),
                    LogInID: formData.loginID,
                    Password: formData.NewPassword,
                    BoUserName: formData.name,
                    BoUserEmailID: formData.emailid,
                    RoleID: parseInt(roleid)
                }
                const res = await BackOfficeUserService.saveBackOfficeUser(formData1);
                if (res.isSuccess) {
                    ShowAlert(1, res.message);
                    initBackOfficeUser();
                    handleClose();
                } else {
                    ShowAlert(0, res.message);
                    handleClose();
                }
            }
            handleClose();
        }
        setopenLoader(false);
    }
    const checkLoginAvailable = async (e) => {
        const value = e.target.value;
        if (value) {
            const res = await BackOfficeUserService.getLoginAvStatus(value);
            if (res.isSuccess) {
                setAvLoginColor("text-success font-13");
                setAvLoginMsg(res.message);
            } else {
                setAvLoginColor("text-danger");
                setAvLoginMsg(res.message);
            }
        } else {
            setAvLoginMsg("");
        }
    }
    const handleEditBackOfficeUser = async (bouserID) => {
        setIsEdit(true);
        if (bouserID > 0) {
            const getBackOfficeUser = await BackOfficeUserService.getBoUserByID(bouserID);
            console.log(getBackOfficeUser);
            setbouserID(bouserID);
            setValue("BOUserID", bouserID);
            setValue("loginID", getBackOfficeUser.logInID);
            setValue("name", getBackOfficeUser.boUserName);
            setValue("emailid", getBackOfficeUser.boUserEmailID);
            setValue("rolename", getBackOfficeUser.roleID);
            setValue("agency", getBackOfficeUser.agencyID);
            let agencyValue = "";
            agencyDropdown.filter(function (f) {
                if (getBackOfficeUser.agencyID.toString() === f.value) {
                    agencyValue = (f.value);
                }
            });
            setagencyvalue(agencyValue);
            let roleValue = "";
            roleDropdown.filter(function (f) {
                if (getBackOfficeUser.roleID.toString() === f.value) {
                    roleValue = (f.value);
                }
            });
            setrolevalue(roleValue);
            clearErrors("BOUserID")
            clearErrors("loginID");
            clearErrors("NewPassword");
            clearErrors("ConfirmNewPassword");
            clearErrors("name");
            clearErrors("emailid");
            clearErrors("rolename");
            clearErrors("agency");
            handleShow();
        }
    }
    const handleSearchChange = () => {
        var searchText = document.getElementById("mySearchInput").value;
        if (searchText !== null && searchText !== "" && searchText !== undefined) {
            initBackOfficeUser(null, searchText, itemPerPage);
        }
        else {
            initBackOfficeUser(null, searchText,itemPerPage);
        }
    }
    const initBackOfficeUser = async (index, SearchInput, itemPerPage) => {
        setopenLoader(true);
            if (SearchInput === "" || SearchInput === null || SearchInput === undefined) {
                SearchInput = ""
            }
            itemPerPage = itemPerPage > 0 ? itemPerPage : 10;
            index = index ? index : 0;
            const res = await BackOfficeUserService.getBackOfficeUser(index + 1, itemPerPage, SearchInput);
            if (res.recordCount) {
                setRecordCount(res.recordCount);
                setpageCount(Math.ceil(res.recordCount / itemPerPage));
                setbackOfficeUserList(res.bouserModels);
                settotalBoUserCount(res.recordCount);
            }
            else {
            }
        setopenLoader(false);
    }
    const initAgencyDropDown = async () => {
        const response = await BackOfficeUserService.getAgencyDropdown();
        setagencyDropdown(response);
    }
    const initRoleDropDown = async () => {
        const res = await BackOfficeUserService.getRoleDropdown();
        setroleDropdown(res);
    }
    const handlePasswordChange = (e) => {
        const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
        let password = e.target.value;
        if (password !== "" && password !== undefined && password !== null) {
            if (strongPassword.test(password)) {
                setpasswordStrength(4);
            } else if (mediumPassword.test(password)) {
                setpasswordStrength(3);
            } else {
                setpasswordStrength(2);
            }
            if (password.length <= 3) {
                setpasswordStrength(1);
            }
        } else {
            setpasswordStrength(0);
        }
    }
    const handleRecordPerPageChange = (value) => {
        let recordPage = parseInt(value);
        console.log(recordPage);
        if (recordPage !== 0 && recordPage !== undefined) {
            initBackOfficeUser(0, null, recordPage);

            // this.initCandidates(null, null, '', this.state.recordPerPage);
        } else {

        }
    }
    useEffect(() => {
        initAgencyDropDown();
        initRoleDropDown();
        console.log(AuthService.getCurrentUser());
        if (AuthService.getApiAuthorizationConfig()) {
            initBackOfficeUser(0,null,10);

        } else {
            navigate('/404');
        }
    }, []);

    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                style={{ marginTop: "3rem" }}
            >
                <Modal.Header>
                    <Modal.Title>
                        <span className='text-primary'>Manage Agency User</span>
                    </Modal.Title>
                    <button onClick={handleClose} type="button" className="close">
                        <span aria-hidden="true">×</span>
                        <span className="sr-only">Close</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <form method='POST' onSubmit={handleSubmit(handleSaveBoUser)}  >
                        <input type="hidden" name='BOUserID' {...register("BOUserID")} />
                        <div className='row mb-2'>
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Login ID : <span style={{ color: "red" }}>*</span></label>
                            </div>
                            <div className='col-7 mt-1'>
                                <input type="text" name='loginID' className="form-control"
                                    {...register("loginID",
                                        {
                                            required: true,
                                            minLength: 3,
                                            pattern: {
                                                value: /^[a-zA-Z0-9_.]+$/,
                                                message: " Login ID should not include space and special character. You can use . and _ ."
                                            },
                                            onChange: (e) => { checkLoginAvailable(e) }
                                        })
                                    } />
                                {AvLoginMsg === "" ? "" : <span className={AvLoginColor}>{AvLoginMsg}</span>}
                                {errors.loginID?.type === 'required' && <p className='text-danger mt-1'>Please enter login id</p>}
                                {errors.loginID?.type === 'minLength' && <p className='text-danger mt-1'>Enter minimum three characters, not containing spaces.You can use . and _</p>}
                                {errors.loginID && <span className='text-danger mt-1'>{errors.loginID.message}</span>}
                            </div>
                            {
                                IsEdit ? null :
                                    <div className='row'>
                                        <div className='col-5 mt-1'>
                                            <label className='control-label' style={{ marginLeft: "9%" }}>Password: <span style={{ color: "red" }}>*</span></label>
                                        </div>
                                        <div className='col-7 mt-1'>
                                            <input type="password"
                                                name='NewPassword'
                                                className="form-control"
                                                {...register("NewPassword",
                                                    {
                                                        //required: true,
                                                        onChange: (e) => { handlePasswordChange(e) }
                                                    }
                                                )}
                                            />
                                            {errors.NewPassword?.type === 'required' && <p className='text-danger mt-1'>Please enter New Password</p>}
                                            {passwordStrength === 1 ? <span className='text-danger'>Too Short</span> : ""}
                                            {passwordStrength === 2 ? <span className='text-danger'>Weak</span> : ""}
                                            {passwordStrength === 3 ? <span className='text-warning'>Medium</span> : ""}
                                            {passwordStrength === 4 ? <span className='text-success'>Strong</span> : ""}
                                        </div>
                                        <div className='col-5 mt-1'>
                                            <label className='control-label' style={{ marginLeft: "8%" }}>Re-type Password: <span style={{ color: "red" }}>*</span></label>
                                        </div>
                                        <div className='col-7 mt-1'>
                                            <input type="password" name='ConfirmNewPassword' className="form-control"
                                                {...register("ConfirmNewPassword",
                                                    {
                                                        //required: true,
                                                        validate: (val) => {
                                                            if (watch('ConfirmNewPassword') != val) {
                                                                setisPasswordmatch(true);
                                                            } else {
                                                                setisPasswordmatch(false);
                                                            }
                                                        }
                                                    })
                                                }
                                            />
                                            {errors.ConfirmNewPassword?.type === 'required' && <p className='text-danger mt-1'>Please enter New Password</p>}
                                            {isPasswordmatch ? <span className='text-danger' style={{ fontSize: "11px" }}>New Password and confirmPassword not matched</span> : null}
                                        </div>
                                    </div>
                            }
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Name: <span style={{ color: "red" }}>*</span></label>
                            </div>
                            <div className='col-7 mt-1'>
                                <input type="text" name='name' className="form-control"
                                    {...register("name",
                                        {
                                            required: true,
                                        })
                                    }
                                />
                            </div>
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Email ID: <span style={{ color: "red" }}>*</span></label>
                            </div>
                            <div className='col-7 mt-1'>
                                <input type="text" name='emailid' className="form-control"
                                    {...register("emailid",
                                        {
                                            required: true,
                                        })
                                    }
                                />
                            </div>
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Role: <span style={{ color: "red" }}>*</span></label>
                            </div>
                            <div className='col-7 mt-1'>
                                <select className='form-control' name='rolename' id='roleId' value={rolevalue}
                                    {...register("rolename", {
                                        required: true,
                                        onChange: (e) => { handleRoleOnChange(e) }
                                    })}
                                >
                                    <option value="">-- Select Role --</option>
                                    {
                                        roleDropdown.map((item, i) =>
                                            <option key={i} value={item.value}>{item.label}</option>
                                        )
                                    }
                                </select>
                            </div>
                            <div className='col-5 mt-1'>
                                <label className='control-label'>Agency: <span style={{ color: "red" }}>*</span></label>
                            </div>
                            <div className='col-7 mt-1'>
                                <select className='form-control' name='agency' id='agencyId' value={agencyvalue}
                                    {...register("agency", {
                                        required: true,
                                        onChange: (e) => { handleAgencyOnChange(e) }
                                    })}>
                                    <option value="">-- Select Agency --</option>
                                    {
                                        agencyDropdown.map((item, i) =>
                                            <option key={i} value={item.value}>{item.label}</option>
                                        )
                                    }
                                </select>
                            </div>
                        </div>
                        <div className='col-7 mt-1'></div>
                        <div className='col-5 float-right'>
                            <button className='btn btn-sm btn-outline-success' type='submit'><i className="fa fa-floppy-o " aria-hidden="true"></i> Save</button> &nbsp;
                            <button className='btn  btn-sm btn-outline-danger' onClick={handleClose}>
                                <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
                            </button>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
            <Layout>
                <h5 className='text-center'>MANAGE AGENCY USER</h5>
                <Card>
                    <CardContent>
                        <input type='text' id="mySearchInput" className='form-control' onKeyUp={handleSearchChange} placeholder='Search' style={{ width: "15%" }} />
                        <Button variant='outline-success' className='btn-sm float-right mb-2' onClick={() => handleAddNewAgencyUser()}>New Agency User</Button>
                        <Table bordered hover id='myTable'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>LogIn ID</th>
                                    <th>Email</th>
                                    <th>Role</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    backOfficeUserList ? backOfficeUserList.map((item, i) =>
                                        <tr key={i} value={item.boUserId}>
                                            <td>{item.name}</td>
                                            <td>{item.loginId}</td>
                                            <td>{item.email}</td>
                                            <td>{item.role}</td>
                                            <td><button className='btn btn-warning' onClick={() => handleEditBackOfficeUser(item.boUserId)}><i className="fa fa-pencil" ></i></button></td>
                                        </tr>
                                    ) : null
                                }
                            </tbody>
                        </Table>
                        <div className='row'>
                            <div className='col-md-7 mt-2' >
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel="next >"
                                    onPageChange={handlePageClick}
                                    pageCount={pageCount}
                                    previousLabel="< previous"
                                    pageClassName="page-item"
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    containerClassName="pagination"
                                    activeClassName="active"
                                    renderOnZeroPageCount={null}
                                />
                            </div>
                            <div className='col-md-2 mt-2'>
                                <div className="recordperPage">Page Size : &nbsp;
                                    <select id='pageDDId' className="selectperPage" onChange={(e) => handleRecordPerPageChange(e.target.value)}>
                                        <option selected="selected" value="10">10</option>
                                        <option value="20">20</option>
                                        <option value="40">40</option>
                                        <option value="60">60</option>
                                        <option value="80">80</option>
                                        <option value="100">100</option>
                                    </select>
                                </div>
                            </div>
                            <div className='col-md-3 mt-2' style={{ color: "#0F7AF9" ,backgroundColor:"#fff",borderColor:"#dee2e6"}}>  Record(s) Total={RecordCount}</div>
                        </div>
                    </CardContent>
                </Card>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Layout>
        </div>
    )
}
export default BackOfficeUser;