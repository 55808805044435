import jwt from 'jwt-decode';
import { UserService } from './UserService';
import { CookieService } from './CookieService';
import { CryptoService } from './CryptoService';
const cookieName = "_secure_auth-token_resumes";
const cookieExpairy = 10;
const localStorageMenuandPermissionName = "menu_permission";
const isAuthenticatedUser = () => {
    if (isExpairyToken()) {
        CookieService.clearCookie(cookieName);
        localStorage.removeItem(localStorageMenuandPermissionName);
        return false;
    } else {
        return true;
    }
}
const logout = () => {
    CookieService.clearCookie(cookieName);
    localStorage.removeItem(localStorageMenuandPermissionName);
}
const isExpairyToken = () => {
    let token = CookieService.getCookie(cookieName);
    if (token !== null && token !== "" && token !== undefined) {
        const decode = jwt(CryptoService.CryptoJSDecrypt(token));
        if (!decode) {
            return true;
        }
        if (new Date().getTime() < decode.exp) {
            CookieService.clearCookie(cookieName);
            return true;
        } else {
            return false;
        }
    } else {
        return true;
    }

}
const getCurrentUser = () => {
    const token = CookieService.getCookie(cookieName);
    const currentUser = {
        loginId: null,
        encLoginId: null,
        userId: null,
        encUserId: null,
        roleId: null,
        encRoleId: null,
        roleName: null,
        encRoleName: null,
        userName: null,
        encUserName: null,
        userTypeCode: null,
        encUserTypeCode: null,
        userTypeId: null,
        encUserTypeId: null,
        userTypeName: null,
        encUserTypeName: null,
        referenceID: null,
        encReferenceID: null
    };
    if (token) {
        let decode = jwt(CryptoService.CryptoJSDecrypt(token));
        if (!isExpairyToken()) {
            currentUser.loginId = decode.LoginId;
            currentUser.roleId = decode.RoleId;
            currentUser.userId = decode.UserId;
            currentUser.roleName = decode.RoleName;
            currentUser.userName = decode.UserName;
            currentUser.userTypeCode = decode.UserTypeCode;
            currentUser.userTypeId = decode.UserTypeId;
            currentUser.userTypeName = decode.UserTypeName;
            currentUser.referenceID = decode.ReferenceID;
        } else {
            currentUser.loginId = null;
            currentUser.encLoginId = null;
            currentUser.userId = null;
            currentUser.encUserId = null;
            currentUser.roleId = null;
            currentUser.encRoleId = null;
            currentUser.roleName = null;
            currentUser.encRoleName = null;
            currentUser.userName = null;
            currentUser.encUserName = null;
            currentUser.userTypeCode = null;
            currentUser.encUserTypeCode = null;
            currentUser.userTypeCode = null;
            currentUser.encUserTypeCode = null;
            currentUser.userTypeName = null;
            currentUser.encUserTypeName = null;
            currentUser.referenceID = null;
            currentUser.encReferenceID = null;
        }
    } else {
        currentUser.loginId = null;
        currentUser.encLoginId = null;
        currentUser.userId = null;
        currentUser.encUserId = null;
        currentUser.roleId = null;
        currentUser.encRoleId = null;
        currentUser.roleName = null;
        currentUser.encRoleName = null;
        currentUser.userName = null;
        currentUser.encUserName = null;
        currentUser.userTypeCode = null;
        currentUser.encUserTypeCode = null;
        currentUser.userTypeCode = null;
        currentUser.encUserTypeCode = null;
        currentUser.userTypeName = null;
        currentUser.encUserTypeName = null;
        currentUser.referenceID = null;
        currentUser.encReferenceID = null;

    }
    return currentUser;
}

const getUserPermissions = () => {
    try {
        const res = JSON.parse(JSON.parse(CryptoService.CryptoJSDecrypt(localStorage.getItem(localStorageMenuandPermissionName))));
        if (res.Permissions)
            return res.Permissions;
    } catch (error) {
        return "";
    }

}
const getUserMenus = () => {
    try {
        const res = JSON.parse(JSON.parse(CryptoService.CryptoJSDecrypt(localStorage.getItem(localStorageMenuandPermissionName))));
        if (res)
            return res.Menus;
    } catch (error) {
        return "";
    }

}
const LoginUser = async (loginId, password, userTypeID) => {
    if (loginId !== "" && password !== "") {
        const res = await UserService.Login(loginId, password, userTypeID);
        if (res.isSuccess) {
            CookieService.setCookie(cookieName, CryptoService.CryptoJSEncrypt(res.token), cookieExpairy);
            localStorage.setItem(localStorageMenuandPermissionName, CryptoService.CryptoJSEncrypt(JSON.stringify(res.apiData)));
        }
        return res;
    }
}
const ResetLogin = async (currentUserID) => {
    if (currentUserID !== "") {
        const res = await UserService.ResetLogin(currentUserID);
        return res;
    }
}

const getApiAuthorizationConfig = () => {
    let config = "";
    if (CryptoService.CryptoJSDecrypt(CookieService.getCookie(cookieName))) {
        config = 'Bearer ' + CryptoService.CryptoJSDecrypt(CookieService.getCookie(cookieName))
    }
    return config;
}
const IsCookieBlockedByClient = () => {
    if (!navigator.cookieEnabled) {
        return true;
    } else {
        return false;
    }
}

export const AuthService = {
    isAuthenticatedUser,
    isExpairyToken,
    getCurrentUser,
    getApiAuthorizationConfig,
    LoginUser,
    ResetLogin,
    logout,
    getUserPermissions,
    getUserMenus
}