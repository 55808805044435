import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "BackOfficeUser/";

const saveBackOfficeUser = async (formData) => {
    const res = await fetch(baseUrl + "SaveOrUpdateBackOfficeUser", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(formData)
    });
    const data = await res.json();
    console.log(data);
    return data;
}
const getBackOfficeUser = async (startIndex, Pagesize,filters) => {
    const res = await fetch(baseUrl + "GetBackOfficeUserByUserID?UserID=" + AuthService.getCurrentUser().userId +"&startIndex="+startIndex+"&pageSize="+Pagesize+"&filters="+filters, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getRoleDropdown = async () => {
    const res = await fetch(baseUrl + "GetRoles", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            //'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}

const getAgencyDropdown = async () => {
    const res = await fetch(baseUrl + "GetAgencies", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getLoginAvStatus = async (loginid) => {
    const res = await fetch(baseUrl + "GetLoginAVStatus?loginID="+loginid, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getBoUserByID = async (bouserID) => {
    const res = await fetch(baseUrl + "getBoUserByID?bouserID="+bouserID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
export const BackOfficeUserService={
    getBackOfficeUser,
    getLoginAvStatus,
    saveBackOfficeUser,
    getRoleDropdown,
    getAgencyDropdown,
    getBoUserByID
}