import React, { useState, useEffect } from 'react';
import { set, useForm } from "react-hook-form";
import LayoutAuthUser from '../../../Layout/Layout'
import '../../../css/3Dgrid.css';
import { CommonDropDownService } from "../../../Services/CommonDropDownService";
import Select from 'react-select';
import { AuthService } from '../../../Services/AuthService';
import { useNavigate } from 'react-router-dom';
function QuickSearch() {
    const { register, setValue, clearErrors, formState: { errors }, handleSubmit } = useForm();
    const navigate = useNavigate();
    const [IndustryList, setIndustryList] = useState([]);
    const [LocationlList, setLocationlList] = useState([]);
    const [UpdatedWithinList, setUpdatedWithinList] = useState([]);
    const [SortResultByList, setSortResultByList] = useState([]);
    const [KeyWordsList, setKeyWordsList] = useState([]);
    const [selectedOptionForIndustry, setselectedOptionForIndustry] = useState([]);
    const [selectedOptionForLocation, setSelectedOptionForLocation] = useState([]);
    const [FormSelectedExperienceFrom, setFormSelectedExperienceFrom] = useState(0);
    const [FormSelectedExperienceTo, setFormSelectedExperienceTo] = useState(0);

    const handleInputChange = (inputText) => {
        initKeyWordsList(inputText);
        if (KeyWordsList.length > 0) {
            autocomplete(document.getElementById("myInput"), KeyWordsList);
        }
    }
    const autocomplete = (inp, arr) => {
        /*the autocomplete function takes two arguments,
        the text field element and an array of possible autocompleted values:*/
        var currentFocus;
        /*execute a function when someone writes in the text field:*/
        inp.addEventListener("input", function (e) {
            var a, b, i, val = this.value;
            /*close any already open lists of autocompleted values*/
            closeAllLists();
            if (!val) { return false; }
            currentFocus = -1;
            /*create a DIV element that will contain the items (values):*/
            a = document.createElement("DIV");
            a.setAttribute("id", this.id + "autocomplete-list");
            a.setAttribute("class", "autocomplete-items");
            /*append the DIV element as a child of the autocomplete container:*/
            this.parentNode.appendChild(a);
            /*for each item in the array...*/
            for (i = 0; i < arr.length; i++) {
                /*check if the item starts with the same letters as the text field value:*/
                if (arr[i].substr(0, val.length).toUpperCase() == val.toUpperCase()) {
                    /*create a DIV element for each matching element:*/
                    b = document.createElement("DIV");
                    /*make the matching letters bold:*/
                    b.innerHTML = "<strong>" + arr[i].substr(0, val.length) + "</strong>";
                    b.innerHTML += arr[i].substr(val.length);
                    /*insert a input field that will hold the current array item's value:*/
                    b.innerHTML += "<input type='hidden' value='" + arr[i] + "'>";
                    /*execute a function when someone clicks on the item value (DIV element):*/
                    b.addEventListener("click", function (e) {
                        /*insert the value for the autocomplete text field:*/
                        inp.value = this.getElementsByTagName("input")[0].value;
                        /*close the list of autocompleted values,
                        (or any other open lists of autocompleted values:*/
                        closeAllLists();
                    });
                    a.appendChild(b);
                }
            }
        });
        /*execute a function presses a key on the keyboard:*/
        inp.addEventListener("keydown", function (e) {
            var x = document.getElementById(this.id + "autocomplete-list");
            if (x) x = x.getElementsByTagName("div");
            if (e.keyCode == 40) {
                /*If the arrow DOWN key is pressed,
                increase the currentFocus variable:*/
                currentFocus++;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 38) { //up
                /*If the arrow UP key is pressed,
                decrease the currentFocus variable:*/
                currentFocus--;
                /*and and make the current item more visible:*/
                addActive(x);
            } else if (e.keyCode == 13) {
                /*If the ENTER key is pressed, prevent the form from being submitted,*/
                e.preventDefault();
                if (currentFocus > -1) {
                    /*and simulate a click on the "active" item:*/
                    if (x) x[currentFocus].click();
                }
            }
        });
        function addActive(x) {
            /*a function to classify an item as "active":*/
            if (!x) return false;
            /*start by removing the "active" class on all items:*/
            removeActive(x);
            if (currentFocus >= x.length) currentFocus = 0;
            if (currentFocus < 0) currentFocus = (x.length - 1);
            /*add class "autocomplete-active":*/
            x[currentFocus].classList.add("autocomplete-active");
        }
        function removeActive(x) {
            /*a function to remove the "active" class from all autocomplete items:*/
            for (var i = 0; i < x.length; i++) {
                x[i].classList.remove("autocomplete-active");
            }
        }
        function closeAllLists(elmnt) {
            /*close all autocomplete lists in the document,
            except the one passed as an argument:*/
            var x = document.getElementsByClassName("autocomplete-items");
            for (var i = 0; i < x.length; i++) {
                if (elmnt != x[i] && elmnt != inp) {
                    x[i].parentNode.removeChild(x[i]);
                }
            }
        }
        /*execute a function when someone clicks in the document:*/
        document.addEventListener("click", function (e) {
            closeAllLists(e.target);
        });
    }

    const handleSelectLocation = (selectedOption) => {
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 10) {
                setSelectedOptionForLocation(selectedOption);
            }
        } else {
            setSelectedOptionForLocation(undefined);
        }
    }
    const handleSelectIndustry = (selectedOption) => {
        if (selectedOption.length > 0) {
            if (selectedOption.length <= 6) {
                setselectedOptionForIndustry(selectedOption);
            }
        } else {
            setselectedOptionForIndustry(undefined);
        }
    }
    const handleExperienceFrom = (e) => {
        if (e.target.value) {
            setFormSelectedExperienceFrom(e.target.value);
        } else {
            setFormSelectedExperienceFrom(undefined);
        }
    }
    const handleExperienceTo = (e) => {
        if (e.target.value) {
            setFormSelectedExperienceTo(e.target.value);
        } else {
            setFormSelectedExperienceTo(undefined);
        }
    }
    const isFormValid = () => {
        setValue(document.getElementById('myInput').value);
        let keywordValue = document.getElementById('myInput').value;
        let errCount = 0;
        errCount += (keywordValue === "" || keywordValue === undefined || keywordValue === null) ? 1 : 0;
        errCount += (FormSelectedExperienceFrom === 0 || FormSelectedExperienceFrom === undefined || FormSelectedExperienceFrom === null) ? 1 : 0;
        errCount += (FormSelectedExperienceTo === 0 || FormSelectedExperienceTo === undefined || FormSelectedExperienceTo === null) ? 1 : 0;
        errCount += (selectedOptionForLocation.length === 0 || selectedOptionForLocation.length === undefined) ? 1 : 0;
        errCount += (selectedOptionForIndustry.length === 0 || selectedOptionForIndustry.length === undefined) ? 1 : 0;
        console.log(errCount);
        return errCount <= 4 ? true : false;
    }

    const handleQuickSearchSubmit = async (formData) => {
        setValue(document.getElementById('myInput').value);
        let keywordValue = document.getElementById('myInput').value
        let keyWord = "";
        if (keywordValue) {
            if (keywordValue !== "" || keywordValue !== null || keywordValue !== undefined) {
                keyWord = keywordValue;
            }
        }
        let experienceFrom = 0;
        if (FormSelectedExperienceFrom !== "" || FormSelectedExperienceFrom !== null || FormSelectedExperienceFrom !== undefined) {
            experienceFrom = parseInt(FormSelectedExperienceFrom) || 0;
        }
        let experienceTo = 0;
        if (FormSelectedExperienceTo !== "" || FormSelectedExperienceTo !== null || FormSelectedExperienceTo !== undefined) {
            experienceTo = parseInt(FormSelectedExperienceTo) || 0;
        }
        if (isFormValid()) {
            const searchdata = {
                UserID: parseInt(AuthService.getCurrentUser().userId),
                SearchKeywords: keyWord,
                SearchAnyKeywords: formData.Allword,
                IndustryIDs: selectedOptionForIndustry,
                CurrentLocationIDs: selectedOptionForLocation,
                ExperienceFrom: experienceFrom,
                ExperienceTo: experienceTo,
                ResumeUpdateWithin: formData.updateWithin,
                SortByResult: formData.sortByresult,
                IsAdvanceSearch: false,
            }
            const parms = btoa(JSON.stringify(searchdata));
            navigate({
                pathname: '/advancedSearchResult',
                search: '?qs=' + parms,
            })
        } else {
            alert("Please select atleast one field.")
        }
    }

    const initKeyWordsList = async (inputText) => {
        const keyWords = await CommonDropDownService.getKeywordsList(inputText);
        keyWords ? setKeyWordsList(keyWords) : setKeyWordsList({});
    }
    useEffect(() => {
        const initIndustrylist = async () => {
            const industry = await CommonDropDownService.getIndustry();
            industry ? setIndustryList(industry) : setIndustryList({});
        }
        const initLocationlist = async () => {
            const location = await CommonDropDownService.getCurrentLocation();
            location ? setLocationlList(location) : setLocationlList({});
        }
        const initUpdatedWithinlist = async () => {
            const updatedwithin = await CommonDropDownService.getResumeLastUpdatedInMonths();
            updatedwithin ? setUpdatedWithinList(updatedwithin) : setUpdatedWithinList({});
        }
        const initSortResultBylist = async () => {
            const sortresultby = await CommonDropDownService.getSortSearchKeyWords();
            sortresultby ? setSortResultByList(sortresultby) : setSortResultByList({});
        }
        initIndustrylist();
        initLocationlist();
        initUpdatedWithinlist();
        initSortResultBylist();

        setValue("sortByresult", "RowRank Desc");
    }, []);
    return (
        <LayoutAuthUser>
            <div className='container mt-2' id='grid'>
                <div className='row'>
                    <form method='POST' onSubmit={handleSubmit(handleQuickSearchSubmit)}>
                        <div className='row'>
                            <h6 className='mt-2 ml-4' >QUICK SEARCH</h6>
                            <hr style={{ backgroundColor: "#1976D2", width: "1095px", marginTop: "1%" }} />
                            <div className='col-md-3'>
                                <h6 className=' ml-2' >FINE TUNE YOUR SEARCH</h6>
                            </div>
                            <div className='col-md-9' style={{ borderLeft: "1px solid #1976D2" }}>
                                <div className='row'>
                                    <div className='col-1'></div>
                                    <div className='col-3'>
                                        <label className='control-label font-13 mt-1'>Keywords :</label>
                                    </div>
                                    <div className='col-6'>
                                        <span className="autocomplete">
                                            <input id="myInput" className='form-control'
                                                onChange={(e) => handleInputChange(e.target.value)}
                                                type="text" />
                                        </span>
                                        <span className="font-11">* Please use comma ( , ) , AND/OR to separate multiple keywords .</span>
                                        {errors.LoginID?.type === 'required' && <p className='text-danger mt-1'>Please enter the Login ID</p>}
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3'>
                                        <label className='control-label font-13 mt-1'> </label>
                                    </div>
                                    <div className='col-6'>
                                        <input type="radio" name="Allword" className='allorany' checked={true} value='ALL'
                                            {...register("Allword", {
                                                required: true,
                                            })}
                                        /> All words &nbsp;
                                        <input type="radio" name="Allword" className='allorany' value='ANY'
                                            {...register("Allword", {
                                                required: true,
                                            })}
                                        /> Any words
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13 '>Industry  :</label>
                                    </div>
                                    <div className='col-6'>
                                        <Select
                                            placeholder={"-- Select Industries --"}
                                            onChange={handleSelectIndustry}
                                            value={selectedOptionForIndustry}
                                            isMulti={true}
                                            options={IndustryList}
                                        />
                                        <span className="font-11">* You can select multiple max 10 Industries.</span>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13'>Location  :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <Select
                                            placeholder={"-- Select locations --"}
                                            onChange={handleSelectLocation}
                                            value={selectedOptionForLocation}
                                            isMulti={true}
                                            options={LocationlList}
                                        />
                                        <span className="font-11">* You can select multiple max 10 Location.</span>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13 mt-1'>Experience :</label>
                                    </div>
                                    <div className='col-3 mt-1'>
                                        <input type="number" placeholder='From' min="0" value={FormSelectedExperienceFrom} onChange={handleExperienceFrom} name='Experience' className="form-control" />
                                    </div>
                                    <div className='col-3 mt-1'>
                                        <input type="number" placeholder='To' min="0" value={FormSelectedExperienceTo} onChange={handleExperienceTo} name='Experience' className="form-control" />
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>

                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13'>Show :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <select className='form-control' name='updateWithin'
                                            {...register("updateWithin", {
                                            })}>
                                            {
                                                UpdatedWithinList.map((item, i) =>
                                                    item.value === "" ?
                                                        <option key={i} value="">{item.label}</option> :
                                                        <option key={i} value={item.value}>{item.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-1'></div>
                                    <div className='col-3 mt-1'>
                                        <label className='control-label font-13'>Sort By :</label>
                                    </div>
                                    <div className='col-6 mt-1'>
                                        <select className='form-control' name='sortByresult'
                                            {...register("sortByresult", {
                                            })}>
                                            {
                                                SortResultByList.map((item, i) =>
                                                    <option key={i} value={item.value}>{item.label}</option>)
                                            }
                                        </select>
                                    </div>
                                    <div className='col-2'></div>
                                    <div className='col-4 mt-2'></div>
                                    <div className='col-2 mt-3 mb-5' style={{ textAlign: "center" }}>
                                        <button type='submit' className='btn btn-sm btn btn-outline-primary mr-1'><i className='fa fa-floppy-o'></i> Submit</button>
                                    </div>
                                    <div className='col-6 mt-2'></div>
                                </div>
                            </div>

                        </div>
                    </form>
                </div>
            </div>
        </LayoutAuthUser>
    )
}

export default QuickSearch;