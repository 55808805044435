import { DomainService } from "./DomainService";
import axios from 'axios';
import { AuthService } from '../Services/AuthService';

const baseUrl = DomainService.GetBaseUrl() + "Admin/";

const getClientListByUserID = async (startIndex,searchText) => {
    const res = await fetch(baseUrl + "GetClientListByUserID?startIndex=" + startIndex+"&userID="+parseInt(AuthService.getCurrentUser().userId)+"&searchText="+searchText, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getAssignmentListByClientID = async (clientID) => {
    const res = await fetch(baseUrl + "GetAssignmentListByClientID?encClientID=" + clientID+"&userID="+parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getAppliedCandidates = async (pageIndex, assignmentID, searchText,pageSize,passports) => {

    const res = await fetch(baseUrl + "GetAppliedCandidates?assignmentID=" + assignmentID + "&userID=" + parseInt(AuthService.getCurrentUser().userId) + "&pageIndex=" + pageIndex + "&searchText=" + searchText +"&pageSize="+pageSize+"&filterPP="+passports, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const saveCustomField = async (reqData) => {
    const res = await fetch(baseUrl + "SaveOrUpdateCandidateCustomField", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}
const getLocation = async () => {
    const res = await fetch(baseUrl + "GetLocation", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const downloadCandidateData = async (assignmentID) => {
    const res = await fetch(baseUrl + "DownloadCandidateData?encAssignmentID=" + assignmentID+"&UserID="+parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const massEmailSend=async (reqData)=>{
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': AuthService.getApiAuthorizationConfig()
            },
        };
        const res = await axios.post(baseUrl + "SendEmailWithAttachment", reqData, config).then((response) => {
            console.log(response);
            return response;
        });
       return res;
    } catch (error) {
        console.log(error);
    }
}
const saveNewCandidate=async (reqData)=>{
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': AuthService.getApiAuthorizationConfig()
            },
        };
        const res = await axios.post(baseUrl + "SaveNewCandidate", reqData, config).then((response) => {
            console.log(response);
            return response;
        });
       return res;
    } catch (error) {
        console.log(error);
    }
}
const saveAssignment = async ( AssignmentName, AssignmentDetails, ClientID, CoordinatorIDs, IsAssignmentActive) => {
    const assignmentData = {
        AssignmentID: 0,
        AssignmentName: AssignmentName,
        AssignmentDetails: AssignmentDetails,
        EncClientID: ClientID,
        CoIDs: CoordinatorIDs,
        AssignmentIsActive: IsAssignmentActive === 0 ? false : true,
        ReferenceID:parseInt(AuthService.getCurrentUser().referenceID)
    }
    const res = await fetch(baseUrl + "SaveOrUpdateAssignment", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(assignmentData)
    });
    const data = await res.json();
    return data;
}
const sendMessage = async (reqData) => {
    try {
        const config = {
            headers: {
                'content-type': 'multipart/form-data',
                'Authorization': AuthService.getApiAuthorizationConfig()
            },
        };
        const res = await axios.post(baseUrl + "SendMessage", reqData, config).then((response) => {
            console.log(response);
            return response;
        });
       return res;
    } catch (error) {
        console.log(error);
    }
}
const getJobPostingsByassignmentId = async (assignmentId) => {
    const res = await fetch(baseUrl + "GetJobPostingByOnAssignment?encAssignmentID="+assignmentId+"&UserID=" + parseInt(AuthService.getCurrentUser().userId) , {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}

//documents
const getCandidateDocumentByCandidateID = async (canID,encJobPostingID,isNotFirstDocView) => {
    const res = await fetch(baseUrl + "GetCandidateDocumentByCandidateID?encCandidateID=" + canID+"&encJobPostingID="+encJobPostingID+"&userID="+parseInt(AuthService.getCurrentUser().userId)+"&isNotFirstDocView="+isNotFirstDocView, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}

const getDocTypes = async () => {
    const res = await fetch(baseUrl + "GetDocTypes", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const saveOrUpdateDocument = async (formData) => {
    const config = {
        headers: {
            'Authorization': AuthService.getApiAuthorizationConfig(),
            'Content-Type': 'multipart/form-data'
        },
    };
    const res = await axios.post(baseUrl + "SaveOrUpdateDocument", formData, config);
    return res;
}
const deleteCandidateDocument = async (encDocID) => {
    const res = await fetch(baseUrl + "DeleteCandidateDocument?encDocID="+encDocID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const downloadCandidateDocument = async (encDocID) => {
    const res = await fetch(baseUrl + "DownloadCandidateDocument?encDocID="+encDocID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const moveToMobilization = async (reqData) => {
    const res = await fetch(baseUrl + "MoveToMobilization", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(reqData)
    });
    const data = await res.json();
    return data;
}
const searchByMultiPPNo = async (passports) => {
    const res = await fetch(baseUrl + "DownloadCandidateDocument?passports="+passports, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const getassignmentNameOnClientID = async (encClientID) => {
    const res = await fetch(baseUrl + "GetAssignmentNameonClientID?encClientID="+encClientID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
export const AdminService = {
    getClientListByUserID,
    getAssignmentListByClientID,
    getAppliedCandidates,
    saveCustomField,
    getLocation,
    downloadCandidateData,
    massEmailSend,
    saveNewCandidate,
    saveAssignment,
    sendMessage,
    getCandidateDocumentByCandidateID,
    getDocTypes,
    saveOrUpdateDocument,
    deleteCandidateDocument,
    downloadCandidateDocument,
    searchByMultiPPNo,
    getJobPostingsByassignmentId,
    moveToMobilization,
    getassignmentNameOnClientID
}