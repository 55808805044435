import React, { useState, useEffect } from 'react';
import Layout from '../../Layout/Layout';
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import Select from 'react-select';
import 'react-toastify/dist/ReactToastify.css';
import { AuthService } from '../../Services/AuthService';
import { AssignmentService } from '../../Services/AssignmentService';
import { Navigate, Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
function NewAssignment() {
    const navigate = useNavigate();
    const isAuth = AuthService.isAuthenticatedUser();
    const [selectedOptionForClient, setselectedOptionForClient] = useState({ label: "-- Select Client --", value: "Select Client" });
    const [selectedOptionForCordinator, setselectedOptionForCordinator] = useState({});
    const [searchText, setsearchText] = useState("");
    const [clientList, setclientList] = useState({});
    const [isShowClientddlErr, setisShowClientddlErr] = useState(false);
    const [isShowcoordinatorddlErr, setisShowcoordinatorddlErr] = useState(false);
    const [isAssignmentActive, setisAssignmentActive] = useState(1);
    const [CoordinatorList, setCoordinatorList] = useState({});
    const [assignmnentName, setassignmnentName] = useState("");
    const [hasassignmnentNameErr, sethasassignmnentNameErr] = useState(false);
    const [assignmnentDetails, setassignmnentDetails] = useState("");
    const [hasAssignmnentDetails, sethasAssignmnentDetails] = useState(false);
    const [isMaxCoordinatorsSelected, setisMaxCoordinatorsSelected] = useState(false);
    const { handleSubmit } = useForm();

    const handleCoordinatorChange = (selectedOption) => {
        setselectedOptionForCordinator(selectedOption);
        if (selectedOption.length === 0 || selectedOption.length === undefined) {
            setisShowcoordinatorddlErr(true);
        } else {
            setisShowcoordinatorddlErr(false);
            if (selectedOption.length > 10) {
                setisMaxCoordinatorsSelected(true);
            } else {
                setisMaxCoordinatorsSelected(false);
            }
        }
    };
    const handleClientChange = (selectedOption) => {
        setselectedOptionForClient(selectedOption);
        if (selectedOption.value === "" || selectedOption.value === "Select Client" || selectedOption.value === undefined) {
            setisShowClientddlErr(true);
        } else {
            setisShowClientddlErr(false);
        }

    }
    const handleClientInputChange = (value) => {
        setsearchText(value);
    }
    const handleAssignmentNameChange = (e) => {
        const val = e.target.value;
        console.log(val);
        setassignmnentName(val);
        if (val === "" || val === undefined || val === null) {
            sethasassignmnentNameErr(true);
        } else {
            sethasassignmnentNameErr(false);
        }
    }
    const handleAssignmentDetailsChange = (e) => {
        const val = e.target.value;
        setassignmnentDetails(val);
        if (val === "" || val === undefined || val === null) {
            sethasAssignmnentDetails(true);
        } else {
            sethasAssignmnentDetails(false);
        }
    }
    const saveAssignment = async (data) => {
        if (assignmnentName === "" || assignmnentName === undefined || assignmnentName === null) {
            sethasassignmnentNameErr(true);
        } else {
            sethasassignmnentNameErr(false);
        }
        if (assignmnentDetails === "" || assignmnentDetails === undefined || assignmnentDetails === null) {
            sethasAssignmnentDetails(true);
        } else {
            sethasAssignmnentDetails(false);
        }
        if (selectedOptionForClient.value === "" || selectedOptionForClient.value === "Select Client" || selectedOptionForClient.value === undefined) {
            setisShowClientddlErr(true);
        } else {
            setisShowClientddlErr(false);
        }
        if (selectedOptionForCordinator.length === 0 || selectedOptionForCordinator.length === undefined) {
            setisShowcoordinatorddlErr(true);
        } else {
            setisShowcoordinatorddlErr(false);
        }
        if (isFormValid()) {
            if (!isMaxCoordinatorsSelected) {

                let coordinatorArr = [];
                for (let index = 0; index < selectedOptionForCordinator.length; index++) {
                    coordinatorArr.push(parseInt(selectedOptionForCordinator[index].value));
                }
                const res = await AssignmentService.saveAssignmentDetails(assignmnentName, assignmnentDetails, parseInt(selectedOptionForClient.value), coordinatorArr, isAssignmentActive);
                if (res.isSuccess) {
                    ShowAlert(1, res.message);
                    setselectedOptionForClient("Select Client");
                    navigate('/');
                    navigate({
                        pathname:'/editassignment',
                        search: '?qs=testQS',
                    })

                } else {
                    ShowAlert(0, res.message);
                }
            }
        }

    }
    const isFormValid = () => {
        let errCount = (assignmnentName === "" || assignmnentName === undefined || assignmnentName === null) ? 1 : 0;
        errCount += (assignmnentDetails === "" || assignmnentDetails === undefined || assignmnentDetails === null) ? 1 : 0;
        errCount += (selectedOptionForClient.value === "" || selectedOptionForClient.value === "Select Client" || selectedOptionForClient.value === undefined) ? 1 : 0;
        errCount += (selectedOptionForCordinator.length === 0 || selectedOptionForCordinator.length === undefined) ? 1 : 0;
        return errCount === 0 ? true : false;
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    const handleCancel = () => {
        window.location.reload();
    }
    useEffect(() => {
        const initClientList = async () => {
            const clients = await AssignmentService.getClientList(searchText);
            if (clients) {
                setclientList(clients);
            } else {
                setclientList("")
            }
        }
        const initCoordinatorList = async () => {
            const Coordinators = await AssignmentService.getCoordinatorList(searchText);
            if (Coordinators) {
                setCoordinatorList(Coordinators);
            } else {
                setCoordinatorList("")
            }
        }
        if (isAuth) {
            initClientList();
            initCoordinatorList();
        }
    }, [searchText]);
    if (isAuth) {
        return (
            <Layout>
                <h6 className='mt-2'>
                    ASSIGNMENT DETAILS
                </h6>
                <form method='POST' onSubmit={handleSubmit(saveAssignment)}>
                    <div className='row'>
                        <div className='col-2'>
                            <span className='font-12'>Assignment Name  </span> <span className='text-danger'>*</span> :
                        </div>
                        <div className='col-4 font-12'>
                            <input type="text"
                                className="form-control"
                                onChange={handleAssignmentNameChange}

                            />
                            {hasassignmnentNameErr ? <p className='text-danger mt-1'>Please enter assignment name.</p> : ""}
                        </div>
                        <div className='col-6'></div>
                        <div className='col-2 mt-3'>
                            <label className='control-label'>
                                <span className='font-12'>Client </span> <span className='text-danger'>*</span> :
                            </label>
                        </div>
                        <div className='col-4 font-12 mt-3'>
                            <Select
                                defaultValue={{ label: "-- Select Client --", value: "Select Client" }}
                                onChange={handleClientChange}
                                options={clientList}
                                onInputChange={handleClientInputChange}
                                value={selectedOptionForClient}
                            />
                            {isShowClientddlErr ? <p className='text-danger mt-1' >Please select client.</p> : ""}
                        </div>
                        <div className='col-6'></div>
                        <div className='col-2 mt-3'>
                            <span className='font-12'>Assignment Details  </span> <span className='text-danger'>*</span> :
                        </div>
                        <div className='col-4 font-12 mt-3'>
                            <textarea className="form-control"
                                rows="4"
                                cols="25"
                                onChange={handleAssignmentDetailsChange}
                            />
                            {hasAssignmnentDetails ? <p className='text-danger mt-1'>Please enter assignmnet details.</p> : ""}
                        </div>
                        <div className='col-6'></div>
                        <div className='col-2 mt-3'>
                            <label className='control-label'>
                                <span className='font-12'>Assignment Co-Ordinator </span> <span className='text-danger'>*</span> :
                            </label>
                        </div>
                        <div className='col-4 font-12 mt-3'>
                            <Select
                                onChange={handleCoordinatorChange}
                                isMulti={true}
                                options={CoordinatorList}
                            />
                            {isShowcoordinatorddlErr ? <p className='text-danger mt-1' > Please select assignment coordinator.</p> : ""}
                            {isMaxCoordinatorsSelected ? <p className='text-danger mt-1' > You can't select more than 10 coordinator .</p> : ""}
                            <span className='mt-1'>* You can select multiple max 10 Coordinators</span>
                        </div>
                        <div className='col-6'></div>
                        <div className='col-2 mt-3'>
                            <span className='font-12'>Assignment Active </span>  :
                        </div>
                        <div className='col-4 font-12 mt-3'>
                            <input type="radio" defaultChecked={true} value="1" name="AssignmentActive" onChange={(e) => { setisAssignmentActive(parseInt(e.target.value)) }} /> <span>Yes</span>
                            <input type="radio" value="0" className='ml-1' onChange={(e) => { setisAssignmentActive(parseInt(e.target.value)) }} name="AssignmentActive" /><span> No</span>
                        </div>
                        <div className='col-6'></div>
                        <div className='col-2 mt-3'>

                        </div>
                        <div className='ml-2 mt-3'>
                            <button className='btn btn-sm btn-primary mr-1'><i className='fa fa-floppy-o'></i> Submit</button>
                            <button type='button' onClick={handleCancel} className='btn btn-sm btn-warning'><i className='fa fa-ban'></i> Cancel</button>
                        </div>

                    </div>
                </form>
                <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </Layout>
        );
    } else {
        return <Navigate to="/login" />
    }
}
export default NewAssignment;