import { useForm } from "react-hook-form";
import React, { useState, useEffect } from 'react';
import '../css/login.css';
import logo from '../img/logo.png';
import { AuthService } from '../Services/AuthService';
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
function Login() {
    const navigate = useNavigate();
    const search = useLocation().search;
    let returnURL = new URLSearchParams(search).get('ReturnUrl');
    const qs = new URLSearchParams(search).get('qs');
    const userType = new URLSearchParams(search).get('type');

    const [isShowLoader, setisShowLoader] = useState(false);
    const { register, formState: { errors }, handleSubmit } = useForm();
    const [user, setUser] = useState([]);
    const [ErrMessage, setErrMessage] = useState("");
    const [isShowResetBtn, setisShowResetBtn] = useState(false);
    const [lastSuccessLoginUserID, setlastSuccessLoginUserID] = useState("");
    const loginSubmit = async (data) => {
        setisShowResetBtn(false);
        setisShowLoader(true);
        let userTypeID = 1;

        setErrMessage("");
        const res = await AuthService.LoginUser(data.LoginId, data.Password, userTypeID);
        if (res.isSuccess) {
            setErrMessage("");
            if (returnURL) {
                if (qs) {
                    returnURL += "?qs=";
                    returnURL += qs;
                }
                navigate(returnURL);
            } else {
                navigate('/');
            }

        } else {
            console.log(res);
            if (res.isShowResetLoginBtn) {
                setlastSuccessLoginUserID(data.LoginId);
                console.log(lastSuccessLoginUserID);
                setisShowResetBtn(true);
            } else {
                setisShowResetBtn(false);
                setlastSuccessLoginUserID("");
            }
            setisShowLoader(false);
            setErrMessage(res.message);
        }
        setisShowLoader(false);
    }
    const handleResetBtnClick = async () => {
        const res = await AuthService.ResetLogin(lastSuccessLoginUserID);
        setErrMessage("");
        if (res.isSuccess) {
            setisShowResetBtn(false);
        } else {
            setErrMessage(res.message);
            setisShowResetBtn(true);
        }
    }
    useEffect(() => {


    }, []);

    return (
        <div>
            <div className='my-login-page'>
                <section className="h-100">
                    <div className="container h-100">
                        <div className="row justify-content-md-center h-100">
                            <div className="card-wrapper">
                                <div className="brand">
                                    <img src={logo} alt="logo" />
                                </div>
                                <div className="card fat">
                                    <div className="card-body">

                                        <h4 className="card-title">Login</h4>
                                        <form method="POST" id="loginForm" className="my-login-validation" onSubmit={handleSubmit(loginSubmit)} >
                                            <div className="form-group">
                                                <label>UserName</label>
                                                <input id="LoginId"
                                                    type="text"
                                                    autoComplete='off'
                                                    className="form-control"
                                                    name="LoginId"
                                                    {...register("LoginId", { required: true })} />
                                                {errors.LoginId?.type === 'required' && <p className='text-danger'>Please enter login id</p>}
                                            </div>

                                            <div className="form-group">
                                                <label >Password
                                                    {/* <a href="forgot.html" className="float-right">
                                            Forgot Password?
                                        </a> */}
                                                </label>
                                                <input id="Password"
                                                    type="Password" autoComplete='off'
                                                    className="form-control"
                                                    name="Password"
                                                    {...register("Password", { required: true })}
                                                />
                                                {errors.Password?.type === 'required' && <p className='text-danger'>Please enter password</p>}
                                            </div>

                                            <div className="form-group">
                                                <div className="custom-checkbox custom-control">
                                                    <input type="checkbox" name="remember" id="remember" className="custom-control-input" />
                                                    <label htmlFor="remember" className="custom-control-label">Remember Me</label>
                                                </div>
                                            </div>

                                            <div className="form-group m-0">
                                                <button type="submit" className="btn btn-primary btn-block">
                                                    Login
                                                </button>
                                            </div>
                                        
                                            <br />
                                            <span className="text-danger invalid-login-msg">{ErrMessage}</span>
                                           {isShowResetBtn ?  <div className="form-group m-0">
                                                <button type="button"
                                                    className="btn btn-warning btn-block reset-login-btn"
                                                    onClick={handleResetBtnClick}
                                                >
                                                    Reset
                                                </button>
                                            </div> : null}
                                        </form>
                                        {isShowLoader ? <span className="loader"></span> : null}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
    );
}

export default Login;