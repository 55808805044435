import React, { useState, useEffect } from 'react';
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import { useForm } from "react-hook-form";
import { AuthService } from '../../../Services/AuthService';
// import { ProfileDashboardService } from '../../../Services/ProfileDashboardservice';
import CurrencyInput from 'react-currency-input-field';
import Backdrop from '@mui/material/Backdrop';
import ReactPaginate from 'react-paginate';
import CircularProgress from '@mui/material/CircularProgress';
import { AdminService } from '../../../Services/AdminService';
import { CommonDropDownService } from '../../../Services/CommonDropDownService';
function ApplyJobModal({ GetEncAssignmentID, GetEncJobpostingID, TriggerModalClose, TriggerCloseModal }) {
    const { register, setValue, clearErrors, watch, formState: { errors }, handleSubmit } = useForm();
    const [IsShowWarningForPersonal, setIsShowWarningForPersonal] = useState(false);
    const [IsShowWarningForEducation, setIsShowWarningForEducation] = useState(false);
    const [IsShowWarningForSalary, setIsShowWarningForSalary] = useState(false);
    const [totalExperience, settotalExperience] = useState("");
    const [totalExperienceMonth, settotalExperienceMonth] = useState("");
    const [indianExperienceMonth, setindianExperienceMonth] = useState("");
    const [overSeasExperienceMonth, setoverSeasExperienceMonth] = useState("");
    const [openLoader, setopenLoader] = React.useState(false);
    const [jobPostingList, setjobPostingList] = useState([]);
    const [interviewLocations, setinterviewLocation] = useState([]);
    const [sources, setsources] = useState([]);
    const [dateofBirth, setDateofBirth] = useState("");
    const [isdateOfbirthErr, setdateOfbirthErr] = useState(false);
    const CloseModal = (isRefresh) => {
        if (isRefresh) {
            TriggerModalClose(1, true);
            setValue("education", "");
            setValue("totalExpYear", "");
            setValue("totalExpMonth", "");
            setValue("indianExpYear", "");
            setValue("indianExpMonth", "");
            setValue("overseasExpYear", "");
            setValue("overseasExpMonth", "");
            setValue("currentSalary", "");
            setValue("expectedSalary", "");
            setValue("noticeperiod", "");
            setValue("currentLocation", "");
            setValue("otherDetails", "");
        } else {
            TriggerModalClose(1, false);
        }
    }
    const handleTotalExperienceChange = (e) => {
        let value = e.target.value;
        if (value >= 35) {
            settotalExperience(35);
        } else {
            settotalExperience(value);
        }
    }
    const handleTotalExpMonthChange = (e) => {
        let value = e.target.value;
        if (value >= 11) {
            settotalExperienceMonth(11);
        } else {
            settotalExperienceMonth(value);
        }
    }
    const handleIndianExpMonthChange = (e) => {
        let value = e.target.value;
        if (value >= 11) {
            setindianExperienceMonth(11);
        } else {
            setindianExperienceMonth(value);
        }
    }
    const handleOverseasExpMonthChange = (e) => {
        let value = e.target.value;
        if (value >= 11) {
            setoverSeasExperienceMonth(11);
        } else {
            setoverSeasExperienceMonth(value);
        }
    }
    const handleInterviewLocChange = (e) => {
        let val = e.target.value;
        if (val !== "" && val !== null && val !== undefined) {
            initSourceList(parseInt(val));
        } else {
            setsources([]);
        }
    }
    const initSourceList = async (locID) => {
        const getSrcList = await CommonDropDownService.getSourceByLocation(locID);
        getSrcList ? setsources(getSrcList) : setsources([]);
    }
    const handleDateOfBirthOnChange = (e) => {
        console.log(e.target.value);
        debugger;
        let dob = e.target.value;
        var today = new Date(),
            birthDate = new Date(dob),
            age = today.getFullYear() - birthDate.getFullYear(),
            m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age--;
            setDateofBirth(dob);
            setdateOfbirthErr(false);
        } else {
            setDateofBirth("");
            setdateOfbirthErr(true);
        }
    }

    const handleSaveNewCandidateDetails = async (formData) => {
        setopenLoader(true);
        console.log(formData);
        let toExpM = "";
        let totalExperience = "";
        if (formData.indianExpMonth.length === 1) {
            toExpM = formData.indianExpMonth = '0' + formData.indianExpMonth;
            totalExperience = formData.totalExpYear + "." + toExpM;
        } else {
            totalExperience = formData.totalExpYear + "." + formData.totalExpMonth;
        }
        let inExpM = "";
        let indianExperience = "";
        if (formData.indianExpMonth.length === 1) {
            inExpM = formData.indianExpMonth = '0' + formData.indianExpMonth;
            indianExperience = formData.indianExpYear + "." + inExpM;
        } else {
            indianExperience = formData.indianExpYear + "." + formData.indianExpMonth;
        }
        let ovExpM = "";
        let overseasExperience = "";
        if (formData.overseasExpMonth.length === 1) {
            ovExpM = formData.overseasExpMonth = '0' + formData.overseasExpMonth;
            overseasExperience = formData.overseasExpYear + "." + ovExpM;
        } else {
            overseasExperience = formData.overseasExpYear + "." + formData.overseasExpMonth;
        }

        const docFile = formData.resume[0];
        const base64 = await blobToBase64DataURL(docFile);
        let formData1 = new FormData();
        formData1.append("UserID", parseInt(AuthService.getCurrentUser().userId));
        formData1.append("CandidateName", formData.name);
        formData1.append("PassportNo", formData.passportNo);
        formData1.append("FileName", docFile.name);
        formData1.append("Base64FileString", base64);
        formData1.append("EmailID", formData.emailID);
        formData1.append("MobileNo", formData.mobileNo);
        formData1.append("TelePhoneNo", formData.telephoneNo);
        formData1.append("DateOfBirth", dateofBirth);
        formData1.append("JobpostingID", formData.positions);
        formData1.append("ReferenceID", parseInt(AuthService.getCurrentUser().referenceID));
        formData1.append("Education", formData.education ? formData.education : "");
        formData1.append("TotalExperience", totalExperience === "." ? 0.00 : parseFloat(totalExperience));
        formData1.append("IndianExperience", indianExperience === "." ? 0.00 : parseFloat(indianExperience));
        formData1.append("OverseasExperience", indianExperience === "." ? 0.00 : parseFloat(overseasExperience));
        formData1.append("CurrentSalary", formData.currentSalary);
        formData1.append("ExpectSalary", formData.expectedSalary);
        formData1.append("NoticePeriod", formData.noticeperiod);
        formData1.append("CurrentLocation", formData.currentLocation);
        formData1.append("OtherDetails", formData.otherDetails);
        formData1.append("InterviewLocID", formData.interviewLoc ? parseInt(formData.interviewLoc) : 0);
        formData1.append("SourceID", formData.source ? parseInt(formData.source) : 0);
        formData1.append("InterviewDate", formData.interviewDate ? formData.interviewDate : "");
        const res = await AdminService.saveNewCandidate(formData1);
        console.log(res);
        if (res.data.isJobApplied) {
            CloseModal(true);
            ShowAlert(1, res.data.jobAppliedMessage);
        } else {
            ShowAlert(0, res.data.jobAppliedMessage);
        }
        setopenLoader(false);
    }
    const blobToBase64DataURL = (blob) => new Promise(
        resolvePromise => {
            const reader = new FileReader();
            reader.onload = () => resolvePromise(reader.result);
            reader.readAsDataURL(blob);
        }
    )
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }

    const initJobPostings = async () => {
        const jobpostings = await AdminService.getJobPostingsByassignmentId('12359');
        console.log(jobpostings);
        jobpostings ? setjobPostingList(jobpostings) : setjobPostingList([]);
    }
    const initInterviewLocation = async () => {
        const location = await AdminService.getLocation();
        console.log(location);
        location ? setinterviewLocation(location) : setinterviewLocation([]);
    }
    const TriggerWarning = () => {
        let nameErr = null;
        let ppErr = null;
        let resumeErr = null;
        let positionsErr = null;
        let dobErr = null;
        let validDobErr = null;
        let telephoneErr = null;
        let phoneErr = null;
        let emailErr = null;
        let currentsalaryErr = null;
        let exsalaryErr = null;
        let educationErr = null;
        let TexpErr = null;
        let IexpErr = null;
        let OExpErr = null;
        let noticeErr = null;
        let currentLocErr = null;
        let otherErr = null;
        let errCount = 0;
        let eduerrCount = 0;
        let salerrCount = 0;
        const nameElement = document.getElementById("nameID");
        if (nameElement) {
            nameErr = nameElement.getAttribute("data-name");
        }
        const ppElement = document.getElementById("ppID");
        if (ppElement) {
            ppErr = ppElement.getAttribute("data-name");
        }
        const resume = document.getElementById('resumeID');
        if (resume) {
            resumeErr = resume.getAttribute("data-name");
        }
        const position = document.getElementById('positionID');
        if (position) {
            positionsErr = position.getAttribute("data-name");
        }
        const dob = document.getElementById('dobID');
        if (dob) {
            dobErr = dob.getAttribute("data-name");
        }
        const dobvalidate = document.getElementById('validdobId');
        if (dobvalidate) {
            validDobErr = dobvalidate.getAttribute("data-name");
        }
        const telephone = document.getElementById('telephoneID');
        if (telephone) {
            telephoneErr = telephone.getAttribute("data-name");
        }
        const mobile = document.getElementById('mobileID');
        if (mobile) {
            phoneErr = mobile.getAttribute("data-name");
        }
        const email = document.getElementById('emailID');
        if (email) {
            emailErr = email.getAttribute("data-name");
        }
        const csalary = document.getElementById('csalaryID');
        if (csalary) {
            currentsalaryErr = csalary.getAttribute("data-name");
        }
        const esalary = document.getElementById('eSalaryID');
        if (esalary) {
            exsalaryErr = esalary.getAttribute("data-name");
        }
        const education = document.getElementById('educationID');
        if (education) {
            educationErr = education.getAttribute("data-name");
        }
        const totalexp = document.getElementById('totalexpyrID');
        if (totalexp) {
            TexpErr = totalexp.getAttribute("data-name");
        }
        const indianexp = document.getElementById('iExpyearID');
        if (indianexp) {
            IexpErr = indianexp.getAttribute("data-name");
        }

        const overExp = document.getElementById('overExpID');
        if (overExp) {
            OExpErr = overExp.getAttribute("data-name");
        }
        const notice = document.getElementById('noticeID');
        if (notice) {
            noticeErr = notice.getAttribute("data-name");
        }
        const clocation = document.getElementById('clocationID');
        if (clocation) {
            currentLocErr = clocation.getAttribute("data-name");
        }
        const others = document.getElementById('otherID');
        if (others) {
            otherErr = others.getAttribute("data-name");
        }

        if (nameErr === "1") {
            errCount += 1;
        }
        if (ppErr === "1") {
            errCount += 1;
        }
        if (resumeErr === "1") {
            errCount += 1;
        }
        if (positionsErr === "1") {
            errCount += 1;
        }
        if (dobErr === "1") {
            errCount += 1;
        }
        if (validDobErr === "2") {
            errCount += 1;
        }
        if (telephoneErr === "1") {
            errCount += 1;
        }
        if (phoneErr === "1") {
            errCount += 1;
        }
        if (emailErr === "1") {
            errCount += 1;
        }
        if (exsalaryErr === "1") {
            salerrCount += 1;
        }
        if (currentsalaryErr == "1") {
            salerrCount += 1;
        }
        if (educationErr === "1") {
            eduerrCount += 1;
        }
        if (TexpErr === "1") {
            eduerrCount += 1;
        }
        if (IexpErr === "1") {
            eduerrCount += 1;
        }
        if (OExpErr === "1") {
            eduerrCount += 1;
        }
        if (noticeErr === "1") {
            eduerrCount += 1;
        }
        if (currentLocErr === "1") {
            eduerrCount += 1;
        }
        if (otherErr === "1") {
            eduerrCount += 1;
        }

        if (errCount > 0) {
            setIsShowWarningForPersonal(true);
        } else {
            setIsShowWarningForPersonal(false);
        }
        if (eduerrCount > 0) {
            setIsShowWarningForEducation(true);
        } else {
            setIsShowWarningForEducation(false);
        }
        if (salerrCount > 0) {
            setIsShowWarningForSalary(true);
        } else {
            setIsShowWarningForSalary(false);
        }
    }

    useEffect(() => {
        initJobPostings();
        initInterviewLocation();
    }, [])

    return (
        <div className='container'>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <form id="candidateForm" onSubmit={handleSubmit(handleSaveNewCandidateDetails)} >
                <div className="row">
                    <div className="col-md-3">
                        <div className="nav flex-column nav-pills nav-pills-custom" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                            <a className="nav-link mb-3 p-1 shadow active" id="v-pills-home-tab" data-toggle="pill" href="#v-pills-personalinfo" role="tab" aria-controls="v-pills-personalinfo" aria-selected="true">
                                <i className="fa fa-user-circle-o mr-2"></i>
                                <span className="font-weight-bold small text-uppercase">Personal Info</span>
                                {
                                    IsShowWarningForPersonal ? <i className="fa fa-exclamation-triangle text-danger ml-5"></i> : null
                                }
                            </a>
                            <a className="nav-link mb-3 p-1 shadow" id="v-pills-settings-tab" data-toggle="pill" href="#v-pills-pCCinfo" role="tab" aria-controls="v-pills-pCCinfo" aria-selected="false">
                                <i className="fa fa-certificate mr-2"></i>
                                <span className="font-weight-bold small text-uppercase">Education Info</span>
                                {
                                    IsShowWarningForEducation ? <i className="fa fa-exclamation-triangle text-danger ml-5"></i> : null
                                }
                            </a>
                            <a className="nav-link mb-3 p-1 shadow" id="v-pills-profile-tab" data-toggle="pill" href="#v-pills-jobinfo" role="tab" aria-controls="v-pills-jobinfo" aria-selected="false">
                                <i className="fa fa-briefcase mr-2"></i>
                                <span className="font-weight-bold small text-uppercase">Job Info</span>
                            </a>

                            <a className="nav-link mb-3 p-1 shadow" id="v-pills-messages-tab" data-toggle="pill" href="#v-pills-salaryinfo" role="tab" aria-controls="v-pills-salaryinfo" aria-selected="false">
                                <i className="fa fa-money mr-2"></i>
                                <span className="font-weight-bold small text-uppercase">Salary Info</span>
                                {
                                    IsShowWarningForSalary ? <i className="fa fa-exclamation-triangle text-danger ml-5"></i> : null
                                }
                            </a>



                            <button type='button' className='btn  btn-warning custom-btn-submit-cancel1' onClick={() => CloseModal()}> Cancel</button>
                            <button type="submit" className='btn  btn-primary custom-btn-submit-cancel' onClick={() => setTimeout(() => {
                                TriggerWarning()
                            }, 500)}>Submit</button>
                        </div>
                    </div>
                    <div className="col-md-9" >
                        <div className="tab-content" id="v-pills-tabContent">
                            <div className="tab-pane fade shadow rounded bg-white show active p-3" id="v-pills-personalinfo" role="tabpanel" aria-labelledby="v-pills-home-tab">
                                <h4 className="hdcol mb-4">Personal Info</h4>
                                <div className=" text-muted mb-2 mycustomClass-OverFlow">
                                    <div className='row'>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'> Name Of Candidate <span className='text-danger'>*</span> :</label>
                                        </div>

                                        <div className='col-md-6'>
                                            <input type='text' className="form-control" name='name'
                                                {...register("name", {
                                                    required: true,
                                                })} />
                                            {errors.name?.type === 'required' && <p id='nameID' className='text-danger' data-name='1' style={{ borderBottom: "none", fontSize: "12px" }}>name can't be empty</p>}
                                        </div>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>PP No  <span className='text-danger'>*</span>:</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type='text' className="form-control text-uppercase" name='passportNo'
                                                {...register("passportNo", {
                                                    required: true,
                                                })} />
                                            {errors.passportNo?.type === 'required' && <p id='ppID' className='text-danger' data-name='1' style={{ borderBottom: "none", fontSize: "12px" }}>passportNo can't be empty</p>}
                                        </div>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Resume  <span className='text-danger'>*</span>:</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type='file' className="form-control" name='resume'
                                                {...register("resume", {
                                                    required: true,
                                                })} />
                                            {errors.resume?.type === 'required' && <p id='resumeID' className='text-danger' data-name='1' style={{ borderBottom: "none", fontSize: "12px" }}>resume can't be empty</p>}
                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Positions <span className='text-danger'>*</span> :</label>
                                        </div>
                                        <div className='col-md-6'>

                                            <select type="text" className="form-control mt-2" name='positions'
                                                {...register("positions", {
                                                    required: true
                                                })}
                                            >
                                                <option value="">--- Select ---</option>
                                                {
                                                    jobPostingList.map((item, i) =>
                                                        <option key={i} value={item.value}>{item.label}</option>
                                                    )
                                                }
                                            </select>
                                            {errors.positions?.type === 'required' && <p id='positionID' className='text-danger' data-name='1' style={{ borderBottom: "none", fontSize: "12px" }}>position can't be empty</p>}
                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>D.O.B  :</label>
                                        </div>
                                        <div className='col-md-6'>

                                            <input type='date' className="form-control" name='dataofbirth'
                                                {...register("dataofbirth", {
                                                    required: true,
                                                    onChange: (e) => { handleDateOfBirthOnChange(e) }
                                                })} />
                                            {errors.dataofbirth?.type === 'required' && <p id='dobID' className='text-danger' data-name='1' style={{ borderBottom: "none", fontSize: "12px" }}>dataofbirth can't be empty</p>}
                                            {isdateOfbirthErr ? <p className='text-danger mt-1' id='validdobId' data-name='2'>DOB must be greater than 18</p> : ""}
                                        </div>
                                        <div className='col-md-1'></div>


                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Tel. No :</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type='text' className="form-control" name='telephoneNo' maxLength="10"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register("telephoneNo", {
                                                    required: true,
                                                })} />
                                            {errors.telephoneNo?.type === 'required' && <p id='telephoneID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>telephoneNo can't be empty</p>}

                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Mobile No :</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type='text' className="form-control" name='mobileNo' maxLength="10"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                {...register("mobileNo", {
                                                    required: true,
                                                })} />
                                            {errors.mobileNo?.type === 'required' && <p id='mobileID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>mobileno can't be empty</p>}

                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Email ID :</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type='text' className="form-control" name='emailID'
                                                {...register("emailID", {
                                                    required: true,
                                                })} />
                                            {errors.emailID?.type === 'required' && <p id='emailID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>email can't be empty</p>}
                                        </div>
                                        <div className='col-md-1'></div>

                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade shadow rounded bg-white p-3" id="v-pills-jobinfo" role="tabpanel" aria-labelledby="v-pills-profile-tab">
                                <h4 className="mb-4 hdcol">Job Info</h4>
                                <div className="text-muted mb-2 mycustomClass-OverFlow" >
                                    <div className='row'>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Date of Interview(MM/DD/YY) :</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type='date' className="form-control" name='interviewDate'
                                                {...register("interviewDate", {
                                                })} />
                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Interview Location :</label>
                                        </div>
                                        <div className='col-md-6'>

                                            <select type="text" className="form-control mt-2" name='interviewLoc'
                                                {...register("interviewLoc", {
                                                    onChange: (e) => { handleInterviewLocChange(e) }
                                                })}
                                            >
                                                <option value="">--- Select ---</option>
                                                {
                                                    interviewLocations.map((item, i) =>
                                                        <option key={i} value={item.value}>{item.label}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Source :</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <select type="text" className="form-control mt-2" name='source'
                                                {...register("source", {
                                                })}
                                            >
                                                <option value="">--- Select ---</option>
                                                {
                                                    sources.map((item, i) =>
                                                        <option key={i} value={item.value}>{item.label}</option>
                                                    )
                                                }
                                            </select>
                                        </div>
                                        <div className='col-md-1'></div>


                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade shadow rounded bg-white p-3" id="v-pills-salaryinfo" role="tabpanel" aria-labelledby="v-pills-messages-tab">
                                <h4 className="hdcol mb-4">Salary Info</h4>
                                <div className=" text-muted mb-2 mycustomClass-OverFlow">
                                    <div className='row'>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Current Salary <span className="text-danger">*</span>:</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <CurrencyInput type="text" className='form-control' min={0.00} data-type="currency"
                                                allowDecimals prefix='$' groupSeparator=',' decimalSeparator="." name='currentSalary' placeholder="$1,000,000"
                                                {...register("currentSalary", {
                                                    required: true,
                                                })} />
                                            {errors.currentSalary?.type === 'required' && <p id='csalaryID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>it can't be empty or $ format</p>}
                                        </div>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Expected Salary <span className="text-danger">*</span>:</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <CurrencyInput type="text" className='form-control' min={0.00} data-type="currency"
                                                allowDecimals prefix='$' groupSeparator=',' decimalSeparator="." name='expectedSalary' placeholder="$1,000,000"
                                                {...register("expectedSalary", {
                                                    required: true,
                                                })} />
                                            {errors.expectedSalary?.type === 'required' && <p id='eSalaryID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>it can't be empty or $ format</p>}
                                        </div>
                                        <div className='col-md-1'></div>

                                    </div>
                                </div>
                            </div>

                            <div className="tab-pane fade shadow rounded bg-white p-3" id="v-pills-pCCinfo" role="tabpanel" aria-labelledby="v-pills-settings-tab">
                                <h4 className="hdcol mb-4">Education Info</h4>
                                <div className=" text-muted mb-2 mycustomClass-OverFlow">
                                    <div className='row'>
                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Education <span className="text-danger">*</span> :</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type='text' className="form-control" name='education'
                                                {...register("education", {
                                                    required: true,
                                                })} />
                                            {errors.education?.type === 'required' && <p id='educationID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>education can't be empty</p>}
                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Total Experience  <span className="text-danger">*</span>:</label>
                                        </div>
                                        <div className='col-md-6 row'>
                                            <div className='col-md-6'>
                                                <input type='number' className="form-control" name='totalExpYear' placeholder='Year' min={0} max={35}
                                                    value={totalExperience === 0 ? "" : totalExperience}
                                                    {...register("totalExpYear", {
                                                        required: true,
                                                        onChange: (e) => { handleTotalExperienceChange(e) }
                                                    })} />
                                                {errors.totalExpYear?.type === 'required' && <p id='totalexpyrID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>it can't be empty</p>}
                                            </div>
                                            <div className='col-md-6'>
                                                <input type='number' className="form-control" name='totalExpMonth' placeholder='Month' min={0} max={11}
                                                    value={totalExperienceMonth === 0 ? 0 : totalExperienceMonth}
                                                    {...register("totalExpMonth", {
                                                        required: true,
                                                        onChange: (e) => { handleTotalExpMonthChange(e) }
                                                    })} />
                                            </div>
                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Indian Experience <span className="text-danger">*</span>:</label>
                                        </div>
                                        <div className='col-md-6 row'>
                                            <div className='col-md-6'>
                                                <input type='number' className="form-control" name='indianExpYear' placeholder='Year' min={0} max={35}

                                                    {...register("indianExpYear", {
                                                        required: true,
                                                    })} />
                                                {errors.indianExpYear?.type === 'required' && <p id='iExpyearID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>it can't be empty</p>}
                                            </div>
                                            <div className='col-md-6'>
                                                <input type='number' className="form-control" name='indianExpMonth' placeholder='Month' min={0} max={11}
                                                    value={indianExperienceMonth === 0 ? 0 : indianExperienceMonth}
                                                    {...register("indianExpMonth", {
                                                        onChange: (e) => { handleIndianExpMonthChange(e) }
                                                    })} />
                                            </div>
                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Overseas Experience <span className="text-danger">*</span>:</label>
                                        </div>
                                        <div className='col-md-6 row'>
                                            <div className='col-md-6'>
                                                <input type='number' className="form-control" name='overseasExpYear' placeholder='Year' min={0} max={35}
                                                    {...register("overseasExpYear", {
                                                        required: true,
                                                    })} />
                                                {errors.overseasExpYear?.type === 'required' && <p id='overExpID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>it can't be empty</p>}
                                            </div>
                                            <div className='col-md-6'>
                                                <input type='number' className="form-control" name='overseasExpMonth' placeholder='Month' min={0} max={11}
                                                    value={overSeasExperienceMonth === 0 ? 0 : overSeasExperienceMonth}
                                                    {...register("overseasExpMonth", {
                                                        onChange: (e) => { handleOverseasExpMonthChange(e) }
                                                    })} />
                                            </div>
                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Notice Period :</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type='text' className="form-control" name='noticeperiod'
                                                {...register("noticeperiod", {
                                                    required: true,
                                                })} />
                                            {errors.noticeperiod?.type === 'required' && <p id='noticeID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>Please enter notice period</p>}
                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Current Location :</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <textarea className="form-control" name='currentLocation' rows="3" cols="25"
                                                {...register("currentLocation", {
                                                    required: true,
                                                })} />
                                            {errors.currentLocation?.type === 'required' && <p id='clocationID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>Please enter current location</p>}
                                        </div>
                                        <div className='col-md-1'></div>

                                        <div className='col-md-1'></div>
                                        <div className='col-md-4'>
                                            <label className='control-label mt-2'>Other Details :</label>
                                        </div>
                                        <div className='col-md-6'>
                                            <input type='text' className="form-control" name='otherDetails' placeholder='other details'
                                                {...register("otherDetails", {
                                                    required: true,
                                                })} />
                                            {errors.otherDetails?.type === 'required' && <p id='otherID' data-name='1' className='text-danger' style={{ borderBottom: "none", fontSize: "12px" }}>Please enter notice period</p>}
                                        </div>
                                        <div className='col-md-1'></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </form >
        </div >
    )
}
export default ApplyJobModal;