import { AuthService } from './AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "MyFolderCandidate/";

const getCanResumeFolder = async (startIndex,pageSize,folderId) => {
    const res = await fetch(baseUrl + "GetCandidateFromResumeFolder?startIndex="+startIndex+"&pageSize="+pageSize+"&folderID="+folderId+"&UserID="+ parseInt(AuthService.getCurrentUser().userId) , {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const deleteCandidateFromFolder = async (candidateIds,folderId) => {
    const res = await fetch(baseUrl + "DeleteFolderCandidate?candidateIds="+candidateIds+"&folderID="+folderId , {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
export const CandidateResumeFolderService={
    getCanResumeFolder,
    deleteCandidateFromFolder
}
