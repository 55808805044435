import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "AdvanceSearchClient/";

const getAdvanceSearchResult = async (formdata) => {
    const res = await fetch(baseUrl + "GetAdvanceSearchResult", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(formdata)
    });

    const data = await res.json();
    return data;
}
const saveAdvanceSearchResult = async (formdata) => {
    const res = await fetch(baseUrl + "SaveSearch", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(formdata)
    });

    const data = await res.json();
    return data;
}
const resumeMoveToFolder = async (folderid, resumeIds) => {
    const res = await fetch(baseUrl + "ResumeMovedToFolder?folderId=" + folderid + "&resumeIDs=" + resumeIds + "&UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },

    });

    const data = await res.json();
    return data;
}
const createNewFolder = async (folderid, folderName) => {
    const res = await fetch(baseUrl + "SaveNewFolder?folderId=" + folderid + "&folderName=" + folderName + "&UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },

    });

    const data = await res.json();
    return data;
}
const createNewJobPostingFolder = async (folderId, folderName, jobpostingid) => {
    const folderdata = {
        FolderId: folderId,
        FolderName: folderName,
        UserID: parseInt(AuthService.getCurrentUser().userId),
        JobPostingID: parseInt(jobpostingid)
    }
    const res = await fetch(baseUrl + "JobPostingFolder", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(folderdata)
    });

    const data = await res.json();
    return data;
}

export const AdvancesearchResultService = {
    getAdvanceSearchResult,
    saveAdvanceSearchResult,
    resumeMoveToFolder,
    createNewFolder,
    createNewJobPostingFolder,
    
}