
import { AuthService } from './AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "EmailSend/";
const createNewEmailTemplate = async (tcode, tname, fromEmail, tsubject, tbody) => {
    const templatedata = {
        TemplateCode: tcode,
        TemplateName: tname,
        TemplateFromE: fromEmail,
        TemplateSubject: tsubject,
        TemplateBody: tbody,
        ModifiedBy: parseInt(AuthService.getCurrentUser().userId),
    }
    const res = await fetch(baseUrl + "CreateNewEmailTemplate", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(templatedata)
    });

    const data = await res.json();
    return data;
}
const sendEmailtoCandidate = async (candidateIDs,jobpostingId,templateTo, fromEmail, tsubject, tbody) => {
    const templatedata = {
        CandidateIDs: candidateIDs,
        JobPostingID: jobpostingId,
        TemplateTo: templateTo,
        TemplateFrom: fromEmail,
        TemplateSubject: tsubject,
        TemplateBody: tbody,
        ReferenceID: parseInt(AuthService.getCurrentUser().referenceID),
    }
    const res = await fetch(baseUrl + "SendEmail", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(templatedata)
    });

    const data = await res.json();
    return data;
}
const getEmailTemplate = async () => {
    const res = await fetch(baseUrl + "GetEmailTemplates?UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getTemplateBody = async (code) => {
    const res = await fetch(baseUrl + "GetTemplateBody?Code=" + code, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getTemplateEmailTo = async (candidateIDs) => {
    const res = await fetch(baseUrl + "GetEmailIdByCandidateID?candidateIDs=" + candidateIDs, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getCurrentUserEmailID = async () => {
    const res = await fetch(baseUrl + "GetCurrentUserEmailId?ReferenceID=" + parseInt(AuthService.getCurrentUser().referenceID), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
export const EmailSendService = {
    createNewEmailTemplate,
    getEmailTemplate,
    getTemplateBody,
    getCurrentUserEmailID,
    getTemplateEmailTo,
    sendEmailtoCandidate
}