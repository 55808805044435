import { AuthService } from './AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "CommonDropDown/";

const getSortSearchKeyWords = async () => {
    const res = await fetch(baseUrl + "GetSortSearchKeyWords", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getKeyWordWithin = async () => {
    const res = await fetch(baseUrl + "GetKeyWordWithin", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getResumeLastUpdatedInMonths = async () => {
    const res = await fetch(baseUrl + "GetResumeLastUpdatedInMonths", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getAnyEmployeeType = async () => {
    const res = await fetch(baseUrl + "GetAnyEmployeeType", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getKeywordsList = async (inputText) => {
    if (inputText === "" || inputText === undefined || inputText === null) {
        inputText = "";
    }
    const res = await fetch(baseUrl + "GetKeywordsList?inputText=" + inputText, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getSearchAnyKeywords = async () => {
    const res = await fetch(baseUrl + "GetSearchAnyKeywords", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getEmployeeSearchCondition = async () => {
    const res = await fetch(baseUrl + "GetEmployeeSearchCondition", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getCountry = async () => {
    const res = await fetch(baseUrl + "GetCountry", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getIndustry = async () => {
    const res = await fetch(baseUrl + "GetIndustry", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getFunctionalAreas = async () => {
    const res = await fetch(baseUrl + "GetFunctionalAreas", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getFunctionalAreasSpecializaton = async (functionalAreaId) => {
    const res = await fetch(baseUrl + "GetFunctionalSpecialization?functionalAreaIds=" + functionalAreaId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getInstitutes = async () => {
    const res = await fetch(baseUrl + "GetInstitutes", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getQualification = async () => {
    const res = await fetch(baseUrl + "GetQualification", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getQualificationSpecialization = async (qualificationID) => {
    const res = await fetch(baseUrl + "GetQualificationSpecialization?qualificationIDs=" + qualificationID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getCurrentLocation = async () => {
    const res = await fetch(baseUrl + "GetCurrentLocation", {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getAssignments = async () => {
    const res = await fetch(baseUrl + "GetAssignments?UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getJobPostingsById = async (assignmentId) => {
    const res = await fetch(baseUrl + "GetJobPostings?UserID=" + parseInt(AuthService.getCurrentUser().userId) + "&assignmentID=" + assignmentId, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getFolders = async () => {
    const res = await fetch(baseUrl + "GetMyFolders?UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getClientsByUserID = async () => {
    const res = await fetch(baseUrl + "GetClientsByUserID?UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    let data = await res.json();
    return data;
}
const getAssignmentByUserID = async () => {
    const res = await fetch(baseUrl + "GetAssignmentByUserID?UserID=" + parseInt(AuthService.getCurrentUser().referenceID), {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    let data = await res.json();
    return data;
}
const getSourceByLocation = async (locationID) => {
    const res = await fetch(baseUrl + "GetSource?locationID=" + locationID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getCoOrdinatorByUserID = async () => {
    const res = await fetch(baseUrl + "GetCoOrdinatorsByUserID?UserID=" + parseInt(AuthService.getCurrentUser().userId), {
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    let data = await res.json();
    return data;
}
export const CommonDropDownService = {
    getKeywordsList,
    getSortSearchKeyWords,
    getKeyWordWithin,
    getResumeLastUpdatedInMonths,
    getAnyEmployeeType,
    getSearchAnyKeywords,
    getEmployeeSearchCondition,
    getCountry,
    getIndustry,
    getFunctionalAreas,
    getFunctionalAreasSpecializaton,
    getInstitutes,
    getQualification,
    getQualificationSpecialization,
    getCurrentLocation,
    getAssignments,
    getJobPostingsById,
    getFolders,
    getAssignmentByUserID,
    getClientsByUserID,
    getSourceByLocation,
    getCoOrdinatorByUserID
} 