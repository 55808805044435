import React, { useState, useEffect } from 'react';
import { set, useForm } from "react-hook-form";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CandidateEntryService } from "../../Services/CandidateEntryService";
import Select from 'react-select';
import Layout from '../../Layout/Layout';
import '../../css/3Dgrid.css';
import '../../css/CandidateEntry.css';
import Modal from "react-bootstrap/Modal";
import Button from 'react-bootstrap/Button';
import CurrencyInput from 'react-currency-input-field';
import { AuthService } from '../../Services/AuthService';
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { saveAs } from 'file-saver';
function CandidateEntry() {
  const navigate = useNavigate();
  const search = useLocation().search;
  const loc = useLocation();
  const qString = loc.state ? loc.state.qst : null;
  const [openLoader, setopenLoader] = React.useState(false);
  const [countryList, setCountryList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [qualificationList, setQualificationList] = useState([]);
  const [qualificationSpecializationList, setQualificationSpecializationList] = useState([]);
  const [instituteList, setInstituteList] = useState([]);
  const [nationalityList, setNationalityList] = useState([]);
  const [religionList, setReligionList] = useState([]);
  const [maritalStatusList, setMaritalStatusList] = useState([]);
  const [functionalAreaList, setfunctionalAreaList] = useState([]);
  const [functionalAreaSpecializationList, setfunctionalAreaSpecializationList] = useState([]);
  const [IndustryList, setIndustryList] = useState([]);
  //For dropdown-selected state constants
  const [formCountry, setFormCountry] = useState([]);
  const [FormLocation, setFormLocation] = useState([]);
  const [formQualification, setFormQualification] = useState([]);
  const [formQualificationSpecialization, setFormQualificationSpecialization] = useState([]);
  const [formNationality, setFormNationality] = useState([]);
  const [formInstitute, setFormInstitute] = useState([]);
  const [formReligion, setFormReligion] = useState([]);
  const [formMaritalStatus, setFormMaritalStatus] = useState([]);
  const [selectedOptionForFunctionalArea, setselectedOptionForFunctionalArea] = useState([]);
  const [selectedOptionForFunctionalAreaSpecialization, setselectedOptionForFunctionalAreaSpecialization] = useState([]);
  const [hasFunctionalAreaShowError, sethasFunctionalAreaShowError] = useState(false);
  const [formIndustry, setFormIndustryList] = useState([]);
  //table data show
  const [showQualification, setshowQualification] = useState("");
  const [showQualificationSpecialization, setshowQualificationSpecialization] = useState("");
  const [showInstitute, setshowInstitute] = useState("");
  const [showPassingYear, setshowPassingYear] = useState("");
  const [items, setitems] = useState([]);

  const [iconClass, seticonClass] = useState("");
  const { register, setValue, clearErrors, watch, formState: { errors }, handleSubmit } = useForm();
  const [GenderIdValue, setGenderIdValue] = useState([]);
  //modal section
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [modalTitle, setmodalTitle] = useState("Add");
  //mandatory field
  const maxFileSize = 1024 * 300;
  const [isshowDateOFBirthErr, setIsshowDateOFBirthErr] = useState(false);
  const [logInIdValue, setlogInIdValue] = useState("");
  const [formCandidateName, setFormCandidateName] = useState([]);
  const [formEmailId, setFormEmailId] = useState([]);
  const [formMobileNumber, setFormMobileNumber] = useState([]);
  const [Formpassportnumber, SetFormpassportnumber] = useState([]);
  const [formkeyskill, setFormkeyskill] = useState([]);
  const [experienceMonth, setexperienceMonth] = useState(0);
  const [experienceYear, setexperienceYear] = useState(0);
  const [isFileValid, setisFileValid] = useState(true);
  const [fileValidationErrorMessage, setfileValidationErrorMessage] = useState("");
  const [formResumeTitle, setFormResumeTitle] = useState([]);
  const [formDateOfBirth, setFormDateOfBirth] = useState([]);
  const [maxDate, setmaxDate] = useState("");
  const [formGender, setFormGender] = useState([]);


  const [Qualifications, setQualifications] = useState("");
  const [QualificationSpecialization, setQualificationSpecialization] = useState("");
  const [passingYear, setPassingYear] = useState(0);
  const [editQualificationID, seteditQualificationID] = useState("");
  const [qItems, setqItems] = useState([]);
  //error constants
  const [hasQualificationShowError, sethasQualificationShowError] = useState(false);
  const [hasQualificationSpecializationShowError, sethasQualificationSpecializationShowError] = useState(false);
  const [showLoginIDErr, setshowLoginIDErr] = useState(false);

  const [isResumeDlbtnShow, setisResumeDlbtnShow] = useState(false);
  const [requiredForResumeError, setrequiredForResumeError] = useState("");
  const [EncResumeID, setEncResumeID] = useState("");
  const BindtextreadData = (qsText) => {
    if (qsText) {

      //Extract gmail Section
      var gmail = qsText.match(/\S+@[^\s.]+\.[^.\s]+/);
      let gmailId = gmail[0];
      setFormEmailId(gmailId);
      //Extract gmail Section End

      //Finding Words from pdf/img Section
      var patt1 = /(\w+)/g;
      var word = qsText.match(patt1);
      //Finding Words from pdf/img Section end
      //Extract maritalstatus Section
      var maritalstatus = [];
      let mstring = [{ name: "married" }, { name: "Married" }, { name: "unmarried" }, { name: "Unmarried" }, { name: "Divorced" }, { name: "divorced" }, { name: "Separated" }, { name: "Widowed" }, { name: "widowed" }];
      word.filter(function (f) {
        for (let i = 0; i < mstring.length; i++) {
          if (f === mstring[i].name) {
            maritalstatus.push({ label: f });
          }
        }
      })
      //setmaritalstatus(maritalstatus[0].label);
      //Extract maritalstatus Section End
      console.log(maritalstatus);
      //Extract nationality Section
      var nationality = [];
      let nationalies = [{ name: "Indian" }, { name: "INDIAN" }, { name: "indian" }];
      word.filter(function (f) {
        for (let i = 0; i < nationalies.length; i++) {
          if (f === nationalies[i].name) {
            nationality.push({ label: f });
          }
        }
      })
      //setnationality(nationality[0].label);
      //Extract nationality Section End
      console.log(nationality);
      //Extract religion Section
      var religion = [];
      let religions = [{ name: "Hindu" }, { name: "hindu" }, { name: "Islam" }, { name: "islam" }, { name: "Muslim" }, { name: "muslim" }, { name: "islam" }, { name: "Musalman" }, { name: "musalman" }, { name: "Christian" }, { name: "christian" }];
      word.filter(function (f) {
        for (let i = 0; i < religions.length; i++) {
          if (f === religions[i].name) {
            religion.push({ label: f });
          }
        }
      });
      const canreligion = [];
      religionList.filter(function (filter) {
        console.log(filter);
        for (let i = 0; i < filter.length; i++) {
          if (filter[i].label === religion[0].label) {
            canreligion.push({ label: filter.label, value: filter.value });
          }
        }
      });
      console.log(canreligion);
      //setFormReligion(religion[0].label);
      //Extract religion Section End
      console.log(religionList);
      
      // Extract phoneNo Section Start
      var re = /(?:[-+() ]*\d){10,13}/gm;
      var phoneNo = qsText.match(re).map(function (s) { return s.trim(); });
      let phNo = parseInt(phoneNo[0]);
      setFormMobileNumber(phNo);
      // Extract phoneNo Section End
      console.log(phNo);
      //Extract passportNumber Section
      var passportNumber = qsText.match(/\b[a-zA-Z0-9]{2}[0-9]{6,7}\b/g);
      var PassNo=passportNumber[0]
      SetFormpassportnumber(PassNo);
      //Extract passportNumber Section End
      console.log(passportNumber);

    }
  }

  const addQualification = () => {
    setValue("ID", 0);
    setValue("Qualification", "");
    setValue("QualificationSpecialization", "");
    setValue("Institute", "");
    setValue("PassingYear", "");
    clearErrors("Qualification");
    clearErrors("QualificationSpecialization");
    clearErrors("Institute");
    clearErrors("PassingYear");
    setmodalTitle("Add");
    seticonClass("fa fa-plus icon-color1");
    handleShow();
  }

  const handleLoginIdOnChange = (e) => {
    let val = e.target.value;
    if (val !== null && val !== "" && val === undefined) {
      setlogInIdValue(val);
      setshowLoginIDErr(false);
    } else {
      setshowLoginIDErr(true);
      setlogInIdValue(null);
    }
  }
  const handleonclickCountry = (e) => {
    if (e.target.value) {
      setFormCountry(e.target.value);
    } else {
      setFormCountry(undefined);
    }
  }
  const handleonclickLocation = (e) => {
    if (e.target.value) {
      setFormLocation(e.target.value);
    } else {
      setFormLocation(undefined);
    }
  }
  const handleonclickQualification = (e) => {
    let val = e.target.value;

    if (val !== null && val !== "" && val !== undefined) {
      getQualificationSpecializationList(val);
      sethasQualificationShowError(false);
    } else {
      setQualificationSpecializationList([]);
      sethasQualificationShowError(true);
    }
  }
  const getQualificationSpecializationList = async (qualificationId) => {
    const getQSpecializations = await CandidateEntryService.GetQualificationSpecializationDropDown(qualificationId);
    setQualificationSpecializationList(getQSpecializations);
  }
  const handleonclickQualificationSpecialization = (e) => {
    let val = e.target.value;
    if (val !== "" && val !== undefined && val !== null) {
      sethasQualificationSpecializationShowError(false);
    } else {
      sethasQualificationSpecializationShowError(true);
    }
  }
  const handleonclickNationality = (e) => {
    if (e.target.value) {
      setFormNationality(e.target.value);
    } else {
      setFormNationality(undefined);
    }
  }
  const handleonclickInstitute = (e) => {
    let val = e.target.value;
    const instituteID = val.split("$");
    if (instituteID) {
      setFormInstitute(instituteID[0]);
      setshowInstitute(instituteID[1]);
    } else {
      setFormInstitute(undefined);
    }
  }
  const handleYearofPassing = (e) => {
    let val = e.target.value;
    if (val) {
      setPassingYear(val);
    } else {
      setPassingYear(0);
    }
  }
  const handleonclickReligion = (e) => {
    if (e.target.value) {
      setFormReligion(e.target.value);
    } else {
      setFormReligion(undefined);
    }
  }
  const handleonclickMaritalStatus = (e) => {
    if (e.target.value) {
      setFormMaritalStatus(e.target.value);
    } else {
      setFormMaritalStatus(undefined);
    }
  }
  const handleSelectFunctionalArea = (selectedOption) => {
    let functionalAreaId = [];
    if (selectedOption.length > 0) {
      if (selectedOption.length <= 5) {
        setselectedOptionForFunctionalArea(selectedOption);
        sethasFunctionalAreaShowError(false);
        for (let i = 0; i < selectedOption.length; i++) {
          functionalAreaId.push(selectedOption[i].value);
        }
        let fnids = "";
        for (let i = 0; i < functionalAreaId.length; i++) {
          fnids += functionalAreaId[i] + ",";

        }
        getFunctionalAreaSpecializationDDList(fnids, null)
      }
    } else {
      functionalAreaId = [];
      sethasFunctionalAreaShowError(true);
      setselectedOptionForFunctionalArea(undefined);
      setselectedOptionForFunctionalArea("");
      setfunctionalAreaSpecializationList(functionalAreaId);
    }
  }
  const getFunctionalAreaSpecializationDDList = async (functionalAreaId, parmFNSP) => {
    const getFunctionalAreaSpecializationList = await CandidateEntryService.GetFunctionalAreaSpecializationDropDownList(functionalAreaId);
    getFunctionalAreaSpecializationList ? setfunctionalAreaSpecializationList(getFunctionalAreaSpecializationList) : setfunctionalAreaSpecializationList([]);
    if (parmFNSP) {
      let fnSp = [];
      const fnspIds = parmFNSP.split(",");
      const filterFnSp = (fnsp) => {
        fnspIds.filter(function (d) {
          if (d === fnsp.value) {
            fnSp.push(fnsp);
          }
        })
      }
      getFunctionalAreaSpecializationList.filter(function (d) {
        filterFnSp(d);
      })
      setselectedOptionForFunctionalAreaSpecialization(fnSp);
    }
  }
  const handleSelectFunctionalAreaSpecialization = (selectedOption) => {
    if (selectedOption.length > 0) {
      if (selectedOption.length <= 5) {
        setselectedOptionForFunctionalAreaSpecialization(selectedOption);
      }
    } else {
      setselectedOptionForFunctionalAreaSpecialization(undefined);
    }
  }
  const handleonclickIndustryList = (e) => {
    if (e.target.value) {
      setFormIndustryList(e.target.value);
    } else {
      setFormIndustryList(undefined);
    }
  }
  const handleGender = (e) => {
    let val = (e.target.value);
    if (val != null) {
      setGenderIdValue(val);
    } else {
      setGenderIdValue("");
    }
  }
  //mandatory field
  const handleonclickCandidateName = (e) => {
    if (e.target.value) {
      setFormCandidateName(e.target.value);
    } else {
      setFormCandidateName(undefined);
    }
  }
  const handleonclickEmailId = (e) => {
    if (e.target.value) {
      setFormEmailId(e.target.value);
    } else {
      setFormEmailId(undefined);
    }
  }
  const handleonclickMobileNumber = (e) => {
    if (e.target.value) {
      setFormMobileNumber(e.target.value);
    } else {
      setFormMobileNumber(undefined);
    }
  }
  const handleonclickkeyskill = (e) => {
    if (e.target.value) {
      setFormkeyskill(e.target.value);
    } else {
      setFormkeyskill(undefined);
    }
  }
  const handleExperienceYearChange = (e) => {
    let value = e.target.value;
    if (value >= 35) {
      setexperienceYear(35);
    } else {
      setexperienceYear(value);
    }
  }
  const handleExperienceMonthChange = (e) => {
    let value = e.target.value;
    if (value >= 11) {
      setexperienceMonth(11);
    } else {
      setexperienceMonth(value);
    }
  }
  const handleResumeUploadChange = (e) => {
    let file = e.target.files[0];
    if (file) {
      if (file.size > 0) {
        if (file.size > maxFileSize) {
          setisFileValid(false);
          setfileValidationErrorMessage("Resume size must be less than 300kb");
          return;
        }
        let filename = file.name;
        let fileExt = filename.split('.').pop().toLowerCase();
        if (fileExt === 'doc' || fileExt === "docx" || fileExt === "pdf") {
          setisFileValid(true);
          setfileValidationErrorMessage("");
        } else {
          setisFileValid(false);
          setfileValidationErrorMessage("Resume should be in .doc,.docx or .pdf format");
        }
      }
    } else {
      setisFileValid(false);
    }
  }
  const handleonclickDateOfBirth = (e) => {
    let val = e.target.value;
    if (val != null) {
      let datePickerId = document.getElementById('dateId').max = new Date(new Date().getTime() - new Date().getTimezoneOffset() * 60000).toISOString().split("T")[0];
      setmaxDate(datePickerId);
      setFormDateOfBirth(e.target.value);
      setIsshowDateOFBirthErr(false);
    } else {
      setFormDateOfBirth("");
      setIsshowDateOFBirthErr(true);
    }
  }
  const handleonclickGender = (e) => {
    if (e.target.value) {
      setFormGender(e.target.value);
    } else {
      setFormGender(undefined);
    }
  }
  const handleOverseasExperience = (e) => {
    if (document.getElementById('hideoverseasExperience').checked) {
      document.getElementById("overseasDesc").style.display = 'block';
    } else {
      document.getElementById("overseasDesc").style.display = 'none';
    }
  }
  const ShowAlert = (type, message) => {
    if (type === 1) {
      toast.success(message, {
        toastId: '',
      })
    } else {
      toast.error(message, {
        toastId: '',
      })
    }
  }

  const handleFormSubmit = async (formData) => {
    //setopenLoader(true);
    let isResumeValid = false;
    if (isResumeDlbtnShow) {
      isResumeValid = true;
    } else {
      if (formData.resume[0] === undefined || formData.resume[0] === null || formData.resume[0] === "") {
        isResumeValid = false;
      }
    }
    if (!isResumeValid) {
      setrequiredForResumeError("please select resume");
      window.scrollTo({
        top: 210,
        behavior: 'smooth'
      });
      return false;
    }else{
      setrequiredForResumeError("");
    }
    let formData1 = new FormData();
    formData1.append("CurrentUserID", parseInt(AuthService.getCurrentUser().userId));
    formData1.append("ReferenceID", parseInt(AuthService.getCurrentUser().referenceID));
    formData1.append("LoginID", formData.loginID);
    formData1.append("CandidateName", formData.candidateName);
    formData1.append("EmailID", formData.email);
    formData1.append("CountryIDForMobile", formData.country);
    formData1.append("MobileNumber", formData.mobilenumber);
    formData1.append("ContactNumber", formData.contactnumber);
    formData1.append("CurrentLocationID", parseInt(formData.location));
    formData1.append("PresentAddressDetails", formData.presentaddress);
    formData1.append("KeySkil", formData.keyskill);
    formData1.append("ResumeTitle", formData.resumetitle);
    formData1.append("ExperienceYear", formData.experienceYear);
    formData1.append("ExperienceMonth", formData.experienceMonth);
    formData1.append("Resume", formData.resume[0]);
    // formData1.append("Qualification", formQualification);
    // formData1.append("QualificationSpecialization", formQualificationSpecialization);
    // formData1.append("Institute", formData.institute);
    // formData1.append("YearofPassing", formData.yearofpassing);
    formData1.append("NationalityID", parseInt(formData.Nationality));
    formData1.append("ReligionID", parseInt(formData.religion));
    formData1.append("DateOfBirth", formData.DOB);
    formData1.append("Gender", formGender);
    formData1.append("MaritalStatusID", parseInt(formData.maritalstatus));
    formData1.append("Photo", formData.photo[0]);
    let functionalareaIds = [];
    for (let i = 0; i < selectedOptionForFunctionalArea.length; i++) {
      functionalareaIds.push(selectedOptionForFunctionalArea[i].value);
    }
    formData1.append("FunctionalArea", functionalareaIds);
    let funcSpecializationIds = [];
    for (let i = 0; i < selectedOptionForFunctionalAreaSpecialization.length; i++) {
      funcSpecializationIds.push(selectedOptionForFunctionalAreaSpecialization[i].value);
    }
    formData1.append("FunctionalAreaSpecialization", funcSpecializationIds);
    formData1.append("PassportNumber", formData.passportnumbernumber);
    formData1.append("PassportDetails", formData.passportdetails);
    formData1.append("CurrentIndustryID", parseInt(formData.industry));
    formData1.append("CurrentEmployer", formData.currentemployer);
    formData1.append("PreviousEmployer", formData.previousemployer);
    let annualSalary = formData.currentyearlysalary.split("$");
    formData1.append("CurrentYearlySalary", annualSalary[1]);
    formData1.append("MaskSalary", formData.maskSalary);
    formData1.append("OverseasExp", formData.overseasExperience);
    formData1.append("OverseasExperience", formData.overseasDetails);
    formData1.append("TermsCondition", formData.acceptTermandCondition);
    const res = await CandidateEntryService.saveCandidateData(formData1);
    try {
      if (res.data.isSuccess) {
        ShowAlert(1, res.data.message);
      } else {
        ShowAlert(0, res.data.message);
      }
    } catch (error) {
      ShowAlert(0, error);
      setopenLoader(false);
    }
  }

  const SaveOrEditQualification = () => {
    let errCount = 0;

    let qualification = document.getElementById("qualifications");
    let qualificationid = qualification.options[qualification.selectedIndex].value;
    let qualificationtext = qualification.options[qualification.selectedIndex].text;


    let qualificationSpecialization = document.getElementById("Qspecialization");
    let qualificationSpecializationid = qualificationSpecialization.options[qualificationSpecialization.selectedIndex].value;
    let qualificationSpecializationText = qualificationSpecialization.options[qualificationSpecialization.selectedIndex].text;


    if (qualificationid === null || qualificationid === undefined || qualificationid === "") {
      sethasQualificationShowError(true);
      errCount += 1;
    } else {
      sethasQualificationShowError(false);
    }
    if (qualificationSpecializationid === null || qualificationSpecializationid === undefined || qualificationSpecializationid === "") {
      sethasQualificationSpecializationShowError(true);
      errCount += 1;
    } else {
      sethasQualificationSpecializationShowError(false);
    }
    if (errCount > 0) {
      return false;
    }
    setQualifications(qualificationid);
    setQualificationSpecialization(qualificationSpecializationid);

    let institutes = document.getElementById("Qinstitute");
    let institutesid = 0;
    let yearofpassingValue = "";
    let institutesText = "";
    if (institutes !== "" && institutes !== null && institutes !== undefined) {
      institutesid = institutes.options[institutes.selectedIndex].value;
      institutesText = institutes.options[institutes.selectedIndex].text;
    }
    if (passingYear !== "" && passingYear !== undefined && passingYear !== null && passingYear !== 0) {
      yearofpassingValue = passingYear;
    }
    let autoID = 0;
    let countID = [];
    let dataEl = [];
    let ID = editQualificationID;
    if (ID && ID !== "") {
      autoID = parseInt(editQualificationID);
    }
    if (qItems.length > 0 && autoID === 0) {
      for (let i = 0; i < qItems.length; i++) {
        countID.push(qItems[i].id)
      }
      var maxValues = Math.max(...countID);
      autoID = maxValues + 1;

    } else if (autoID !== 0) {

    } else {
      autoID = 1;
    }
    const checkIsEditTbl = (parmAutoID) => {
      let isEdit = false;
      if (qItems.length > 0) {
        for (let i = 0; i < qItems.length; i++) {
          if (parmAutoID === qItems[i].id) {
            isEdit = true;
          }
        }
      } else {
        isEdit = false;
      }
      return isEdit;
    }
    const isAlreadyExistQual = (parmQualID, isEdit, prevQualID) => {
      let exist = false;
      if (isEdit) {
        if (parmQualID !== prevQualID) {
          if (qItems.length > 0) {
            for (let i = 0; i < qItems.length; i++) {
              if (qItems[i].qualID === prevQualID) {
                exist = true;
              }
            }
          }
        }
      } else {
        if (qItems.length > 0) {
          for (let i = 0; i < qItems.length; i++) {
            if (qItems[i].qualID === parmQualID) {
              exist = true;
            }
          }
        }
      }

      return exist;
    }
    if (!checkIsEditTbl(autoID)) {
      if (isAlreadyExistQual(qualificationid, false, null)) {
        alert("qualification already exist");
        return false;
      }
      dataEl.push({
        id: autoID,
        qual: qualificationtext,
        qualID: qualificationid,
        spec: qualificationSpecializationText,
        specID: qualificationSpecializationid,
        insti: institutesid === "" ? "" : institutesText,
        instiID: institutesid,
        year: yearofpassingValue
      });
      if (qItems.length > 0) {
        for (let i = 0; i < qItems.length; i++) {
          dataEl.push(qItems[i]);
        }
        setqItems([]);
      }
      setqItems(dataEl);
    } else {
      let allItems = [];
      let prevQualID = "";
      for (let i = 0; i < qItems.length; i++) {
        if (autoID !== qItems[i].id) {
          allItems.push(qItems[i]);
        }
        if (qItems[i].id === autoID) {
          prevQualID = qItems[i].qualID
        }
      }
      if (isAlreadyExistQual(qualificationid, true, prevQualID)) {
        alert("qualification already exist");
        return false;
      }
      setqItems([]);
      dataEl.push({
        id: autoID,
        qual: qualificationtext,
        qualID: qualificationid,
        spec: qualificationSpecializationText,
        specID: qualificationSpecializationid,
        insti: institutesid === "" ? "" : institutesText,
        instiID: institutesid,
        year: yearofpassingValue
      });
      if (allItems.length > 0) {
        for (let i = 0; i < allItems.length; i++) {
          dataEl.push({
            id: allItems[i].id,
            qual: allItems[i].qual,
            qualID: allItems[i].qualID,
            spec: allItems[i].spec,
            specID: allItems[i].specID,
            insti: allItems[i].insti,
            instiID: allItems[i].instiID,
            year: allItems[i].year
          });

        }
      }
      setqItems(dataEl);
    }
    handleClose();

  }
  const handleEditQualification = async (id) => {
    seteditQualificationID(id);
    sethasQualificationShowError(false);
    sethasQualificationSpecializationShowError(false);
    for (let i = 0; i < qItems.length; i++) {
      if (qItems[i].id === id) {
        setValue("ID", qItems[i].id);
        setValue("qualification", qItems[i].qualID);
        getQualificationSpecializationList(qItems[i].qualID);
        setTimeout(() => {
          setValue("qualificationspecialization", qItems[i].specID);
        }, 300);
        setValue("institute", qItems[i].instiID);
        setValue("yearofpassing", qItems[i].year);
        handleShow();
      }
    }

  }
  const handleDeleteQualification = async (id) => {
    let tmpData = [];
    for (let i = 0; i < qItems.length; i++) {
      if (qItems[i].id !== id) {
        tmpData.push(qItems[i]);
      }
    }
    setqItems(tmpData);
  }
  const handleQualificationModalClose = () => {
    sethasQualificationShowError(false);
    sethasQualificationSpecializationShowError(false);
    setShow(false);
  }


  ///download file

  const handleShowResumeClick = async () => {
    setopenLoader(true);
    const res = await CandidateEntryService.getCandidateResumeForDownload(EncResumeID);
    let type = "application/" + res.fileName.split('.').pop().toLowerCase();
    downloadFile(res.apiData, res.fileName, type);
    setopenLoader(false);
  }
  const downloadFile = (base64, fileName, type) => {
    const sampleArr = base64ToArrayBuffer(base64);
    saveByteArray(fileName, sampleArr, type);
    setopenLoader(false);
  }
  const base64ToArrayBuffer = (base64) => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };
  const saveByteArray = (fileName, byte, type) => {
    const blob = new Blob([byte], { type: type });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = fileName;
    link.click();
  };

  ///end download file
  useEffect(() => {
    setrequiredForResumeError("");
    const initcountrylist = async () => {
      const country = await CandidateEntryService.GetCountryDropDown();
      country ? setCountryList(country) : setCountryList({});
    }
    const initlocationlist = async () => {
      const location = await CandidateEntryService.GetCurrentLocationDropDown();
      location ? setLocationList(location) : setLocationList({});
    }
    const initQualificationlist = async () => {
      const qualification = await CandidateEntryService.GetQualificationDropDown();
      qualification ? setQualificationList(qualification) : setQualificationList({});
    }
    const initQualificationSpecializationlist = async () => {
      const qualificationSpecialization = await CandidateEntryService.GetQualificationSpecializationDropDown();
      qualificationSpecialization ? setQualificationSpecializationList(qualificationSpecialization) : setQualificationSpecializationList({});
    }
    const initInstituteList = async () => {
      const institute = await CandidateEntryService.GetInstituteDropDown();
      institute ? setInstituteList(institute) : setInstituteList({});
    }
    const initNationalityList = async () => {
      const nationality = await CandidateEntryService.GetNationalityDropDown();
      nationality ? setNationalityList(nationality) : setNationalityList({});
    }
    const initReligionList = async () => {
      const religion = await CandidateEntryService.GetReligionDropdown();
      religion ? setReligionList(religion) : setReligionList({});
    }
    const initMaritalStatusList = async () => {
      const maritalStatus = await CandidateEntryService.GetMaritalStatusDropDown();
      maritalStatus ? setMaritalStatusList(maritalStatus) : setMaritalStatusList({});
    }
    const initFunctionalAreaList = async (parmFN, parnFNSP) => {
      const functionalArea = await CandidateEntryService.GetFunctionalAreaDropDownList();
      functionalArea ? setfunctionalAreaList(functionalArea) : setfunctionalAreaList({});
      if (parmFN) {
        const funAres = parmFN.split(",");
        let selectedFunAres = [];
        let fnIDs = "";
        const filterFnAres = (fnArea) => {
          funAres.filter(function (d) {
            if (d === fnArea.value) {
              selectedFunAres.push(fnArea);
              fnIDs += d + ",";

            }
          })
        }
        functionalArea.filter(function (d) {
          filterFnAres(d);
        });
        setselectedOptionForFunctionalArea(selectedFunAres);
        getFunctionalAreaSpecializationDDList(fnIDs, parnFNSP);

      }
    }
    // const initFunctionalAreaSpecializationList = async () => {
    //   const functionalAreaSpecialization = await CandidateEntryService.GetFunctionalAreaSpecializationDropDownList();
    //   functionalAreaSpecialization ? setFunctionalAreaSpecializationList(functionalAreaSpecialization) : setFunctionalAreaSpecializationList({});
    // }
    if (qString) {
      BindtextreadData(qString);
    }
    const initIndustryList = async () => {
      const industry = await CandidateEntryService.GetCurrentIndustryDropDown();
      industry ? setIndustryList(industry) : setIndustryList({});
    }
    const IsNullOrEmpty = (val) => {
      if (val === null || val === "" || val === undefined || val === 0 || val === "0") {
        return true;
      } else {
        return false;
      }
    }

    if (AuthService.isAuthenticatedUser()) {

      initcountrylist();
      initlocationlist();
      initQualificationlist();
      initQualificationSpecializationlist();
      initInstituteList();
      initNationalityList();
      initReligionList();
      initMaritalStatusList();
      //initFunctionalAreaSpecializationList();
      const initGetCandidateByIDFromQS = async (qs) => {
        try {
          setopenLoader(true);
          const candidateData = await CandidateEntryService.getCandidateByID(qs);
          console.log(candidateData);
          if (candidateData.isSuccess) {
            setEncResumeID(candidateData.encResumeID);
            setisResumeDlbtnShow(true);
            //other details
            setValue("loginID", candidateData.loginID);
            setValue("candidateName", candidateData.candidateName);
            setValue("email", candidateData.emailID);
            setValue("country", candidateData.countryIDForMobile);
            setValue("mobilenumber", candidateData.mobileNumber);
            setValue("contactnumber", candidateData.contactNumber);
            setValue("location", candidateData.currentLocationID);
            setValue("presentaddress", candidateData.presentAddressDetails);
            setValue("keyskill", candidateData.keySkil);
            setValue("resumetitle", candidateData.resumeTitle);
            if (!IsNullOrEmpty(candidateData.experienceYear)) {
              setexperienceYear(parseInt(candidateData.experienceYear));
              setValue("experienceYear",parseInt(candidateData.experienceYear));
            }
            if (!IsNullOrEmpty(candidateData.experienceMonth)) {

              setexperienceMonth(parseInt(candidateData.experienceMonth));
            }
            setValue("Nationality", candidateData.nationalityID);
            setValue("religion", candidateData.religionID);
            if (!IsNullOrEmpty(candidateData.dateOfBirth)) {
              setValue("DOB", candidateData.dateOfBirth);
            }
            if (candidateData.gender) {
              const radioGenEl = document.getElementsByClassName('rdGen');
              setValue("gender", setFormGender(candidateData.gender));
              for (let i = 0; i < radioGenEl.length; i++) {
                if (radioGenEl[i].defaultValue === candidateData.gender) {
                  radioGenEl[i].checked = true;
                }
              }
            }

            setValue("maritalstatus", candidateData.maritalStatusID);
            if (!IsNullOrEmpty(candidateData.functionalArea)) {
              initFunctionalAreaList(candidateData.functionalArea, candidateData.functionalAreaSpecialization);
            }
            setValue("passportnumbernumber", candidateData.passportNumber);
            setValue("passportdetails", candidateData.passportDetails);
            setValue("industry", candidateData.currentIndustryID);
            setValue("currentemployer", candidateData.currentEmployer);
            setValue("previousemployer", candidateData.previousEmployer);
            setValue("currentyearlysalary", candidateData.currentYearlySalary);
            setValue("maskSalary", candidateData.maskSalary);

            const chkOverSeasEl = document.getElementsByClassName('overseasClass');
            chkOverSeasEl[0].checked = candidateData.overseasExp;
            if (candidateData.overseasExp) {
              document.getElementById("overseasDesc").style.display = 'block';
              setValue("overseasDetails", candidateData.overseasExperience);
            } else {
              document.getElementById("overseasDesc").style.display = 'none';
            }
            //qualifications
            if (candidateData.qualifications) {
              if (candidateData.qualifications.length > 0) {
                let qualifications = [];
                for (let i = 0; i < candidateData.qualifications.length; i++) {
                  qualifications.push({
                    id: i,
                    qualID: candidateData.qualifications[i].qualificationID,
                    qual: candidateData.qualifications[i].qualificationName,
                    specID: candidateData.qualifications[i].qualificationSpecializationID,
                    spec: candidateData.qualifications[i].qualificationSpecializationName,
                    instiID: candidateData.qualifications[i].qualificationInstituteID,
                    insti: candidateData.qualifications[i].qualificationInstituteName,
                    year: candidateData.qualifications[i].qualificationYearOfPassing
                  })
                }
                setqItems(qualifications);
              }
            }
          } else {
            ShowAlert(0, candidateData.message)
          }

          setopenLoader(false);
        } catch (error) {
          setopenLoader(false);
        }
        setopenLoader(false);
      }
      initIndustryList();
      const qs = new URLSearchParams(search).get('qs');
      if (qs) {
        initGetCandidateByIDFromQS(qs);
      } else {
        initFunctionalAreaList();
      }

    } else {
      const qs = new URLSearchParams(search).get('qs');
      let returnUrl = "?ReturnUrl=/candidateentry";
      if (qs) {
        returnUrl += "&qs=" + qs;
      }
      navigate({
        pathname: "/login",
        search: returnUrl
      })
    }

  }, []);
  return (
    <div>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openLoader}

      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Modal
        show={show}
        backdrop="static"
        keyboard={false}
        style={{ marginTop: "5rem" }}
      >
        <Modal.Header>
          <Modal.Title>
            <span className='text-primary'><i className={iconClass} aria-hidden="true"></i> QUALIFICATION DETAILS</span>
          </Modal.Title>
          <button onClick={() => handleQualificationModalClose()} type="button" className="close">
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div className='row mb-2'>
            <input type="hidden" name="ID"
              {...register("ID", {
              })}
            />
            <div className='col-5 mt-1'>
              <label className='control-label'>Qualification <span className='text-danger'>*</span>:</label>
            </div>
            <div className='col-7 mt-1'>
              <select id='qualifications' name='qualification' className='form-control'
                {...register("qualification", {
                  onChange: (e) => { handleonclickQualification(e) }
                })}
              >
                <option value="">-- select ---</option>
                {
                  qualificationList.map((item, i) =>
                    <option key={i} value={item.value} >{item.label}</option>
                  )
                }
              </select>
              {hasQualificationShowError ? <p className='text-danger mt-1'>Please select qualification</p> : null}
            </div>

            <div className='col-5 mt-1'>
              <label className='control-label'>Specialization <span className='text-danger'>*</span>:</label>
            </div>
            <div className='col-7 mt-1'>
              <select id='Qspecialization' name='qualificationspecialization' className='form-control'
                {...register("qualificationspecialization", {
                  onChange: (e) => { handleonclickQualificationSpecialization(e) }
                })}
              >
                <option value="">-- select ---</option>
                {
                  qualificationSpecializationList.map((item, i) =>
                    <option key={i} value={item.value}>{item.label}</option>
                  )
                }
              </select>
              {hasQualificationSpecializationShowError ? <p className='text-danger mt-1'>Please select specialization</p> : null}
            </div>
            <div className='col-5 mt-1'>
              <label className='control-label'>Institute  :</label>
            </div>
            <div className='col-7 mt-1'>
              <select id='Qinstitute' className='form-control' name='institute'
                {...register("institute", {
                  onChange: (e) => { handleonclickInstitute(e) }
                })}
              >
                <option value=""> --- Select Institute ---</option>
                {
                  instituteList.map((item, i) =>
                    <option key={i} value={item.value}>{item.label}</option>
                  )
                }
              </select>

            </div>
            <div className='col-5 mt-1'>
              <label className='control-label'>Year of Passing :</label>
            </div>
            <div className='col-7 mt-1'>
              <input type="number" id='yearpassing' name='yearofpassing' className='form-control' min={1940}
                {...register("yearofpassing", {
                  onChange: (e) => { handleYearofPassing(e) }
                })}
              />
            </div>
          </div>
          <div className='float-right'>
            <Button variant="danger" onClick={() => handleQualificationModalClose()}>
              <i className="fa fa-times icon-color" aria-hidden="true"></i> Close
            </Button>
            &nbsp;
            <button className="btn btn-primary" onClick={() => SaveOrEditQualification()} >
              <i className="fa fa-floppy-o icon-color" aria-hidden="true"></i> Save
            </button>
          </div>

        </Modal.Body>

      </Modal>
      <Layout>
        <div className='container mt-4' id='grid'>
          <form method='POST' onSubmit={handleSubmit(handleFormSubmit)}>
            {/* LOGIN DETAILS AREA */}
            <div className='row'>
              <input type='hidden' name='ID'   {...register("ID")} />
              <div className='mt-3 ml-2 heading'>LOGIN DETAILS</div>
              <hr style={{ backgroundColor: "#1976D2", width: "1095px" }} />
              <div className='col-2'><label className='control-label font-13'>Login ID <span id='red'>*</span> :</label></div>
              <div className='col-4'>
                <input className='form-control' name='loginID' type="text" placeholder="Login ID"
                  {...register("loginID", {
                    required: true,
                    minLength: 3,
                    pattern: {
                      value: /^[a-zA-Z0-9_.]+$/,
                      message: " Login ID should not include space and special character. You can use . and _ ."
                    },
                    onChange: (e) => { handleLoginIdOnChange(e) }
                  })}
                />
                {errors.loginID?.type === 'required' && <p className='text-danger mt-1'>Please enter login id</p>}
                {errors.loginID?.type === 'minLength' && <p className='text-danger mt-1'>Enter minimum three characters, not containing spaces.You can use . and _</p>}
                {/* {showLoginIDErr ? <p className='text-danger mt-1'>Please enter the Login ID</p> : " "} */}
              </div>
              <div className='col-6'></div>
            </div>
            {/* CONTACT DETAILS AREA */}
            <div className='row'>
              <div className='mt-3 ml-2 heading'>CONTACT DETAILS</div>
              <hr style={{ backgroundColor: "#1976D2", width: "1095px" }} />
              <div className='col-2'><label className='control-label font-13'>Name <span id='red'>* </span> :</label></div>
              <div className='col-4'>
                <input className='form-control'
                  name='candidateName'
                  type="text"
                  placeholder="Name"
                  {...register("candidateName", {
                    required: true,
                    onChange: (e) => { handleonclickCandidateName(e) }
                  })}
                />
                {errors.candidateName?.type === 'required' && <p className='text-danger mt-1'>Please enter the name</p>}
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-1'><label className='control-label font-13'>Email ID <span id='red'>* </span> :</label></div>
              <div className='col-4 mt-1'>
                <input className='form-control' name='email' type="text" value={formEmailId}
                  placeholder="Email ID"
                  {...register("email", {
                    required: true,
                    pattern: {
                      value: /\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/,
                      message: " Please enter a valid email"
                    },
                    onChange: (e) => { handleonclickEmailId(e) }
                  })}
                />
                {errors.email?.type === 'required' && <p className='text-danger mt-1'>Please enter the email</p>}
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-1'><label className='control-label font-13'>Mobile Number <span id='red'>* </span> :</label></div>
              <div className='col-2 mt-1'>
                <select name='country' className='form-control'
                  {...register("country",
                    {
                      required: true,
                      onChange: (e) => { handleonclickCountry(e) }
                    })}
                >
                  <option value="">-- select ---</option>
                  {
                    countryList.map((item, i) =>
                      <option key={i} value={item.value}>{item.label}</option>)
                  }
                </select>
                {errors.country?.type === 'required' && <p className='text-danger mt-1'>Please select the country</p>}
              </div>
              <div className='col-2 mt-1'>
                <input className='form-control' name='mobilenumber' type="text" maxLength="10" value={formMobileNumber}
                  placeholder="Mobile Number"
                  {...register("mobilenumber",
                    {
                      required: true,
                      pattern: {
                        value: /^(0|[1-9]\d*)(\.\d+)?$/,
                        message: " Please enter  number only"
                      },
                      onChange: (e) => { handleonclickMobileNumber(e) }
                    })
                  }
                />
                {errors.mobilenumber?.type === 'required' && <p className='text-danger mt-1'>Please enter mobile number</p>}
                {errors.mobilenumber && <span className='text-danger mt-1'>{errors.mobilenumber.message}</span>}
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Contact Number :</label></div>
              <div className='col-4 mt-2'>
                <input className='form-control'
                  name='contactnumber'
                  type="text"
                  placeholder="Contact Number"
                  {...register("contactnumber",
                    {
                      required: true,
                    })
                  }
                />
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Current Location <span id='red'>* </span> :</label></div>
              <div className='col-4 mt-2'>
                <select className='form-control' name='location'
                  {...register("location",
                    {
                      required: true,
                      onChange: (e) => { handleonclickLocation(e) }
                    })}
                >
                  <option value="">-- select ---</option>
                  {
                    locationList.map((item, i) =>
                      <option key={i} value={item.value}>{item.label}</option>)
                  }

                </select>
                {errors.location?.type === 'required' && <p className='text-danger mt-1'>Please select location</p>}
              </div>

              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Present Adddress :</label></div>
              <div className='col-4 mt-2'>
                <textarea className='form-control'
                  name='presentaddress'
                  {...register("presentaddress",
                    {
                      required: true,
                    })}
                />
              </div>
              <div className='col-6'></div>
            </div>
            {/* PROFESSIONAL DETAILS AREA */}
            <div className='row'>
              <div className='mt-3 ml-2 heading'>PROFESSIONAL DETAILS</div>
              <hr style={{ backgroundColor: "#1976D2", width: "1095px" }} />
              <div className='col-2'><label className='control-label font-13'>Key Skill <span id='red'>* </span> :</label></div>
              <div className='col-10'>
                <input className='form-control'
                  name='keyskill'
                  type="text"
                  placeholder="Key Skill "
                  {...register("keyskill", {
                    required: true,
                    onChange: (e) => { handleonclickkeyskill(e) }
                  })}
                />
                {errors.keyskill?.type === 'required' && <p className='text-danger mt-1'>Please enter the resume key skill</p>}
              </div>
              <div className='col-2 mt-1'><label className='control-label font-13'>Resume Title <span id='red'>* </span> :</label></div>
              <div className='col-10 mt-1'>
                <input className='form-control'
                  name='resumetitle'
                  type="text"
                  placeholder="Resume Title"
                  {...register("resumetitle", {
                    required: true,

                  })}
                />
                {errors.resumetitle?.type === 'required' && <p className='text-danger mt-1'>Please enter the resume title</p>}
              </div>
              <div className='col-2 mt-1'><label className='control-label font-13'>Experience <span id='red'>* </span> :</label></div>
              <div className='col-2 mt-1'>
                <input type="number" className="form-control" name="experienceYear" min={0} placeholder='[Year] '
                  value={experienceYear === 0 ? "" : experienceYear}
                  {...register("experienceYear",
                    {
                      required: true,
                      onChange: (e) => { handleExperienceYearChange(e) }
                    }
                  )}
                />
                {errors.experienceYear?.type === 'required' && <p className='text-danger mt-1'>Please select the years of experience</p>}
              </div>
              <div className='col-2 mt-1'>
                <input type="number" className="form-control margin-mob-top" name="experienceMonth" placeholder='[Month] ' min={0}
                  value={experienceMonth === 0 ? "" : experienceMonth}
                  {...register("experienceMonth",
                    {
                      required: true,
                      onChange: (e) => { handleExperienceMonthChange(e) }
                    }
                  )}
                />
                {errors.experienceMonth?.type === 'required' && <p className='text-danger mt-1'>Please select the months of experience</p>}
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-1'><label className='control-label font-13'>Resume <span id='red'>* </span> :</label></div>
              <div className='col-4 mt-1'>
                {isResumeDlbtnShow ? null :
                  <input type="file" accept="application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                    name='resume'
                    {...register("resume", {
                      onChange: (e) => { handleResumeUploadChange(e) }
                    })}
                  />
                }
                {
                  isResumeDlbtnShow ? <div className='mt-1'>
                    <button type='button' className='btn btn-sm btn-info' onClick={() => handleShowResumeClick()}><i className="fa fa-download"></i> Show Resume</button>&nbsp;
                    <button type='button' onClick={() => setisResumeDlbtnShow(false)} className='btn btn-sm btn-info'><i className="fa fa-cloud-upload"></i> Update Resume</button>
                  </div>
                    : null
                }

                {/* {errors.resume?.type === 'required' && <p className='text-danger mt-1'>Please Upload .doc, .docx, .pdf file upto 300 KB</p>} */}
                {isFileValid ? "" : <p className='text-danger mt-1'>{fileValidationErrorMessage}</p>}
                {requiredForResumeError === "" ? null : <p className='text-danger mt-1'>{requiredForResumeError}</p>}
              </div>
              <div className='col-6'></div>

            </div>
            {/* QUALIFICATION DETAILS AREA */}
            <div className='row'>
              <div className='mt-3 ml-2 heading'>QUALIFICATION DETAILS <span id='red'>*</span> </div>
              <hr style={{ backgroundColor: "#1976D2", width: "1095px" }} />
              <div className='col-10'>
                <table className='table table-bordered'>
                  <thead className="table-active">
                    <tr>
                      <th>Qualification</th>
                      <th>Specialization</th>
                      <th>Institute</th>
                      <th>Passing Year</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody id='Qresult'>
                    {
                      qItems.map((it, i) =>

                        <tr key={i} >
                          <td className='autoID'>{it.qual}</td>
                          <td>{it.spec}</td>
                          <td>{it.insti}</td>
                          <td>{it.year}</td>
                          <td>
                            <button type='button' className='btn btn-sm btn-info'
                              onClick={() => handleEditQualification(it.id)}><i className="fa fa-pencil" ></i>
                            </button>
                            &nbsp;
                            <button type='button' className='btn btn-sm btn-danger'
                              onClick={() => handleDeleteQualification(it.id)}><i className="fa fa-trash"></i>
                            </button>
                          </td>
                        </tr>

                      )
                    }
                  </tbody>
                </table>
              </div>
              <div className='col-2 float-right'>
                <button type='button' className='btn btn-primary btn-sm' onClick={() => addQualification()}> <i className="fa fa-plus icon-color" aria-hidden="true"></i> Add Qualification</button>
              </div>

            </div>
            {/* PERSONAL DETAILS AREA */}
            <div className=' form-group row'>
              <div className='ml-2 heading'>PERSONAL DETAILS</div>
              <hr style={{ backgroundColor: "#1976D2", width: "1095px" }} />
              <div className='col-2 mt-2'><label className='control-label font-13'>Nationality :</label></div>
              <div className='col-4 mt-2' >
                <select className='form-control' name='Nationality'
                  {...register("Nationality", {
                    required: true,
                    onChange: (e) => { handleonclickCountry(e) }
                  })}
                >
                  <option value="">-- select ---</option>
                  {
                    nationalityList.map((item, i) =>
                      <option key={i} value={item.value}>{item.label}</option>)
                  }
                </select>
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Religion :</label></div>
              <div className='col-4 mt-2'>
                <select className='form-control' name='religion'
                  {...register("religion", {
                    required: true,
                    onChange: (e) => { handleonclickReligion(e) }
                  })}
                >
                  <option value="">-- select ---</option>
                  {
                    religionList.map((item, i) =>
                      <option key={i} value={item.value}>{item.label}</option>)
                  }
                </select>
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Date Of Birth <span id='red'>* </span> :</label></div>
              <div className='col-4 mt-2'>
                <input className='form-control' name='DOB' id='dateId'
                  type="date" min="1930-01-01"
                  pattern='(0[1-9]|1[0-9]|2[0-9]|3[01])/(0[1-9]|1[012])/[0-9]{4}'
                  {...register("DOB", {
                    required: true,
                    onChange: (e) => { handleonclickDateOfBirth(e) }
                  })}
                />
                {isshowDateOFBirthErr ? <span className='text-danger'>Please enter DOB</span> : ""}
              </div>

              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Gender <span id='red'>* </span> :</label></div>
              <div className='col-4 mt-2' onChange={handleGender}>
                <input type="radio" name="gender" value="M" className='rdGen'
                  {...register("gender", {
                    required: true,
                    onChange: (e) => { handleonclickGender(e) }
                  })}
                /> Male &nbsp;
                <input type="radio" name="gender" value="F" className='rdGen'
                  {...register("gender", {
                    required: true,
                    onChange: (e) => { handleonclickGender(e) }
                  })}
                /> Female
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Marital Status :</label></div>
              <div className='col-4 mt-2'>
                <select className='form-control' name='maritalstatus'
                  {...register("maritalstatus", {
                    required: true,
                    onChange: (e) => { handleonclickMaritalStatus(e) }
                  })}
                >
                  <option>-- select ---</option>
                  {
                    maritalStatusList.map((item, i) =>
                      <option key={i} value={item.value}>{item.label}</option>)
                  }
                </select>
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Photo :</label></div>
              <div className='col-4 mt-2'>
                <input type="file" name='photo' accept="image/*"
                  {...register("photo", {

                  })}
                />
              </div>
              <div className='col-6'></div>
            </div>
            {/* OTHER DETAILS AREA */}
            <div className='row'>
              <div className='mt-3 ml-2 heading'>OTHER DETAILS</div>
              <hr style={{ backgroundColor: "#1976D2", width: "1095px" }} />
              <div className='col-2 mt-2'>
                <label className='control-label font-13 mt-1'>Functional Area <span id='red'>*</span> :</label>
              </div>
              <div className='col-4 mt-2'>
                <Select
                  name='functionalarea'
                  placeholder={"---- Select Functional Area ----"}
                  onChange={handleSelectFunctionalArea}
                  value={selectedOptionForFunctionalArea}
                  isMulti={true}
                  options={functionalAreaList}
                />
                {hasFunctionalAreaShowError ? <p className='text-danger mt-1'>Please select functionalAres</p> : ""}
                <span className="font-11">* You can select multiple max 5 Functional Areas.</span>
              </div>
              <div className='col-6 mt-2'></div>
              <div className='col-2 mt-2'>
                <label className='control-label font-13 mt-1'>Specialization :</label>
              </div>
              <div className='col-4 mt-1'>
                <Select
                  name='specialization'
                  placeholder={"---- Select Specialization ----"}
                  onChange={handleSelectFunctionalAreaSpecialization}
                  value={selectedOptionForFunctionalAreaSpecialization}
                  isMulti={true}
                  options={functionalAreaSpecializationList}
                />
                <span className="font-11">* You can select multiple max 5 Functional Areas Specialization .</span>
              </div>
              <div className='col-6 mt-1'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Passport Number :</label></div>
              <div className='col-4 mt-2'>
                <input className='form-control text-uppercase' name='passportnumbernumber' value={Formpassportnumber}
                  type="text" placeholder="Passport Number"
                  {...register("passportnumbernumber", {
                    required: true,
                  })}
                />
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Passport Details :</label></div>
              <div className='col-4 mt-2'>
                <textarea className='form-control' name='passportdetails'
                  type="text" placeholder="Passport Details"
                  {...register("passportdetails", {
                    required: true,
                  })}
                />
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Current Industry <span id='red'>* </span> :</label></div>
              <div className='col-4 mt-2'>
                <select className='form-control' name='industry'
                  {...register("industry", {
                    required: true,
                    onChange: (e) => { handleonclickIndustryList(e) }
                  })}
                >
                  <option value="">-- select ---</option>
                  {
                    IndustryList.map((item, i) =>
                      <option key={i} value={item.value}>{item.label}</option>)
                  }
                </select>
                {errors.industry?.type === 'required' && <p className='text-danger mt-1'>* You can select industry</p>}
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Current Employer:</label></div>
              <div className='col-4 mt-2'>
                <input className='form-control' name='currentemployer'
                  type="text" placeholder="Current Employer"
                  {...register("currentemployer", {
                    required: true,
                  })}
                />
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Previous Employer :</label></div>
              <div className='col-4 mt-2'>
                <input className='form-control' name='previousemployer'
                  type="text" placeholder="Previous Employer"
                  {...register("previousemployer", {
                    required: true,
                  })}
                />
              </div>
              <div className='col-6'></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Current Yearly Salary :</label></div>
              <div className='col-4 mt-2'>
                <CurrencyInput className='form-control'
                  id='currentsalary'
                  allowDecimals
                  decimalSeparator="."
                  name='currentyearlysalary'
                  placeholder="Current Yearly Salary"
                  prefix='$'
                  groupSeparator=','
                  {...register("currentyearlysalary", {
                    required: true,
                  })}
                />
              </div>
              <div className='col-6'><span id='red'>(USD)</span>&nbsp;&nbsp;
                <input type="checkbox" id='hidesalary' name='maskSalary'
                  {...register("maskSalary", {


                  })}
                />&nbsp;&nbsp;<span id='red'>Please Hide this field</span></div>
              <div className='col-2 mt-2'><label className='control-label font-13'>Overseas Exp.. :</label></div>
              <div className='col-4 mt-2'>
                <input type="checkbox" name='overseasExperience' id='hideoverseasExperience' className='overseasClass'
                  {...register("overseasExperience", {
                    onChange: (e) => { handleOverseasExperience(e) }
                  })
                  }
                />
                <textarea className="form-control" placeholder='Overseas Experience Description' id='overseasDesc' name='overseasDetails' style={{ display: "none" }}
                  rows="3"
                  cols="25"
                  {...register("overseasDetails")}
                />
              </div>
              <div className='col-6'></div>
              <div className='col-8'>
                <input type="checkbox" name='acceptTermandCondition'
                  {...register("acceptTermandCondition", {

                  })}
                /> &nbsp;
                <label className='control-label font-13'> By submitting the resume i agree to the Terms & conditions of the site<span id='red'>* </span> :</label></div>

              <div className='col-4'></div>
            </div>
            <div className='col-2 mt-2'></div>
            <div className='col-4 mt-3 mb-5' style={{ textAlign: "center" }}>
              <button type='submit' style={{ marginLeft: "28%" }} className='btn btn-sm btn btn-outline-primary mr-1'><i className='fa fa-floppy-o'></i> Submit</button>
            </div>
            <div className='col-6 mt-2'></div>

          </form>

        </div>
        <br></br>
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </Layout>
    </div>
  )
}

export default CandidateEntry