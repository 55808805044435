import React, { useState, useEffect } from 'react';
import 'react-toastify/dist/ReactToastify.css';
// import '../../../css/profileDashboard.css';
import { useForm } from "react-hook-form";
import { Navigate } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import Button from 'react-bootstrap/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import LayoutAuthUser from '../../../Layout/Layout';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { AuthService } from '../../../Services/AuthService';
import { UserService } from '../../../Services/UserService';



function ChangePassword() {

    const [openLoader, setopenLoader] = React.useState(false);
    const navigate = useNavigate();
    const [message, setmessage] = useState("");
    const { register, setValue, clearErrors, watch, formState: { errors }, handleSubmit } = useForm();
    const [isPasswordmatch, setisPasswordmatch] = useState(false);
    const [passwordStrength, setpasswordStrength] = useState(0);
    const handleChangeCancel = () => {
        if (AuthService.isAuthenticatedUser()) {
            navigate("/");
        } else {
            navigate("/404");
        }
    }
    const handlePasswordChange = (e) => {
        const strongPassword = new RegExp('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})')
        const mediumPassword = new RegExp('((?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,}))|((?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9])(?=.{8,}))')
        let password = e.target.value;
        if (password !== "" && password !== undefined && password !== null) {
            if (strongPassword.test(password)) {
                setpasswordStrength(4);
            } else if (mediumPassword.test(password)) {
                setpasswordStrength(3);
            } else {
                setpasswordStrength(2);
            }
            if (password.length <= 3) {
                setpasswordStrength(1);
            }
        } else {
            setpasswordStrength(0);
        }
    }
    const handleNewPasswordSave = async (data) => {

        setopenLoader(true);
        if (data.NewPassword === data.ConfirmNewPassword) {
            const res = await UserService.changeUserPassword(data.OldPassword, data.ConfirmNewPassword);

            if (res.isSuccess) {
                setmessage(res.message);
                ShowAlert(1, res.message);
                navigate('/');
            } else {
                setmessage(res.message);
                ShowAlert(0, res.message);
            }
        } else {

            setisPasswordmatch(true);
        }
        setopenLoader(false);
    }
    const ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    useEffect(() => {

        if (AuthService.isAuthenticatedUser()) {

        } else {
            navigate({
                pathname: '/login',
                search: "?ReturnUrl=/changePassword",
            });
        }

    }, []);
    return (
        <div>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={openLoader}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            <LayoutAuthUser>
                <div className='' style={{ marginTop: "6rem", width: "45%", marginLeft: "25%" }}>
                    <h3 className='text-center'><b>CHANGE PASSWORD </b><i className="fa fa-lock" style={{ fontSize: "28px" }}></i></h3>
                    <Card sx={{ minWidth: 100 }}>
                        <CardContent>
                            <form method='POST' onSubmit={handleSubmit(handleNewPasswordSave)}>
                                <input type="hidden" name='ID' {...register("ID")} />

                                <div className='row mb-2'>
                                    <div className='col-5'>
                                        <label className='control-label'> Old Password</label>
                                    </div>
                                    <div className='col-7'>

                                        <input type="password"
                                            name='OldPassword'
                                            className="form-control"
                                            {...register("OldPassword", { required: true })}
                                        />


                                        {errors.OldPassword?.type === 'required' && <p className='text-danger mt-1'>Please enter Old Password</p>}
                                    </div>

                                    <div className='col-5 mt-2'>
                                        <label className='control-label'> New Password</label>
                                    </div>
                                    <div className='col-7 mt-2'>

                                        <input type="password"
                                            name='NewPassword'
                                            className="form-control"
                                            {...register("NewPassword",
                                                {
                                                    required: true,
                                                    onChange: (e) => { handlePasswordChange(e) }
                                                }
                                            )}

                                        />

                                        {errors.NewPassword?.type === 'required' && <p className='text-danger mt-1'>Please enter New Password</p>}
                                        {passwordStrength === 1 ? <span className='text-danger'>Too Short</span> : ""}
                                        {passwordStrength === 2 ? <span className='text-danger'>Weak</span> : ""}
                                        {passwordStrength === 3 ? <span className='text-warning'>Medium</span> : ""}
                                        {passwordStrength === 4 ? <span className='text-success'>Strong</span> : ""}
                                    </div>
                                    <div className='col-5 mt-2'>
                                        <label className='control-label'> Confirm New Password</label>
                                    </div>
                                    <div className='col-7 mt-2'>

                                        <input type="password"
                                            name='ConfirmNewPassword'
                                            className="form-control"

                                            {...register("ConfirmNewPassword",
                                                {
                                                    required: true,
                                                    validate: (val) => {
                                                        if (watch('ConfirmNewPassword') != val) {
                                                            setisPasswordmatch(true);
                                                        } else {
                                                            setisPasswordmatch(false);
                                                        }
                                                    }
                                                })
                                            }
                                        />
                                        {errors.ConfirmNewPassword?.type === 'required' && <p className='text-danger mt-1'>Please enter New Password</p>}
                                        {isPasswordmatch ? <span className='text-danger' style={{ fontSize: "11px" }}>New Password and confirmPassword not matched</span> : null}

                                    </div>

                                </div>
                                <span className='' style={{ color: "#c0c0c0", fontSize: "12px", marginLeft: "35%" }}><span style={{ color: "red" }}>*</span> Use a mix of alphanumeric and special characters.</span>
                                <div className='mt-3 mb-2 float-right'>
                                    <Button variant="success" type='submit'><i className="fa fa-floppy-o " aria-hidden="true"></i> Save</Button> &nbsp;
                                    <Button variant="danger" onClick={handleChangeCancel}>
                                        <i className="fa fa-times " aria-hidden="true" ></i> Close
                                    </Button>

                                </div>

                            </form>

                        </CardContent>
                    </Card>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </LayoutAuthUser>
        </div>
    )
}
export default ChangePassword;