import { AuthService } from './AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "ShortListCandidate/";

const shortListToOtherJob = async (candidateIDs,jobpostingids,statusID) => {
    const resumeData = {
        CandidateIDs: candidateIDs,
        JobPostingIDs:jobpostingids,
        StatusID:parseInt(statusID),
        UserID: parseInt(AuthService.getCurrentUser().userId),
        UserTypeID: 1,
    }
    console.log(resumeData);
    const res = await fetch(baseUrl + "ShortListtoOtherJobs", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(resumeData)
    });
    const data = await res.json();
    return data;
}
const getClientList = async () => {
    const res = await fetch(baseUrl + "ClientList?UserID=" + parseInt(AuthService.getCurrentUser().userId) , {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getAssignMentOnClients = async (clientIDs) => {
    const res = await fetch(baseUrl + "AssignMentOnClients?ClientIDs="+clientIDs+"&ReferenceID=" + parseInt(AuthService.getCurrentUser().referenceID), {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getJobPostingsByassignmentId = async (assignmentId) => {
    const res = await fetch(baseUrl + "GetJobPostingByOnAssignment?assignmentIDs="+assignmentId+"&UserID=" + parseInt(AuthService.getCurrentUser().userId) , {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getStatuses = async () => {
    const res = await fetch(baseUrl + "StatusList" , {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        }
    });
    const data = await res.json();
    return data;
}
const getCandidateByID = async (canID) => {
    const res = await fetch(baseUrl + "CandidateList?xmlcandidateIDs=" + canID, {
        method: "GET",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
export const ShortListCandidateService={
    getAssignMentOnClients,
    getJobPostingsByassignmentId,
    getClientList,
    getStatuses,
    shortListToOtherJob,
    getCandidateByID
}