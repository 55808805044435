import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from 'react-router-dom';
import { AdminService } from "../../../Services/AdminService";
import "../../../css/EditCell.css";
import "../../../css/sortable.css";
import { AuthService } from "../../../Services/AuthService";
import { CommonDropDownService } from "../../../Services/CommonDropDownService";
import ReactPaginate from 'react-paginate';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { ToastContainer, toast } from 'react-toastify';
import * as XLSX from 'xlsx';
import * as FileSaver from "file-saver";
import Layout from "../../../Layout/Layout";
import Tooltip from '@mui/material/Tooltip';
import { confirmAlert } from 'react-confirm-alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Modal from "react-bootstrap/Modal";
// import CandidateComponent from '../Components/Candidates/CandidateComponent';
import NewCandidate from '../JobPosting/NewCandidate';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { saveAs } from 'file-saver';

import { AssignmentService } from "../../../Services/AssignmentService";
import { ShortListCandidateService } from "../../../Services/ShortListCandidateService";
class DynamicTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Expanded: false,
            assignmentID: "",
            candidateID: "",
            jobPostingID: "",
            dialogOpen: false,
            Open: false,
            WapOpen: false,
            MassEmailOpen: false,
            UploadDocOpen: false,
            fileViewDialogOpen: false,
            iframeSrc: "",
            searchText: "",
            recordPerPage: 10,
            isApiCalled: false,
            openLoader: true,
            startIndex: 0,
            isApiCalled: false,
            columnTypes: [
                { name: "CandidateFirstName", type: "text" },
                { name: "CandidateEmailID", type: "text" },
                { name: "CandidatePresentAddress", type: "text" },
                { name: "CandidatePermanentAddress", type: "text" },
                { name: "PassportNo", type: "text" },
                { name: "OtherDetails", type: "text" },
                { name: "NoticePeriod", type: "text" },

                { name: "CandidateTelephoneNo", type: "num" },
                { name: "CandidateMobileNo", type: "num" },
                { name: "TotalExp", type: "num" },
                { name: "IndianExp", type: "num" },
                { name: "OverseasExp", type: "num" },
                { name: "CurrentSalary", type: "num" },
                { name: "ExpectedSalary", type: "num" },

                { name: "CandidateDateOfBirth", type: "date" },

                { name: "ResumeStatusName", type: "dd" },
                { name: "CurrentLocation", type: "dd" },
                { name: "Education", type: "dd" },
            ],
            candidateListRecords: 0,
            dynamicData: [],
            coordinatorList: [],
            currentLocationList: [],
            currentinterviewLocationList:[],
            resumeStatusList: [],
            sourceList: [],
            educationList: [],

            pageCount: 0,
            DCEL: "",
            multipassportNo: "",
            FileErr: {
                HasErr: false,
                ErrMsg: ""
            },
            filePlaceHolder: "",
            documents: [],
            docTypes: [],
            docErr: "",
            docTypeErr: ""
        }
        window.onclick = e => {
            if (e.detail === 1) {
                if (this.state.DCEL !== "" && this.state.DCEL !== null && this.state.DCEL !== undefined) {
                    const dcElSp = this.state.DCEL.split(":");
                    const checkID = dcElSp[0] + dcElSp[1];
                    if (e.target.id !== checkID) {
                        const splitEl = this.state.DCEL.split(":");
                        document.getElementById(splitEl[0] + splitEl[1]).style.display = "none";
                        document.getElementById(splitEl[0] + "lbl" + splitEl[1]).style.display = "block";
                    }
                }

            }
        }
    }
    componentDidMount() {
        const queryParams = new URLSearchParams(window.location.search);
        const assignmentID = queryParams.get("qs");
        const jobPostingID = queryParams.get("jobPostingID");
        this.setState({ assignmentID: assignmentID });
        this.setState({ jobPostingID: jobPostingID });
        if (AuthService.isAuthenticatedUser()) {
            if (assignmentID !== null && assignmentID !== "" && assignmentID !== undefined) {
                this.initCandidates(0, assignmentID);
                this.initCoordinatorList();
                this.initCurrentLocation();
                this.initCurrentInterviewLocation();
                this.initResumeStatus();
                this.initEducation();
                this.initDocTypes();
            } else {
                window.location.href = "/404";
            }
        } else {
            window.location.href = "/login";
        }
    }
    handlePageClick = (event) => {
        const newOffset = (event.selected * 10) % this.state.candidateListRecords;
        this.setState({ startIndex: event.selected });
        //setItemOffset(newOffset);
        const pageSizeDDValue = document.getElementById("currentPageSizeID").value;
        this.initCandidates(event.selected, this.state.assignmentID, "", pageSizeDDValue, "");
    }
    handleSearchChange = (value) => {
        if (value !== null && value !== "" && value !== undefined) {

            this.initCandidates(0, this.state.assignmentID, value, this.state.recordPerPage);
        } else {
            this.initCandidates(0, this.state.assignmentID, null, this.state.recordPerPage);

        }
    }
    clearSearch = () => {
        this.setState({ searchText: "" });
        let searchText = document.getElementById("searchID").value = "";
        this.state.dynamicData = [];
        const pageSizeDDValue = document.getElementById("currentPageSizeID").value = 10;
        this.initCandidates(0, this.state.assignmentID, searchText, pageSizeDDValue);
    }
    handleSearchByPassportNoBtnClick = () => {

    }
    handleChange = (panel) => (event, isExpanded) => {
        this.setState({ Expanded: isExpanded ? panel : false })
    };
    initDocView = async (candidateID, canCandidateID, isNotFirstDocView) => {
        try {
            this.setState({ openLoader: true });
            const res = await AdminService.getCandidateDocumentByCandidateID(candidateID, canCandidateID, isNotFirstDocView);
            if (res.isSuccess) {
                this.setState({ DocOpen: true });
                
                if (res.apiData != null) {
                    this.setState({ documents: JSON.parse(res.apiData) })
                    console.log(JSON.parse(res.apiData));
                }
            }
            this.setState({ openLoader: false });
        } catch (error) {
            this.setState({ openLoader: false });
        }

    }
    uploadDoc = async () => {
        this.setState({ docErr: "" });
        this.setState({ docTypeErr: "" });
        this.setState({ UploadDocOpen: true });
    }
    handleSelectAllClick = (isCheckAll) => {
        const allEl = document.getElementsByClassName('item');
        if (isCheckAll) {
            for (let i = 0; i < allEl.length; i++) {
                if (allEl[i].innerText) {
                    allEl[i].classList.add('opa1');
                    allEl[i].classList.remove('opa05');
                    allEl[i].firstElementChild.firstElementChild.classList.remove('red');
                    allEl[i].firstElementChild.firstElementChild.classList.add('green');
                    allEl[i].firstElementChild.firstElementChild.classList.add('fa-check');
                    allEl[i].firstElementChild.firstElementChild.classList.remove('fa-times');
                    allEl[i].firstElementChild.firstElementChild.classList.add('opa1');
                    allEl[i].firstElementChild.firstElementChild.classList.remove('opa05');
                }
            }
        } else {
            for (let i = 0; i < allEl.length; i++) {
                if (allEl[i].innerText) {
                    allEl[i].classList.remove('opa1');
                    allEl[i].classList.add('opa05');
                    allEl[i].firstElementChild.firstElementChild.classList.remove('green');
                    allEl[i].firstElementChild.firstElementChild.classList.add('red');
                    allEl[i].firstElementChild.firstElementChild.classList.remove('fa-check');
                    allEl[i].firstElementChild.firstElementChild.classList.add('fa-times');
                    allEl[i].firstElementChild.firstElementChild.classList.remove('opa1');
                    allEl[i].firstElementChild.firstElementChild.classList.add('opa05');
                }
            }
        }
        this.handleGoClick();

    }
    handleSelectAllCheckBoxClick = (e) => {
        const allCheckEl = document.getElementsByClassName('chk-candidate');
        if (e.target.checked) {
            for (let i = 0; i < allCheckEl.length; i++) {
                allCheckEl[i].checked = true;
            }
        } else {
            for (let i = 0; i < allCheckEl.length; i++) {
                allCheckEl[i].checked = false;
            }
        }
    }
    handleRecordPerPageChange = (value) => {
        let recordPage = parseInt(value);
        let index = 0;
        const indexEL = document.getElementsByClassName("pagination");
        for (let i = 0; i < indexEL[0].children.length; i++) {
            if (indexEL[0].children[i].classList.contains("active")) {
                index = parseInt(indexEL[0].children[i].innerText);
            }
        }
        if (value !== 0 && value !== undefined) {
            this.initCandidates(0, this.state.assignmentID, '', recordPage, "");
        } else {
            this.setState({ recordPerPage: 10 })
        }
    }
    ShowAlert = (type, message) => {
        if (type === 1) {
            toast.success(message, {
                toastId: '',
            })
        } else {
            toast.error(message, {
                toastId: '',
            })
        }
    }
    handleInterviewLocChange = (e, name, canID, projID, seq) => {
        const val = e.target.value;
        if (val !== "" && val !== null && val !== undefined) {
            this.initSourceList(e.target.value);
        } else {
            this.setState({ sourceList: [] })
        }
    }
    editTableCell = (e, name, val, encCanID, encJobPostID, seq) => {
        console.log(e.target.value, val, encCanID, encJobPostID);
        this.setState({ isApiCalled: false });
        let setDlClick = name + ":" + seq;
        this.setState({ DCEL: setDlClick });
        if (name !== "" && name !== undefined && name !== null) {
            switch (name) {
                case "interviewlocation":
                    const currentElInterviewLoc = document.getElementById("interviewlocationlbl" + seq);
                    const targetElcurrentElInterviewLoc = document.getElementById("interviewlocation" + seq);
                    let optLoc = "";
                    optLoc += "<option value=''> Select </option>";
                    this.state.currentinterviewLocationList.filter(function (d) {
                        d.label.toLowerCase() === currentElInterviewLoc.innerHTML.toLowerCase() ?
                            optLoc += `<option selected="selected" value=${d.value}>${d.label}</option>`
                            : optLoc += `<option  value=${d.value}>${d.label}</option>`
                    })
                    targetElcurrentElInterviewLoc.innerHTML = optLoc;
                    currentElInterviewLoc.style.display = "none";
                    targetElcurrentElInterviewLoc.style.display = "block";
                    break;
                case "sourcename":
                    const currentElSource = document.getElementById("sourcenamelbl" + seq);
                    const targetElcurrentElSource = document.getElementById("sourcename" + seq);
                    let optSource = "";
                    optSource += "<option value=''> Select </option>";
                    this.state.sourceList.filter(function (d) {
                        d.label.toLowerCase() === currentElSource.innerHTML.toLowerCase() ?
                            optSource += `<option selected="selected" value=${d.value}>${d.label}</option>`
                            : optSource += `<option  value=${d.value}>${d.label}</option>`
                    })
                    targetElcurrentElSource.innerHTML = optSource;
                    currentElSource.style.display = "none";
                    targetElcurrentElSource.style.display = "block";
                    break;
                case "interviewdate":
                    const currentElInterviewDate = document.getElementById("interviewdatelbl" + seq);
                    const targetElInterviewDate = document.getElementById("interviewdate" + seq);
                    targetElInterviewDate.value = val;
                    currentElInterviewDate.style.display = "none";
                    targetElInterviewDate.style.display = "block";
                    break;
                case "candidatefirstname":
                    const currentElCFName = document.getElementById("candidatefirstnamelbl" + seq);
                    const targetElCFName = document.getElementById("candidatefirstname" + seq);
                    targetElCFName.value = val;
                    currentElCFName.style.display = "none";
                    targetElCFName.style.display = "block";
                    break;
                case "resumestatusname":
                    const currentElResumeName = document.getElementById("resumestatusnamelbl" + seq);
                    const targetElcurrentElResumeName = document.getElementById("resumestatusname" + seq);
                    let opt = "";
                    opt += "<option value=''> Select </option>";
                    this.state.resumeStatusList.filter(function (d) {
                        d.label.toLowerCase() === currentElResumeName.innerHTML.toLowerCase() ?
                            opt += `<option selected="selected" value=${d.value}>${d.label}</option>`
                            : opt += `<option  value=${d.value}>${d.label}</option>`
                    })
                    targetElcurrentElResumeName.innerHTML = opt;
                    currentElResumeName.style.display = "none";
                    targetElcurrentElResumeName.style.display = "block";
                    break;
                case "dobname":
                    const currentElCFDOB = document.getElementById("candidatedateofbirthlbl" + seq);
                    const targetElCFDOB = document.getElementById("dobname" + seq);
                    targetElCFDOB.value = val;
                    currentElCFDOB.style.display = "none";
                    targetElCFDOB.style.display = "block";
                    break;
                case "candidateemailIdname":
                    const currentElEmailID = document.getElementById("candidateemailIdlbl" + seq);
                    const targetElEmailID = document.getElementById("candidateemailIdname" + seq);
                    targetElEmailID.value = val;
                    currentElEmailID.style.display = "none";
                    targetElEmailID.style.display = "block";
                    break;
                case "candidatepresentaddressname":
                    const currentElPAddress = document.getElementById("candidatepresentaddresslbl" + seq);
                    const targetElPAddress = document.getElementById("candidatepresentaddressname" + seq);
                    targetElPAddress.value = val;
                    currentElPAddress.style.display = "none";
                    targetElPAddress.style.display = "block";
                    break;
                case "candidatepermanentaddressname":
                    const currentElPermanentAddress = document.getElementById("candidatepermanentaddresslbl" + seq);
                    const targetElPermanentAddress = document.getElementById("candidatepermanentaddressname" + seq);
                    targetElPermanentAddress.value = val;
                    currentElPermanentAddress.style.display = "none";
                    targetElPermanentAddress.style.display = "block";
                    break;
                case "candidatetelephonenoname":
                    const currentElTelePhoneNo = document.getElementById("candidatetelephonenolbl" + seq);
                    const targetElTelePhoneNo = document.getElementById("candidatetelephonenoname" + seq);
                    targetElTelePhoneNo.value = val;
                    currentElTelePhoneNo.style.display = "none";
                    targetElTelePhoneNo.style.display = "block";
                    break;
                case "candidatemobilenoname":
                    const currentElMobileNo = document.getElementById("candidatemobilenolbl" + seq);
                    const targetElMobileNo = document.getElementById("candidatemobilenoname" + seq);
                    targetElMobileNo.value = val;
                    currentElMobileNo.style.display = "none";
                    targetElMobileNo.style.display = "block";
                    break;
                case "passportno":
                    const currentElPPNo = document.getElementById("passportnolbl" + seq);
                    const targetElPPNo = document.getElementById("passportno" + seq);
                    targetElPPNo.value = val;
                    currentElPPNo.style.display = "none";
                    targetElPPNo.style.display = "block";
                    break;
                case "educationselectname":
                    const currentElEducation = document.getElementById("educationlbl" + seq);
                    const targetcurrentElEducation = document.getElementById("educationselectname" + seq);
                    targetcurrentElEducation.value = val;
                    currentElEducation.style.display = "none";
                    targetcurrentElEducation.style.display = "block";

                    break;
                case "totalexp":
                    const currentElTotalExp = document.getElementById("totalexplbl" + seq);
                    const targetElTotalExp = document.getElementById("totalexp" + seq);
                    targetElTotalExp.value = val;
                    currentElTotalExp.style.display = "none";
                    targetElTotalExp.style.display = "block";
                    break;
                case "indianexp":
                    const currentElIndianExp = document.getElementById("indianexplbl" + seq);
                    const targetElIndianExp = document.getElementById("indianexp" + seq);
                    targetElIndianExp.value = val;
                    currentElIndianExp.style.display = "none";
                    targetElIndianExp.style.display = "block";
                    break;
                case "overseasexp":
                    const currentElOverseasExp = document.getElementById("overseasexplbl" + seq);
                    const targetElOverSeasExp = document.getElementById("overseasexp" + seq);
                    targetElOverSeasExp.value = val;
                    currentElOverseasExp.style.display = "none";
                    targetElOverSeasExp.style.display = "block";
                    break;
                case "currentsalary":
                    const currentElCurrentSalary = document.getElementById("currentsalarylbl" + seq);
                    const targetElCurrentSalary = document.getElementById("currentsalary" + seq);
                    targetElCurrentSalary.value = val;
                    currentElCurrentSalary.style.display = "none";
                    targetElCurrentSalary.style.display = "block";
                    break;
                case "expectedsalary":
                    const currentElExpectedSalary = document.getElementById("expectedsalarylbl" + seq);
                    const targetElExpectedSalary = document.getElementById("expectedsalary" + seq);
                    targetElExpectedSalary.value = val;
                    currentElExpectedSalary.style.display = "none";
                    targetElExpectedSalary.style.display = "block";
                    break;
                case "noticeperiod":
                    const currentElNoticePeriod = document.getElementById("noticeperiodlbl" + seq);
                    const targetElNoticePeriod = document.getElementById("noticeperiod" + seq);
                    targetElNoticePeriod.value = val;
                    currentElNoticePeriod.style.display = "none";
                    targetElNoticePeriod.style.display = "block";
                    break;
                case "currentlocationselect":
                    const currentElCurrentLocation = document.getElementById("currentlocationlbl" + seq);
                    const targetELCurrentLocation = document.getElementById("currentlocationselect" + seq);
                    targetELCurrentLocation.innerHTML = val;
                    currentElCurrentLocation.style.display = "none";
                    targetELCurrentLocation.style.display = "block";
                    break;
                case "otherdetails":
                    const currentElOtherDetails = document.getElementById("otherdetailslbl" + seq);
                    const targetElOtherDetails = document.getElementById("otherdetails" + seq);
                    targetElOtherDetails.value = val;
                    currentElOtherDetails.style.display = "none";
                    targetElOtherDetails.style.display = "block";
                    break;

            }
        }
    }
    handleKeyPress = (e, name, canID, jobID, seq) => {
        if (e.key === 'Enter') {
            this.customFieldSave(e, name, canID, jobID, seq);
        }

    }
    customFieldSave = (e, name, canID, jobID, seq) => {
        console.log(e.target.value, canID, jobID, seq);
        const ReferenceID = parseInt(AuthService.getCurrentUser().referenceID);
        let ColumnName = "";
        let Value = "";
        switch (name) {
            case "interviewlocation":
                const targetElInterviewLoc = document.getElementById("interviewlocationlbl" + seq);
                const currentElInterviewLoc = document.getElementById("interviewlocation" + seq);
                let selectedInterviewLoc = currentElInterviewLoc.options[currentElInterviewLoc.selectedIndex].text;
                if (selectedInterviewLoc === "Select") {
                    selectedInterviewLoc = "";
                    Value = "";
                } else {
                    Value = e.target.value;
                }
                targetElInterviewLoc.innerHTML = selectedInterviewLoc;
                currentElInterviewLoc.style.display = "none";
                targetElInterviewLoc.style.display = "block";
                ColumnName = "interviewlocation";
                break;
            case "sourcename":
                const targetElSourceName = document.getElementById("sourcenamelbl" + seq);
                const currentElSourceName = document.getElementById("sourcename" + seq);
                let selectedSourceNameText = currentElSourceName.options[currentElSourceName.selectedIndex].text;
                if (selectedSourceNameText === "Select") {
                    selectedSourceNameText = "";
                    Value = "";
                } else {
                    Value = e.target.value;
                }
                targetElSourceName.innerHTML = selectedSourceNameText;
                currentElSourceName.style.display = "none";
                targetElSourceName.style.display = "block";
                ColumnName = "sourcename";
                break;
            case "interviewdate":
                const targetElinterviewdate = document.getElementById("interviewdatelbl" + seq);
                const currentElinterviewdate = document.getElementById("interviewdate" + seq);
                targetElinterviewdate.innerHTML = this.FormatDate(e.target.value, true);
                currentElinterviewdate.style.display = "none";
                targetElinterviewdate.style.display = "block";
                ColumnName = "interviewdate";
                Value = e.target.value;
                break;
            case "candidatefirstname":
                const targetElName = document.getElementById("candidatefirstnamelbl" + seq);
                const currentElName = document.getElementById("candidatefirstname" + seq);
                targetElName.innerHTML = e.target.value;
                currentElName.style.display = "none";
                targetElName.style.display = "block";
                ColumnName = "candidatefirstname";
                Value = e.target.value;
                break;
            case "resumestatusname":
                const targetElResumeStatus = document.getElementById("resumestatusnamelbl" + seq);
                const currentElResumeStatus = document.getElementById("resumestatusname" + seq);
                let selectedResumeStatusText = currentElResumeStatus.options[currentElResumeStatus.selectedIndex].text;
                if (selectedResumeStatusText === "Select") {
                    selectedResumeStatusText = "";
                    Value = "";
                } else {
                    Value = e.target.value;
                }
                targetElResumeStatus.innerHTML = selectedResumeStatusText;
                currentElResumeStatus.style.display = "none";
                targetElResumeStatus.style.display = "block";
                ColumnName = "resumestatusname";
                break;
            case "dobname":
                const targetElDob = document.getElementById("candidatedateofbirthlbl" + seq);
                const currentElDob = document.getElementById("dobname" + seq);
                targetElDob.innerHTML = this.FormatDate(e.target.value, true);
                currentElDob.style.display = "none";
                targetElDob.style.display = "block";
                ColumnName = "dobname";
                Value = e.target.value;
                break;
            case "candidateemailIdname":
                const targetElEmailID = document.getElementById("candidateemailIdlbl" + seq);
                const currentElEmailID = document.getElementById("candidateemailIdname" + seq);
                targetElEmailID.innerHTML = e.target.value;
                currentElEmailID.style.display = "none";
                targetElEmailID.style.display = "block";
                ColumnName = "candidateemailIdname";
                Value = e.target.value;
                break;
            case "candidatepresentaddressname":
                const targetElPresentAddress = document.getElementById("candidatepresentaddresslbl" + seq);
                const currentElPresentAddress = document.getElementById("candidatepresentaddressname" + seq);
                targetElPresentAddress.innerHTML = e.target.value;
                currentElPresentAddress.style.display = "none";
                targetElPresentAddress.style.display = "block";
                ColumnName = "candidatepresentaddressname";
                Value = e.target.value;
                break;
            case "candidatepermanentaddressname":
                const targetElPermanentAddress = document.getElementById("candidatepermanentaddresslbl" + seq);
                const currentElPermanentAddress = document.getElementById("candidatepermanentaddressname" + seq);
                targetElPermanentAddress.innerHTML = e.target.value;
                currentElPermanentAddress.style.display = "none";
                targetElPermanentAddress.style.display = "block";
                ColumnName = "candidatepermanentaddressname";
                Value = e.target.value;
                break;
            case "candidatetelephonenoname":
                const targetElTelePhoneNo = document.getElementById("candidatetelephonenolbl" + seq);
                const currentElTelePhoneNo = document.getElementById("candidatetelephonenoname" + seq);
                targetElTelePhoneNo.innerHTML = e.target.value;
                currentElTelePhoneNo.style.display = "none";
                targetElTelePhoneNo.style.display = "block";
                ColumnName = "candidatetelephonenoname";
                Value = e.target.value;
                break;
            case "candidatemobilenoname":
                const targetElMobileNo = document.getElementById("candidatemobilenolbl" + seq);
                const currentElMobileNo = document.getElementById("candidatemobilenoname" + seq);
                targetElMobileNo.innerHTML = e.target.value;
                currentElMobileNo.style.display = "none";
                targetElMobileNo.style.display = "block";
                ColumnName = "candidatemobilenoname";
                Value = e.target.value;
                break;
            case "passportno":
                const targetElPPNo = document.getElementById("passportnolbl" + seq);
                const currentElPPNo = document.getElementById("passportno" + seq);
                targetElPPNo.innerHTML = e.target.value;
                currentElPPNo.style.display = "none";
                targetElPPNo.style.display = "block";
                ColumnName = "passportno";
                Value = e.target.value;
                break;
            case "educationselectname":
                const targetElEducation = document.getElementById("educationlbl" + seq);
                const currentElEducation = document.getElementById("educationselectname" + seq);
                targetElEducation.innerHTML = e.target.value;
                currentElEducation.style.display = "none";
                targetElEducation.style.display = "block";
                ColumnName = "educationselectname";
                Value = e.target.value;
                break;
            case "totalexp":
                const targetElTotalExp = document.getElementById("totalexplbl" + seq);
                const currentElTotalExp = document.getElementById("totalexp" + seq);
                targetElTotalExp.innerHTML = e.target.value;
                currentElTotalExp.style.display = "none";
                targetElTotalExp.style.display = "block";
                ColumnName = "totalexp";
                Value = e.target.value;
                break;
            case "indianexp":
                const targetElIndianExp = document.getElementById("indianexplbl" + seq);
                const currentElIndianExp = document.getElementById("indianexp" + seq);
                targetElIndianExp.innerHTML = e.target.value;
                currentElIndianExp.style.display = "none";
                targetElIndianExp.style.display = "block";
                ColumnName = "indianexp";
                Value = e.target.value;
                break;
            case "overseasexp":
                const targetElOverseasExp = document.getElementById("overseasexplbl" + seq);
                const currentElOverseasExp = document.getElementById("overseasexp" + seq);
                targetElOverseasExp.innerHTML = e.target.value;
                currentElOverseasExp.style.display = "none";
                targetElOverseasExp.style.display = "block";
                ColumnName = "overseasexp";
                Value = e.target.value;
                break;
            case "currentsalary":
                const targetElCurrentSalary = document.getElementById("currentsalarylbl" + seq);
                const currentElCurrentSalary = document.getElementById("currentsalary" + seq);
                targetElCurrentSalary.innerHTML = e.target.value;
                currentElCurrentSalary.style.display = "none";
                targetElCurrentSalary.style.display = "block";
                ColumnName = "currentsalary";
                Value = e.target.value;
                break;
            case "expectedsalary":
                const targetElExpectedSalary = document.getElementById("expectedsalarylbl" + seq);
                const currentElExpectedSalary = document.getElementById("expectedsalary" + seq);
                targetElExpectedSalary.innerHTML = e.target.value;
                currentElExpectedSalary.style.display = "none";
                targetElExpectedSalary.style.display = "block";
                ColumnName = "expectedsalary";
                Value = e.target.value;
                break;
            case "noticeperiod":
                const targetElNoticePeriod = document.getElementById("noticeperiodlbl" + seq);
                const currentElNoticePeriod = document.getElementById("noticeperiod" + seq);
                targetElNoticePeriod.innerHTML = e.target.value;
                currentElNoticePeriod.style.display = "none";
                targetElNoticePeriod.style.display = "block";
                ColumnName = "noticeperiod";
                Value = e.target.value;
                break;
            case "currentlocationselect":
                const targetElCurrentLocation = document.getElementById("currentlocationlbl" + seq);
                const currentElCurrentLocation = document.getElementById("currentlocationselect" + seq);
                targetElCurrentLocation.innerHTML = e.target.value;
                currentElCurrentLocation.style.display = "none";
                targetElCurrentLocation.style.display = "block";
                ColumnName = "currentlocationselect";
                Value = e.target.value;
                break;
            case "otherdetails":
                const targetElOtherDetails = document.getElementById("otherdetailslbl" + seq);
                const currentElOtherDetails = document.getElementById("otherdetails" + seq);
                targetElOtherDetails.innerHTML = e.target.value;
                currentElOtherDetails.style.display = "none";
                targetElOtherDetails.style.display = "block";
                ColumnName = "otherdetails";
                Value = e.target.value;
                break;
        }
        const reqData = {
            UserID: parseInt(AuthService.getCurrentUser().userId),
            ReferenceID: ReferenceID,
            EncCandidateID: canID,
            EncJobpostingID: jobID,
            ColumnName: ColumnName,
            Value: Value ? Value.toString() : Value
        }
        let index = 0;
        const indexEL = document.getElementsByClassName("pagination");
        for (let i = 0; i < indexEL[0].children.length; i++) {
            if (indexEL[0].children[i].classList.contains("active")) {
                index = parseInt(indexEL[0].children[i].innerText);
            }
        }
        index = index - 1;
        index = index < 0 ? 0 : index;
        if (!this.state.isApiCalled) {
            this.setState({ isApiCalled: true });
            AdminService.saveCustomField(reqData).then(res => {
                console.log(res.message);
                if (res.isSuccess) {
                    let searchText = document.getElementById("searchID").value;
                    if (searchText != null && searchText != "") {
                        this.initCandidates(index, this.state.assignmentID, searchText, this.state.recordPerPage, "");
                    } else if (this.state.multipassportNo != null && this.state.multipassportNo != "") {
                        this.initCandidates(index, this.state.assignmentID, "", this.state.recordPerPage, this.state.multipassportNo);
                    } else {
                        this.initCandidates(index);
                    }
                } else {
                    this.ShowAlert(0, res.message);
                }
            })
        }
    }
    handleCandidateMoveToMobilizationClick = async () => {
        const allChkBoxesEl = document.getElementsByClassName("chk-candidate");
        let candidateEncIDs = "";
        for (let i = 0; i < allChkBoxesEl.length; i++) {
            if (allChkBoxesEl[i].checked == true) {
                candidateEncIDs += allChkBoxesEl[i].getAttribute("data-id") + ":" + allChkBoxesEl[i].getAttribute("data-jid") + ",";
            }
        }
        if (candidateEncIDs !== "" && candidateEncIDs.length > 0) {
            console.log(candidateEncIDs);
            const reqData = {
                candidates: candidateEncIDs,
                userID: parseInt(AuthService.getCurrentUser().userId)
            }
            let index = 0;
            const indexEL = document.getElementsByClassName("pagination");
            for (let i = 0; i < indexEL[0].children.length; i++) {
                if (indexEL[0].children[i].classList.contains("active")) {
                    index = parseInt(indexEL[0].children[i].innerText);
                }
            }
            index = index - 1;
            index = index < 0 ? 0 : index;
            try {
                this.setState({ openLoader: true });
                const res = await AdminService.moveToMobilization(reqData);
                if (res.isSuccess) {
                    this.ShowAlert(1, res.message);
                    this.initCandidates(index);
                } else {
                    this.ShowAlert(0, res.message);
                }
                this.setState({ openLoader: false });
            } catch (error) {
                console.log(error);
            }
        } else {
            this.ShowAlert(0, "Please select at least 1 candidate");
        }
    }
    downloadCandidateExcel = async () => {
        let hasData = false;
        this.setState({ openLoader: true });

        let searchText = document.getElementById("searchID").value;
        if (this.state.multipassportNo != null && this.state.multipassportNo != "") {
            const canList = await AdminService.getAppliedCandidates(1, this.state.assignmentID, "", this.state.recordPerPage, this.state.multipassportNo);
            const dataToJSON = JSON.parse(canList.apiData);
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            let fileName = "excel";
            try {
                const ws = XLSX.utils.json_to_sheet(dataToJSON);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExtension);
                this.setState({ openLoader: false });
            } catch (error) {
                this.setState({ openLoader: false });
                console.log(error);
            }
        } else if (searchText != null && searchText != "") {
            const canList = await AdminService.getAppliedCandidates(1, this.state.assignmentID, searchText, this.state.recordPerPage, "");
            const dataToJSON = JSON.parse(canList.apiData);
            const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
            const fileExtension = ".xlsx";
            let fileName = "excel";
            try {
                const ws = XLSX.utils.json_to_sheet(dataToJSON);
                const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExtension);
                this.setState({ openLoader: false });
            } catch (error) {
                this.setState({ openLoader: false });
                console.log(error);
            }
        } else {
            const excelApiData = await AdminService.downloadCandidateData(this.state.assignmentID);
            if (excelApiData.appliedCandidateData) {
                hasData = true;
            }
            if (hasData) {
                const dataToJSON = JSON.parse(excelApiData.appliedCandidateData);
                const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
                const fileExtension = ".xlsx";
                let fileName = "excel";

                try {
                    const ws = XLSX.utils.json_to_sheet(dataToJSON);
                    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
                    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
                    const data = new Blob([excelBuffer], { type: fileType });
                    FileSaver.saveAs(data, fileName + fileExtension);
                    this.setState({ openLoader: false });
                } catch (error) {
                    this.setState({ openLoader: false });
                    console.log(error);
                }
            } else {
                this.ShowAlert(0, excelApiData.message);
                this.setState({ openLoader: false });
            }
        }
        this.initCandidates(0, this.state.assignmentID, "", this.state.recordPerPage, "");
    }
    FormatDate = (dateString, IsSet) => {
        if (IsSet) {
            let d = new Date(dateString);
            let ld = d.toLocaleDateString();
            let rd = ld.split("/");
            let mm = rd[0].toString().length === 1 ? "0" + rd[0] : rd[0];
            let dd = rd[1].toString().length === 1 ? "0" + rd[1] : rd[1];
            let rtn = "";
            rtn += dd + "/";
            rtn += mm + "/" + rd[2];
            return rtn;
        } else {
            let dateParts = dateString.split("/");
            let d = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
            let ld = d.toLocaleDateString();
            let rd = ld.split("/");
            let rtn = "";
            rtn += rd[2] + "-";
            let mm = rd[0].toString().length === 1 ? "0" + rd[0] : rd[0];
            let dd = rd[1].toString().length === 1 ? "0" + rd[1] : rd[1];
            rtn += mm + "-" + dd;
            return rtn;
        }
    }
    handleBulkWhatsAppBtnClick = (isSingle, canID) => {

        if (isSingle) {
            this.setState({ WapOpen: true });
            setTimeout(() => {
                document.getElementById("singleCandidateID").value = canID;
            }, 100);
        } else {
            if (!this.GetValidationForWA(false)) {
                this.ShowAlert(0, "Please select at least 1 candidate");
                return false;
            }
            this.setState({ WapOpen: true });
            setTimeout(() => {
                document.getElementById("singleCandidateID").value = "";
            }, 100);
        }
    }
    GetValidationForWA = (isRtnData) => {
        const allChkBoxesEl = document.getElementsByClassName("chk-candidate");
        let checkCount = 0;
        let candidateEncIDs = "";
        for (let i = 0; i < allChkBoxesEl.length; i++) {
            if (allChkBoxesEl[i].checked == true) {
                checkCount += 1;
            }
            if (isRtnData) {
                candidateEncIDs += allChkBoxesEl[i].getAttribute("data-id") + ",";
            }
        }
        if (isRtnData) {
            if (checkCount > 0) {
                return candidateEncIDs;
            }
        } else {
            if (checkCount === 0) {
                return false;
            } else {
                return true;
            }
        }
    }
    handleWAMsgChange = (val) => {
        if (val === "" || val === undefined || val === null) {
            this.setState({ hasErrorForWAMsg: true });
        } else {
            this.setState({ hasErrorForWAMsg: false });
        }
    }
    handleWAAttachChange = () => {
        const docFileEl = document.getElementById("waAttachFileid");
        const docFile = docFileEl.files[0];
        if (docFile === "" || docFile === null || docFile === undefined) {
            this.setState({ wapAttachErr: "Please select file" });
        } else {
            if (docFile.size > 1024 * 1024 * 3) {
                this.setState({ wapAttachErr: "File size must be less than equal to 3mb" });
            } else {
                this.setState({ wapAttachErr: "" });
            }
        }
    }
    handleSendWASendMsgClick = async () => {
        const singleValue = document.getElementById("singleCandidateID").value;
        const msg = document.getElementById("waMsgId").value;
        if (msg === "" || msg === undefined || msg === null) {
            this.setState({ hasErrorForWAMsg: true });
            return false;
        } else {
            this.setState({ hasErrorForWAMsg: false });
        }
        let candidateIDs = "";
        if (singleValue !== null && singleValue !== "" && singleValue !== undefined) {
            candidateIDs = singleValue + ",";
        } else {
            candidateIDs = this.GetValidationForWA(true);
        }
        if (candidateIDs === null || candidateIDs === "") {
            this.ShowAlert(0, "Invalid candidateIds");
            return false;
        }
        const docFileEl = document.getElementById("waAttachFileid");
        const docFile = docFileEl.files[0];
        if (docFile === "" || docFile === null || docFile === undefined) {
            this.setState({ wapAttachErr: "Please select file" });
        } else {
            this.setState({ wapAttachErr: "" });
        }
        const reqData = {
            Message: msg,
            Attachment: docFile,
            CandidateIDs: candidateIDs,
            UserID: parseInt(AuthService.getCurrentUser().userId)
        }
        try {
            this.setState({ openLoader: true });
            const res = await AdminService.sendMessage(reqData);
            console.log(res);
            if (res.data.isSuccess) {
                this.setState({ WapOpen: false });
                this.ShowAlert(1, res.data.message);
            } else {
                this.ShowAlert(0, res.data.message);
            }
            this.setState({ openLoader: false });
        } catch (error) {
            this.setState({ openLoader: false });
            this.ShowAlert(0, "Error");
        }


    }
    handleMassEmailBtnClick = () => {
        const allChkBoxesEl = document.getElementsByClassName("chk-candidate");
        let candidateEncIDs = [];
        for (let i = 0; i < allChkBoxesEl.length; i++) {
            if (allChkBoxesEl[i].checked == true) {
                candidateEncIDs.push(allChkBoxesEl[i].value);
            }
        }
        if (candidateEncIDs.length > 0) {
            this.setState({ MassEmailOpen: true });
        } else {
            this.ShowAlert(0, "Please select at least 1 candidate");
        }
    }
    handleMassEmailMsgChange = (val) => {
        if (val === "" || val === undefined || val === null) {
            this.setState({ hasErrorForMassEmailMsg: true });
        } else {
            this.setState({ hasErrorForMassEmailMsg: false });
        }
    }
    handleMassEmailAttachChange = () => {
        const docFileEl = document.getElementById("mmAttachFileid");
        const docFile = docFileEl.files[0];
        if (docFile === "" || docFile === null || docFile === undefined) {
            this.setState({ attachErr: "Please select file" });
        } else {
            if (docFile.size > 1024 * 1024 * 3) {
                this.setState({ attachErr: "File size must be less than equal to 3mb" });
            } else {
                this.setState({ attachErr: "" });
            }
        }
    }
    handleSendMassEmailClick = async () => {
        const msgbody = document.getElementById("massMailMsg").value;
        if (msgbody === "" || msgbody === undefined || msgbody === null) {
            this.setState({ hasErrorForMassEmailMsg: true });
            return false;
        } else {
            this.setState({ hasErrorForMassEmailMsg: false });
        }
        const docFileEl = document.getElementById("mmAttachFileid");
        const docFile = docFileEl.files[0];
        if (docFile === "" || docFile === null || docFile === undefined) {
            this.setState({ attachErr: "Please select file" });
        } else {
            this.setState({ attachErr: "" });
        }
        const allChkBoxesEl = document.getElementsByClassName("chk-candidate");
        let candidateEncIDs = "";
        for (let i = 0; i < allChkBoxesEl.length; i++) {
            if (allChkBoxesEl[i].checked == true) {
                candidateEncIDs += allChkBoxesEl[i].getAttribute("data-id") + ",";
            }
        }
        if (candidateEncIDs === null || candidateEncIDs === "") {
            this.ShowAlert(0, "Invalid candidateIds");
            return false;
        }
        console.log(candidateEncIDs);
        let formData = new FormData();
        formData.append("UserID", parseInt(AuthService.getCurrentUser().userId));
        // formData.append("EncAssignmentID", "12359");
        formData.append("Body", msgbody);
        formData.append("CandidateIDs", candidateEncIDs);
        formData.append("Attatchment", docFile);
        try {
            this.setState({ openLoader: true });
            const res = await AdminService.massEmailSend(formData);
            console.log(res);
            if (res.data.isSuccess) {
                this.setState({ MassEmailOpen: false });
                this.ShowAlert(1, res.data.message);
            } else {
                this.setState({ MassEmailOpen: false });
                this.ShowAlert(0, res.data.message);
            }
            this.setState({ openLoader: false });
        } catch (error) {
            this.setState({ openLoader: false });
            this.ShowAlert(0, "Error");
        }
    }
    handleDocChange = () => {
        const docFileEl = document.getElementById("docID");
        const docFile = docFileEl.files[0];
        if (docFile === "" || docFile === null || docFile === undefined) {
            this.setState({ docErr: "Please select file" });
        } else {
            if (docFile.size > 1024 * 1024 * 3) {
                this.setState({ docErr: "File size must be less than equal to 3mb" });
            } else {
                this.setState({ docErr: "" });
            }
        }
    }
    handleDocTypeChange = () => {
        const docType = document.getElementById("docTypeID").value;
        if (docType === "" || docType === null || docType === undefined) {
            this.setState({ docTypeErr: "Please select docType" });
        } else {
            this.setState({ docTypeErr: "" });
        }
    }
    saveDocumentClick = async () => {
        const docFileEl = document.getElementById("docID");
        const docType = document.getElementById("docTypeID").value;
        const docFile = docFileEl.files[0];

        if (docFile === "" || docFile === null || docFile === undefined) {
            this.setState({ docErr: "Please select file" });
            return false;
        } else {
            if (docFile.size > 1024 * 1024 * 3) {
                this.setState({ docErr: "File size must be less than equal to 3mb" });
                return false;
            } else {
                this.setState({ docErr: "" });
            }
        }
        if (docType === "" || docType === null || docType === undefined) {
            this.setState({ docTypeErr: "Please select docType" });
            return false;
        } else {
            this.setState({ docTypeErr: "" });
        }
        const base64 = await this.blobToBase64DataURL(docFile);
        const formData = {
            FileName: docFile.name,
            Base64FileString: base64,
            EncCandidateID: this.state.documents[0][0].EncCandidateID,
            DocFile: docFile,
            DocType: parseInt(docType),
            UserID: parseInt(AuthService.getCurrentUser().userId)
        }
        try {
            this.setState({ openLoader: true });
            const res = await AdminService.saveOrUpdateDocument(formData);
            if (res.data.isSuccess) {
                this.setState({ UploadDocOpen: false });
                this.initDocView(this.state.documents[0][0].EncCandidateID, this.state.documents[0][0].EncCandidateID, true);
                this.ShowAlert(1, res.data.message);
            } else {
                this.ShowAlert(0, res.data.message);
            }
            this.setState({ openLoader: false });
        } catch (error) {
            this.setState({ openLoader: false });
            this.ShowAlert(0, "error");
        }
    }
    initDocTypes = async () => {
        const docTypes = await AdminService.getDocTypes();
        docTypes ? this.setState({ docTypes: docTypes }) : this.setState({ docTypes: docTypes });
    }
    initEducation = async () => {
        const educations = await CommonDropDownService.getQualification();
        if (educations) {
            this.setState({ educationList: educations })
        }
    }
    //CommonDropDownService.getCurrentLocation();currentinterviewLocationList
    initCurrentLocation = async () => {
        const clocations = await AdminService.getLocation();
        if (clocations) {
            this.setState({ currentLocationList: clocations })
        }
    }
    initCurrentInterviewLocation = async () => {
        const locations = await AdminService.getLocation();
        if (locations) {
            this.setState({ currentinterviewLocationList: locations })
        }
    }
    initResumeStatus = async () => {
        const getStatusList = await ShortListCandidateService.getStatuses();
        getStatusList ? this.setState({ resumeStatusList: getStatusList }) : this.setState({ resumeStatusList: [] });
    }
    initSourceList = async (locID) => {
        const getSrcList = await CommonDropDownService.getSourceByLocation(locID);
        getSrcList ? this.setState({ sourceList: getSrcList }) : this.setState({ sourceList: [] });
    }
    initCoordinatorList = async () => {
        // const Coordinators = await AssignmentService.getCoordinatorList(searchText);
        // if (Coordinators) {
        //     this.setState({ coordinatorList: Coordinators })
        // } 
    }
    initCandidates = async (index, assignmentID, search, recordPerPage, passportNos) => {
        try {
            this.setState({ openLoader: true });
            if (search === "") {
                search = "";
            }
            if (search === null) {
                search = this.state.searchText;
            }
            if (search === undefined) {
                search = this.state.searchText;
            }
            passportNos = passportNos ? passportNos : "";
            assignmentID = assignmentID ? assignmentID : this.state.assignmentID;
            index = index ? index : 0
            recordPerPage = recordPerPage > 0 ? recordPerPage : 10;

            const canList = await AdminService.getAppliedCandidates(index + 1, assignmentID, search, recordPerPage, passportNos);
            console.log(JSON.parse(canList.apiData));
            this.setState({
                candidateListRecords: canList.recordsTotal,
                //dynamicColumns: JSON.parse(canList.apiDataHeader),
                dynamicData: canList.apiData ? JSON.parse(canList.apiData) : [],
                pageCount: canList.recordsTotal / recordPerPage
            })

            this.setState({ openLoader: false });
        } catch (error) {
            this.setState({ openLoader: false });
            console.log(error);
        }
    };
    deleteCandidateDocument = async (encDocID) => {
        try {
            this.setState({ openLoader: true });
            const res = await AdminService.deleteCandidateDocument(encDocID);
            if (res.isSuccess) {
                this.initDocView(this.state.documents[0][0].EncCandidateID, "", true);
                this.ShowAlert(1, res.message);
            } else {
                this.ShowAlert(0, res.message);
            }
            this.setState({ openLoader: false });
        } catch (error) {
            this.ShowAlert(0, "error");
            this.setState({ openLoader: false });
        }
    }
    downloadCandidateDocument = async (encDocID) => {
        try {
            this.setState({ openLoader: true });
            const res = await AdminService.downloadCandidateDocument(encDocID);
            if (res.isSuccess) {
                this.downloadFile(atob(res.apiData), res.name, res.contentType);
            } else {
                this.ShowAlert(0, res.message);
            }
            this.setState({ openLoader: false });
        } catch (error) {
            this.ShowAlert(0, "error");
            this.setState({ openLoader: false });
        }
    }
    downloadFile = (base64, fileName, type) => {
        this.setState({ openLoader: true });
        // const sampleArr = this.base64ToArrayBuffer(base64);
        // this.saveByteArray(fileName, sampleArr, type);
        var a = document.createElement("a"); //Create <a>
        a.href = base64; //Image Base64 Goes here
        a.download = fileName; //File name Here
        a.click(); //Downloaded file
        this.setState({ openLoader: false });
    }
    viewCandidateDocument = async (encDocID) => {
        try {
            this.setState({ openLoader: true });
            const res = await AdminService.downloadCandidateDocument(encDocID);
            console.log(res);
            if (res.isSuccess) {
                const b64 = atob(res.apiData);
                const fileName = res.name;
                let fileExt = fileName.split('.').pop().toLowerCase();
                if (fileExt === 'xlsx' || fileExt === 'csv' || fileExt === 'xls' || fileExt === 'doc' || fileExt === 'docx') {
                    this.downloadFile(atob(res.apiData), res.name, res.contentType);
                } else {
                    this.viewFile(b64, res.name, res.contentType);
                }
            } else {
                this.ShowAlert(0, res.message);
            }
            this.setState({ openLoader: false });
        } catch (error) {
            this.ShowAlert(0, error);
            this.setState({ openLoader: false });
        }
    }
    viewFile = (base64, fileName, type) => {
        // this.setState({ openLoader: true });
        // const sampleArr = this.base64ToArrayBuffer(base64);
        // this.openByteArrayNewTab(fileName, sampleArr, type);
        // this.setState({ openLoader: false });

        this.setState({ iframeSrc: base64 });
        this.setState({ fileViewDialogOpen: true });
    }
    base64ToArrayBuffer = (base64) => {
        const binaryString = window.atob(base64);
        const binaryLen = binaryString.length;
        const bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            const ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };
    blobToBase64DataURL = (blob) => new Promise(
        resolvePromise => {
            const reader = new FileReader();
            reader.onload = () => resolvePromise(reader.result);
            reader.readAsDataURL(blob);
        }
    )
    saveByteArray = (fileName, byte, type) => {
        const blob = new Blob([byte], { type: type });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = fileName;
        link.click();
    };
    openByteArrayNewTab = (fileName, byte, type) => {
        const blob = new Blob([byte], { type: type });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        window.open(link.href, '_blank').focus();
    };
    handleDialogClose = () => {
        this.setState({ dialogOpen: false });
    }
    handlePassportNoSearchonChange = (val) => {
        const ppNos = document.getElementById("ppNumbers").value;
        // this.setState({ multipassportNo: ppNos });
    }
    handleSearchMultiplePassportNo = () => {
        const ppNos = document.getElementById("ppNumbers").value;
        let someText = ppNos.replace(/(\r\n|\n|\r)/gm, ",");
        let newpp = someText.split(",");
        let passportNos = "";
        for (let i = 0; i < newpp.length; i++) {
            passportNos += "'" + newpp[i].trim() + "',";
        }
        passportNos = passportNos.substring(0, passportNos.length - 1);
        this.setState({ multipassportNo: passportNos });
        if (passportNos.length > 0) {
            this.initCandidates(0, this.state.assignmentID, "", null, passportNos);
            this.setState({ dialogOpen: false });
        } else {
            this.setState({ dialogOpen: false });
        }

    }
    CloseCandidateModal = (value) => {
        if (value === 1) {
            this.setState({ openLoader: false });
            this.setState({ Open: false });
        }
    }
    CloseChildModal = (value, isRefreshList) => {
        if (value === 1) {
            this.setState({ Open: false });
        }
        if (isRefreshList) {
            this.initCandidates(0, this.state.assignmentID, "", this.state.recordPerPage);
        }
    }
    handleFileDialogClose = () => {
        this.setState({ fileViewDialogOpen: false })
    }
    render() {
        return (
            <Layout>
                <div style={{ marginTop: "6%" }}>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={this.state.openLoader}
                    >
                        <CircularProgress color="inherit" />
                    </Backdrop>
                    <Modal className='newcandidate-lg-modal'
                        size="lg"
                        style={{ marginTop: "5%" }}
                        show={this.state.Open}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>

                            <Modal.Title>
                                <span className=' mb-4'> New Candidate</span>
                            </Modal.Title>
                            <h4 className='text-center' >
                                {/* <i className={iconClass}></i> {ModalCanidateNameStatus} */}
                            </h4>
                            <button type="button" className="close"
                                onClick={() => this.setState({ Open: false })}
                            >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </Modal.Header>

                        <Modal.Body className='candidate-modal-body'>
                            <section className="header">
                                <NewCandidate
                                    GetEncAssignmentID={this.state.assignmentID}
                                    GetEncJobpostingID={this.state.jobPostingID}
                                    TriggerModalClose={this.CloseChildModal}
                                    TriggerCloseModal={this.CloseCandidateModal}
                                />
                            </section>
                        </Modal.Body>
                        <Modal.Footer >
                        </Modal.Footer>
                    </Modal>
                    <Modal
                        style={{ marginTop: "6%" }}
                        size='md'
                        show={this.state.WapOpen}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>

                            <Modal.Title>
                                <span className=' mb-4 hdcol'> <i className="fa fa-comment" aria-hidden="true"></i> Send Bulk Message</span>
                            </Modal.Title>
                            <h4 className='text-center hdcol' style={{ position: "relative", left: "38%" }}>

                            </h4>
                            <button type="button" className="close"
                                onClick={() => this.setState({ WapOpen: false })}
                            >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body >
                            <div className="row">
                                <input type="hidden" id="singleCandidateID" />
                                <div className="col-md-12">
                                    <label className="control-label">Message</label>
                                </div>
                                <div className="col-md-12">
                                    <textarea className="form-control"
                                        onChange={(e) => this.handleWAMsgChange(e.target.value)}
                                        id="waMsgId">

                                    </textarea>
                                    {this.state.hasErrorForWAMsg ? <p className="text-danger">Please enter message</p> : null}
                                </div>
                                <div className="col-md-12">
                                    <label className="control-label">Attachment</label>
                                </div>
                                <div className="col-md-12">
                                    <input type='file' className="form-control"
                                        onChange={() => this.handleWAAttachChange()}
                                        id="waAttachFileid" />
                                    <span className="text-danger">{this.state.wapAttachErr !== "" ? this.state.wapAttachErr : null}</span>
                                </div>
                                <div className="col-md-6 mt-3"></div>
                                <div className="col-md-6 mt-3">
                                    <div className="float-right">
                                        <button type="button" className="btn btn-success"
                                            onClick={() => this.handleSendWASendMsgClick()}
                                        >
                                            <i className="fa fa-comment"></i>
                                            &nbsp;Send</button>
                                        &nbsp;
                                        <button type="button" className="btn btn-warning"
                                            onClick={() => this.setState({ WapOpen: false })}
                                        >
                                            <i className="fa fa-ban" ></i> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal
                        style={{ marginTop: "6%" }}
                        size='md'
                        show={this.state.MassEmailOpen}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>

                            <Modal.Title>
                                <span className=' mb-4 hdcol'> <i className="fa fa-envelope"></i> Send Mass Email </span>
                            </Modal.Title>
                            <h4 className='text-center hdcol' style={{ position: "relative", left: "38%" }}>

                            </h4>
                            <button type="button" className="close"
                                onClick={() => this.setState({ MassEmailOpen: false })}
                            >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body >
                            <div className="row">
                                <input type="hidden" id="singleEmailCandidateID" />
                                <div className="col-md-12">
                                    <label className="control-label">Message</label>
                                </div>
                                <div className="col-md-12">
                                    <textarea className="form-control"
                                        onChange={(e) => this.handleMassEmailMsgChange(e.target.value)}
                                        id="massMailMsg">

                                    </textarea>
                                    {this.state.hasErrorForMassEmailMsg ? <p className="text-danger">Please enter message</p> : null}
                                </div>
                                <div className="col-md-12">
                                    <label className="control-label">Attachment</label>
                                </div>
                                <div className="col-md-12">
                                    <input type='file' className="form-control"
                                        onChange={() => this.handleMassEmailAttachChange()}
                                        id="mmAttachFileid" />
                                    <span className="text-danger">{this.state.attachErr !== "" ? this.state.attachErr : null}</span>
                                </div>
                                <div className="col-md-6 mt-3"></div>
                                <div className="col-md-6 mt-3">
                                    <div className="float-right">
                                        <button type="button" className="btn btn-success"
                                            onClick={() => this.handleSendMassEmailClick()}
                                        >
                                            <i className="fa fa-comment"></i>
                                            &nbsp;Send</button>
                                        &nbsp;
                                        <button type="button" className="btn btn-warning"
                                            onClick={() => this.setState({ MassEmailOpen: false })}
                                        >
                                            <i className="fa fa-ban" ></i> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    <Modal className='candidate-lg-modal'
                        size='lg'
                        show={this.state.DocOpen}
                        backdrop="static"
                        keyboard={false}
                        style={{ marginTop: "3rem" }}
                    >
                        <Modal.Header>
                            <Modal.Title>
                                <span className='mb-4 hdcol'> Candidate Documents</span>

                            </Modal.Title>
                            <button type="button" style={{ marginLeft: "56%" }} className="btn btn-primary" onClick={() => this.uploadDoc()}>Upload Document <i className="fa fa-file"></i></button>
                            <button type="button" className="close"
                                onClick={() => this.setState({ DocOpen: false, Expanded: false })}
                            >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body className='doc-modal-body' >
                            <section className="header">
                                <div className="">
                                    {
                                        this.state.documents != null ?
                                            this.state.documents.map((d, i) =>
                                                <div key={d + i}>
                                                    <Accordion key={i} expanded={this.state.Expanded === 'panel' + i} onChange={this.handleChange('panel' + i)}>
                                                        <AccordionSummary key={i + 200}
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls={"panel" + i + "bh-content"}
                                                            id={"panel" + i + "bh-header"}
                                                        >
                                                            <Typography key={i + 300} sx={{ width: '33%', flexShrink: 0 }}>
                                                                {d[0].FolderName} &nbsp;

                                                                {
                                                                    d[0].HasFile ? <i className="fa fa-check-circle-o text-success" aria-hidden="true"></i> : <i className="fa fa-ban text-danger" aria-hidden="true"></i>
                                                                }

                                                            </Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails key={i + 500}>
                                                            {

                                                                d.length >= 0 ?
                                                                    <Typography key={i + 700}>
                                                                        <div id="resp-table">
                                                                            <div id="resp-table-body">
                                                                                <div className="resp-table-row">
                                                                                    <div className="table-body-cell">
                                                                                        Doc name
                                                                                    </div>
                                                                                    <div className="table-body-cell">
                                                                                        File name
                                                                                    </div>
                                                                                    <div className="table-body-cell">
                                                                                        Action
                                                                                    </div>

                                                                                </div>
                                                                                {
                                                                                    d.map((f, j) =>
                                                                                        (f.FileName !== "" && f.FileName !== null) ?
                                                                                            <div className="resp-table-row">
                                                                                                <div className="table-body-cell">
                                                                                                    {f.DocName}
                                                                                                </div>
                                                                                                <div className="table-body-cell">
                                                                                                    {f.FileName}
                                                                                                </div>
                                                                                                <div className="table-body-cell">
                                                                                                    <button type="button" className="btn btn-sm btn-success" title="view"
                                                                                                        onClick={() => this.viewCandidateDocument(f.EncDocID)}>
                                                                                                        <i className="fa fa-file-pdf-o" aria-hidden="true"></i>
                                                                                                    </button>
                                                                                                    &nbsp;
                                                                                                    <button type="button" className="btn btn-sm btn-info" title="download"
                                                                                                        onClick={() => this.downloadCandidateDocument(f.EncDocID)} >
                                                                                                        <i className="fa fa-download" aria-hidden="true"></i>
                                                                                                    </button>
                                                                                                    &nbsp;
                                                                                                    <button type="button" className="btn btn-sm btn-danger" title="delete"
                                                                                                        onClick={() => this.deleteCandidateDocument(f.EncDocID)}>
                                                                                                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                                                                                                    </button>
                                                                                                </div>

                                                                                            </div> : null
                                                                                    )
                                                                                }

                                                                            </div>
                                                                        </div>


                                                                    </Typography>
                                                                    : null
                                                            }

                                                        </AccordionDetails>
                                                    </Accordion>

                                                </div>

                                            )
                                            : null
                                    }


                                </div>
                            </section>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>
                    {/* file view modal */}
                    <Modal className='candidate-lg-modal'
                        size='lg'
                        show={this.state.fileViewDialogOpen}
                        backdrop="static"
                        keyboard={false}
                        style={{ marginTop: "3rem" }}
                    >
                        <Modal.Header>

                            <Modal.Title>
                                <span className='mb-4 hdcol'> View Documents</span>

                            </Modal.Title>
                            <button type="button" className="close"
                                onClick={() => this.handleFileDialogClose()}
                            >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body className='doc-modal-body' >
                            <iframe width="100%" height="650px" scrolling="yes"
                                src={this.state.iframeSrc} name="imgbox" id="imgbox">
                            </iframe>
                        </Modal.Body>
                        <Modal.Footer>
                        </Modal.Footer>
                    </Modal>

                    {/* // upload doc modal */}
                    <Modal
                        size='md'
                        show={this.state.UploadDocOpen}
                        backdrop="static"
                        keyboard={false}
                    >
                        <Modal.Header>

                            <Modal.Title>
                                <span className=' mb-4 hdcol'> <i className="fa fa-file" aria-hidden="true"></i> Upload New Document</span>
                            </Modal.Title>

                            <button type="button" className="close"
                                onClick={() => this.setState({ UploadDocOpen: false })}
                            >
                                <span aria-hidden="true">×</span>
                                <span className="sr-only">Close</span>
                            </button>
                        </Modal.Header>
                        <Modal.Body >
                            <div className="row">

                                <div className="col-md-12">
                                    <label className="control-label">Document</label>
                                </div>
                                <div className="col-md-12">
                                    <input type="file" id="docID" className="form-control" onChange={() => this.handleDocChange()} />
                                    <span className="text-danger">{this.state.docErr !== "" ? this.state.docErr : null}</span>
                                </div>
                                <div className="col-md-12 mb-2 mt-2">
                                    <select className="form-control" id="docTypeID" onChange={() => this.handleDocTypeChange()}>
                                        <option value="">--- Select Doc Type ---</option>
                                        {
                                            this.state.docTypes.map((d, i) =>
                                                <option key={i} value={d.value}>{d.label}</option>
                                            )
                                        }
                                    </select>
                                    <span className="text-danger">{this.state.docTypeErr !== "" ? this.state.docTypeErr : null}</span>
                                </div>
                                <div className="col-md-6 mt-3"></div>
                                <div className="col-md-6 mt-3">
                                    <div className="float-right">
                                        <button type="button" className="btn btn-success"
                                            onClick={() => this.saveDocumentClick()}
                                        >
                                            <i className="fa fa-save"></i>
                                            &nbsp;Save</button>
                                        &nbsp;
                                        <button type="button" className="btn btn-warning"
                                            onClick={() => this.setState({ UploadDocOpen: false })}
                                        >
                                            <i className="fa fa-ban" ></i> Cancel</button>
                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>

                    {/* dialog*/}
                    <Dialog open={this.state.dialogOpen} onClose={() => { this.handleDialogClose() }}>
                        <DialogTitle>Candidate Search</DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                Candidate search By Multiple PassportNo Separated By comma<br />
                                ex-Like- ABCD1234,RTAF3456,GTS6GTTE
                            </DialogContentText>
                            <textarea className='form-control text-uppercase' id="ppNumbers" row={25} col={3} onChange={(e) => { this.handlePassportNoSearchonChange(e.target.value) }}>

                            </textarea>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { this.handleDialogClose() }}>Cancel</Button>
                            <Button onClick={() => { this.handleSearchMultiplePassportNo() }}>Search</Button>
                        </DialogActions>
                    </Dialog>
                    <div className='row'>

                        {/*  <div className='col-md-4 mt-1'>
                                 <div className="row">
                                <div className="col-md-7">
                                    <div className="file-upload">
                                        <div className="file-select">
                                            <div className="file-select-button" >Choose File</div>
                                            <div className="file-select-name" >
                                                {this.state.filePlaceHolder}
                                            </div>
                                            <input type="file" id="excelFileID" onChange={(e) => this.handleFileChange(e)} name="excel" accept='.xlsx,.csv,.xls'

                                            />

                                        </div>
                                    </div>
                                    {this.state.FileErr.HasErr ?
                                        <span style={{ fontSize: "13px" }} className="text-danger">
                                            {this.state.FileErr.ErrMsg}
                                        </span> : null}
                                </div>
                                <div className="col-md-5" style={{ marginLeft: "-6%" }}>
                                    <button type='submit' title='upload candidate' onClick={() => this.handleExcelUploadSubmit()} className='btn btn-primary'><i className="fa fa-cloud-upload" ></i> Upload</button>
                                </div>
                            </div>


                        </div> */}

                        {/* <div className='col-md-4 mt-1' >
                             <div className='row' >
                                <div className='col-md-3'>
                                    <button style={{ marginLeft: "-110%" }}
                                        className="btn btn-primary"
                                        onClick={() => this.handleBulkWhatsAppBtnClick()}
                                    >
                                        <i className="fa fa-comment"></i>
                                        &nbsp;Bulk Message
                                    </button>

                                </div>
                                <div className='col-md-3'>
                                    <button style={{ marginLeft: "-50%" }}
                                        className="btn btn-primary email-btn"
                                        onClick={() => this.handleMassEmailBtnClick()}
                                    >
                                        <i className="fa fa-envelope"></i>
                                        &nbsp;Mass Mail
                                    </button>

                                </div>

                                <div className='col-md-6' style={{ marginLeft: "-5%" }}>
                                    <input type="text" id="searchID" onChange={(e) => this.handleSearchChange(e.target.value)}
                                        placeholder='Type here to search' className="form-control" />
                                    <button type='button' className='btn  btn-warning' title='clear search' style={{ marginLeft: "102%", marginTop: "-34%" }}
                                        onClick={() => this.clearSearch()}
                                    ><i className="fa fa-times text-white"></i></button>
                                </div>
                            </div>


                        </div>  */}



                        <div className="col-md-4">
                            <div className="row">
                                <div className='col-md-7 row' >
                                    <div className="col-md-10">
                                        <input type="text" id="searchID" onChange={(e) => this.handleSearchChange(e.target.value)}
                                            placeholder='Type here to search' className="form-control" />
                                    </div>
                                    <div className="col-md-2">
                                        <button type='button' className='btn  btn-danger float-right' id="clearsearchbtn" title='clear search'
                                            onClick={() => this.clearSearch()}
                                        ><i className="fa fa-times text-white"></i></button>
                                    </div>
                                </div>
                                <div className='col-md-5 ' >
                                    <Tooltip title="search By Multiple Passport No Separated By comma" placement="bottom">
                                        <button className="btn  btn-primary float-right" onClick={() => this.setState({ dialogOpen: true })}>
                                            <i className="fa fa-search"></i>&nbsp;PassportNo
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <button
                                className="btn btn-primary"
                                onClick={() => this.handleBulkWhatsAppBtnClick()}
                            >
                                <i className="fa fa-comment"></i>
                                &nbsp;Bulk Message
                            </button>
                            &nbsp;
                            <button
                                className="btn btn-primary email-btn"
                                onClick={() => this.handleMassEmailBtnClick()}
                            >
                                <i className="fa fa-envelope"></i>
                                &nbsp;Mass Mail
                            </button>
                            &nbsp;
                            <button
                                className="btn btn-primary" title='add new candidate'
                                onClick={() => this.setState({ Open: true })}
                            >
                                <i className="fa fa-plus" ></i>
                                &nbsp;Add Candidate
                            </button>

                        </div>
                        <div className='col-md-4'>
                            &nbsp;
                            <button type='button' title='Move to Mobilization' className='btn btn-primary  mt-1' onClick={() => this.handleCandidateMoveToMobilizationClick()} > Move To Mobilization <i className="fa fa-upload" aria-hidden="true"></i></button>
                            &nbsp;
                            <button type='button' title='download candidate excel' className='btn btn-primary  mt-1 float-right mr-2' onClick={() => this.downloadCandidateExcel()}><i className="fa fa-file-excel-o" ></i> Download</button>
                        </div>

                    </div>


                    <div className="row">
                        {/* <div className="col-md-3 mt-2">
                            <div id="box" className="box mt-1">
                                {
                                    this.state.preferedColumns.map((d, i) =>
                                        <div key={1000 + i} className="item" draggable="true">
                                            <span key={i}
                                                data-id={d.ColumnID}
                                                onClick={(e) => this.handleShowHideClick(e)}
                                            >
                                                <i className={d.IsVisible ? 'fa fa-check chkbox green opa1' : 'fa fa-times chkbox red opa05'} />
                                                {d.ColumnName}
                                            </span>
                                        </div>
                                    )
                                }

                                <div className="item"></div>
                            </div>
                            <div id="itemClip" className="item itemClip hide"></div>
                        </div> */}
                        <div className="col-md-12">
                            <div className="table-wrapper" >
                                <table className="fl-table"   >
                                    <thead>
                                        <tr className="canTr">
                                            <th className="sticky-col first-col "><input type='checkbox'
                                                id="checkAll" style={{ marginLeft: "-20%" }}
                                                onClick={(e) => this.handleSelectAllCheckBoxClick(e)} /> Action</th>
                                            {/* <th data-sort=""
                                                title="click here to sort column"
                                                style={{ cursor: "pointer" }}
                                            >
                                            </th> */}
                                            <th>Mobilization Status</th>
                                            <th>CandidateFirstName		</th>
                                            <th>PassportNo	             </th>
                                            <th>CandidateMobileNo	     </th>
                                            <th>CandidateEmailID	     </th>
                                            <th>ResumeStatusName	     </th>
                                            <th>Position</th>
                                            <th>CandidateDateOfBirth	 </th>
                                            <th>CandidatePresentAddress	 </th>
                                            <th>CandidatePermanentAddress</th>
                                            <th>CandidateTelephoneNo	 </th>
                                            <th>Education	             </th>
                                            <th>TotalExp				</th>
                                            <th>IndianExp				</th>
                                            <th>OverseasExp				</th>
                                            <th>CurrentSalary	         </th>
                                            <th>ExpectedSalary	         </th>
                                            <th>NoticePeriod	         </th>
                                            <th>CurrentLocation	         </th>
                                            <th>OtherDetails             </th>
                                            <th>InterviewLocation	     </th>
                                            <th>Source		</th>
                                            <th>InterviewDate	 </th>
                                        </tr>
                                    </thead>
                                    <tbody id="tblBody">
                                        {
                                            this.state.dynamicData.map((item, i) =>
                                                <tr key={i}>
                                                    <td className="sticky-col first-col"><input type='checkbox' className="chk-candidate" data-id={item.EncCandidateID} data-jid={item.EncJobPostingID} />&nbsp;
                                                        <Tooltip title="View Document" placement="bottom">
                                                            <button className="btn btn-sm btn-warning mr-1" style={{ fontSize: "10px" }}
                                                                onClick={() => { this.initDocView(item.EncCandidateID, item.EncJobPostingID, false); }}>
                                                                <i className="fa fa-file" aria-hidden="true"></i>
                                                            </button>
                                                        </Tooltip>&nbsp;
                                                        <Tooltip title="Send Message" placement="right">
                                                            <button className="btn btn-sm btn-success mr-1" style={{ fontSize: "10px" }}
                                                                onClick={() => { this.handleBulkWhatsAppBtnClick(true, item.EncCandidateID) }} >
                                                                <i className="fa fa-comment" aria-hidden="true"></i>
                                                            </button>
                                                        </Tooltip>
                                                    </td>
                                                    <td title="Not Editable">{item.MovedToMobilization == "Done" ? <b style={{ color: "green" }}>{item.MovedToMobilization}</b> : <b style={{ color: "red" }}>{item.MovedToMobilization}</b>}</td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'candidatefirstname', item.CandidateFirstName, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input onBlur={(e) => { this.customFieldSave(e, 'candidatefirstname', item.EncCandidateID, item.EncJobPostingID, i) }} id={"candidatefirstname" + i} type='text' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'candidatefirstname', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"candidatefirstnamelbl" + i}>{item.CandidateFirstName}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'passportno', item.PassportNo, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"passportno" + i} onBlur={(e) => { this.customFieldSave(e, 'passportno', item.EncCandidateID, item.EncJobPostingID, i) }} type='text' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'passportno', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"passportnolbl" + i}>{item.PassportNo}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'candidatemobilenoname', item.CandidateMobileNo, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"candidatemobilenoname" + i} onBlur={(e) => { this.customFieldSave(e, 'candidatemobilenoname', item.EncCandidateID, item.EncJobPostingID, i) }} type='text' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'candidatemobilenoname', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"candidatemobilenolbl" + i}>{item.CandidateMobileNo}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'candidateemailIdname', item.CandidateEmailID, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"candidateemailIdname" + i} onBlur={(e) => { this.customFieldSave(e, 'candidateemailIdname', item.EncCandidateID, item.EncJobPostingID, i) }} type='text' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'candidateemailIdname', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"candidateemailIdlbl" + i}>{item.CandidateEmailID}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'resumestatusname', item.ResumeStatusName, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <select id={"resumestatusname" + i} onBlur={(e) => { this.customFieldSave(e, 'resumestatusname', item.EncCandidateID, item.EncJobPostingID, i) }} style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'resumestatusname', item.EncCandidateID, item.EncJobPostingID, i) }}
                                                        ></select>
                                                        <label id={"resumestatusnamelbl" + i} >{item.ResumeStatusName}</label>
                                                    </td>
                                                    <td>{item.JobPostingTitle}</td>

                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'dobname', item.CandidateDateOfBirth, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"dobname" + i} onBlur={(e) => { this.customFieldSave(e, 'dobname', item.EncCandidateID, item.EncJobPostingID, i) }} type='date' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'dobname', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"candidatedateofbirthlbl" + i}>{item.CandidateDateOfBirth}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'candidatepresentaddressname', item.CandidatePresentAddress, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"candidatepresentaddressname" + i} onBlur={(e) => { this.customFieldSave(e, 'candidatepresentaddressname', item.EncCandidateID, item.EncJobPostingID, i) }} type='text' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'candidatepresentaddressname', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"candidatepresentaddresslbl" + i}>{item.CandidatePresentAddress}</label>
                                                        {/* <label id={"candidatepresentaddresslbl" + i}><span dangerouslySetInnerHTML={{ __html: item.CandidatePresentAddress }} /></label> */}
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'candidatepermanentaddressname', item.CandidatePermanentAddress, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"candidatepermanentaddressname" + i} onBlur={(e) => { this.customFieldSave(e, 'candidatepermanentaddressname', item.EncCandidateID, item.EncJobPostingID, i) }} type='text' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'candidatepermanentaddressname', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"candidatepermanentaddresslbl" + i}>{item.CandidatePermanentAddress}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'candidatetelephonenoname', item.CandidateTelephoneNo, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"candidatetelephonenoname" + i} onBlur={(e) => { this.customFieldSave(e, 'candidatetelephonenoname', item.EncCandidateID, item.EncJobPostingID, i) }} type='text' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'candidatetelephonenoname', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"candidatetelephonenolbl" + i}>{item.CandidateTelephoneNo}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'educationselectname', item.Education, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input type="text" id={"educationselectname" + i} onBlur={(e) => { this.customFieldSave(e, 'educationselectname', item.EncCandidateID, item.EncJobPostingID, i) }} style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'educationselectname', item.EncCandidateID, item.EncJobPostingID, i) }}
                                                        />
                                                        <label id={"educationlbl" + i}>{item.Education}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'totalexp', item.TotalExp, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"totalexp" + i} onBlur={(e) => { this.customFieldSave(e, 'totalexp', item.EncCandidateID, item.EncJobPostingID, i) }} type='number' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'totalexp', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"totalexplbl" + i}>{item.TotalExp}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'indianexp', item.IndianExp, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"indianexp" + i} onBlur={(e) => { this.customFieldSave(e, 'indianexp', item.EncCandidateID, item.EncJobPostingID, i) }} type='number' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'indianexp', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"indianexplbl" + i}>{item.IndianExp}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'overseasexp', item.OverseasExp, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"overseasexp" + i} onBlur={(e) => { this.customFieldSave(e, 'overseasexp', item.EncCandidateID, item.EncJobPostingID, i) }} type='number' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'overseasexp', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"overseasexplbl" + i} >{item.OverseasExp}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'currentsalary', item.CurrentSalary, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"currentsalary" + i} onBlur={(e) => { this.customFieldSave(e, 'currentsalary', item.EncCandidateID, item.EncJobPostingID, i) }} type='number' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'currentsalary', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"currentsalarylbl" + i}>{item.CurrentSalary}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'expectedsalary', item.ExpectedSalary, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"expectedsalary" + i} onBlur={(e) => { this.customFieldSave(e, 'expectedsalary', item.EncCandidateID, item.EncJobPostingID, i) }} type='number' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'expectedsalary', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"expectedsalarylbl" + i}>{item.ExpectedSalary}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'noticeperiod', item.NoticePeriod, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"noticeperiod" + i} onBlur={(e) => { this.customFieldSave(e, 'noticeperiod', item.EncCandidateID, item.EncJobPostingID, i) }} type='text' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'noticeperiod', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"noticeperiodlbl" + i}>{item.NoticePeriod}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'currentlocationselect', item.CurrentLocation, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input type="text" id={"currentlocationselect" + i} onBlur={(e) => { this.customFieldSave(e, 'currentlocationselect', item.EncCandidateID, item.EncJobPostingID, i) }} style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'currentlocationselect', item.EncCandidateID, item.EncJobPostingID, i) }}
                                                        />
                                                        <label id={"currentlocationlbl" + i}>{item.CurrentLocation}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'otherdetails', item.OtherDetails, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input id={"otherdetails" + i} onBlur={(e) => { this.customFieldSave(e, 'otherdetails', item.EncCandidateID, item.EncJobPostingID, i) }} type='text' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'otherdetails', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"otherdetailslbl" + i}>{item.OtherDetails}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'interviewlocation', item.InterviewLocation, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <select id={"interviewlocation" + i} onBlur={(e) => { this.customFieldSave(e, 'interviewlocation', item.EncCandidateID, item.EncJobPostingID, i) }} style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'interviewlocation', item.EncCandidateID, item.EncJobPostingID, i) }}
                                                            onChange={(e) => { this.handleInterviewLocChange(e, 'interviewlocation', item.EncCandidateID, item.EncJobPostingID, i) }}
                                                        ></select>
                                                        <label id={"interviewlocationlbl" + i} >{item.InterviewLocation}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'sourcename', item.Source, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <select id={"sourcename" + i} onBlur={(e) => { this.customFieldSave(e, 'sourcename', item.EncCandidateID, item.EncJobPostingID, i) }} style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'sourcename', item.EncCandidateID, item.EncJobPostingID, i) }}
                                                        ></select>
                                                        <label id={"sourcenamelbl" + i} >{item.Source}</label>
                                                    </td>
                                                    <td onDoubleClick={(e) => { this.editTableCell(e, 'interviewdate', item.InterviewDate, item.EncCandidateID, item.EncJobPostingID, i) }}>
                                                        <input onBlur={(e) => { this.customFieldSave(e, 'interviewdate', item.EncCandidateID, item.EncJobPostingID, i) }} id={"interviewdate" + i} type='date' style={{ display: "none" }}
                                                            onKeyPress={(e) => { this.handleKeyPress(e, 'interviewdate', item.EncCandidateID, item.EncJobPostingID, i) }} />
                                                        <label id={"interviewdatelbl" + i}>{item.InterviewDate}</label>
                                                    </td>
                                                </tr>
                                            )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-1">

                        </div>
                        <div className="col-md-7">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel="next >"
                                onPageChange={this.handlePageClick}
                                pageCount={this.state.pageCount}
                                pageRangeDisplayed={3}
                                previousLabel="< previous"
                                pageClassName="page-item"
                                pageLinkClassName="page-link"
                                previousClassName="page-item"
                                previousLinkClassName="page-link"
                                nextClassName="page-item"
                                nextLinkClassName="page-link"
                                breakClassName="page-item"
                                breakLinkClassName="page-link"
                                containerClassName="pagination"
                                activeClassName="active"
                                renderOnZeroPageCount={null}
                            />

                        </div>
                        <div className=" col-md-2">
                            <div className="recordperPage">Page Size : &nbsp;
                                <select className="selectperPage" id="currentPageSizeID" onChange={(e) => this.handleRecordPerPageChange(e.target.value)}>
                                    <option selected="selected" value="10">10</option>
                                    <option value="20">20</option>
                                    <option value="40">40</option>
                                    <option value="60">60</option>
                                    <option value="80">80</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div className="recordTotal"> &nbsp; &nbsp;Record(s) Total : {this.state.candidateListRecords} </div>
                        </div>
                    </div>
                    <ToastContainer
                        position="top-center"
                        autoClose={3000}
                        hideProgressBar
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                    />
                </div>
            </Layout>
        );

    }
}
export default DynamicTable;