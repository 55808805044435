import { AuthService } from '../Services/AuthService';
import { DomainService } from './DomainService';
const baseUrl = DomainService.GetBaseUrl() + "SavedSearches/";
const getSavedSearches = async (StartIndex) => {
    const paginateData = {
        StartIndex: StartIndex,
        UserID: AuthService.getCurrentUser().userId
    }
    const res = await fetch(baseUrl + "GetSavedSearches", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(paginateData)
    });
    const data = await res.json();
    return data;
}
const deleteSavedSearches = async (searchRuleID) => {
    const res = await fetch(baseUrl + "DeleteSavedSearch?clientSearchRuleID="+searchRuleID, {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
    });
    const data = await res.json();
    return data;
}
const renameSavedSearches = async (modelData) => {
   
    const res = await fetch(baseUrl + "RenameSavedsearch", {
        method: "POST",
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': AuthService.getApiAuthorizationConfig()
        },
        body: JSON.stringify(modelData)
    });
    const data = await res.json();
    return data;
}


export const SavedSearchService={
    getSavedSearches,
    deleteSavedSearches,
    renameSavedSearches
}